import { globalErrorCodeMappings } from "./global";
import liveContractsErrorCodeMappings from "./live-contracts";
import usersErrorCodeMappings from "./users";
import directDebitErrorCodeMappings from "./direct-debit";
import forgotDetailsErrorCodeMappings from "./forgot-details";
import contractAmendmentsErrorCodeMappings from "./contract-amendments";
import profileErrorCodeMappings from "./profile";
import ordersErrorCodeMappings from "./orders";
import documentsErrorCodeMappings from "./documents";
import apiKeys from "../api-keys";

const errorCodeMappings = {};
errorCodeMappings[apiKeys.liveContracts] = liveContractsErrorCodeMappings;
errorCodeMappings[apiKeys.users] = usersErrorCodeMappings;
errorCodeMappings[apiKeys.directDebit] = directDebitErrorCodeMappings;
errorCodeMappings[apiKeys.forgotDetails] = forgotDetailsErrorCodeMappings;
errorCodeMappings[apiKeys.contractAmendments] =
  contractAmendmentsErrorCodeMappings;
errorCodeMappings[apiKeys.profile] = profileErrorCodeMappings;
errorCodeMappings[apiKeys.orders] = ordersErrorCodeMappings;
errorCodeMappings[apiKeys.documents] = documentsErrorCodeMappings;
errorCodeMappings[apiKeys.___global___] = globalErrorCodeMappings;

export default errorCodeMappings;
