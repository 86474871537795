import { useFormContext } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import {
  GenericTextField,
  GenericSelectField,
} from "./field-utils/field-utils.js";
import * as fieldData from "./data/field-data.js";
import PropTypes from "prop-types";

// Employment Details Fields

export const EmploymentStatusField = ({ employmentStatuses, ...props }) => {
  return (
    <GenericSelectField
      controlId="employmentStatus"
      labelText="Employment status"
      options={employmentStatuses ?? fieldData.employmentStatuses}
      placeholderOptionText="Please select an employment status"
      ariaText="Please select an employment status"
      {...props}
    />
  );
};

EmploymentStatusField.propTypes = {
  employmentStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};

export const OccupationField = (props) => {
  return (
    <GenericTextField
      controlId="occupation"
      labelText="Occupation"
      {...props}
    />
  );
};

OccupationField.propTypes = {
  occupations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};

export const EmployerNameField = (props) => {
  return (
    <GenericTextField
      controlId="employerName"
      labelText="Employer name"
      {...props}
    />
  );
};

export const TimeAtEmploymentField = () => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors, touchedFields, isSubmitted },
  } = methods;

  return (
    <Form.Group className="mb-3">
      <Form.Label className="w-100" id="timeAtEmployment">
        Time at employment (years / months)
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          type="number"
          maxLength={3}
          min={0}
          aria-labelledby="timeAtEmployment"
          id="timeAtEmploymentYears"
          isInvalid={errors.timeAtEmploymentYears}
          {...register("timeAtEmploymentYears")}
        />
        <InputGroup.Text>/</InputGroup.Text>
        <Form.Control
          type="number"
          maxLength={2}
          aria-labelledby="timeAtEmployment"
          id="timeAtEmploymentMonths"
          min={0}
          max={11}
          isInvalid={errors.timeAtEmploymentMonths}
          {...register("timeAtEmploymentMonths")}
        />
      </InputGroup>
      <Form.Control.Feedback
        type="invalid"
        className={`${
          (touchedFields["timeAtEmploymentYears"] ||
            touchedFields["timeAtEmploymentMonths"] ||
            isSubmitted) &&
          (errors.timeAtEmploymentYears || errors.timeAtEmploymentMonths)
            ? "d-block"
            : "d-none"
        }`}
      >
        {`${
          (touchedFields["timeAtEmploymentYears"] ||
            touchedFields["timeAtEmploymentMonths"] ||
            isSubmitted) &&
          (errors.timeAtEmploymentYears || errors.timeAtEmploymentMonths)
            ? "Please enter a valid time at employment."
            : ""
        }`}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
