import { Tab, Tabs } from "react-bootstrap";
import BasePage from "../basepages/basepage/BasePage";
import PersonalDetailsForm from "../../component/forms/profile/PersonalDetailsForm";
import ChangePasswordForm from "../../component/forms/profile/ChangePasswordForm";

const ProfilePage = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };

  return (
    <BasePage
      pageTitle="Customer profile"
      pageSubTitle="Keep your contact details up to date and change your password."
    >
      <Tabs
        defaultActiveKey="personal"
        id="justify-tab-personal"
        className="mb-3 ps-0 h3"
        justify
      >
        <Tab eventKey="personal" title="Personal details">
          <PersonalDetailsForm scrollTop={scrollTop} />
        </Tab>
        <Tab eventKey="password" title="Password">
          <ChangePasswordForm scrollTop={scrollTop} />
        </Tab>
      </Tabs>
    </BasePage>
  );
};

export default ProfilePage;
