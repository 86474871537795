import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollHelper = () => {
  let location = useLocation();
  const [isFirefox] = useState(() => {
    return navigator.userAgent.indexOf("Firefox") > -1;
  });
  const [page, setPage] = useState(null);
  const [hash, setHash] = useState(null);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  // Debounce
  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  // Detect window resize (debounced)
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  // Detect navigation change
  useEffect(() => {
    if (location.pathname !== page) {
      setPage(location.pathname);
    }

    if (location.hash !== hash) {
      setHash(location.hash.substring(1));
    }
  }, [location, dimensions, hash, page]);

  // Scroll to top on page change
  useEffect(() => {
    setTimeout(
      () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      },
      isFirefox ? 100 : 10
    );
  }, [page, isFirefox]);

  // Scroll to on-page element with header height offset
  useEffect(() => {
    let hashElementOffset = null;
    let headerHeight = null;
    try {
      hashElementOffset = document.getElementById(hash).offsetTop ?? null;
      headerHeight = document.getElementById("header").offsetHeight ?? null;
    } catch (error) {
      return;
    }

    setTimeout(
      () => {
        window.scrollTo({
          top: hashElementOffset - headerHeight,
          left: 0,
          behavior: "smooth",
        });
      },
      isFirefox ? 100 : 10
    );
  }, [hash, isFirefox]);
};

export default ScrollHelper;
