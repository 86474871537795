import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { log, debug } from "../../../../../utils/logging/local-log";
import actionCodes from "../../action-codes";
import {
  actionCode,
  decision,
} from "../../../../../redux/features/payments/cardPaymentSlice";
import {
  useAbandonPayment,
  useCompletePayment,
} from "../../../../../api/hooks/card-payment";

let localRegister = [];
let softDeclineCount = 0;
let shouldAbandonPayment = false;

const usePaymentGateway = (
  onPaymentSuccess,
  onPaymentFailure,
  onSetIframeHeight
) => {
  const dispatch = useDispatch();
  const sessionKey = useSelector((state) => state.cardPayment.sessionKey);
  const transactionKey = useSelector(
    (state) => state.cardPayment.transactionKey
  );

  useEffect(() => {
    localRegister = [];
    softDeclineCount = 0;
    shouldAbandonPayment = false;
  }, []);

  const getBrand = () => {
    if (window.location.host === "www.managemyvehicle.co.uk") {
      return "MMA";
    } else {
      return "LASI";
    }
  };

  const onCompleteCardPaymentSuccess = (data) => {
    try {
      if (data) {
        dispatch(actionCode(data?.actionCode));
        dispatch(decision(data?.decision));
        if (
          typeof data.decision === "string" &&
          data.decision.toUpperCase() === "REJECT" &&
          typeof data.actionCode === "string" &&
          data.actionCode.toUpperCase() === "SDL" &&
          softDeclineCount <= 2 &&
          typeof window?.top?.FreedomPay?.Apm?.ConsumerAuth?.invoke ===
            "function"
        ) {
          // Retry payment
          softDeclineCount = softDeclineCount + 1;
          window.top.FreedomPay.Apm.ConsumerAuth.invoke();
        } else if (
          typeof data.decision === "string" &&
          data.decision.toUpperCase() === "ACCEPTED"
        ) {
          window.removeEventListener("message", processEvent);
          onPaymentSuccess();
        } else {
          window.removeEventListener("message", processEvent);
          onPaymentFailure();
        }
      } else {
        dispatch(actionCode(actionCodes.generic));
        window.removeEventListener("message", processEvent);
        onPaymentFailure();
      }
    } catch (e) {
      dispatch(actionCode(actionCodes.generic));
      window.removeEventListener("message", processEvent);
      onPaymentFailure();
    }
  };

  const onCompleteCardPaymentFailure = (data) => {
    dispatch(actionCode(actionCodes.generic));
    window.removeEventListener("message", processEvent);
    onPaymentFailure();
  };
  const { mutate: mutateCompletePayment } = useCompletePayment(
    onCompleteCardPaymentSuccess,
    onCompleteCardPaymentFailure
  );

  const submitPayment = (data) => {
    localRegister = [];
    let cardNumber = data.attributes.find(
      (x) => x.Key === "MaskedCardNumber"
    ).Value;
    let issuer = data.attributes.find((x) => x.Key === "CardIssuer").Value;
    let expiry = data.attributes.find((x) => x.Key === "ExpirationDate").Value;
    let card = {
      partialPan: cardNumber,
      issuer: issuer,
      expiryDate: expiry,
    };
    const payload = {
      paymentKey: data.paymentKeys[0],
      sessionKey: sessionKey,
      brandId: getBrand(),
      card: card,
    };
    mutateCompletePayment({ transactionKey: transactionKey, payload: payload });
  };

  const onAbandonSuccess = (data) => {
    try {
      dispatch(actionCode(data.actionCode));
      dispatch(decision(data.decision));
      window.removeEventListener("message", processEvent);
      onPaymentFailure();
    } catch (e) {
      dispatch(actionCode(actionCodes.generic));
      window.removeEventListener("message", processEvent);
      onPaymentFailure();
    }
  };

  const onAbandonFailure = () => {
    dispatch(actionCode(actionCodes.generic));
    window.removeEventListener("message", processEvent);
    onPaymentFailure();
  };

  const { mutate: mutateAbandonPayment } = useAbandonPayment(
    onAbandonSuccess,
    onAbandonFailure
  );

  const abandonPayment = () => {
    const payload = {
      brandId: getBrand(),
    };
    mutateAbandonPayment({ transactionKey: transactionKey, payload: payload });
  };

  const checkIfPaymentReady = () => {
    const type16 = localRegister.find((x) => x.type === 16);
    const type3 = localRegister.find((x) => x.type === 3);
    log("CP Type 16 data: ", type16);
    log("CP Type 3 data: ", type3);
    if (type16 && type3) {
      log("Both type16 and type3 present");
      const dataToSubmit = type3.data;
      log("Data is: ", dataToSubmit);
      log("About to submit payment");
      submitPayment(dataToSubmit);
    }
  };

  const processEvent = function (event) {
    let cavv = undefined;
    let eciFlag = undefined;
    const message = event?.data;
    const data = message?.data;
    if (message) {
      switch (message.type) {
        case 1:
          log("CP case 1");
          window.removeEventListener("message", processEvent);
          dispatch(actionCode(actionCodes.generic));
          if (typeof onPaymentFailure === "function") {
            onPaymentFailure();
          }
          break;
        case 2:
          log("CP case 2");
          if (data?.height) {
            onSetIframeHeight(data.height);
          }
          break;
        case 3:
          log("CP case 3");
          localRegister.push({ type: 3, data: data });
          break;
        case 4:
          log("CP case 4");
          break;
        case 11:
          log("CP case 11");
          localRegister = [];
          break;
        case 12:
          log("CP case 12");
          break;
        case 16:
          log("CP case 16");
          localRegister.push({ type: 16, data: data });

          if (data.Payment && data.Payment.ExtendedData) {
            cavv = data.Payment.ExtendedData.CAVV;
            eciFlag = data.Payment.ExtendedData.ECIFlag;
          } else if (data.CmpiLookup) {
            cavv = data.CmpiLookup.Cavv;
            eciFlag = data.CmpiLookup.EciFlag;
          }
          if (!shouldAbandonPayment) {
            shouldAbandonPayment =
              cavv === "" && (eciFlag === "00" || eciFlag === "07");
          }
          if (shouldAbandonPayment) {
            log("Abandon payment");
            window.removeEventListener("message", processEvent);
            abandonPayment();
          }
          break;
        default:
          debug("CP case: ", message.type);
          break;
      }
    }
    if (!shouldAbandonPayment) {
      checkIfPaymentReady();
    }
  };

  useEffect(() => {
    window.addEventListener("message", processEvent);
    return () => {
      window.removeEventListener("message", processEvent);
    };
    // eslint-disable-next-line
  }, []);
};

export default usePaymentGateway;
