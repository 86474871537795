import { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import { reset } from "../../../redux/features/order-review/orderReviewSlice";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import magicStrings from "../../../utils/magic-string";
import { formatDate } from "../../../utils/pages/date-format-utils";

const OrderSignedFailurePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const { state } = useLocation();

  const signedOrderDetails = state;

  useEffect(() => {
    if (!signedOrderDetails) {
      navigate("/orders");
    }
  }, [navigate, signedOrderDetails]);

  const onViewOrders = () => {
    navigate("/orders");
  };

  return (
    <WizardBasePage
      pageTitle="There was a problem with signing your document"
      pageSubTitle=""
    >
      <TitleWithIcon title="There was a problem" variant="failure" />

      <Row>
        <Col xs={12}>
          <h2 className="text-primary mt-8">Order summary</h2>
          <p className="mt-4">
            {" "}
            There was a problem processing your documents, this may be because
            your credit application has expired, please contact your Sales
            Introducer.
          </p>
        </Col>
        <Col xs={12}>
          <dl className="table-list-row">
            <Row>
              <dt>Order number</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {signedOrderDetails?.orderNumber ?? "N/A"}
              </dd>
            </Row>
            <Row>
              <dt>Vehicle description</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {signedOrderDetails?.vehicleDescription ?? "N/A"}
              </dd>
            </Row>
            <Row>
              <dt>Date signed</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {signedOrderDetails?.dateSigned
                  ? formatDate(signedOrderDetails?.dateSigned, "ddMMyyyy")
                  : "N/A"}
              </dd>
            </Row>
          </dl>
        </Col>
      </Row>

      <CenterAlignedButtonContainer>
        <Button onClick={onViewOrders}>View orders</Button>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default OrderSignedFailurePage;
