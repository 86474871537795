import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import magicStrings from "../../utils/magic-string";
const TableStripedColumns = ({
  colConfig = [{ width: 8 }, { width: 4 }],
  tableData,
}) => {
  try {
    let initialColWidth = 0;
    if (colConfig.length >= 2 && colConfig.length <= 3) {
      const colTotalWidth = colConfig
        ?.map((col) => {
          if (!col.width || typeof col.width !== "number") {
            throw new Error(
              "width is not provided for colConfig or it is not a number"
            );
          }
          return col.width;
        })
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialColWidth
        );
      if (colTotalWidth !== 12) {
        throw new Error(
          "colConfig prop requires the total width of 12 columns"
        );
      }
    } else {
      throw new Error("colConfig required atleast two objects");
    }
  } catch (error) {
    throw error;
  }

  return (
    <>
      <Row>
        <Col className="my-3">
          <dl>
            {tableData?.map((dataItem, outerIndex) => {
              const tableDataLength = tableData.length - 1;
              return (
                <Row
                  key={outerIndex}
                  className={`border-start border-end mx-0 ${
                    outerIndex === 0 ? "border-top" : ""
                  } ${tableDataLength === outerIndex ? "border-bottom" : ""}`}
                >
                  {dataItem?.map((item, innerIndex) => {
                    const dataItemLength = dataItem.length - 1;
                    return (
                      <Fragment key={innerIndex}>
                        {innerIndex === 0 && (
                          <dt
                            className={`py-3 border-end col-${
                              colConfig[innerIndex].width
                            } ${
                              tableDataLength !== outerIndex
                                ? "border-bottom"
                                : ""
                            }`}
                          >
                            {item}
                          </dt>
                        )}
                        {innerIndex === 1 && (
                          <dd
                            className={`py-3 text-end mb-0 col-${
                              colConfig[innerIndex].width
                            } ${
                              dataItemLength === innerIndex
                                ? "bg-secondary text-white border-white"
                                : ""
                            } ${
                              tableDataLength !== outerIndex
                                ? "border-bottom"
                                : ""
                            } ${magicStrings.maskInformation}`}
                          >
                            {item}
                          </dd>
                        )}
                        {dataItem.length >= 3 &&
                          colConfig.length > 2 &&
                          innerIndex === 2 && (
                            <dd
                              className={`py-3 text-end mb-0 bg-secondary text-white border-white col-${
                                colConfig[innerIndex].width
                              } ${
                                tableDataLength !== outerIndex
                                  ? "border-bottom"
                                  : ""
                              } ${magicStrings.maskInformation}`}
                            >
                              {item}
                            </dd>
                          )}
                      </Fragment>
                    );
                  })}
                </Row>
              );
            })}
          </dl>
        </Col>
      </Row>
    </>
  );
};

TableStripedColumns.propTypes = {
  colConfig: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number.isRequired,
    }).isRequired
  ),
  tableData: PropTypes.array.isRequired,
};

export default TableStripedColumns;
