import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import {
  pageTitle,
  pageSubTitle,
} from "../../utils/contract-amends-page-utils";
import ContractAmendsStepper from "../../contract-amends-stepper/ContractAmendsStepper";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import useContractAmendsStepCheck from "../../hooks/useContractAmendsStepCheck";
import steps from "../../contract-amends-steps";
import {
  reset,
  step,
  partners as dispatchPartners,
  currentPartnerIndex as dispatchCurrentPartnerIndex,
} from "../../../../redux/features/contract-amends/contractAmendsSlice";
import TitleWithIcon from "../../../../component/title-with-icon/TitleWithIcon";

const PartnerErrorPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useContractAmendsStepCheck(
    steps.bch.limitedCompany.partnerError,
    "../../save-quote"
  );

  const partnerType = useSelector((state) => state.contractAmends.partnerType);
  const partners = useSelector((state) => state.contractAmends.partners);
  const currentPartnerIndex = useSelector(
    (state) => state.contractAmends.currentPartnerIndex
  );

  const onCancelApplication = () => {
    dispatch(reset());
    navigate("/");
  };

  const onAddNewPartner = () => {
    const partnersToSave = [...partners];
    partnersToSave.push({
      personalDetails: {},
      addressDetails: {},
      isNewPartner: true,
      isNewPartnerSaved: false,
    });
    const indexToSave =
      currentPartnerIndex + 1 >= partnersToSave.length
        ? partnersToSave.length - 1
        : currentPartnerIndex + 1;
    dispatch(dispatchCurrentPartnerIndex(indexToSave));
    dispatch(
      step(steps.bch.limitedCompany.partners[indexToSave].personalDetails)
    );
    dispatch(dispatchPartners(partnersToSave));
    navigate("../personal-details", { replace: true });
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <div className="text-center">
        <TitleWithIcon title="We need more details" variant="failure" />
        <CenterAlignedButtonContainer className="mt-0">
          <div>
            We need more information about the people who are responsible for
            your business. To continue please select to add a new {partnerType}.
          </div>

          <br />
          <Button id="btnAddNewPartner" onClick={onAddNewPartner}>
            Add a new {partnerType}
          </Button>
          <Button
            id="btnCancelApplication"
            variant="outline-primary"
            onClick={onCancelApplication}
          >
            Cancel application
          </Button>
        </CenterAlignedButtonContainer>
      </div>
    </WizardBasePage>
  );
};

export default PartnerErrorPage;
