import { useFormContext } from "react-hook-form";
import { Form, InputGroup, Button } from "react-bootstrap";
import {
  GenericTextField,
  GenericSelectField,
} from "./field-utils/field-utils.js";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { postCodePattern } from "../schemas/fields/address-schema.js";
import { trimStartOnChange } from "../../../utils/form/form-text-utils";
import magicStrings from "../../../utils/magic-string.js";
// Address Finder Fields

export const PostCodeField = ({
  onAddressSearch,
  showSearchButton = true,
  registerAttributes,
}) => {
  const methods = useFormContext();
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitted, touchedFields },
  } = methods;

  const postCodeValue = watch("postCode");

  useEffect(() => {
    const initialPostcodeValue = getValues("postCode");
    if (initialPostcodeValue) {
      setValue("postCode", initialPostcodeValue, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [getValues, setValue]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (postCodePattern.test(postCodeValue) && showSearchButton) {
        event.preventDefault();
        onAddressSearch();
      }
    }
  };

  const localRegisterAttributes = {
    ...{
      onChange: trimStartOnChange,
    },
    registerAttributes,
  };

  return (
    <Form.Group controlId="postCode">
      <Form.Label className="w-100">Postcode</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          type="string"
          {...register("postCode", localRegisterAttributes)}
          isInvalid={errors.postCode}
          maxLength={8}
          onKeyDown={handleKeyPress}
        />
        {showSearchButton && (
          <Button
            disabled={
              (!isSubmitted &&
                !touchedFields["postCode"] &&
                !postCodePattern.test(postCodeValue)) ||
              errors.postCode
            }
            onClick={onAddressSearch}
          >
            Search
          </Button>
        )}
        <Form.Control.Feedback type="invalid">
          {errors.postCode?.message}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

PostCodeField.propTypes = {
  onAddressSearch: PropTypes.func,
};

export const SelectAddressField = ({ addresses, ...props }) => {
  return (
    <GenericSelectField
      className={`${magicStrings.maskInformation}`}
      controlId="selectAddress"
      labelText="Select an address"
      options={addresses ?? []}
      placeholderOptionText="Please select an address"
      ariaText="Please select an address"
      {...props}
    />
  );
};

SelectAddressField.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

// Address Fields

export const AddressLine1Field = (props) => {
  return (
    <GenericTextField
      controlId="addressLine1"
      labelText="Address line 1"
      {...props}
    />
  );
};

export const AddressLine2Field = (props) => {
  return (
    <GenericTextField
      controlId="addressLine2"
      labelText="Address line 2"
      {...props}
    />
  );
};

export const AddressLine3Field = (props) => {
  return (
    <GenericTextField
      controlId="addressLine3"
      labelText="Address line 3"
      {...props}
    />
  );
};

export const CityField = (props) => {
  return <GenericTextField controlId="city" labelText="City/Town" {...props} />;
};

export const CountyField = (props) => {
  return <GenericTextField controlId="county" labelText="County" {...props} />;
};

export const TimeAtAddressField = () => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors, touchedFields, isSubmitted },
  } = methods;

  return (
    <Form.Group className="mb-3">
      <Form.Label className="w-100" id="timeAtAddress">
        Time at address (years / months)
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          type="number"
          aria-labelledby="timeAtAddress"
          id="timeAtAddressYears"
          isInvalid={errors.timeAtAddressYears}
          {...register("timeAtAddressYears")}
        />
        <InputGroup.Text>/</InputGroup.Text>
        <Form.Control
          type="number"
          aria-labelledby="timeAtAddress"
          id="timeAtAddressMonths"
          isInvalid={errors.timeAtAddressMonths}
          {...register("timeAtAddressMonths")}
        />
      </InputGroup>
      <Form.Control.Feedback
        type="invalid"
        className={`${
          (isSubmitted ||
            touchedFields["timeAtAddressYears"] ||
            touchedFields["timeAtAddressMonths"]) &&
          (errors.timeAtAddressYears || errors.timeAtAddressMonths)
            ? "d-block"
            : "d-none"
        }`}
      >
        {`${
          (isSubmitted ||
            touchedFields["timeAtAddressYears"] ||
            touchedFields["timeAtAddressMonths"]) &&
          errors.timeAtAddress
            ? "Please enter a valid time at address."
            : ""
        }`}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
