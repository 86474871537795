// All documents related api error has need to map in the range of 1740001 to 1799999
// The number is divided into 3 parts
// First part is the api key range, for documents will always be 17
// Second part is the http error status code e.g 400
// Last part is the identifier for the unique error message we want to display.
// Two server api error codes are okay to be mapped to single error code, if required.

const documentsErrorCodeMappings = {
  400: {
    CODE_INVALID_SIGNING_NAME: 1740001,
  },
  404: {
    CODE_ORDER_NOT_FOUND: 1740401,
    CODE_DOCUMENT_NOT_FOUND: 1740402,
    CODE_DOCUMENT_NOT_AVAILABLE: 1740402,
  },
  500: {
    default: 1750001,
  },
  // default is used to handle the case where it is an unknown http status code
  default: {
    default: 1799901,
    // This default is the absolute fallback error code for documents api.
  },
};

export default documentsErrorCodeMappings;
