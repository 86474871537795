import { DateTime } from "luxon";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse, Button, Alert } from "react-bootstrap";
import BasePage from "../basepages/basepage/BasePage";
import InvoiceDocumentIcon from "../../component/icons/InvoiceDocumentIcon";
import ArrowDownIcon from "../../component/icons/ArrowDownIcon";
import EmptyStateIcon from "../../component/icons/EmptyStateIcon";
import MessageBox from "../../component/modals/message-box/MessageBox";
import { openPdfDocument } from "../../utils/pdf/pdf-utils";
import { useInvoices, useSupportingDocument } from "../../api/hooks/invoices";
import magicStrings from "../../utils/magic-string";
import PageHeading from "../../component/page-heading/PageHeading";

const InvoicesPage = () => {
  const { invoices, isError } = useInvoices();
  const [maxInvoice, setMaxInvoice] = useState(10);
  const [openCollapse, setOpenCollapse] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [documentDetail, setDocumentDetail] = useState({});
  const [pdfContent, setPdfContent] = useState();
  const { isError: isInvoiceDocumentError, refetch } = useSupportingDocument(
    documentDetail?.referenceNumber,
    documentDetail?.documentName
  );

  useEffect(() => {
    if (documentDetail?.documentName) {
      refetch().then((response) => {
        if (response.isError) {
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }, 100);
        } else {
          setOpenPopup(true);
          setPdfContent(response.data.content);
        }
      });
    }
  }, [refetch, documentDetail?.referenceNumber, documentDetail?.documentName]);

  const expandCollapse = (e) => {
    setOpenCollapse(e.target.id === openCollapse ? "" : e.target.id);
  };

  const loadMoreInvoice = () => {
    setMaxInvoice(maxInvoice + 10);
  };

  const convertDateFormat = (date) => {
    return DateTime.fromISO(date).toFormat("dd-MM-yyyy");
  };

  const openSupportingDocument = (buttonId) => {
    setOpenPopup(false);
    if (buttonId === 1) {
      openPdfDocument(pdfContent, "Invoice.pdf");
    }
    setDocumentDetail({});
  };

  const accordionIcon = (transaction) => {
    const referenceNo = transaction.referenceNumber;
    return (
      <Button
        id={referenceNo}
        variant="link"
        className="text-decoration-none text-secondary"
        onClick={(event) => expandCollapse(event)}
      >
        <ArrowDownIcon
          title="Accordion Icon"
          pointerEvents="none"
          className={`collapse-icon-transition ${
            openCollapse === referenceNo ? "collapse-reverse-transition" : ""
          }`}
          size="xs"
        />
      </Button>
    );
  };

  const supportingDocuments = (invoice) => {
    return (
      invoice &&
      invoice?.documents?.length > 0 &&
      invoice.documents.map((doc, index) => {
        return (
          <div key={index}>
            {doc.documentMedia !== "TIF" && (
              <>
                <InvoiceDocumentIcon
                  title={doc.documentType}
                  width="10"
                  height="15"
                  className="text-black"
                />
                {doc.documentMedia === "PDF" &&
                  doc.documentType === "Invoice" && (
                    <Link
                      href="#!"
                      title="Invoice - Link opens in a new window"
                      className="m-1 p-0"
                      onClick={() =>
                        setDocumentDetail({
                          referenceNumber: invoice.referenceNumber,
                          documentName: doc.documentName,
                        })
                      }
                    >
                      Invoice
                    </Link>
                  )}
                {doc.documentMedia === "PDF" &&
                  doc.documentType === "SupportingDoc" && (
                    <Link
                      href="#!"
                      title="Supporting information - Link opens in a new window"
                      className=" m-1 p-0"
                      onClick={() =>
                        setDocumentDetail({
                          referenceNumber: invoice.referenceNumber,
                          documentName: doc.documentName,
                        })
                      }
                    >
                      Supporting information
                    </Link>
                  )}
                {doc.documentMedia === "BundleUrl" &&
                  doc.documentType === "SupportingDoc" && (
                    <a
                      href={`https://endofcontractimages.lexautolease.co.uk/view-damages/${doc.documentName}`}
                      title="Supporting Information - Link opens in a new window"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="m-1 p-0"
                    >
                      Supporting information
                    </a>
                  )}
              </>
            )}
          </div>
        );
      })
    );
  };

  return (
    <BasePage
      pageTitle="Invoices"
      pageSubTitle="View and download your invoices and supporting documents."
    >
      {(isError || isInvoiceDocumentError) && (
        <Alert variant="danger">
          We are sorry but we are unable to retrieve your invoice details.
          Please try again and if the problem continues contact us.
        </Alert>
      )}
      {invoices && invoices?.length > 0 ? (
        <>
          <MessageBox
            title="Document ready"
            show={openPopup}
            onClose={openSupportingDocument}
            buttons={["Open", "Cancel"]}
          >
            Your document is ready. Please press "Open" to download and view it.
          </MessageBox>
          <PageHeading align="left">Invoice history</PageHeading>

          <p className="mb-5">
            View your invoices and download your supporting documents.
          </p>
          <table className="table">
            <thead>
              <tr className="d-none d-lg-table-row text-start fw-bold border-top align-middle">
                <td className="text-start py-3">Invoice number</td>
                <td>Type</td>
                <td>Invoice date</td>
                <td>Invoice amount</td>
                <td>Supporting documents</td>
              </tr>
              <tr className="d-table-row d-lg-none fw-bold border-top">
                <td className="py-3">Invoice date</td>
                <td className="text-end py-3">
                  <div className="px-5 mx-3">Invoice amount</div>
                </td>
              </tr>
            </thead>
            <tbody>
              {invoices.slice(0, maxInvoice).map((invoice) => {
                const referenceNo = invoice.referenceNumber;
                const transactionDate = convertDateFormat(
                  invoice.transactionDate
                );
                const totalAmount = Math.abs(invoice.totalAmount)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                const type = invoice.type;
                return (
                  <Fragment key={referenceNo}>
                    <tr className="d-table-row d-lg-none">
                      <td colSpan="2" className="py-3">
                        <div className="d-flex justify-content-between">
                          <div className={`${magicStrings.maskInformation}`}>
                            {transactionDate}
                          </div>
                          <div className="text-end">
                            <div
                              className={`px-5 pound d-inline-block ${magicStrings.maskInformation}`}
                            >
                              {totalAmount}
                            </div>
                            <div className="d-inline-block">
                              {accordionIcon(invoice)}
                            </div>
                          </div>
                        </div>
                        <Collapse in={openCollapse === referenceNo}>
                          <dl className="py-1 my-0 row">
                            <dt className="col-sm-5">Invoice number</dt>
                            <dd
                              className={`col-sm-7 ${magicStrings.maskInformation}`}
                            >
                              {referenceNo}
                            </dd>
                            <dt className="col-sm-5">Invoice description</dt>
                            <dd
                              className={`col-sm-7 ${magicStrings.maskInformation}`}
                            >
                              {type}
                            </dd>
                            {invoice?.documents?.length > 0 && (
                              <>
                                <dt className="col-sm-5">Documents</dt>
                                <dd
                                  className={`col-sm-7 ${magicStrings.maskInformation}`}
                                >
                                  {supportingDocuments(invoice)}
                                </dd>
                              </>
                            )}
                          </dl>
                        </Collapse>
                      </td>
                    </tr>
                    <tr
                      data-testid="invoice"
                      className="d-none d-lg-table-row text-start align-middle"
                    >
                      <td
                        width="20%"
                        className={`py-3 ${magicStrings.maskInformation}`}
                      >
                        {referenceNo}
                      </td>
                      <td
                        width="15%"
                        className={`${magicStrings.maskInformation}`}
                      >
                        {type}
                      </td>
                      <td
                        width="18%"
                        className={`${magicStrings.maskInformation}`}
                      >
                        {transactionDate}
                      </td>
                      <td
                        width="17%"
                        className={`pound ${magicStrings.maskInformation}`}
                      >
                        {totalAmount}
                      </td>
                      <td
                        width="30%"
                        className={`${magicStrings.maskInformation}`}
                      >
                        {supportingDocuments(invoice)}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
              {maxInvoice < invoices?.length && (
                <tr>
                  <td colSpan="5">
                    <div className="mx-auto text-center">
                      <Button
                        variant="link"
                        id="btnLoadMore"
                        className="text-decoration-none text-primary"
                        onClick={loadMoreInvoice}
                      >
                        <h2 className="h4 p-0 m-0">Load more</h2>
                        <ArrowDownIcon title="Load more" size="xs" />
                      </Button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      ) : (
        invoices?.length === 0 && (
          <EmptyStateIcon
            title="No invoice"
            textContent="You currently have no invoices"
          />
        )
      )}

      <h2 className="mt-5">Easy guide list of terms</h2>
      <p>
        If you need support with the words used in your invoice, take a look at
        our 'easy guide list of terms' to help you understand. If you'd like a
        printed copy of the document sent to you in the post, please call us on{" "}
        <Link to="tel: 0344 879 6633">0344 879 6633</Link>, (Open Monday -
        Friday, 9am - 5pm. We're closed on weekends and bank holidays).
      </p>
      <p>
        <Link
          target="_blank"
          to="https://www.lexautolease.co.uk/content/bbp/repositories/64bd0369-dde0-4429-bd8f-59ed913de60d?path=%2FPDF%2Flexeasyread.pdf"
          rel="noopener noreferrer"
        >
          View our easy guide list of terms here {">"}
        </Link>{" "}
        (PDF, 726 KB). Link will open in new window.
      </p>
    </BasePage>
  );
};

export default InvoicesPage;
