import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const DirectDebitIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    aria-label={title}
    viewBox="0 0 30 34"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="direct-debit-icon_svg__a"
        d="M30 4.5h-1.5v-3h-3v3h-15v-3h-3v3H6c-1.65 0-3 1.35-3 3v24c0 1.65 1.35 3 3 3h24c1.65 0 3-1.35 3-3v-24c0-1.65-1.35-3-3-3Zm0 27H6V15h24v16.5ZM30 12H6V7.5h24V12Z"
      />
    </defs>
    <use
      xlinkHref="#direct-debit-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-3 -1)"
    />
  </svg>
);

DirectDebitIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { DirectDebitIcon };
export default DirectDebitIcon;
