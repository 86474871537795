import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
  const isFetching = useIsFetching({
    predicate: (query) => {
      let isMatching = true;
      if (
        query &&
        Array.isArray(query.queryKey) &&
        query.queryKey.length > 0 &&
        (query.queryKey[0] === "account-notifications" ||
          query.queryKey[0] === "account-balance")
      ) {
        isMatching = false;
      }
      return isMatching;
    },
  });
  const isMutating = useIsMutating();
  if (!isFetching && !isMutating) {
    return null;
  }
  return (
    <div className="modal-backdrop show d-flex justify-content-center align-items-center">
      <Spinner role="status" className="text-primary" id="spinner">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default LoadingSpinner;
