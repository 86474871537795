const magicStrings = {
  notApplicable: "NA",
  maskInformation: "data-cs-mask",
  sessionExpired: "SESSION_EXPIRED",
  inactivityTimeout: "INACTVITY_TIMEOUT",
  //Account gets locked when IIQ & Identity Check incorrect atttempt limits reached
  accountLocked: "USER_LOCKED",
};

export default magicStrings;
