import { Route, Routes } from "react-router-dom";
import ErrorPageNotFoundPage from "../pages/error/page-not-found/ErrorPageNotFoundPage";
import ForgotLoginDetailsPage from "../pages/forgotten-details/ForgotLoginDetailsPage";
import ForgotPasswordPage from "../pages/forgotten-details/forgot-password/ForgotPasswordPage";
import ForgotUserIdPage from "../pages/forgotten-details/forgot-user-id/ForgotUserIdPage";
import ForgotUserIdAndPasswordPage from "../pages/forgotten-details/forgot-user-id-and-password/ForgotUserIdAndPasswordPage";

const ForgotLoginDetailsRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<ErrorPageNotFoundPage />} />
      <Route index element={<ForgotLoginDetailsPage />} />
      <Route path="password" element={<ForgotPasswordPage />} />
      <Route path="user-id" element={<ForgotUserIdPage />} />
      <Route
        path="user-id-and-password"
        element={<ForgotUserIdAndPasswordPage />}
      />
    </Routes>
  );
};

export default ForgotLoginDetailsRoutes;
