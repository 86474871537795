import * as yup from "yup";

// Address Fields Schema

export const postCodePattern = /^[a-z]{1,2}\d{1,2}? ?\d[a-z]{2}$/i;

export const postCodeSchema = {
  postCode: yup
    .string()
    .required("Postcode is required.")
    .max(8, "Enter 8 or less characters for postcode.")
    .matches(postCodePattern, "A valid postcode is required."),
};

export const selectAddressSchema = {
  selectAddress: yup
    .string()
    .required(
      "An address is required. If no addresses are populated then search by postcode first or enter address manually."
    ),
};

export const addressLine1Schema = {
  addressLine1: yup
    .string()
    .required("Address line 1 is required.")
    .max(50, "Enter 50 or less characters for address line 1."),
};

export const addressLine2Schema = {
  addressLine2: yup
    .string()
    .max(50, "Enter 50 or less characters for address line 2."),
};

export const addressLine3Schema = {
  addressLine3: yup
    .string()
    .max(50, "Enter 50 or less characters for address line 3."),
};

export const citySchema = {
  city: yup.string().max(50, "Enter 50 or less characters for city."),
};

export const countySchema = {
  county: yup.string().max(50, "Enter 50 or less characters for county."),
};

export const timeAtAddressSchema = {
  timeAtAddressYears: yup
    .number()
    .typeError("Please enter a valid time at address.")
    .min(0, "Years at address must be 0 or more.")
    .max(99, "Years at address must be 99 or less.")
    .required("Please enter a valid time at address.")
    .integer("Please enter a valid time at address."),
  timeAtAddressMonths: yup
    .number()
    .typeError("Please enter a valid time at address.")
    .min(0, "Months at address must be 0  or more.")
    .max(11, "Months at address must be 11 or less.")
    .required("Please enter a valid time at address.")
    .integer("Please enter a valid time at address."),
  timeAtAddress: yup.string().test({
    name: "timeAtAddress",
    exclusive: false,
    params: {},
    message: "Please enter a valid time at address.",
    test: function () {
      const d = this.parent.timeAtAddressMonths;
      const y = this.parent.timeAtAddressYears;

      if (
        !isNaN(parseInt(d)) &&
        !isNaN(parseInt(y)) &&
        d <= 11 &&
        y <= 99 &&
        (d > 0 || y > 0)
      ) {
        return true;
      }

      return false;
    },
  }),
};
