import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import PageHeading from "../../../component/page-heading/PageHeading";

const RegisterConfirmationPage = () => {
  const navigate = useNavigate();

  const onNavigate = () => {
    navigate("/login", { replace: true });
  };

  return (
    <BasePage pageTitle="Confirmation">
      <div className="text-center">
        <CenterAlignedButtonContainer>
          <PageHeading>
            Thank you. Your details have now been successfully updated.
          </PageHeading>
          <Button
            size="lg"
            onClick={onNavigate}
            id="btnLogin"
            className="text-decoration-none mt-4 px-5"
          >
            Login
          </Button>
        </CenterAlignedButtonContainer>
      </div>
    </BasePage>
  );
};

export default RegisterConfirmationPage;
