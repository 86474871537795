import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const CenterAlignedContainer = ({ children }) => {
  return (
    <Row className="d-flex justify-content-center">
      <Col lg={8}>{children}</Col>
    </Row>
  );
};

CenterAlignedContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
export default CenterAlignedContainer;
