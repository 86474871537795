import { useEffect } from "react";

import { axiosPrivate } from "../../api/axios/axios";
import useRefreshToken from "../use-refresh-token/useRefreshToken";
import useAuth from "../use-auth/useAuth";
import { transformApiError } from "../../api/error-handling/transform-error";
import useLogout from "../use-logout/useLogout";
import { onTrackError } from "../../utils/analytics/analytics-utils";
import magicStrings from "../../utils/magic-string";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const logout = useLogout();
  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const trackError = (url, statusCode, error) => {
      try {
        let errorToLog = {};
        if (typeof error === "object") {
          errorToLog = {
            code: error.code,
            reasonCode: error.reasonCode,
            message: error.message,
            httpCode: statusCode,
          };
        }
        onTrackError(`Api error: ${url}`, errorToLog);
      } catch (e) {}
    };

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (
          error?.response?.status === 401 &&
          !prevRequest?.sent &&
          auth?.user?.status?.toUpperCase() === "OK"
        ) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        } else {
          transformApiError(error);
          trackError(
            error?.config?.url,
            error?.response?.status,
            error?.response?.data
          );
          if (
            error?.response?.status === 401 &&
            auth?.user?.status?.toUpperCase() === "OK"
          ) {
            // Mot likely the user session has expired downstream.
            // Need to find a way to prompt user here that their session has expired
            logout(magicStrings.sessionExpired);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, [auth, refresh, logout]);
  return axiosPrivate;
};

export default useAxiosPrivate;
