const contractAmendmentsErrorMessages = {};

// WQR(Work queue rule) messages are not used/allowed to display the error messages in front-end screens
contractAmendmentsErrorMessages["1140001"] = "Work queue rule limitation.";
contractAmendmentsErrorMessages["1140002"] =
  "Work queue rule limitation for rule ID 149.";
contractAmendmentsErrorMessages["1140003"] =
  "Work queue rule limitation for rule ID 16.";
// WQR(Work queue rule) messages are not used/allowed to display the error messages in front-end screens

contractAmendmentsErrorMessages["1150001"] =
  "An unexpected error has occurred. Please try again later.";

contractAmendmentsErrorMessages["1199901"] =
  "Something went wrong. Please try again later.";

export default contractAmendmentsErrorMessages;
