const log = (message, ...args) => {
  if (window?.envConfig?.showConsoleLog === "1") {
    console.log(message, ...args);
  }
};

const warn = (message, ...args) => {
  if (window?.envConfig?.showConsoleWarning === "1") {
    console.warn(message, ...args);
  }
};

const error = (message, ...args) => {
  if (window?.envConfig?.showConsoleError === "1") {
    console.error(message, ...args);
  }
};

const debug = (message, ...args) => {
  if (window?.envConfig?.showConsoleDebug === "1") {
    console.debug(message, ...args);
  }
};

export { log, warn, error, debug };
