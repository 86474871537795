import { useNavigate } from "react-router-dom";
import BasePage from "../../../basepages/basepage/BasePage";
import ReviewDirectDebit from "../../../../component/payments/direct-debit/review-direct-debit/ReviewDirectDebit";

const ReviewDirectDebitPage = () => {
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate("/payments/new-direct-debit/confirmed");
  };

  const onCancel = () => {
    navigate("/payments/new-direct-debit");
  };

  return (
    <BasePage pageTitle="Set up a Direct Debit">
      <ReviewDirectDebit onSuccess={onSuccess} onCancel={onCancel} />
    </BasePage>
  );
};

export default ReviewDirectDebitPage;
