import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { GenericTextField } from "./field-utils/field-utils.js";

// Login fields

export const UserIdField = (props) => {
  return (
    <GenericTextField
      controlId="userId"
      labelText="User ID or alias"
      {...props}
    />
  );
};

export const PasswordField = (props) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Form.Group className="mb-4" controlId="password">
      <Form.Label>Password</Form.Label>
      <Form.Control
        type="password"
        maxLength={50}
        isInvalid={errors.password}
        {...props}
        {...register("password")}
      />
      <Form.Control.Feedback type="invalid">
        {errors.password?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
