import { Row } from "react-bootstrap";
import magicStrings from "../../../../utils/magic-string";

const BankAccountAndAddressDetails = ({
  savedBankAccountAndAddressDetails,
}) => {
  const sortcodeFormatChange = (sortCode) => {
    if (sortCode) {
      const sortcodePartOne = sortCode.substring(0, 2);
      const sortcodePartTwo = sortCode.substring(2, 4);
      const sortcodePartThree = sortCode.substring(4, 6);
      return `${sortcodePartOne}-${sortcodePartTwo}-${sortcodePartThree}`;
    }
  };
  return (
    <dl className="table-list-row mt-5">
      <Row>
        <dt>Name on the account</dt>
        <dd className={`${magicStrings.maskInformation}`}>
          {savedBankAccountAndAddressDetails?.bankAccount?.accountName}
        </dd>
      </Row>
      <Row>
        <dt>Account number</dt>
        <dd className={`${magicStrings.maskInformation}`}>
          {savedBankAccountAndAddressDetails?.bankAccount?.accountNumber}
        </dd>
      </Row>
      <Row>
        <dt>Sort code</dt>
        <dd className={`${magicStrings.maskInformation}`}>
          {sortcodeFormatChange(
            savedBankAccountAndAddressDetails?.bankAccount?.bank?.sortCode
          )}
        </dd>
      </Row>
      <Row>
        <dt>Bank name</dt>
        <dd className={`${magicStrings.maskInformation}`}>
          {savedBankAccountAndAddressDetails?.bankAccount?.bank?.bankName}
        </dd>
      </Row>
      <Row>
        <dt>Branch name</dt>
        <dd className={`${magicStrings.maskInformation}`}>
          {savedBankAccountAndAddressDetails?.bankAccount?.bank?.branchName}
        </dd>
      </Row>
      <Row>
        <dt>Bank address</dt>
        <dd className={`${magicStrings.maskInformation}`}>
          <div>
            {
              savedBankAccountAndAddressDetails?.bankAccount?.bank?.bankAddress
                ?.line1
            }
          </div>
          <div>
            {
              savedBankAccountAndAddressDetails?.bankAccount?.bank?.bankAddress
                ?.line2
            }
          </div>
          <div>
            {
              savedBankAccountAndAddressDetails?.bankAccount?.bank?.bankAddress
                ?.line3
            }
          </div>
          <div>
            {
              savedBankAccountAndAddressDetails?.bankAccount?.bank?.bankAddress
                ?.line4
            }
          </div>
          <div>
            {
              savedBankAccountAndAddressDetails?.bankAccount?.bank?.bankAddress
                ?.line5
            }
          </div>
          <div>
            {
              savedBankAccountAndAddressDetails?.bankAccount?.bank?.bankAddress
                ?.postcode
            }
          </div>
        </dd>
      </Row>
    </dl>
  );
};

export default BankAccountAndAddressDetails;
