import apiKeys from "../error-handling/api-keys";
import { useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";

const validateRegistrationDetails = async (axios, payload) => {
  const { data } = await axios.post("/auth/register/user", payload, {
    headers: attachApiKeyHeaderToRequest(apiKeys.users),
  });
  return data;
};

const validateUserId = async (axios, payload) => {
  const { data } = await axios.put("/auth/register/user", payload, {
    headers: attachApiKeyHeaderToRequest(apiKeys.users),
  });
  return data;
};

const useValidateUserId = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((payload) => validateUserId(axios, payload), {
    onSuccess: (data) => onSuccess(data),
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

const useValidateRegistrationDetails = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((payload) => validateRegistrationDetails(axios, payload), {
    onSuccess: (data) => onSuccess(data),
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

export { useValidateRegistrationDetails, useValidateUserId };
