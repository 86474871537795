import { DateTime } from "luxon";
import {
  formatCurrency,
  formatNumber,
} from "../../../utils/pages/number-format-utils";
import { formatDate } from "../../../utils/pages/date-format-utils";

const useEtQuoteDetails = ({ contract }) => {
  const registrationNumber = contract?.vehicle?.registrationNumber;
  const make = contract?.vehicle?.make;
  const model = contract?.vehicle?.model;
  const endDate = contract?.endDate && formatDate(contract?.endDate);

  let totalMonthlyRentalCharge = contract?.totalMonthlyRentalCharge;
  const remainingPayments = contract?.remainingPayments;
  let outstandingAmount = totalMonthlyRentalCharge * remainingPayments;
  totalMonthlyRentalCharge = formatCurrency(
    contract?.totalMonthlyRentalCharge,
    "NA"
  );
  outstandingAmount = formatCurrency(outstandingAmount);
  const excessMileageCharge = formatNumber(contract?.excessMileageCharge);
  const vatLabel =
    contract?.vatInclusive === true ? "including VAT" : "excluding VAT";

  const earlyTerminationsData = contract?.earlyterminations;
  let scheduleIds = [];
  let maxScheduleId;

  earlyTerminationsData?.map((et) => {
    scheduleIds.push(et.scheduleId);
    return false;
  });

  maxScheduleId = Math.max(...scheduleIds);

  const etToDisplay = earlyTerminationsData?.find((et) => {
    // eslint-disable-next-line
    return et.scheduleId == maxScheduleId;
  });

  // quote A
  let quoteAEtCost = formatCurrency(etToDisplay?.quoteA?.etCost);

  const quoteAEtMileage = formatNumber(
    etToDisplay?.quoteA?.etMileage,
    0,
    "N/A"
  );

  const quoteAExpiryDate = etToDisplay?.quoteA?.expiryDate;

  const quoteBStartDateByDayAndMonth = DateTime.fromISO(quoteAExpiryDate)
    .plus({
      day: 1,
    })
    .toFormat("dd MMMM");

  const quoteAExpiryDateByDayAndMonth =
    etToDisplay?.quoteA?.expiryDate &&
    DateTime.fromISO(etToDisplay?.quoteA?.expiryDate).toFormat("dd MMMM");

  const quoteADdDateByDayAndMonth =
    etToDisplay?.quoteA?.ddDate &&
    DateTime.fromISO(etToDisplay?.quoteA?.ddDate).toFormat("dd MMMM");

  // quoteB
  let quoteBEtCost = formatCurrency(etToDisplay?.quoteB?.etCost);

  const quoteBEtMileage = formatNumber(
    etToDisplay?.quoteB?.etMileage,
    0,
    "N/A"
  );

  const quoteBExpiryDateByMonth =
    etToDisplay?.quoteB?.expiryDate &&
    DateTime.fromISO(etToDisplay?.quoteB?.expiryDate).toFormat("MMMM");

  const quoteBExpiryDateByDayAndMonth =
    etToDisplay?.quoteB?.expiryDate &&
    DateTime.fromISO(etToDisplay?.quoteB?.expiryDate).toFormat("dd MMMM");

  const quoteBDdDateByDayAndMonth =
    etToDisplay?.quoteB?.ddDate &&
    DateTime.fromISO(etToDisplay?.quoteB?.ddDate).toFormat("dd MMMM");

  const quoteNumber = etToDisplay?.quoteB?.quoteNumber;

  const quoteDetails = {
    registrationNumber: registrationNumber,
    make: make,
    model: model,
    totalMonthlyRentalCharge: totalMonthlyRentalCharge,
    endDate: endDate,
    remainingPayments: remainingPayments,
    outstandingAmount: outstandingAmount,
    excessMileageCharge: excessMileageCharge,
    vatLabel: vatLabel,
    quoteAEtCost: quoteAEtCost,
    quoteAEtMileage: quoteAEtMileage,
    quoteAExpiryDateByDayAndMonth: quoteAExpiryDateByDayAndMonth,
    quoteADdDateByDayAndMonth: quoteADdDateByDayAndMonth,
    quoteBEtCost: quoteBEtCost,
    quoteBEtMileage: quoteBEtMileage,
    quoteBExpiryDateByMonth: quoteBExpiryDateByMonth,
    quoteBExpiryDateByDayAndMonth: quoteBExpiryDateByDayAndMonth,
    quoteBDdDateByDayAndMonth: quoteBDdDateByDayAndMonth,
    quoteBStartDateByDayAndMonth: quoteBStartDateByDayAndMonth,
    quoteNumber: quoteNumber,
  };

  return quoteDetails;
};

export { useEtQuoteDetails };
