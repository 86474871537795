import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const CloseIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    role="img"
    viewBox="0 0 21 22"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.95 6.56 14.419 5l-3.943 4.01L6.533 5 5 6.56l3.942 4.01L5 14.582l1.533 1.56 3.942-4.01 3.943 4.01 1.533-1.56-3.943-4.01z"
    />
  </svg>
);

CloseIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
export { CloseIcon };
export default CloseIcon;
