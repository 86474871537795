import { DateTime } from "luxon";
const formatDate = (val, format = "ISO", fallback = "") => {
  if (typeof val === "undefined" || val === null || val === "") {
    return fallback;
  } else {
    let formatDate;
    if (format === "ISO") {
      formatDate = DateTime.fromISO(val).toFormat("dd/MM/yyyy");
    } else {
      formatDate = DateTime.fromFormat(val, format).toFormat("dd/MM/yyyy");
    }
    return formatDate;
  }
};

export { formatDate };
