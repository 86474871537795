import { useSelector } from "react-redux";

import Stepper from "../../../component/stepper/Stepper";

const stepperSteps = [
  {
    step: 1,
    description: "Create a quote",
  },
  {
    step: 2,
    description: "Personal and financial details",
  },
  {
    step: 3,
    description: "Review application",
  },
];
const ContractAmendsStepper = () => {
  const currentStep = useSelector((state) => state.contractAmends.step);
  let activeStep = 1;
  if (
    Array.isArray(currentStep) &&
    currentStep[0] >= 1 &&
    currentStep[0] <= 3
  ) {
    activeStep = currentStep[0];
  }
  return (
    <Stepper
      steps={stepperSteps}
      activeStep={activeStep}
      mode="number"
      showCompletionStep="number"
      onClickHandler={() => {}}
    />
  );
};

export default ContractAmendsStepper;
