import { useMutation } from "@tanstack/react-query";
import apiKeys from "../error-handling/api-keys";
import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";

const submitIdVerificationDetails = async (axios, payload) => {
  return axios
    .post("/auth/register/identification", payload, {
      headers: attachApiKeyHeaderToRequest(apiKeys.users),
    })
    .then((res) => {
      return res.data;
    });
};

const useSubmitIdVerificationDetails = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((payload) => submitIdVerificationDetails(axios, payload), {
    onSuccess: (data) => onSuccess(data),
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

export { useSubmitIdVerificationDetails };
