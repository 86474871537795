import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForgotPassword } from "../../../api/hooks/auth";
import { useState } from "react";
import BasePage from "../../basepages/basepage/BasePage";
import ForgotPasswordForm from "../../../component/forms/forgotten-details/forgot-password/ForgotPasswordForm";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const ForgotPasswordPage = () => {
  const [message, setmessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const onSuccessful = () => {
    setmessage(true);
  };

  const onFailure = (error) => {
    setmessage(false);
    setErrorMessage(error);
  };

  const { mutate } = useForgotPassword(onSuccessful, onFailure);

  const onFormSubmit = (data) => {
    mutate(data);
  };

  return (
    <BasePage pageTitle="Forgotten password">
      {message ? (
        <>
          <TitleWithIcon
            title="We've sent a temporary password"
            variant="success"
          />
          <CenterAlignedContainer>
            <h2 className="h4 mt-4 text-primary fw-bold">
              Forgotten your login details
            </h2>
            <p>
              <strong>
                If you've entered a valid user ID or alias, we've sent a
                temporary password to your registered email address. You should
                receive this from us within the next few minutes.
              </strong>
            </p>
            <p>
              If you don't see our message in your inbox, please check your junk
              or spam folders. To ensure our emails reach your inbox, please add
              our email address to your address book.
            </p>
            <p>
              If you don't know your user ID or alias, please call{" "}
              <Link to="tel:0344 879 6633">0344 879 6633</Link>. Phone lines are
              open Monday - Friday between 9am - 5pm. We're closed on weekends
              and bank holidays.
            </p>
          </CenterAlignedContainer>

          <CenterAlignedButtonContainer>
            <Button
              as={Link}
              to="/login"
              id="btnLogin"
              className="text-decoration-none"
            >
              Login
            </Button>
          </CenterAlignedButtonContainer>
        </>
      ) : (
        <>
          <p className="text-center">
            Please enter your user ID or alias and we'll send you a temporary
            password.
          </p>

          <CenterAlignedButtonContainer className="mb-0 col-sm-12">
            {errorMessage?.localErrorMessage && (
              <Alert variant="danger">
                {getSanitisedErrorMessage(errorMessage.localErrorMessage)}
              </Alert>
            )}
            <ForgotPasswordForm onFormSubmit={onFormSubmit} />
          </CenterAlignedButtonContainer>
        </>
      )}
    </BasePage>
  );
};
export default ForgotPasswordPage;
