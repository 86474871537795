import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

const HeroBanner = ({ title, subTitle }) => {
  return (
    <Row className="bg-secondary text-center text-white">
      <Col>
        <Container className="p-4 p-lg-5">
          <h1 id="heroHeading">{title}</h1>
          <p className="lead">{subTitle}</p>
        </Container>
      </Col>
    </Row>
  );
};
export default HeroBanner;
