import PropTypes from "prop-types";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import {
  GenericTextField,
  GenericSelectField,
} from "./field-utils/field-utils.js";
import { trimStartOnChange } from "../../../utils/form/form-text-utils";
import * as fieldData from "./data/field-data.js";

import nationalitiesData from "../fields/data/nationalities.json";
// Personal Details Fields

export const TitleField = ({ titles, ...props }) => {
  return (
    <GenericSelectField
      controlId="title"
      labelText="Title"
      options={titles ?? fieldData.titles}
      placeholderOptionText="Please select a title"
      ariaText="Please select a title"
      {...props}
    />
  );
};

TitleField.propTypes = {
  titles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};

export const TitleTextField = (props) => {
  return <GenericTextField controlId="title" labelText="Title" {...props} />;
};

export const FirstNameField = (props) => {
  return (
    <GenericTextField controlId="firstName" labelText="First name" {...props} />
  );
};

export const LastNameField = (props) => {
  return (
    <GenericTextField controlId="lastName" labelText="Last name" {...props} />
  );
};

export const MobileNumberField = (props) => {
  return (
    <GenericTextField
      controlId="mobileNumber"
      type="string"
      maxLength={20}
      labelText="Mobile number (if available)"
      {...props}
    />
  );
};

export const DobField = (props) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors, isSubmitted, touchedFields },
    trigger,
    watch,
  } = methods;

  // Check DOB is in a valid format
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "dobDay":
        case "dobMonth":
        case "dobYear":
          trigger("dateOfBirth");
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  return (
    <Form.Group>
      <Form.Label className="w-100" id="dateOfBirth">
        Date of birth
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          type="number"
          maxLength={2}
          min={1}
          max={31}
          aria-labelledby="dateOfBirth"
          placeholder="DD"
          isInvalid={
            errors.dobDay ||
            ((isSubmitted ||
              (touchedFields["dobYear"] &&
                touchedFields["dobMonth"] &&
                touchedFields["dobDay"])) &&
              errors.dateOfBirth)
          }
          {...register("dobDay")}
          {...props}
        />
        <InputGroup.Text>/</InputGroup.Text>
        <Form.Control
          type="number"
          maxLength={2}
          min={1}
          max={12}
          aria-labelledby="dateOfBirth"
          placeholder="MM"
          isInvalid={
            errors.dobMonth ||
            ((isSubmitted ||
              (touchedFields["dobYear"] &&
                touchedFields["dobMonth"] &&
                touchedFields["dobDay"])) &&
              errors.dateOfBirth)
          }
          {...register("dobMonth")}
          {...props}
        />
        <InputGroup.Text>/</InputGroup.Text>
        <Form.Control
          type="number"
          maxLength={4}
          min={1900}
          aria-labelledby="dateOfBirth"
          placeholder="YYYY"
          isInvalid={
            errors.dobYear ||
            ((isSubmitted ||
              (touchedFields["dobYear"] &&
                touchedFields["dobMonth"] &&
                touchedFields["dobDay"])) &&
              errors.dateOfBirth)
          }
          {...register("dobYear")}
          {...props}
        />
        <Form.Control.Feedback
          type="invalid"
          className={`${
            (isSubmitted ||
              touchedFields["dobYear"] ||
              touchedFields["dobMonth"] ||
              touchedFields["dobDay"]) &&
            (errors.dobDay ||
              errors.dobMonth ||
              errors.dobYear ||
              ((isSubmitted ||
                (touchedFields["dobYear"] &&
                  touchedFields["dobMonth"] &&
                  touchedFields["dobDay"])) &&
                errors.dateOfBirth))
              ? "d-block"
              : "d-none"
          }`}
        >
          {`${
            (isSubmitted ||
              touchedFields["dobYear"] ||
              touchedFields["dobMonth"] ||
              touchedFields["dobDay"]) &&
            (errors.dobDay ||
              errors.dobMonth ||
              errors.dobYear ||
              errors.dateOfBirth)
              ? errors?.dateOfBirth?.message ||
                errors?.dobDay?.message ||
                errors?.dobMonth?.message ||
                errors?.dobYear?.message
              : ""
          }`}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export const MaritalStatusField = ({ maritalStatuses, ...props }) => {
  return (
    <GenericSelectField
      controlId="maritalStatus"
      labelText="Marital status"
      options={maritalStatuses ?? fieldData.maritalStatuses}
      placeholderOptionText="Please select a marital status"
      ariaText="Please select a marital status"
      {...props}
    />
  );
};

MaritalStatusField.propTypes = {
  maritalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};

export const NumOfDependantsField = (props) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors, isSubmitted, touchedFields },
  } = methods;
  return (
    <Form.Group className="mb-3" controlId="numOfDependants">
      <Form.Label className="w-100" aria-label="No. of dependants*">
        No. of dependants*
        <br />
        <em className="fw-normal">
          *Include anyone who lives with you and financially depends on you,
          including any children you are expecting or adopting
        </em>
      </Form.Label>
      <Form.Control
        type="number"
        max="99"
        isInvalid={errors.numOfDependants}
        {...register("numOfDependants")}
        {...props}
      />
      <Form.Control.Feedback
        type="invalid"
        className={`${
          (isSubmitted || touchedFields["numOfDependants"]) &&
          errors.numOfDependants
            ? "d-block"
            : "d-none"
        }`}
      >
        {`${
          (isSubmitted || touchedFields["numOfDependants"]) &&
          errors.numOfDependants
            ? errors.numOfDependants.message
            : ""
        }`}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const AccommodationField = ({ accommodations, ...props }) => {
  return (
    <GenericSelectField
      controlId="accommodation"
      labelText="Accommodation"
      options={accommodations ?? fieldData.accommodations}
      placeholderOptionText="Please select accommodation"
      ariaText="Please select accommodation"
      {...props}
    />
  );
};

AccommodationField.propTypes = {
  accommodations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};

export const PhoneNumbersFormFields = (props) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
    watch,
    trigger,
  } = methods;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "mobileNumber":
          trigger(["homeNumber", "workNumber"], { shouldFocus: false });
          break;
        case "homeNumber":
          trigger(["mobileNumber", "workNumber"], { shouldFocus: false });
          break;
        case "workNumber":
          trigger(["mobileNumber", "homeNumber"], { shouldFocus: false });
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  return (
    <>
      <Form.Group className="mb-3" controlId="mobileNumber">
        <Form.Label className="w-100">Mobile number</Form.Label>
        <Form.Control
          type="string"
          maxLength={props.maxLength ? props.maxLength : 20}
          isInvalid={errors.mobileNumber}
          {...register("mobileNumber", {
            onChange: trimStartOnChange,
          })}
          {...props}
        />
        {errors.mobileNumber?.message &&
          /[[MINLENGTH]]/.test(errors.mobileNumber.message) && (
            <Form.Control.Feedback type="invalid">
              {errors.mobileNumber?.message.replace("[[MINLENGTH]]", "")}
            </Form.Control.Feedback>
          )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="workNumber">
        <Form.Label className="w-100">Work number</Form.Label>
        <Form.Control
          type="string"
          maxLength={props.maxLength ? props.maxLength : 20}
          isInvalid={errors.workNumber}
          {...register("workNumber", {
            onChange: trimStartOnChange,
          })}
          {...props}
        />
        {errors.workNumber?.message &&
          /[[MINLENGTH]]/.test(errors.workNumber.message) && (
            <Form.Control.Feedback type="invalid">
              {errors.workNumber?.message.replace("[[MINLENGTH]]", "")}
            </Form.Control.Feedback>
          )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="homeNumber">
        <Form.Label className="w-100">Home number</Form.Label>
        <Form.Control
          type="string"
          maxLength={props.maxLength ? props.maxLength : 20}
          isInvalid={errors.homeNumber}
          {...register("homeNumber", {
            onChange: trimStartOnChange,
          })}
          {...props}
        />
        <Form.Control.Feedback type="invalid">
          {errors.homeNumber?.message.replace("[[MINLENGTH]]", "")}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export const NationalityField = ({ nationalities, ...props }) => {
  if (Array.isArray(nationalitiesData) && nationalitiesData.length > 0) {
    nationalities = nationalitiesData;
  }

  return (
    <GenericSelectField
      controlId="nationality"
      labelText="Nationality"
      options={nationalities ?? [{ id: 1, value: 1, text: "British" }]}
      placeholderOptionText="Please select a nationality"
      ariaText="Please select a nationality"
      {...props}
    />
  );
};

NationalityField.propTypes = {
  nationalities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};

export const EmailAddressField = (props) => {
  return (
    <GenericTextField
      controlId="emailAddress"
      labelText="Email address"
      maxLength={props.maxLength ? props.maxLength : 255}
      {...props}
    />
  );
};
