import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { step } from "../../../redux/features/contract-amends/contractAmendsSlice";

const compareSteps = (arr1, arr2) => {
  const len = Math.max(arr1.length, arr2.length);
  for (let i = 0; i < len; i++) {
    const a = arr1[i] || 0;
    const b = arr2[i] || 0;
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }
  }
  return 0;
};

const useContractAmendsStepCheck = (
  stepToCheck,
  redirectionPath,
  skipStepCheck
) => {
  const [isChecked, setIsChecked] = useState(() => false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStep = useSelector((state) => state.contractAmends.step);
  useEffect(() => {
    if (isChecked || skipStepCheck) {
      return;
    }
    if (!currentStep) {
      navigate(redirectionPath, { replace: true });
    } else if (currentStep.length === 1 && currentStep[0] === 0) {
      navigate("/contract-amendment", { replace: true });
    } else {
      const stepCheckResult = compareSteps(currentStep, stepToCheck);
      if (stepCheckResult < 0) {
        navigate(redirectionPath, { replace: true });
      } else if (stepCheckResult > 0) {
        dispatch(step(stepToCheck));
      }
    }
    setIsChecked(true);
  }, [
    navigate,
    currentStep,
    dispatch,
    stepToCheck,
    redirectionPath,
    isChecked,
    skipStepCheck,
  ]);
};

export default useContractAmendsStepCheck;
