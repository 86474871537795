import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  PersonalDetailsTermsCheckbox,
  CreditCheckTermsCheckbox,
} from "../fields";
import * as fieldSchemas from "../schemas/fields";
import CenterAlignedButtonContainer from "../../center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../back-to-home-button/BackToHomeButton";

const ReviewApplicationForm = ({ onFormSubmit, extraContent }) => {
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.personalDetailsTermsSchema,
        ...fieldSchemas.creditCheckTermsSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit: internalHandleSubmit } = methods;

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={internalHandleSubmit(onSubmit)}
        noValidate
        name="reviewApplicationForm"
      >
        <PersonalDetailsTermsCheckbox />
        <CreditCheckTermsCheckbox />
        {extraContent}
        <CenterAlignedButtonContainer>
          <Button
            className="mt-3"
            type="submit"
            id="btnReviewApplicationFormSubmit"
          >
            Submit application
          </Button>
          <BackToHomeButton />
        </CenterAlignedButtonContainer>
      </Form>
    </FormProvider>
  );
};
export default ReviewApplicationForm;
