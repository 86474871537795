import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { SignHereTextField, SignHereCheckbox } from "../fields";
import * as fieldSchemas from "../schemas/fields";

const SignAgreementForm = ({ onFormSubmit }) => {
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.signatureSchema,
        ...fieldSchemas.SignAgreementSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        name="signAgreementForm"
        autoComplete="off"
      >
        <SignHereTextField />
        <SignHereCheckbox />

        <div className="d-grid gap-2">
          <Button className="mt-3" type="submit" id="btnSignAgreementForm">
            Sign my amended contract
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};
export default SignAgreementForm;
