import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";
import apiKeys from "../error-handling/api-keys";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";

const getAccountBalance = async (axios) => {
  return axios.get("/account/balance").then((res) => {
    return res.data;
  });
};

const getAccountNotifications = async (axios, sinceDate) => {
  return axios.get(`/account/notifications/since/${sinceDate}`).then((res) => {
    return res.data;
  });
};

const getPaymentHolidayFinancialSupport = async (axios) => {
  return axios
    .get("/account/liability", {
      headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
    })
    .then((res) => {
      return res.data;
    });
};

const useGetAccountBalance = () => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const { data = fallback, isError } = useQuery(
    queryKeys.accountBalance,
    () => getAccountBalance(axios),
    { cacheTime: 30 * 60 * 1000, staleTime: 30 * 60 * 1000 }
  );
  return {
    data,
    isError,
  };
};

const useGetAccountNotifications = (sinceDate) => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const { data = fallback, isError } = useQuery(
    queryKeys.accountNotifications,
    () => getAccountNotifications(axios, sinceDate),
    { cacheTime: 30 * 60 * 1000, staleTime: 30 * 60 * 1000 }
  );
  return {
    data,
    isError,
  };
};

const usePaymentHolidayFinancialSupport = () => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const { data = fallback, isError } = useQuery(
    queryKeys.paymentHolidayFinancialSupport,
    () => getPaymentHolidayFinancialSupport(axios),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      select: (paymentHolidaySupport) => {
        const transformPaymentHolidaySupportData = (ph) => {
          let phData = {};
          if (ph?.deferredFrom && ph?.deferredTo && ph?.delayMonths) {
            phData = ph;
          }
          return phData;
        };

        return transformPaymentHolidaySupportData(paymentHolidaySupport);
      },
    }
  );
  return {
    paymentHolidayFinancialSupport: data,
    isError,
  };
};

export {
  useGetAccountBalance,
  useGetAccountNotifications,
  usePaymentHolidayFinancialSupport,
};
