import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { trimStartOnChange } from "../../../../utils/form/form-text-utils";
import magicStrings from "../../../../utils/magic-string";

// Utility Generics

export const GenericTextField = ({
  controlId,
  labelText = "",
  placeholderText = "",
  maxLength = 50,
  registerAttributes,
  ...props
}) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;

  const localRegisterAttributes = {
    ...{
      onChange: trimStartOnChange,
    },
    registerAttributes,
  };

  return (
    <Form.Group className="mb-3" controlId={`${controlId}`}>
      <Form.Label className="w-100">{labelText}</Form.Label>
      <Form.Control
        type="string"
        maxLength={maxLength}
        placeholder={placeholderText}
        isInvalid={errors[`${controlId}`]}
        {...register(`${controlId}`, localRegisterAttributes)}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {errors[`${controlId}`]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

GenericTextField.propTypes = {
  controlId: PropTypes.string,
  placeholderText: PropTypes.string,
  maxLength: PropTypes.number,
  registerAttributes: PropTypes.object,
};

export const GenericSelectField = ({
  controlId,
  labelText,
  options,
  placeholderOptionText,
  ariaText,
  registerAttributes,
  ...props
}) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;
  const selectOptions = options ?? [];
  return (
    <Form.Group className="mb-3" controlId={`${controlId}`}>
      <Form.Label className="w-100">{labelText}</Form.Label>
      <Form.Select
        aria-label={ariaText}
        {...register(`${controlId}`, registerAttributes)}
        isInvalid={errors[`${controlId}`]}
        {...props}
      >
        {placeholderOptionText ? (
          <option value="">{placeholderOptionText}</option>
        ) : (
          ""
        )}
        {selectOptions.map((option) => {
          return (
            <option value={option.value ?? option.text} key={option.id}>
              {option.text}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {errors[`${controlId}`]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

GenericSelectField.propTypes = {
  controlId: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string,
    })
  ),
  placeholderOptionText: PropTypes.string,
  ariaText: PropTypes.string,
  registerAttributes: PropTypes.object,
};

export const GenericTextArea = ({
  controlId,
  labelText = "",
  registerAttributes,
  ...props
}) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;
  const localRegisterAttributes = {
    ...{
      onChange: trimStartOnChange,
    },
    registerAttributes,
  };
  return (
    <Form.Group className="mb-3" controlId={`${controlId}`}>
      <Form.Label className="w-100">{labelText}</Form.Label>
      <Form.Control
        as="textarea"
        type="textarea"
        rows={5}
        isInvalid={errors[`${controlId}`]}
        {...register(`${controlId}`, localRegisterAttributes)}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {errors[`${controlId}`]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

GenericTextArea.propTypes = {
  controlId: PropTypes.string,
  placeholderText: PropTypes.string,
  rows: PropTypes.number,
  registerAttributes: PropTypes.object,
};

export const GenericCheckbox = ({
  controlId,
  labelText = "",
  registerAttributes,
  ...props
}) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;
  return (
    <Form.Group className="mb-3" controlId={`${controlId}`}>
      <Form.Check
        type="checkbox"
        label={labelText}
        {...register(`${controlId}`, registerAttributes)}
        {...props}
        isInvalid={errors[`${controlId}`]}
      ></Form.Check>
      <Form.Control.Feedback
        type="invalid"
        style={
          errors[`${controlId}`] ? { display: "block" } : { display: "none" }
        }
      >
        {errors[`${controlId}`]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

GenericCheckbox.propTypes = {
  controlId: PropTypes.string,
  registerAttributes: PropTypes.object,
};

export const GenericNumberField = ({
  controlId,
  labelText = "",
  min = 0,
  max,
  step = 1,
  registerAttributes,
  ...props
}) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Form.Group className="mb-3" controlId={`${controlId}`}>
      <Form.Label className="w-100">{labelText}</Form.Label>
      <Form.Control
        type="number"
        isInvalid={errors[`${controlId}`]}
        min={min}
        max={max}
        step={step}
        {...register(`${controlId}`, registerAttributes)}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {errors[`${controlId}`]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

GenericNumberField.propTypes = {
  controlId: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  registerAttributes: PropTypes.object,
};

export const GenericRadioSelectField = ({
  controlId,
  radioText,
  radioHint,
  options,
  ...props
}) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors, touchedFields, isSubmitted },
  } = methods;

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      <fieldset>
        {radioText && (
          <legend className="w-100 h5" id={controlId}>
            {radioText}
          </legend>
        )}
        {radioText && <div className="w-100">{radioHint}</div>}
        <div className="my-2 mx-2">
          {options?.map((option) => (
            <Form.Check
              className="my-3"
              aria-labelledby={radioText ? controlId : null}
              type={"radio"}
              key={option.id}
              id={option.id}
              name={controlId}
              label={option.text}
              value={option.id}
              isInvalid={errors[controlId]}
              {...register(controlId)}
              {...props}
            />
          ))}
          <Form.Control.Feedback
            type="invalid"
            className={`${
              (touchedFields[controlId] || isSubmitted) && errors[controlId]
                ? "d-block mt-2"
                : "d-none"
            }`}
          >
            {errors[controlId]?.message}
          </Form.Control.Feedback>
        </div>
      </fieldset>
    </Form.Group>
  );
};

GenericRadioSelectField.propTypes = {
  controlId: PropTypes.string,
  radioText: PropTypes.string,
  radioHint: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export const GenericChecklistField = ({
  controlId,
  checklistText,
  checklistHint,
  options,
  ...props
}) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors, touchedFields, isSubmitted },
  } = methods;

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      <fieldset>
        {checklistText && (
          <legend
            className={`w-100 h2 mb-4 ${magicStrings.maskInformation}`}
            id={controlId}
          >
            {checklistText}
          </legend>
        )}
        {checklistHint && <div className="w-100 mb-3">{checklistHint}</div>}
        <div className="my-2 mx-2">
          {options?.map((option) => (
            <Form.Check key={option.id} id={option.id} className="my-3">
              <Form.Check.Input
                type={"checkbox"}
                aria-labelledby={checklistText ? controlId : null}
                name={controlId}
                value={option.id}
                defaultChecked={option?.selected}
                isInvalid={errors[controlId]}
                {...register(controlId)}
                {...props}
              />
              <Form.Check.Label>
                <div
                  className={
                    option?.hint
                      ? `fw-bold mb-2 ${magicStrings.maskInformation}`
                      : ""
                  }
                >
                  {option.text}
                </div>
                {option?.hint && (
                  <div className={`${magicStrings.maskInformation}`}>
                    {option.hint}
                  </div>
                )}
              </Form.Check.Label>
            </Form.Check>
          ))}
          <Form.Control.Feedback
            type="invalid"
            className={`${
              (touchedFields[controlId] || isSubmitted) && errors[controlId]
                ? "d-block mt-2"
                : "d-none"
            }`}
          >
            {errors[controlId]?.message}
          </Form.Control.Feedback>
        </div>
      </fieldset>
    </Form.Group>
  );
};

GenericChecklistField.propTypes = {
  controlId: PropTypes.string,
  checklistText: PropTypes.string,
  checklistHint: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      hint: PropTypes.string,
    })
  ),
};

export const GenericPasswordField = ({
  controlId,
  labelText,
  placeholderText = "",
  maxLength = 20,
  registerAttributes,
  ...props
}) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Form.Group className="mb-3" controlId={`${controlId}`}>
      <Form.Label>{labelText}</Form.Label>
      <Form.Control
        type="password"
        maxLength={maxLength}
        placeholder={placeholderText}
        isInvalid={errors[`${controlId}`]}
        {...register(`${controlId}`, registerAttributes)}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {errors[`${controlId}`]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

GenericPasswordField.propTypes = {
  controlId: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  placeholderText: PropTypes.string,
  maxLength: PropTypes.number,
  registerAttributes: PropTypes.object,
};
