import * as yup from "yup";

// Login Fields Schema

export const userIdSchema = {
  userId: yup.string().required("User ID is required.").max(50),
};

export const userIdOrAliasSchema = {
  userId: yup.string().required("User ID or Alias is required.").max(50),
};

export const passwordSchema = {
  password: yup.string().required("Password is required.").max(50),
};
