import { Col, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

const renderStartOfMonthFillerDays = (year, month, showWeekends = true) => {
  let firstDayOfMonth = DateTime.utc(year, month, 1).weekday;

  if (firstDayOfMonth > 1) {
    return (
      <>
        {[...Array(firstDayOfMonth - 1).keys()].map((item, index) => {
          return (
            (showWeekends || (!showWeekends && index < 5)) && (
              <div key={index}></div>
            )
          );
        })}
      </>
    );
  }
  return <></>;
};

const isDateSelected = (year, month, day, selectedDate) => {
  if (selectedDate) {
    let date = DateTime.utc(year, month, day);
    return (
      date.year === selectedDate.year &&
      date.month === selectedDate.month &&
      date.day === selectedDate.day
    );
  }

  return false;
};

const isDateSelectable = (
  year,
  month,
  day,
  minDate,
  maxDate,
  unavailableDates
) => {
  let date = DateTime.utc(year, month, day).startOf("day");
  if (date < minDate) {
    return false;
  }
  if (date > maxDate.plus({ hour: 1 })) {
    return false;
  }

  if (
    unavailableDates.some(
      (d) =>
        d.year === date.year && d.month === date.month && d.day === date.day
    )
  ) {
    return false;
  }

  return true;
};

const renderDaysOfMonth = ({
  year,
  month,
  showWeekends,
  minDate,
  maxDate,
  unavailableDates,
  selectedDate,
  onDateSelected,
}) => {
  let numberOfDays = DateTime.utc(year, month, 1).endOf("month").day;
  let firstDayOfMonth = DateTime.utc(year, month, 1).weekday;

  return (
    <>
      {[...Array(numberOfDays).keys()].map((item, index) => {
        const showDate =
          showWeekends ||
          !(
            (index + firstDayOfMonth) % 7 === 0 ||
            (index + 1 + firstDayOfMonth) % 7 === 0
          );
        let isSelectable = true;
        let isSelected = isDateSelected(year, month, index + 1, selectedDate);
        if (showDate) {
          isSelectable = isDateSelectable(
            year,
            month,
            index + 1,
            minDate,
            maxDate,
            unavailableDates
          );
        }

        return (
          showDate && (
            <div
              tabIndex={isSelectable ? 0 : null}
              key={index}
              role="button"
              className={`day 
                 ${isSelectable ? "selectable" : "disabled"} 
                 ${isSelected ? "selected" : ""}
                 ${showWeekends ? "" : "no-weekends"}
                `}
              onClick={() =>
                isSelectable && typeof onDateSelected === "function"
                  ? onDateSelected(
                      `${String(year).padStart(4, "0")}-${String(
                        month
                      ).padStart(2, "0")}-${String(index + 1).padStart(2, "0")}`
                    )
                  : null
              }
            >
              {index + 1}
            </div>
          )
        );
      })}
    </>
  );
};

const CalendarMonth = ({
  year,
  month,
  showWeekends = true,
  minDate = DateTime.utc(-271821, 3, 20),
  maxDate = DateTime.utc(271821, 3, 20),
  unavailableDates = [],
  selectedDate = "",
  onDateSelected,
}) => {
  const date = DateTime.utc(year, month, 1);

  return (
    <div className="calendar" data-testid="calendarMonth">
      <Row>
        <Col>
          <div className="text-center my-3 fw-bold">
            {date.toFormat("MMMM yyyy")}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={`day-title-container ${
              showWeekends ? "" : "no-weekends"
            }`}
          >
            <div className={`day-title ${showWeekends ? "" : "no-weekends"}`}>
              Mon
            </div>
            <div className={`day-title ${showWeekends ? "" : "no-weekends"}`}>
              Tue
            </div>
            <div className={`day-title ${showWeekends ? "" : "no-weekends"}`}>
              Wed
            </div>
            <div className={`day-title ${showWeekends ? "" : "no-weekends"}`}>
              Thu
            </div>
            <div className={`day-title ${showWeekends ? "" : "no-weekends"}`}>
              Fri
            </div>
            {showWeekends && (
              <>
                <div
                  className={`day-title ${showWeekends ? "" : "no-weekends"}`}
                >
                  Sat
                </div>
                <div
                  className={`day-title ${showWeekends ? "" : "no-weekends"}`}
                >
                  Sun
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={`day-title-container ${
              showWeekends ? "" : "no-weekends"
            }`}
          >
            {renderStartOfMonthFillerDays(year, month, showWeekends)}
            {renderDaysOfMonth({
              year,
              month,
              showWeekends,
              minDate,
              maxDate,
              unavailableDates,
              selectedDate,
              onDateSelected,
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

CalendarMonth.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).isRequired,
  showWeekends: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onDateSelected: PropTypes.func.isRequired,
  unavailableDates: PropTypes.array,
};

export default CalendarMonth;
