import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import BasePage from "../../basepages/basepage/BasePage";
import SupportNeedsForm from "../../../component/forms/accessibility-support/SupportNeedsForm";
import { useSupportNeeds } from "../../../api/hooks/support-needs";
import steps from "./support-needs-steps";
import {
  step,
  supportNeeds,
  selectedSupportNeeds,
  deselectedSupportNeeds,
} from "../../../redux/features/support-needs/supportNeedsSlice";
import PageHeading from "../../../component/page-heading/PageHeading";

const SupportNeedsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessibilityNeeds, isError } = useSupportNeeds();

  const onFormSubmit = (selectedOptions) => {
    const initialSelection =
      accessibilityNeeds
        .filter((option) => option.isOpted === true)
        .map((option) => option.id.toString()) ?? [];

    const deselectedOptions = initialSelection
      .sort()
      .filter((option) => !selectedOptions.includes(option));

    let newAccessibilityNeeds = [];

    for (let option in accessibilityNeeds) {
      newAccessibilityNeeds.push({
        ...accessibilityNeeds[option],
        isOpted: selectedOptions.includes(accessibilityNeeds[option].id),
      });
    }

    dispatch(selectedSupportNeeds(selectedOptions));
    dispatch(deselectedSupportNeeds(deselectedOptions));
    dispatch(supportNeeds(accessibilityNeeds));
    dispatch(step(steps.confirmNeeds));
    navigate("../support-needs/confirm");
  };

  const onPrevious = () => {
    dispatch(step(steps.home));
    navigate(-1);
  };

  return (
    <BasePage
      pageTitle="Help and support"
      pageSubTitle="Find help when you need it."
    >
      <PageHeading align="left">Your support needs</PageHeading>
      <p>
        Please select each support need you'd like us to help you with. We'll
        take these needs into account next time you call.
      </p>
      <hr className="my-5" />
      {!isError &&
      Array.isArray(accessibilityNeeds) &&
      accessibilityNeeds.length > 0 ? (
        <SupportNeedsForm
          defaultValues={accessibilityNeeds}
          initialValues={accessibilityNeeds}
          onFormSubmit={onFormSubmit}
          onPrevious={onPrevious}
        />
      ) : (
        <Alert variant="danger">
          Sorry, we are unable to retrieve any help and support needs.
        </Alert>
      )}
    </BasePage>
  );
};

export default SupportNeedsPage;
