import { useDispatch } from "react-redux";

import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import BasePage from "../../basepages/basepage/BasePage";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import { reset } from "../../../redux/features/contract-amends/contractAmendsSlice";

const ApplicationReferredPage = () => {
  const dispatch = useDispatch();

  const onBackToHomeClicked = () => {
    dispatch(reset());
  };

  return (
    <BasePage pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <TitleWithIcon title="We've received your application" variant="info" />
      <p className="mt-5">Thank you for applying to amend your contract.</p>
      <p>
        We might need extra information to help us process your request. If we
        do, we'll be in touch by phone or email in the next 5 working days.
      </p>
      <CenterAlignedButtonContainer>
        <BackToHomeButton
          variant="primary"
          onButtonClicked={onBackToHomeClicked}
        />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ApplicationReferredPage;
