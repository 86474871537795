import * as yup from "yup";
import { Form, Button, Alert } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { SupportNeedsField } from "../fields";
import CenterAlignedButtonContainer from "../../center-aligned-button-container/CenterAlignedButtonContainer";
import * as fieldSchemas from "../schemas/fields";
import { useEffect, useState } from "react";

const sortOptions = (options) => {
  const sortedOptions = options.sort((a, b) =>
    a?.isOpted === b?.isOpted ? 0 : a?.isOpted ? -1 : 1
  );
  return sortedOptions;
};

const sortGroups = (groups) => {
  const newGroups = Object.entries(groups).map((group = []) => {
    let optedCount = group[1]?.filter(
      (option) => option.isOpted === true
    ).length;
    let newOptions = sortOptions(group[1]);
    return [group[0], newOptions, optedCount];
  });
  const sortedGroups = newGroups.sort((a, b) => (a[2] < b[2] ? 1 : -1));
  return sortedGroups;
};

const extractGroupNames = (xs = [], prop) => {
  let grouped = {};
  // Separate options into groups
  for (let i = 0; i < xs.length; i++) {
    let p = xs[i][prop];
    if (!grouped[p]) {
      grouped[p] = [];
    }
    grouped[p].push(xs[i]);
  }
  return grouped;
};

const SupportNeedsForm = ({
  defaultValues,
  initialValues = defaultValues,
  onFormSubmit,
  onPrevious = () => {},
}) => {
  const [hasChanges, setHasChanges] = useState(false);

  const [sortedGroups] = useState(
    () => sortGroups(extractGroupNames(defaultValues, "groupName") ?? {}) ?? []
  );

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        supportNeedsChecklist:
          fieldSchemas.supportNeedsSchema.supportNeedsChecklist.notRequired(),
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    formState: { isSubmitted, isValidating, isDirty },
  } = methods;

  useEffect(() => {
    const currentValues = Object.values(getValues())
      .filter((entry) => entry !== false)
      .reduce((acc, curVal) => {
        return acc.concat(curVal);
      }, []);

    const initialData =
      initialValues
        .filter((option) => option.isOpted === true)
        .map((option) => option.id.toString()) ?? [];

    setHasChanges(
      JSON.stringify(initialData.sort()) !==
        JSON.stringify(currentValues.sort())
    );
  }, [defaultValues, initialValues, isDirty, isValidating, getValues]);

  const onSubmit = (data) => {
    let submitData = Object.values(data)
      .filter((entry) => entry !== false)
      .reduce((acc, curVal) => {
        return acc.concat(curVal);
      }, []);
    if (hasChanges) {
      onFormSubmit(submitData);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="supportNeedsForm"
        style={{ fontSize: "1.05rem" }}
      >
        {sortedGroups?.map((group, index) => {
          return (
            <div key={index}>
              <SupportNeedsField
                groupName={group[0]}
                options={group[1]}
                controlId={"supportNeedsChecklist_" + index}
                key={"supportNeedsChecklist_" + index}
              />
              <hr className="my-5" />
            </div>
          );
        })}
        {!hasChanges && isSubmitted && (
          <Alert variant="danger">
            You haven't made any changes to your support needs. To proceed
            please update your support needs.
          </Alert>
        )}
        <CenterAlignedButtonContainer>
          <div className="d-grid gap-2">
            <Button
              className="px-5"
              type="submit"
              id="btnSupportNeedsFormSubmit"
            >
              Submit
            </Button>
            <Button
              onClick={onPrevious}
              variant="Link"
              className="text-decoration-none"
              size="md"
            >
              <strong>{"< Previous"}</strong>
            </Button>
          </div>
        </CenterAlignedButtonContainer>
      </Form>
    </FormProvider>
  );
};

SupportNeedsForm.propTypes = {
  defaultValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      groupName: PropTypes.string,
      description: PropTypes.string,
      notes: PropTypes.string,
      isOpted: PropTypes.bool,
    })
  ),
  onFormSubmit: PropTypes.func,
};
export default SupportNeedsForm;

export { sortOptions, sortGroups, extractGroupNames };
