import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { QuestionnaireRadioField } from "../fields";
import * as fieldSchemas from "../schemas/fields";

const QuestionnaireForm = ({ questionNumber, questionData, onFormSubmit }) => {
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.questionnaireRadioSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit, reset } = methods;

  const remapAnswersToRadio = (answers) => {
    let radioOptions = [];
    answers.forEach((answer) => {
      let option = {
        id: answer.answerId,
        text: answer.answerText,
      };
      radioOptions.push(option);
    });

    return radioOptions;
  };

  const onSubmit = (data) => {
    let submitData = {
      questionId: questionData?.questionId,
      answerId: data.questionnaireRadio,
    };

    onFormSubmit(submitData);
    reset({ questionnaireRadio: null });
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="QuestionnaireForm"
      >
        <QuestionnaireRadioField
          questionNumber={questionNumber}
          questionText={questionData.questionText}
          questionHint={questionData.questionHint}
          answers={remapAnswersToRadio(questionData.answers)}
        />
        <div className="d-grid gap-2">
          <Button
            className="px-5 col-sm-8 col-md-5"
            type="submit"
            id="QuestionnaireFormSubmitBtn"
          >
            Submit answer
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

QuestionnaireForm.propTypes = {
  questionData: PropTypes.shape({
    questionText: PropTypes.string,
    questionHint: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }),
  onFormSubmit: PropTypes.func,
};
export default QuestionnaireForm;
