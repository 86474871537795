import { Button, Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import BasePage from "../../basepages/basepage/BasePage";
import useAuth from "../../../hooks/use-auth/useAuth";
import {
  step,
  reset,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import PageHeading from "../../../component/page-heading/PageHeading";

const IntroductionPage = () => {
  const { auth } = useAuth();
  const userRegulated = auth?.user?.regulated;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onNewQuote = () => {
    dispatch(step(steps.newQuote.vehicleSelection));
    navigate("vehicle-selection");
  };

  const onExistingQuotes = () => {
    dispatch(step(steps.existingQuotes));
    navigate("existing-quotes");
  };

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <BasePage
      pageTitle="Contract amendment"
      pageSubTitle="Request to change your contract term and/or mileage."
    >
      <PageHeading align="left">What is a contract amendment?</PageHeading>
      <p>
        A contract amendment is the term we use to describe any changes you make
        to a vehicle's contract length or mileage allowance.
      </p>
      <p>
        If your circumstances change, you may find adjusting your contract
        length, mileage allowance, or both, may suit your needs better. For
        example, a new job or a house move might mean a different commute, which
        could impact how many miles you'll need.
      </p>
      <p>
        You can make amends to your mileage and contract length whenever you
        want, as long as they fit within the requirements we've given below. Any
        contract amends are subject to status and credit checks. We'll ask your
        permission before doing any checks, and these may show on your credit
        file.
      </p>
      <p>
        If you're experiencing money worries, reducing the length of your
        contract through a contract amendment may not be the best solution for
        you as this could increase your monthly payments. Please give us a call
        on <Link to="tel:0800 015 4140">0800 015 4140</Link> and we can help you
        look at your options. We're here 8am - 5.30pm, Monday - Friday. You can
        also find other ways to get support on our{" "}
        <Link
          to={
            userRegulated === true
              ? "/help/money-worries"
              : "/help/money-worries-non-regulated"
          }
        >
          money worries page
        </Link>
        .
      </p>
      <Accordion className="border-top my-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            What can you change in a contract amendment?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                You can extend your contract, up to a maximum total contract
                length of 60 months (five years). The total length includes your
                original contract length and your requested extension. So, if
                your current contract is for 36 months (three years), the
                maximum extension you can get is 24 months (two years).
              </li>
              <li>
                You can reduce your contract, down to a minimum of 24 months
                (two years).
              </li>
              <li>
                You can increase your mileage if you have at least three months
                left on your contract, up to a maximum of
                <ul>
                  <li>
                    120,000 miles for petrol, hybrid, or electric vehicles.
                  </li>
                  <li>
                    150,000 miles for light commercial petrol, hybrid, or
                    electric vehicles.
                  </li>
                  <li>150,000 miles for diesel vehicles.</li>
                  <li>180,000 miles for light commercial diesel vehicles.</li>
                </ul>
                This is the maximum mileage across the whole of your agreement,
                including any extension.
              </li>
              <li>
                You can reduce your mileage, down to a minimum of 5,000 miles
                over your full contract, as long as you have at least three
                months left on your contract.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <h2 className="h3 text-primary">Next steps for changing your contract</h2>
      <ol>
        <li>
          Choose your new contract length, mileage allowance, or both - and
          you'll see how much it will cost per month compared to your current
          contract.
        </li>
        <li>
          If you'd like to go ahead - we'll ask for some additional personal and
          financial information from you so we can complete our credit checks.
          We'll ask your permission before doing these checks, and they may show
          on your credit file.
        </li>
        <li>
          If we approve your application, we'll ask you to review and sign your
          amended contract if you're happy with it.
        </li>
      </ol>
      <p>
        You'll start paying your new monthly rental from the date you've agreed.
      </p>
      <CenterAlignedButtonContainer>
        <Button id="startNewQuote" onClick={onNewQuote}>
          Start new quote
        </Button>
        <Button
          id="btnViewExistingQuotes"
          onClick={onExistingQuotes}
          variant="outline-primary"
        >
          View existing quotes
        </Button>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default IntroductionPage;
