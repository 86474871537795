import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  NavItem,
  NavLink,
  TabContainer,
  TabContent,
  TabPane,
  Row,
  Col,
  Nav,
  Container,
} from "react-bootstrap";
import WizardBasePage from "../../pages/basepages/wizard-basepage/WizardBasePage";
import OrdersBanner from "../../component/orders/OrdersBanner";
import CurrentAndHistoricalOrders from "../../component/orders/CurrentAndHistoricalOrders";
import { useOrders } from "../../api/hooks/orders";

import { reset } from "../../redux/features/order-review/orderReviewSlice";

const OrdersPage = () => {
  const dispatch = useDispatch();

  const ordersLimit = 6;
  let currentOrdersLength = 0;
  let historicalOrdersLength = 0;
  let [maxCurrentOrdersLimit, setMaxCurrentOrdersLimit] = useState(ordersLimit);
  let [maxHistoricalOrdersLimit, setMaxHistoricalOrdersLimit] =
    useState(ordersLimit);
  const { orders, isError, errorResponse } = useOrders();

  let loadMoreOrders = (ordersType) => {
    if (ordersType === "current") {
      setMaxCurrentOrdersLimit(maxCurrentOrdersLimit + ordersLimit);
    } else if (ordersType === "historical") {
      setMaxHistoricalOrdersLimit(maxHistoricalOrdersLimit + ordersLimit);
    }
  };

  currentOrdersLength = orders?.current?.length;
  historicalOrdersLength = orders?.historical?.length;

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <WizardBasePage
      pageTitle="Your orders"
      pageSubTitle="Choose the current order you wish to sign."
      wizardContent={
        <OrdersBanner>
          <p className="m-0 lead">
            You can find orders you've previously signed in historical orders.
          </p>
        </OrdersBanner>
      }
      isMainContainerFluid={true}
    >
      <>
        <TabContainer defaultActiveKey="currentOrders">
          <Row className="bg-light-opaque">
            <Col>
              <Container>
                <Nav className="row nav-tabs">
                  <NavItem className="col-6 pe-0 mb-0 h3 d-flex justify-content-center">
                    <NavLink
                      eventKey="currentOrders"
                      className="bg-transparent text-center py-4 px-3 text-decoration-none d-block w-100"
                    >
                      Current orders{" "}
                      {currentOrdersLength > 0 && <>({currentOrdersLength})</>}
                    </NavLink>
                  </NavItem>
                  <NavItem className="col-6 ps-0 mb-0 h3 d-flex justify-content-center">
                    <NavLink
                      eventKey="historicalOrders"
                      className="bg-transparent text-center py-4 px-3 text-decoration-none d-block w-100"
                    >
                      Historical orders{" "}
                      {historicalOrdersLength > 0 && (
                        <>({historicalOrdersLength})</>
                      )}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container>
                <TabContent>
                  <TabPane eventKey="currentOrders">
                    <CurrentAndHistoricalOrders
                      ordersData={orders}
                      loadMoreOrders={loadMoreOrders}
                      maxOrdersLimit={maxCurrentOrdersLimit}
                      isError={isError}
                      errorResponse={errorResponse}
                    />
                  </TabPane>
                  <TabPane eventKey="historicalOrders">
                    <CurrentAndHistoricalOrders
                      ordersData={orders}
                      loadMoreOrders={loadMoreOrders}
                      maxOrdersLimit={maxHistoricalOrdersLimit}
                      ordersType="historical"
                      isError={isError}
                      errorResponse={errorResponse}
                    />
                  </TabPane>
                </TabContent>
              </Container>
            </Col>
          </Row>
        </TabContainer>
      </>
    </WizardBasePage>
  );
};

export default OrdersPage;
