import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../../contract-amends-stepper/ContractAmendsStepper";
import {
  pageTitle,
  pageSubTitle,
} from "../../utils/contract-amends-page-utils";
import CenterAlignedContainer from "../../../../component/center-aligned-container/CenterAlignedContainer";
import QuoteDetailsPreview from "../../../../component/contract-amends/quote-details-preview/QuoteDetailsPreview";
import { formatCurrency } from "../../../../utils/pages/number-format-utils";
import steps from "../../contract-amends-steps";
import useContractAmendsStepCheck from "../../hooks/useContractAmendsStepCheck";
import ReviewApplicationForm from "../../../../component/forms/contract-amendment/ReviewApplicationForm";
import ContractAmendmentsPreview from "../../../../component/contract-amends/bch/contract-amendments-preview/ContractAmendmentsPreview";
import {
  currentPartnerIndex,
  step,
} from "../../../../redux/features/contract-amends/contractAmendsSlice";
import useReviewApplication from "../../hooks/useReviewApplication";
import useAuth from "../../../../hooks/use-auth/useAuth";
import { useContractAmendSubmitProposal } from "../../../../api/hooks/contracts";
import PageHeading from "../../../../component/page-heading/PageHeading";
import magicStrings from "../../../../utils/magic-string";

const ReviewApplicationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSoleTrader = useSelector(
    (state) => state.contractAmends.isSoleTrader
  );

  let currentStep;
  let pageToNavigate;

  currentStep = isSoleTrader
    ? steps.bch.soleTrader.reviewApplication
    : steps.bch.limitedCompany.reviewApplication;
  pageToNavigate = "../current-address";

  useContractAmendsStepCheck(currentStep, pageToNavigate);

  const savedAmendment = useSelector(
    (state) => state.contractAmends.proposedAmendment
  );

  const contract = useSelector((state) => state.contractAmends.contractDetails);
  const partners = useSelector((state) => state.contractAmends.partners);
  const deletedPartners = useSelector(
    (state) => state.contractAmends.deletedPartners
  );
  const { auth } = useAuth();
  const { getApplicationPayload } = useReviewApplication();

  const onSubmitProposalSuccessful = (data) => {
    if (data && data.caProposalDecisionStatus) {
      const decision = data.caProposalDecisionStatus.toLowerCase();
      if (decision === "accept") {
        dispatch(step(steps.applicationSuccess));
        navigate("../../application-success");
      } else if (decision === "refer") {
        dispatch(step(steps.applicationReferred));
        navigate("../../application-referred");
      } else if (decision === "decline") {
        dispatch(step(steps.applicationDeclined));
        navigate("../../application-declined");
      } else {
        dispatch(step(steps.customerServices));
        navigate("../../customer-services");
      }
    } else {
      dispatch(step(steps.customerServices));
      navigate("../../customer-services");
    }
  };

  const onSubmitProposalFailure = () => {
    dispatch(step(steps.customerServices));
    navigate("../../customer-services");
  };

  const { mutate } = useContractAmendSubmitProposal(
    onSubmitProposalSuccessful,
    onSubmitProposalFailure
  );

  const onFormSubmit = (data) => {
    const payload = getApplicationPayload(
      auth?.user?.counterParty?.code,
      null,
      partners,
      deletedPartners,
      isSoleTrader
    );
    mutate({
      payload,
      contractId: contract.contractId,
      caNumber: savedAmendment.caNumber,
    });
  };

  const onEditPartners = () => {
    dispatch(currentPartnerIndex(0));
    if (isSoleTrader) {
      dispatch(step(steps.bch.soleTrader.personalDetails));
      navigate(-1);
    } else {
      dispatch(step(steps.bch.limitedCompany.partners[0].personalDetails));
      navigate(-2);
    }
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Review application</PageHeading>
          <br />
          <div className="text-center">
            <div className="h3 fw-bold mb-0">Registration:</div>
            <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
              {contract?.vehicle?.registrationNumber}
            </div>
            <div className={`${magicStrings.maskInformation}`}>
              {contract?.vehicle?.description}
            </div>
            <div className="h3 fw-bold mb-0 mt-4">Total rental payment:</div>
            <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
              {formatCurrency(savedAmendment?.proposedRentalPayment)}
            </div>
            <div>
              {savedAmendment?.vatInclusive ? "including VAT" : "excluding VAT"}
            </div>
          </div>
        </div>
      </CenterAlignedContainer>
      <br />
      <br />
      {contract && contract.contractId && savedAmendment && (
        <QuoteDetailsPreview
          currentContract={contract}
          proposedAmendment={savedAmendment}
        />
      )}
      <ContractAmendmentsPreview
        partners={partners}
        onEdit={onEditPartners}
      ></ContractAmendmentsPreview>
      <br />
      <ReviewApplicationForm onFormSubmit={onFormSubmit} />
    </WizardBasePage>
  );
};

export default ReviewApplicationPage;
