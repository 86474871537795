const isActiveCreditInvoiceWithOutstandingBalance = (invoice) => {
  return (
    typeof invoice.transactionCategory === "string" &&
    invoice.transactionCategory.toUpperCase() === "ACTIVE" &&
    typeof invoice.type === "string" &&
    invoice.type.toUpperCase() === "CREDIT" &&
    invoice.outstandingBalance
  );
};

const isActiveInvoiceWithOutstandingBalance = (invoice) => {
  return (
    typeof invoice.transactionCategory === "string" &&
    invoice.transactionCategory.toUpperCase() === "ACTIVE" &&
    invoice.outstandingBalance &&
    typeof invoice.type === "string"
  );
};

const useInvoiceSelection = () => {
  const parseInvoices = (invoicesToParse) => {
    let creditInvoices = [];
    let outstandingBalance = 0.0;
    let invoicesToPay = [];
    let balanceToPay = 0.0;

    if (Array.isArray(invoicesToParse)) {
      let invoices = JSON.parse(JSON.stringify(invoicesToParse));

      invoices
        .filter((invoice) => {
          return isActiveCreditInvoiceWithOutstandingBalance(invoice);
        })
        .forEach((invoice) => {
          if (invoice.outstandingBalance < 0.0) {
            creditInvoices.push(invoice);
          }
          outstandingBalance =
            outstandingBalance + parseFloat(invoice.outstandingBalance);
        });

      invoices
        .filter((invoice) => {
          return isActiveInvoiceWithOutstandingBalance(invoice);
        })
        .forEach((invoice) => {
          if (
            invoice.outstandingBalance > 0.0 &&
            invoice.type.toUpperCase() === "INVOICE"
          ) {
            balanceToPay =
              balanceToPay + parseFloat(invoice.outstandingBalance);
            let currentInvoice = invoicesToPay.find(
              (currentInvoice) =>
                currentInvoice.referenceNumber === invoice.referenceNumber
            );
            if (currentInvoice) {
              let combinedAmount =
                parseFloat(currentInvoice.outstandingBalance) +
                parseFloat(invoice.outstandingBalance);
              currentInvoice.outstandingBalance = currentInvoice.totalAmount =
                combinedAmount;
            } else {
              invoice.isSelected = false;
              invoicesToPay.push(invoice);
            }
          }
        });
      if (invoicesToPay.length === 1) {
        invoicesToPay[0].isSelected = true;
      }
    }
    return { invoicesToPay, creditInvoices, balanceToPay };
  };

  return { parseInvoices };
};

export default useInvoiceSelection;
