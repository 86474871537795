import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import BasePage from "../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import { reset } from "../../../redux/features/support-needs/supportNeedsSlice";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";

const SupportNeedsSuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const onReturnHome = () => {
    navigate("/home", { replace: true });
  };

  return (
    <BasePage
      pageTitle="Help and support"
      pageSubTitle="Find help when you need it."
    >
      <TitleWithIcon title="We've updated our records" variant="success" />
      <h3 className="h1">Thanks for letting us know</h3>
      <p className="mb-4">
        We'll keep your needs in mind when communicating with you. Remember to
        let us know if they change.
      </p>
      <h3 className="mt-5">
        <strong>Need a letter in a different format?</strong>
      </h3>
      <p>
        Let us know if you need a letter in large format print, Braille or audio
        by calling <Link to="tel:08000684025">0800 068 4025</Link>.
      </p>
      <p>
        If getting in touch with us by email is better for you, then you can
        reach us at{" "}
        <Link to="mailto: customer.services@lexautolease.co.uk">
          customer.services@lexautolease.co.uk
        </Link>
        .
      </p>

      <hr className="my-5" />
      <h3>
        <strong>Money worries or problems?</strong>
      </h3>
      <p>
        We're here to help if you need support managing your{" "}
        <Link to="/help/money-worries-non-regulated">
          car finances and other money worries.
        </Link>
      </p>
      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" onButtonClicked={onReturnHome} />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default SupportNeedsSuccessPage;
