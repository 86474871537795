import { Route, Routes } from "react-router-dom";

import IntroductionPage from "../pages/early-termination/introduction/IntroductionPage";
import VehicleSelectionPage from "../pages/early-termination/new-quote/vehicle-selection/VehicleSelectionPage";
import QuoteDetailsPage from "../pages/early-termination/quote-details/QuoteDetailsPage";
import AcceptQuotePage from "../pages/early-termination/accept-quote/AcceptQuotePage";
import SavedQuotesPage from "../pages/early-termination/existing-quote/saved-quotes/SavedQuotesPage";
import NotAllowedPage from "../pages/early-termination/not-allowed/NotAllowedPage";
import NotRequiredPage from "../pages/early-termination/not-required/NotRequiredPage";
import ReferredPage from "../pages/early-termination/referred/ReferredPage";
import ThankYouPage from "../pages/early-termination/thank-you/ThankYouPage";
import ErrorNotFoundPage from "../pages/error/page-not-found/ErrorPageNotFoundPage";
const EarlyTerminationRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<ErrorNotFoundPage />} />
      <Route index element={<IntroductionPage />} />
      <Route path="/new-quote/*">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route
          index
          path="vehicle-selection"
          element={<VehicleSelectionPage />}
        />
        <Route path="quote-details" element={<QuoteDetailsPage />} />
        <Route path="accept-quote" element={<AcceptQuotePage />} />
      </Route>
      <Route path="/existing-quote/*">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route index path="saved-quotes" element={<SavedQuotesPage />} />
        <Route
          path="quote-details"
          element={<QuoteDetailsPage isETNewQuote={false} />}
        />
        <Route
          path="accept-quote"
          element={<AcceptQuotePage isETNewQuote={false} />}
        />
      </Route>
      <Route path="not-allowed" element={<NotAllowedPage />} />
      <Route path="not-required" element={<NotRequiredPage />} />
      <Route path="referred" element={<ReferredPage />} />
      <Route path="thank-you" element={<ThankYouPage />} />
    </Routes>
  );
};

export default EarlyTerminationRoutes;
