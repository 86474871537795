import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import BasePage from "../../basepages/basepage/BasePage";
import NotificationBanner from "../../../component/notification-banner/NotificationBanner";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { formatCurrency } from "../../../utils/pages/number-format-utils";
import { useTransactions } from "../../../api/hooks/transactions";
import WarningOutlineIcon from "../../../component/icons/WarningOutlineIcon";
import useInvoiceSelection from "./hooks/useInvoiceSelection";
import { formatDate } from "../../../utils/pages/date-format-utils";
import RoundedPill from "../../../component/rounded-pill/RoundedPill";
import { transactionStatusDetails } from "../../transactions/transaction-data";
import {
  creditInvoices as dispathCreditInvoices,
  selectedInvoices as dispatchSelectedInvoices,
  step,
} from "../../../redux/features/payments/cardPaymentSlice";
import steps from "../card-payment/card-payment-steps";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";

const InvoiceSelectionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isStepChecked, setIsStepChecked] = useState(false);
  const currentStep = useSelector((state) => state.cardPayment.step);
  const { transactions, isError } = useTransactions();
  const { parseInvoices } = useInvoiceSelection();

  const [savedInvoices, setSavedInvoices] = useState([]);
  const [total, setTotal] = useState(0);
  const [creditInvoices, setCreditInvoices] = useState([]);
  const [isOverdueWarningShown, setIsOverdueWarningShown] = useState(false);
  const [showNoInvoicesSelectedWarning, setShowNoInvoicesSelectedWarning] =
    useState(false);

  useEffect(() => {
    if (!isStepChecked) {
      setIsStepChecked(true);
      if (currentStep < steps.invoiceSelection) {
        navigate("../../make-a-payment", { replace: true });
      } else if (
        currentStep > steps.invoiceSelection &&
        currentStep !== steps.noPaymentRequired
      ) {
        dispatch(step(steps.invoiceSelection));
      }
    }
  }, [currentStep, dispatch, isStepChecked, navigate]);

  useEffect(() => {
    if (Array.isArray(transactions)) {
      const { invoicesToPay, creditInvoices, balanceToPay } =
        parseInvoices(transactions);
      setSavedInvoices(invoicesToPay);
      setCreditInvoices(creditInvoices);
      if (balanceToPay <= 0.0) {
        dispatch(step(steps.noPaymentRequired));
        navigate("../no-payment-required", { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [transactions, navigate]);

  useEffect(() => {
    calculateTotal(savedInvoices);
  }, [savedInvoices]);

  const calculateTotal = (invoices) => {
    let totalToSave = 0.0;
    if (Array.isArray(invoices)) {
      invoices.forEach((invoice) => {
        if (invoice.isSelected) {
          totalToSave =
            parseFloat(totalToSave) + parseFloat(invoice.totalAmount);
        }
      });
    }
    setTotal(totalToSave);
  };

  const onInvoiceClicked = (referenceNumber) => {
    const invoicesToSave = [...savedInvoices];
    const currentInvoice = invoicesToSave.findIndex(
      (i) => i.referenceNumber === referenceNumber
    );

    if (currentInvoice >= 0) {
      invoicesToSave[currentInvoice].isSelected =
        !invoicesToSave[currentInvoice].isSelected;
      setSavedInvoices(invoicesToSave);
    }
    setShowNoInvoicesSelectedWarning(false);
  };

  const isAnyOverdueInvoiceNotSelected = () => {
    return savedInvoices.some((invoice) => {
      return (
        !invoice.isSelected &&
        typeof invoice.status === "string" &&
        invoice.status.toUpperCase() === "OVERDUE"
      );
    });
  };

  const isAnyInvoiceSelected = () => {
    return savedInvoices.some((invoice) => {
      return invoice.isSelected;
    });
  };

  const getSelectedInvoices = () => {
    return savedInvoices
      .filter((invoice) => {
        return invoice.isSelected;
      })
      .map((invoice) => {
        return {
          referenceNumber: invoice.referenceNumber,
          totalAmount: invoice.totalAmount,
        };
      });
  };

  const onContinueClicked = () => {
    //todo: Need to show error here if invoices are selected
    if (!isAnyInvoiceSelected()) {
      setShowNoInvoicesSelectedWarning(true);
    } else if (isAnyOverdueInvoiceNotSelected() && !isOverdueWarningShown) {
      setIsOverdueWarningShown(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (Array.isArray(creditInvoices) && creditInvoices.length > 0) {
      dispatch(dispatchSelectedInvoices(getSelectedInvoices()));
      dispatch(dispathCreditInvoices(creditInvoices));
      dispatch(step(steps.creditBalance));
      navigate("../credit-balance");
    } else {
      dispatch(dispatchSelectedInvoices(getSelectedInvoices()));
      dispatch(step(steps.payeeDetails));
      navigate("../payee-details");
    }
  };

  return (
    <BasePage pageTitle="Which invoices would you like to pay?">
      {isOverdueWarningShown && (
        <>
          <NotificationBanner size="lg" variant="warning" isFullHeight={true}>
            <div className="d-flex flex-row p-sm-2 p-md-3 p-lg-5">
              <div className="text-center me-3 mt-1 text-warning">
                <WarningOutlineIcon title="Overdue invoices" size="lg" />
              </div>
              <div className="ms-3">
                <div className="mb-2">
                  Are you sure you want to continue without selecting your
                  overdue payment?
                </div>
                Overdue payments can negatively impact your credit score.
              </div>
            </div>
          </NotificationBanner>
        </>
      )}

      <PageHeading>Select the payments you want to make today</PageHeading>

      {isError && (
        <Alert variant="danger">
          Sorry, we are unable to retrieve your invoices. Please try again and
          if the problem continues, contact us.
        </Alert>
      )}

      <div role="table" className="mt-5">
        {!isError &&
          savedInvoices.map((invoice, index) => {
            return (
              <Row key={index} role="row">
                <Col xs={12} md={3} role="cell">
                  <Form.Check
                    className={`${magicStrings.maskInformation}`}
                    aria-label={`Invoice ${invoice.description}`}
                    type="checkbox"
                    id={`invoice-${invoice.referenceNumber}`}
                    label={`${formatCurrency(invoice.totalAmount)}`}
                    checked={invoice.isSelected}
                    onChange={() => onInvoiceClicked(invoice.referenceNumber)}
                  ></Form.Check>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  xl={7}
                  className={`my-3 my-md-0 ${magicStrings.maskInformation}`}
                  role="cell"
                >
                  {`${invoice.description} | ${formatDate(
                    invoice.transactionDate
                  )}`}
                </Col>
                <Col xs={6} sm={3} xl={2} role="cell">
                  <RoundedPill
                    variant={
                      transactionStatusDetails(invoice.status)?.variant ??
                      "info"
                    }
                    toolTip={
                      transactionStatusDetails(invoice.status)?.tooltipContent
                    }
                  >
                    {transactionStatusDetails(invoice.status)?.status ?? "N/A"}
                  </RoundedPill>
                </Col>
                <Col xs={12}>
                  <hr role="presentation" />
                </Col>
              </Row>
            );
          })}
      </div>

      {total !== 0.0 && (
        <>
          <div role="alert">
            Total payment:{" "}
            <strong className={`${magicStrings.maskInformation}`}>
              {formatCurrency(total)}
            </strong>
          </div>
          <hr />
        </>
      )}

      <CenterAlignedButtonContainer>
        {showNoInvoicesSelectedWarning && (
          <Alert variant="danger">
            You have not selected any invoices. Please select at least one
            invoice before proceeding.
          </Alert>
        )}
        <Button
          id="btnInvoiceSelection"
          className="text-decoration-none"
          variant="primary"
          onClick={onContinueClicked}
        >
          Continue
        </Button>
        <BackToHomeButton>Cancel</BackToHomeButton>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default InvoiceSelectionPage;
