import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../contract-amends-stepper/ContractAmendsStepper";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import {
  step,
  piFinancialDetails,
  reset,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import FinancialDetailsForm from "../../../component/forms/contract-amendment/FinancialDetailsForm";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import PageHeading from "../../../component/page-heading/PageHeading";

const FinancialDetailsPage = () => {
  useContractAmendsStepCheck(
    steps.newQuote.financialDetails,
    "../employment-details"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const financialDetails = useSelector(
    (state) => state.contractAmends.privateIndividual.financialDetails
  );

  const onFormSubmit = (data) => {
    dispatch(piFinancialDetails(data));
    dispatch(step(steps.newQuote.reviewApplication));
    navigate("../review-application");
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <div className="text-center">
        <PageHeading>Financial details</PageHeading>
        <CenterAlignedContainer>
          <p>Let us know about your current income and expenditure.</p>
          <p>
            It's important you provide accurate information, or we may not be
            able to process your request. Find out what you should include in
            our{" "}
            <Link to="../income-and-expenditure-information">
              Income and Expenditure Guide
            </Link>
            .
          </p>
        </CenterAlignedContainer>
      </div>
      <CenterAlignedButtonContainer>
        <FinancialDetailsForm
          onFormSubmit={onFormSubmit}
          defaultValues={financialDetails}
        />
        <BackToHomeButton onButtonClicked={() => dispatch(reset())} />
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default FinancialDetailsPage;
