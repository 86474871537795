import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { Form, Button, Alert } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { ConfirmSupportNeedsTermsCheckbox } from "../../../component/forms/fields/accessibility-support-fields";
import {
  extractGroupNames,
  sortGroups,
} from "../../../component/forms/accessibility-support/SupportNeedsForm";
import * as fieldSchemas from "../../../component/forms/schemas/fields";
import BasePage from "../../basepages/basepage/BasePage";
import { useUpdateSupportNeeds } from "../../../api/hooks/support-needs";
import useSupportNeedsStepCheck from "./hooks/useSupportNeedsStepCheck";
import steps from "./support-needs-steps";
import { step } from "../../../redux/features/support-needs/supportNeedsSlice";
import CloseDdIcon from "../../../component/icons/CloseDdIcon";
import TickOutlineIcon from "../../../component/icons/TickOutlineIcon";
import EditIcon from "../../../component/icons/EditIcon";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";

const ConfirmSupportNeedsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useSupportNeedsStepCheck(
    steps.home,
    steps.confirmNeeds,
    "/help/accessibility-home"
  );

  const accessibilityNeeds = useSelector(
    (state) => state.supportNeeds.supportNeeds
  );

  const selectedNeeds = useSelector(
    (state) => state.supportNeeds.selectedSupportNeeds
  );

  const deselectedNeeds = useSelector(
    (state) => state.supportNeeds.deselectedSupportNeeds
  );

  const generateSummarySelection = () => {
    const newSelection =
      accessibilityNeeds
        .filter((option) => selectedNeeds.includes(option.id.toString()))
        .map((option) => {
          return { ...option, isOpted: true };
        }) ?? [];

    const newDeselection =
      accessibilityNeeds
        .filter((option) => deselectedNeeds.includes(option.id.toString()))
        .map((option) => {
          return { ...option, isOpted: false };
        }) ?? [];

    const selectionSummary = newSelection.concat(newDeselection);

    return selectionSummary;
  };

  const updatedSupportNeeds = generateSummarySelection() ?? [];

  const [sortedGroups] = useState(
    () =>
      sortGroups(extractGroupNames(updatedSupportNeeds, "groupName") ?? {}) ??
      []
  );

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.confirmSupportNeedsTermsSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const [showError, setShowError] = useState(false);

  const onUpdateSuccessful = (data) => {
    dispatch(step(steps.updatedNeeds));
    navigate("../thank-you");
  };

  const onUpdateFailure = () => {
    setShowError(true);
  };

  const { mutate } = useUpdateSupportNeeds(onUpdateSuccessful, onUpdateFailure);

  const onFormSubmit = () => {
    setShowError(false);
    mutate(
      updatedSupportNeeds.map((option) => ({
        id: option.id,
        isOpted: option.isOpted,
      }))
    );
  };

  const onPrevious = () => {
    navigate(-1);
  };

  const onEdit = () => {
    navigate(-1);
  };

  return (
    <BasePage
      pageTitle="Help and support"
      pageSubTitle="Find help when you need it."
    >
      <PageHeading align="left">Confirm your support needs</PageHeading>
      <p>
        Please check and confirm your support needs. You can update or remove
        them at any time if you need to.
      </p>
      <h3 className="mt-5">Here's what you've told us</h3>
      <hr className="mb-2" />
      {sortedGroups?.length > 0 ? (
        sortedGroups?.map((group, index) => {
          return (
            <div key={"updatedNeeds" + index} className="mb-5">
              <h3
                className={`w-100 h4 mt-3 mb-3 ${magicStrings.maskInformation}`}
                key={group[0]}
              >
                {group[0]}
              </h3>
              {group[1].map((item) => {
                return (
                  <div
                    className="d-flex flex-row align-items-center mb-4"
                    key={"option" + item.id}
                  >
                    <div className="text-center">
                      {item.isOpted ? (
                        <TickOutlineIcon
                          title="selected"
                          className="align-center text-success me-2 d-inline-block"
                          size="sm"
                        />
                      ) : (
                        <CloseDdIcon
                          title="deselected"
                          className="align-center me-2 d-inline-block alert-danger text-danger"
                          size="sm"
                        />
                      )}
                    </div>
                    <div className={`${magicStrings.maskInformation}`}>
                      {item.description}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })
      ) : (
        <Alert className="my-4" variant="danger">
          Sorry, we are unable to retrieve any help and support needs.
        </Alert>
      )}
      <Link
        onClick={onEdit}
        to="../"
        className="d-flex flex-row align-items-center mt-3 mb-2"
      >
        <strong>Edit support needs</strong>
        <div className="text-center">
          <EditIcon
            title="Edit"
            className="align-center ms-2 d-inline-block text-primary"
            size="sm"
          />
        </div>
      </Link>
      <hr className="mt-2 mb-5" />
      <div className="mt-2">
        <p>
          See our{" "}
          <strong>
            <Link to="/privacy" target="_blank">
              data privacy notice
            </Link>
          </strong>{" "}
          to check how we use and store your information so it stays safe and
          confidential.
        </p>
      </div>
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onFormSubmit)}
          noValidate
          name="confirmSupportNeedsForm"
          style={{ fontSize: "1.05rem" }}
        >
          <h3 className="mt-4 mb-3">Confirm your support needs</h3>
          <ConfirmSupportNeedsTermsCheckbox />
          {showError && (
            <Alert
              variant="danger"
              onClose={() => setShowError(false)}
              dismissible
            >
              There was an error updating your support needs. Please try again.
            </Alert>
          )}
          <CenterAlignedButtonContainer>
            <div className="d-grid gap-2">
              <Button
                className="px-5"
                type="submit"
                id="btnConfirmSupportNeedsFormSubmit"
                disabled={updatedSupportNeeds?.length < 1}
              >
                Submit
              </Button>
              <Button
                onClick={onPrevious}
                variant="Link"
                className="text-decoration-none"
                size="md"
              >
                <strong>{"< Previous"}</strong>
              </Button>
            </div>
          </CenterAlignedButtonContainer>
        </Form>
      </FormProvider>
    </BasePage>
  );
};

export default ConfirmSupportNeedsPage;
