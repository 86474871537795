import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Questionnaire from "../../../../component/questionnaire/Questionnaire";
import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import { step } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";
import CollectionStepper from "../../collection-stepper/CollectionStepper";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import PageHeading from "../../../../component/page-heading/PageHeading";

const Question4Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useVehicleCollectionStepCheck(steps.roadWorthyQuestion4, "../question3");

  const onActionClicked = (result) => {
    dispatch(step(steps.roadWorthyQuestion5));
    if (result === 2) {
      navigate("../question5");
    } else if (result === 1) {
      navigate("../not-road-worthy");
    }
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      <PageHeading align="left">Arranging a collection</PageHeading>
      <p className="mb-5">
        We need to ask you some questions to check that your vehicle is
        roadworthy and ready to be collected. If a collection is arranged and
        the vehicle is unsafe to drive your collection will be cancelled and you
        will be charged a fee.
      </p>
      <h3>
        Question 4 of 6: Are there any engine management warning lights
        displayed?
      </h3>
      <p>
        Your collection may be cancelled if the vehicle has engine management
        warning lights displayed.* We recommend that you arrange for them to be
        resolved prior to arranging your collection.
      </p>
      <p className="mb-5">
        *Vehicles may still be collected with an active service reminder light,
        however, if the vehicle is returned and found to have not been serviced
        in accordance to the manufacturers servicing/maintenance schedule this
        may result in de-hire damage charges.
      </p>
      <Questionnaire
        option1Title="Yes"
        option2Title="No"
        option1Content="There are one or more engine management warning lights displayed."
        option2Content="There are no engine management warning lights displayed."
        option1ActionContent="Yes"
        option2ActionContent="No"
        actionCallback={onActionClicked}
      />
    </WizardBasePage>
  );
};

export default Question4Page;
