import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import queryKeys from "../react-query/queryKeys";
import queryClient from "../react-query/query-client";

const initialisePayment = async (axios, payload) => {
  const { data } = await axios.post(`/payment/card/initialise`, payload);
  return data;
};

const abandonPayment = async (axios, transactionKey, payload) => {
  const { data } = await axios.post(
    `/payment/card/${transactionKey}/abandon`,
    payload
  );
  return data;
};

const completePayment = async (axios, transactionKey, payload) => {
  const { data } = await axios.post(
    `/payment/card/${transactionKey}/complete`,
    payload
  );
  return data;
};

const useInitialisePayment = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((payload) => initialisePayment(axios, payload), {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: onError,
  });
};

const useAbandonPayment = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    ({ transactionKey, payload }) =>
      abandonPayment(axios, transactionKey, payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: onError,
    }
  );
};

const useCompletePayment = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    ({ transactionKey, payload }) =>
      completePayment(axios, transactionKey, payload),
    {
      onSuccess: (data) => {
        queryClient.resetQueries(queryKeys.transactions);
        queryClient.resetQueries(queryKeys.accountBalance);
        queryClient.resetQueries(queryKeys.invoices);

        onSuccess(data);
      },
      onError: onError,
    }
  );
};

export { useInitialisePayment, useAbandonPayment, useCompletePayment };
