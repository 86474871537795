// All users related api error for profile has need to map in the range of 1440001 to 1499999
// The number is divided into 3 parts
// First part is the api key range, for users forgot details will always be 14
// Second part is the http error status code e.g 400
// Last part is the identifier for the unique error message we want to display.
// Two server api error codes are okay to be mapped to single error code, if required.

const profileErrorCodeMappings = {
  400: {
    REUSED_PASSWORD: 1440001,
    INVALID_NEW_PASSWORD: 1440002,
  },
  401: {
    CODE_INVALID_ALIAS: 1440101,
  },
  403: {
    INVALID_CURRENT_PASSWORD: 1440301,
  },
  500: {
    DEFAULT_ERROR: 1450001,
  },
  // default is used to handle the case where it is an unknown http status code
  default: {
    default: 1499901,
    // This default is the absolute fallback error code for users profile api.
  },
};

export default profileErrorCodeMappings;
