import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { GenericPasswordField } from "./field-utils/field-utils.js";

// Current password fields

export const CurrentPasswordField = (props) => {
  return (
    <GenericPasswordField
      controlId="currentPassword"
      labelText="Current password"
      maxLength={20}
      {...props}
    />
  );
};

// Password and Confirm password fields with live validation

export const PasswordAndConfirmPasswordFields = ({
  passwordControlId,
  passwordLabelText,
  passwordConfirmControlId,
  passwordConfirmLabelText,
}) => {
  const methods = useFormContext();
  const { watch } = methods;

  let watchPassword = watch(`${passwordControlId}`, "");

  let upperCaseValidated = false;
  let lowerCaseValidated = false;
  let numberValidated = false;
  let specialCharValidated = false;
  let lengthValidated = false;

  const containsUppercase = (value) => {
    return /[A-Z]/.test(value);
  };
  const containsLowerCase = (value) => {
    return /[a-z]/.test(value);
  };
  const containsNumber = (value) => {
    return /[0-9]/.test(value);
  };
  const containsSpecialChar = (value) => {
    return /[!@#%^&*]/.test(value);
  };
  const containsLength = (value) => {
    return !(value.length < 8);
  };

  if (containsUppercase(watchPassword) === true) {
    upperCaseValidated = true;
  }
  if (containsLowerCase(watchPassword) === true) {
    lowerCaseValidated = true;
  }
  if (containsNumber(watchPassword) === true) {
    numberValidated = true;
  }
  if (containsSpecialChar(watchPassword) === true) {
    specialCharValidated = true;
  }
  if (containsLength(watchPassword) === true) {
    lengthValidated = true;
  }

  return (
    <>
      <GenericPasswordField
        controlId={`${passwordControlId}`}
        labelText={`${passwordLabelText}`}
        maxLength={20}
      />

      <GenericPasswordField
        controlId={`${passwordConfirmControlId}`}
        labelText={`${passwordConfirmLabelText}`}
        maxLength={20}
      />

      <p className="mb-0">Your password must have:</p>
      <ul className="input-requirements list-unstyled ps-0">
        <li
          className={`${
            lengthValidated ? "valid text-success" : "invalid text-danger"
          }`}
        >
          At least 8 characters.
        </li>
        <li
          className={`${
            upperCaseValidated ? "valid text-success" : "invalid text-danger"
          }`}
        >
          Contains uppercase letters.
        </li>
        <li
          className={`${
            lowerCaseValidated ? "valid text-success" : "invalid text-danger"
          }`}
        >
          Contains lowercase letters.
        </li>
        <li
          className={`${
            numberValidated ? "valid text-success" : "invalid text-danger"
          }`}
        >
          Contains numbers.
        </li>
        <li
          className={`${
            specialCharValidated ? "valid text-success" : "invalid text-danger"
          }`}
        >
          Contains special character.
        </li>
      </ul>
    </>
  );
};

PasswordAndConfirmPasswordFields.propTypes = {
  passwordControlId: PropTypes.string.isRequired,
  passwordLabelText: PropTypes.string.isRequired,
  passwordConfirmControlId: PropTypes.string.isRequired,
  passwordConfirmLabelText: PropTypes.string.isRequired,
};
