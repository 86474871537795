import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import BasePage from "../../basepages/basepage/BasePage";
import { useSubmitIiqDetails } from "../../../api/hooks/iiq";
import { setItem } from "../../../storage/SessionStorage";
import useRegistrationStepCheck from "../hooks/useRegistrationStepCheck";
import AuthContext from "../../../providers/auth-provider/AuthProvider";
import QuestionnaireForm from "../../../component/forms/register/QuestionnaireForm";
import MessageBox from "../../../component/modals/message-box/MessageBox";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";

const IiqPage = () => {
  const navigate = useNavigate();
  const [iiq, setIiq] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [questionNo, setQuestionNo] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const { setAuth } = useContext(AuthContext);

  const currentStatus = useRegistrationStepCheck("PENDINGCHECK");

  const onSuccessful = (data) => {
    const status = data?.user?.status.toUpperCase();
    if (status === "PENDINGPASSWORD") {
      setItem("user", data?.user);
      setAuth({ user: data?.user, accessToken: data?.accessToken });
      navigate("/user-credential", { replace: true });
    } else if (status === "PENDINGANSWER") {
      setQuestionNo(questionNo + 1);
      setIiq(data.user);
    } else if (status === "RETRYCHECK") {
      setOpenPopup(true);
    } else if (status === "OK") {
      setAuth({});
      navigate("/confirmation", { replace: true });
    }
  };

  const onFailure = (error) => {
    if (error?.localCode === 1240301) {
      setAuth({});
      navigate("/", {
        state: { logoutReason: magicStrings.accountLocked },
      });
    } else {
      setIiq({
        question: {
          transactionId: "",
          questionId: "",
          questionText: "",
          questionHint: "",
          answers: [],
        },
      });
      setQuestionNo(0);
      setErrorMessage(error?.localErrorMessage);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const { mutate } = useSubmitIiqDetails(onSuccessful, onFailure);

  useEffect(() => {
    if (currentStatus === "PENDINGCHECK") {
      mutate({
        transactionId: null,
        questionId: null,
        answerId: null,
      });
    }
  }, [mutate, currentStatus]);

  const resetIiqQuestion = () => {
    setOpenPopup(false);
    setQuestionNo(0);
    mutate({
      transactionId: null,
      questionId: null,
      answerId: null,
    });
  };

  const onFormSubmit = (data) => {
    const payload = {
      transactionId: iiq.question.transactionId,
      questionId: data.questionId,
      answerId: data.answerId,
    };
    setErrorMessage("");
    mutate(payload);
  };

  return (
    <BasePage
      pageTitle="ID verification"
      pageSubTitle="Answer a few security questions to proceed."
    >
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}
      <MessageBox
        title="ID Verification"
        show={openPopup}
        onClose={resetIiqQuestion}
        closeOnBackgroundClick={false}
        buttons={["OK"]}
      >
        Unfortunately the answers provided have not been validated. Please try
        again.
      </MessageBox>
      <PageHeading align="left">Why we are asking questions</PageHeading>

      <p>
        In order to confirm your identity we will ask a number of questions only
        you should know the answers to. We work with a credit reference agency
        to get the questions and answers.
      </p>
      <p>
        This identity check will be undertaken via the same Credit Reference
        Agency that we consulted during the agreement approval process, but will
        not result in an additional credit search. The search will check your
        details against records held.
      </p>
      {iiq?.question?.transactionId && (
        <QuestionnaireForm
          questionNumber={questionNo}
          questionData={iiq?.question}
          onFormSubmit={onFormSubmit}
        />
      )}
    </BasePage>
  );
};

export default IiqPage;
