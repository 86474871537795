import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const ArrowRightIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    style={{
      verticalAlign: "text-top",
    }}
    role="img"
    viewBox="0 0 11 18"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.977 9.25 1.105 3.133c-.187-.188-.187-.375 0-.563L2.16 1.516c.211-.188.399-.2.563-.036L9.895 8.97A.38.38 0 0 1 10 9.25a.38.38 0 0 1-.105.281L2.723 17.02c-.188.187-.375.187-.563 0l-1.055-1.09c-.187-.188-.187-.375 0-.563L6.977 9.25Z"
    />
  </svg>
);

ArrowRightIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { ArrowRightIcon };
export default ArrowRightIcon;
