import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Container, Row, Col, Button } from "react-bootstrap";
import InfoIcon from "../../../icons/InfoIcon";
import { findOrdinalByDay } from "../../../../utils/ordinal/ordinal-utils";
import NotificationBanner from "../../../notification-banner/NotificationBanner";
import BankAccountAndAddressDetails from "../bank-account-and-address-details/BankAccountAndAddressDetails";
import { reset } from "../../../../redux/features/payments/directDebitSlice";
import useAuth from "../../../../hooks/use-auth/useAuth";
import {
  useDirectDebitMandate,
  useSubmitDirectDebitDetails,
} from "../../../../api/hooks/direct-debit";
import magicStrings from "../../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../../api/error-handling/transform-error";

const ReviewDirectDebit = ({
  onSuccess,
  onCancel,
  isNewDirectDebitFlow = true,
}) => {
  const { auth } = useAuth();
  const profile = auth?.user;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState({});
  const isNewDirectDebit = isNewDirectDebitFlow === true ? true : false;
  const savedDirectDebitDetails = useSelector((state) => state.directDebit);
  const savedBankAccountAndAddressDetails =
    savedDirectDebitDetails?.bankAccountAndAddressDetails;
  const { mandate, isError, errorResponse } = useDirectDebitMandate();

  useEffect(() => {
    if (isError && Object.keys(errorMessage).length === 0) {
      onFailure(errorResponse);
    }
  }, [isError, errorResponse, errorMessage]);

  useEffect(() => {
    if (savedBankAccountAndAddressDetails?.bankAccount?.accountName === "") {
      navigate("/payments");
    }
  }, [navigate, savedBankAccountAndAddressDetails]);

  const onChangeEmailAddress = () => {
    dispatch(reset());
  };

  const onSuccessful = () => {
    setTimeout(() => dispatch(reset()), 100);
    onSuccess();
  };

  const onFailure = (error) => {
    setErrorMessage(error);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const { mutate } = useSubmitDirectDebitDetails(onSuccessful, onFailure);

  const onSubmitDirectDebit = () => {
    const payload = {
      ...savedBankAccountAndAddressDetails,
    };
    mutate(payload);
  };

  return (
    <>
      {errorMessage?.localErrorMessage && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorMessage?.localErrorMessage)}
        </Alert>
      )}
      {!isError ? (
        <>
          <h2>
            Please check the below details and select continue to setup your
            Direct Debit
          </h2>
          <NotificationBanner variant="info" size="lg">
            <div className="d-flex flex-row p-sm-2 p-md-4 p-lg-5">
              <div
                className={`text-center me-3 mt-1 ${
                  !isNewDirectDebit ? "ms-lg-5" : ""
                }`}
              >
                <InfoIcon title="Info Icon" className="text-info" size="md" />
              </div>
              <div className="ms-3">
                <div className={`mb-2 ${!isNewDirectDebit ? "ms-lg-5" : ""}`}>
                  We'll take future invoices by Direct Debit on the{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {findOrdinalByDay(mandate?.deferredDdDay)}
                  </strong>{" "}
                  of each month.{" "}
                  <span
                    className={`${!isNewDirectDebit ? "mt-4 d-block" : ""}`}
                  >
                    You will be able to change this date once your Direct Debit
                    is set up.
                  </span>
                </div>
                {isNewDirectDebit && (
                  <div className="mt-3">
                    Please pay any existing unpaid invoices separately, by BACS
                    or card payment.
                  </div>
                )}
              </div>
            </div>
          </NotificationBanner>
          <BankAccountAndAddressDetails
            savedBankAccountAndAddressDetails={
              savedBankAccountAndAddressDetails
            }
          />
          <div className="border-bottom pb-4 mb-4">
            <div className={`${magicStrings.maskInformation} mb-2`}>
              We will send confirmation of this Direct Debit instruction by
              email to <strong>{profile?.emailAddress}</strong>.
            </div>

            <Link to="/profile" onClick={onChangeEmailAddress}>
              Change email address
            </Link>
          </div>
          <div className="border-bottom pb-2 mb-4">
            <h3>The Direct Debit guarantee</h3>
            <p>
              This guarantee is offered by all banks and building societies that
              accept instructions to pay Direct Debits.
            </p>
            <p>
              If there are any changes to the amount, date or frequency of your
              Direct Debit Lex Autolease will notify you 10 working days in
              advance of your account being debited or as otherwise agreed. If
              you request Lex Autolease to collect a payment, confirmation of
              the amount and date will be given to you at the time of the
              request.
            </p>
            <p>
              If an error is made in the payment of your Direct Debit, by Lex
              Autolease or your bank or building society, you are entitled to a
              full and immediate refund of the amount paid from your bank or
              building society. If you receive a refund you are not entitled to,
              you must pay it back when Lex Autolease asks you to.
            </p>
            <p>
              You can cancel a Direct Debit at any time by simply contacting
              your bank or building society. Written confirmation may be
              required. Please also notify us.
            </p>
          </div>
          <p>
            If you have any questions please visit our{" "}
            <Link to="/help">Payments FAQs</Link> page.
          </p>
          <Container className="mt-4 px-0">
            <Row>
              <Col sm={6} className="mb-4 mb-sm-0">
                <Button
                  className="px-5 w-100"
                  type="button"
                  id="btnContinue"
                  onClick={onSubmitDirectDebit}
                >
                  Continue
                </Button>
              </Col>
              <Col sm={6} className="mb-4 mb-sm-0">
                <Button
                  type="button"
                  id="btnCancel"
                  className="px-5 w-100"
                  variant="outline-primary"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ReviewDirectDebit;
