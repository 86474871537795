import PropTypes from "prop-types";
import { formatCurrency } from "../../utils/pages/number-format-utils";
import CenterAlignedContainer from "../center-aligned-container/CenterAlignedContainer";
import TableStripedColumns from "../table/TableStripedColumns";
import magicStrings from "../../utils/magic-string";

const PaymentHolidayFinancialSupportTable = ({
  paymentHolidayFinancialSupportData,
}) => {
  const tableData = [
    [
      "Payment holiday length",
      <div
        className={`${magicStrings.maskInformation}`}
      >{`${paymentHolidayFinancialSupportData.delayMonths} months`}</div>,
    ],
    [
      "Total balance of payment holiday",
      <div className={`${magicStrings.maskInformation}`}>
        {formatCurrency(paymentHolidayFinancialSupportData.totalAmount)}
      </div>,
    ],
  ];

  return (
    <div data-testid="allow-payment-holiday-ext">
      <CenterAlignedContainer>
        <TableStripedColumns tableData={tableData} />
      </CenterAlignedContainer>
      <p>
        * This is the total balance of ALL invoices added to your account during
        your payment holiday period i.e. monthly rental, road fund licence,
        fines and penalty notices.
      </p>
    </div>
  );
};

PaymentHolidayFinancialSupportTable.propTypes = {
  paymentHolidayFinancialSupportData: PropTypes.object.isRequired,
};

export default PaymentHolidayFinancialSupportTable;
