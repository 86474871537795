import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const OrdersBanner = ({ children }) => {
  return (
    <>
      <Container className="py-5">
        <Row>
          <Col className="text-center">{children}</Col>
        </Row>
      </Container>
    </>
  );
};

OrdersBanner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

export default OrdersBanner;
