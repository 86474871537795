import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import Questionnaire from "../../../../component/questionnaire/Questionnaire";
import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import { step } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";
import CollectionStepper from "../../collection-stepper/CollectionStepper";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import PageHeading from "../../../../component/page-heading/PageHeading";
import magicStrings from "../../../../utils/magic-string";

const Question2Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedContract = useSelector(
    (state) => state.vehicleCollection.contractDetails
  );
  const motExpiryDate = selectedContract?.vehicle?.motExpiryDate
    ? DateTime.fromISO(selectedContract.vehicle.motExpiryDate)
    : "";

  useVehicleCollectionStepCheck(steps.roadWorthyQuestion2, "../question1");

  const onActionClicked = (result) => {
    dispatch(step(steps.roadWorthyQuestion3));
    if (result === 1) {
      navigate("../question3");
    } else if (result === 2) {
      navigate("../not-road-worthy");
    }
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      <PageHeading align="left">Arranging a collection</PageHeading>
      <p className="mb-5">
        We need to ask you some questions to check that your vehicle is
        roadworthy and ready to be collected. If a collection is arranged and
        the vehicle is unsafe to drive your collection will be cancelled and you
        will be charged a fee.
      </p>
      <h3>
        Question 2 of 6: Will the vehicle have a valid MOT at the point of
        collection?
      </h3>
      <p>
        You are contractually required to return the vehicle with a valid MOT.
      </p>
      {motExpiryDate && (
        <p>
          Your MOT expiry date{" "}
          <strong className={`${magicStrings.maskInformation}`}>
            {motExpiryDate.toFormat("dd MMMM yyyy")}*
          </strong>
        </p>
      )}
      <p className="pb-5">
        *If an MOT has been completed in the last 2 days our records may not
        have been updated yet.
      </p>
      <Questionnaire
        option1Title="Yes"
        option2Title="No"
        option1Content="The vehicle will have a valid MOT at point of collection."
        option2Content="The vehicle will not have a valid MOT at point of collection."
        option1ActionContent="Yes"
        option2ActionContent="No"
        actionCallback={onActionClicked}
      />
    </WizardBasePage>
  );
};

export default Question2Page;
