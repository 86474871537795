import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const ManageDirectDebitIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    viewBox="0 0 28 28"
    aria-label={title}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="manage-direct-debit-icon_svg__a"
        d="M9.917 15.583H7.083V25.5h2.834v-9.917Zm8.5 0h-2.834V25.5h2.834v-9.917Zm12.041 12.75H3.542v2.834h26.916v-2.834Zm-3.541-12.75h-2.834V25.5h2.834v-9.917ZM17 6.035l7.38 3.882H9.62L17 6.035Zm0-3.202L3.542 9.917v2.833h26.916V9.917L17 2.833Z"
      />
    </defs>
    <use
      xlinkHref="#manage-direct-debit-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-3 -2)"
    />
  </svg>
);

ManageDirectDebitIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { ManageDirectDebitIcon };
export default ManageDirectDebitIcon;
