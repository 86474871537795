import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";
import queryClient from "../react-query/query-client";

const getSupportNeeds = async (axios) => {
  return axios.get("/customers/support-needs").then((res) => {
    return res.data;
  });
};

const updateSupportNeeds = async (axios, request) => {
  const { data } = await axios.put("/customers/support-needs", request);
  return data;
};

const useSupportNeeds = () => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const { data = fallback, isError } = useQuery(
    queryKeys.supportNeeds,
    () => getSupportNeeds(axios),
    { cacheTime: 10 * 60 * 1000, staleTime: 10 * 60 * 1000 }
  );
  return {
    accessibilityNeeds: data,
    isError,
  };
};

const useUpdateSupportNeeds = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((changeNeeds) => updateSupportNeeds(axios, changeNeeds), {
    onSuccess: (data) => {
      queryClient.resetQueries(queryKeys.supportNeeds);
      onSuccess(data);
    },
    onError: onError,
  });
};

export { useSupportNeeds, useUpdateSupportNeeds };
