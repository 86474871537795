function getBase64FromDataURI(dataURI) {
  try {
    const BASE64_MARKER = ";base64,";
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    return base64;
  } catch (error) {
    return {
      loadSuccessful: false,
      message: "Failed to extract base64. Error: " + error,
    };
  }
}

function convertBase64ToBinary(base64) {
  try {
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  } catch (error) {
    return {
      loadSuccessful: false,
      message: "Failed to convert base64 to binary. Error: " + error.toString(),
    };
  }
}

const loadPDFFromBase64 = async (pdfContainerRef, base64, onLoad = null) => {
  const binaryPDF = convertBase64ToBinary(base64);

  if (binaryPDF instanceof Uint8Array) {
    onLoad(await loadPDF(pdfContainerRef, binaryPDF));
  } else {
    // Return error object
    onLoad(binaryPDF);
  }
};

const loadPDFFromDataURI = async (pdfContainerRef, dataURI, onLoad = null) => {
  const base64 = getBase64FromDataURI(dataURI);
  if (base64 instanceof String) {
    const binaryPDF = convertBase64ToBinary(base64);
    if (binaryPDF instanceof Uint8Array) {
      onLoad(await loadPDF(pdfContainerRef, binaryPDF));
    } else {
      // Return error object from convertBase64ToBinary
      onLoad(binaryPDF);
    }
  } else {
    // Return error object from getBase64FromDataURI
    onLoad(base64);
  }
};

const loadPDFFromFilePath = async (pdfContainerRef, path, onLoad = null) => {
  onLoad(await loadPDF(pdfContainerRef, path));
};

const generatePDFTextLayerStyle = () => {
  var textLayerCSS = `/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.textLayer{
  position:absolute;
  text-align:initial;
  inset:0;
  overflow:hidden;
  opacity:0.25;
  line-height:1;
  -webkit-text-size-adjust:none;
     -moz-text-size-adjust:none;
          text-size-adjust:none;
  forced-color-adjust:none;
  transform-origin:0 0;
  z-index:2;
}

.textLayer :is(span, br){
    color:transparent;
    position:absolute;
    white-space:pre;
    cursor:text;
    transform-origin:0% 0%;
  }

.textLayer span.markedContent{
    top:0;
    height:0;
  }

.textLayer .highlight{
    --highlight-bg-color:rgb(180 0 170);
    --highlight-selected-bg-color:rgb(0 100 0);

    margin:-1px;
    padding:1px;
    background-color:var(--highlight-bg-color);
    border-radius:4px;
  }

@media screen and (forced-colors: active){

.textLayer .highlight{
      --highlight-bg-color:Highlight;
      --highlight-selected-bg-color:ButtonText;
  }
    }

.textLayer .highlight.appended{
      position:initial;
    }

.textLayer .highlight.begin{
      border-radius:4px 0 0 4px;
    }

.textLayer .highlight.end{
      border-radius:0 4px 4px 0;
    }

.textLayer .highlight.middle{
      border-radius:0;
    }

.textLayer .highlight.selected{
      background-color:var(--highlight-selected-bg-color);
    }

.textLayer ::-moz-selection{
    background:blue;
    background:AccentColor;
  }

.textLayer ::selection{
    background:blue;
    background:AccentColor;
  }

.textLayer br::-moz-selection{
    background:transparent;
  }

.textLayer br::selection{
    background:transparent;
  }`,
    head = document.head || document.getElementsByTagName("head")[0],
    style = document.createElement("style");
  style.type = "text/css";
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = textLayerCSS;
  } else {
    style.appendChild(document.createTextNode(textLayerCSS));
  }
  head.appendChild(style);
};

const loadPDF = async (pdfContainerRef, pdf, retries = 0) => {
  if (window.pdfjsLib) {
    // Clear initial container
    const initialPDFContainer = pdfContainerRef.current;
    if (initialPDFContainer && initialPDFContainer.hasChildNodes()) {
      initialPDFContainer.replaceChildren();
    }

    return window.pdfjsLib.getDocument(pdf).promise.then(
      async function (pdf) {
        const pdfContainer = pdfContainerRef.current;
        var total_pages = pdf.numPages;

        if (!pdfContainer) {
          return {
            loadSuccessful: false,
            message: "PDF: Container DOM still loading...",
            isLoading: true,
          };
        }

        for (let pageNum = 1; pageNum <= total_pages; pageNum++) {
          await pdf.getPage(pageNum).then(function (page) {
            // Prepare page where both canvas and text layer will be present
            const pageId = "pdfPageNum_" + page._pageIndex;
            var pdfPage = document.createElement("div");
            pdfPage.className = "pdfPage w-100 d-block position-relative";
            pdfPage.id = pageId;

            // Change scale to fit ratio of varying sizes (Letter, A4, A5)
            const scalingFactor = 2;
            var unscaledViewport = page.getViewport({ scale: 1 });
            var scale =
              scalingFactor * (window.screen.width / unscaledViewport.width);
            var viewport = page.getViewport({ scale: scale });

            // Prepare canvas using PDF page dimensions
            var canvas = document.createElement("canvas");
            canvas.className = "mx-auto w-100 d-block";
            var context = canvas.getContext("2d");
            canvas.height = Math.floor(viewport.height);
            canvas.width = Math.floor(viewport.width);
            canvas.style.height = "auto";
            canvas.style.width = "100%";
            canvas.id = pageId + "-canvas";

            // Prepare text layer based on canvas
            var textLayerDiv = document.createElement("div");
            textLayerDiv.className = "textLayer mx-auto";
            textLayerDiv.style.setProperty("--scale-factor", viewport.scale);

            // Resizes text layer upon window resize
            window.addEventListener("resize", (e) => {
              // Change scale of text based on scale of canvas
              textLayerDiv.style.width = canvas.style.width;
              textLayerDiv.style.height = canvas.style.height;
              textLayerDiv.style.setProperty(
                "--scale-factor",
                (canvas.offsetHeight / canvas.height) * viewport.scale // (new height / initial height)/scale
              );
            });

            // Add the textLayer css to the header
            generatePDFTextLayerStyle();

            // Add canvas and text layer to the page
            pdfPage.appendChild(canvas);
            pdfPage.appendChild(textLayerDiv);

            // Create render context for canvas
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            // Render canvas and then text layer
            page.render(renderContext);
            page.getTextContent().then(function (textContent) {
              window.pdfjsLib.renderTextLayer({
                textContentSource: textContent,
                container: textLayerDiv,
                textDivs: [],
                viewport: viewport,
              });
            });

            // Resizes text layer to match canvas once canvas loads in DOM
            setTimeout(function () {
              textLayerDiv.style.setProperty(
                "--scale-factor",
                (canvas.offsetHeight / canvas.height) * viewport.scale // (new height / initial height)/scale
              );
            }, 0);

            // If page is already in container skip it
            if (!document.getElementById(pageId)) {
              // Append the canvas to the list of pages
              pdfContainer.appendChild(pdfPage);
            }
          });
        }

        return {
          loadSuccessful: true,
          message: "",
        };
      },
      function (reason) {
        // Clear container if PDF failed to load
        const pdfContainer = pdfContainerRef.current;
        if (pdfContainer && pdfContainer.hasChildNodes()) {
          pdfContainer.replaceChildren();
        }
        // PDF loading error
        return {
          loadSuccessful: false,
          message: "PDF: Failed to open given file - Error: " + reason,
        };
      }
    );
  } else {
    if (retries < 2) {
      setTimeout(() => {
        //  Re-attempt to load pdf if it fails to access pdfjslib
        loadPDF(pdfContainerRef, pdf, retries + 1);
      }, 500);
      return {
        loadSuccessful: false,
        message:
          "PDF: pdfjsLibrary is not included within the window correctly",
      };
    }
  }
};

const openPdfDocument = (
  pdfContent,
  documentName = "document.pdf",
  target = "_blank"
) => {
  function base64ToArrayBuffer(data) {
    const binaryString = window.atob(data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const arrBuffer = base64ToArrayBuffer(pdfContent);

  const newBlob = new Blob([arrBuffer], { type: "application/pdf" });
  const data = window.URL.createObjectURL(newBlob);

  const link = document.createElement("a");
  document.body.appendChild(link);
  link.href = data;
  link.download = documentName;
  if (target) {
    link.target = target;
  }
  link.click();
  window.URL.revokeObjectURL(data);
  link.remove();
};
export {
  loadPDF,
  loadPDFFromBase64,
  loadPDFFromDataURI,
  loadPDFFromFilePath,
  openPdfDocument,
};
