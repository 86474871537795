import PropTypes from "prop-types";
import {
  GenericCheckbox,
  GenericChecklistField,
} from "./field-utils/field-utils.js";

export const SupportNeedsField = ({ groupName, options, ...props }) => {
  const sortOptions = (options) => {
    const sortedOptions = options.sort((a, b) =>
      a?.selected === b?.selected ? 0 : a?.selected ? -1 : 1
    );
    return sortedOptions;
  };

  const remapOptions = (options) => {
    let checkboxOptions = [];
    options.forEach((option) => {
      let newOption = {
        id: option.id,
        text: option.description,
        hint: option.notes,
        selected: option.isOpted,
      };
      checkboxOptions.push(newOption);
    });

    return checkboxOptions;
  };

  return (
    <GenericChecklistField
      controlId="supportNeedsChecklist"
      checklistText={groupName}
      options={sortOptions(remapOptions(options))}
      {...props}
    />
  );
};

SupportNeedsField.propTypes = {
  groupName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      notes: PropTypes.string,
      isOpted: PropTypes.bool,
    })
  ),
};

export const ConfirmSupportNeedsTermsCheckbox = (props) => {
  return (
    <GenericCheckbox
      controlId="confirmSupportNeedsTermsAccepted"
      labelText="Please tick this box to confirm we can use the information you've given us to adapt our support to your specific needs. To do this, we'll need to share your information with other organisations in our Group and sometimes with other support partners, such as the RNIB. You can change or remove your consent at any time by un-ticking this box."
      {...props}
    />
  );
};
