import { useNavigate } from "react-router-dom";
import BasePage from "../../../basepages/basepage/BasePage";
import DirectDebitForm from "../../../../component/forms/payments/direct-debit/DirectDebitForm";

const ChangeBankDetailsPage = () => {
  const navigate = useNavigate();

  const onFormSubmit = () => {
    navigate("/payments/existing-direct-debit/review-bank-details");
  };

  const onFormCancel = () => {
    navigate("/payments/existing-direct-debit");
  };
  return (
    <BasePage pageTitle="Change Bank Details">
      <DirectDebitForm
        onFormSubmit={onFormSubmit}
        onFormCancel={onFormCancel}
      />
    </BasePage>
  );
};

export default ChangeBankDetailsPage;
