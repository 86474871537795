import * as React from "react";
import { iconSizeSquare } from "./icons-utils";
import PropTypes from "prop-types";

const TickOutlineIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    aria-label={title}
    viewBox="0 0 69 69"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <circle
        cx={32.5}
        cy={32.5}
        r={32.5}
        stroke="currentColor"
        strokeWidth={3}
      />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="m18.619 29.178-5.083 5.083 12.707 12.707 23.72-23.72-5.083-5.083-18.637 18.637z"
      />
    </g>
  </svg>
);

TickOutlineIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { TickOutlineIcon };
export default TickOutlineIcon;
