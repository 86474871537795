import * as yup from "yup";
import { useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { ContractTermField, MileagePerYearField } from "../fields";
import TableStripedColumns from "../../table/TableStripedColumns";
import { contractTermSchema, mileagePerYearSchema } from "../schemas/fields";
import magicStrings from "../../../utils/magic-string";
import { formatNumber } from "../../../utils/pages/number-format-utils";

const TermMileageForm = ({
  minimumTerm,
  maximumTerm,
  minimumMileageLimit,
  maximumMileageLimit,
  currentContractTerm,
  currentContractMileage,
  newSelectedContractTerm,
  newSelectedContractMileage,
  highestRecordedMileage,
  calculateQuoteError,
  onFormSubmit,
}) => {
  const methods = useForm({
    defaultValues: {
      contractTerm:
        newSelectedContractTerm?.toString() ?? currentContractTerm.toString(),
      mileagePerYear:
        newSelectedContractMileage?.toString() ??
        currentContractMileage.toString(),
    },
    resolver: yupResolver(
      yup.object().shape({ ...contractTermSchema, ...mileagePerYearSchema })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitted },
  } = methods;

  const [totalMileage, setTotalMileage] = useState(0);
  const [errorAlert, setErrorAlert] = useState(null);
  const selectedContractTerm = watch("contractTerm");
  const selectedContractMileage = watch("mileagePerYear");

  const generateTerms = () => {
    let terms = [];

    let current = minimumTerm;
    let end = maximumTerm;

    let index = 0;
    while (current <= end) {
      terms.push({ id: index, text: current.toString() });
      index++;
      current += 1;
    }

    return terms;
  };

  const generateMileages = () => {
    let mileages = [];

    let current = minimumMileageLimit;
    let end = 50000;

    let index = 0;
    if (currentContractMileage % 1000 !== 0) {
      mileages.push({ id: index, text: currentContractMileage.toString() });
      index++;
    }
    while (current <= end) {
      mileages.push({ id: index, text: current.toString() });
      index++;
      current += 1000;
    }

    return mileages;
  };

  useEffect(() => {
    let newTotalMileage = () => {
      let averageMonthlyMileage = selectedContractMileage / 12;
      let newTotalMileage = averageMonthlyMileage * selectedContractTerm;
      return Math.round(newTotalMileage).toString();
    };

    setTotalMileage(newTotalMileage);
  }, [selectedContractTerm, selectedContractMileage]);

  useEffect(() => {
    if (isSubmitted) {
      setErrorAlert(null);

      // At least one option must be changed
      if (
        selectedContractTerm.toString() === currentContractTerm.toString() &&
        selectedContractMileage.toString() === currentContractMileage.toString()
      ) {
        setErrorAlert({
          id: "no-changes-made",
          key: "warning",
          variant: "warning",
          message: "You must change at least one of Term or Mileage.",
        });
        return;
      }

      // Mileage must not exceed limit
      if (totalMileage > maximumMileageLimit) {
        setErrorAlert({
          id: "exceeded-mx-mileage",
          key: "danger",
          variant: "danger",
          message:
            "You have exceeded the allowed total mileage. Please amend your selection.",
        });
        return;
      }

      // Total mileage must be more than highestRecorded mileage
      if (
        highestRecordedMileage &&
        highestRecordedMileage > 0 &&
        totalMileage < highestRecordedMileage
      ) {
        setErrorAlert({
          id: "total-mileage-less-than-heighest-recorded-mileage",
          key: "danger",
          variant: "danger",
          message:
            "Total mileage is less than current highest recorded mileage. Please update.",
        });
        return;
      }
    }
  }, [
    isSubmitted,
    totalMileage,
    selectedContractTerm,
    selectedContractMileage,
    currentContractTerm,
    currentContractMileage,
    maximumMileageLimit,
    highestRecordedMileage,
  ]);

  const onSubmit = (data) => {
    setErrorAlert(null);

    // At least one option must be changed
    if (
      selectedContractTerm.toString() === currentContractTerm.toString() &&
      selectedContractMileage.toString() === currentContractMileage.toString()
    ) {
      setErrorAlert({
        id: "no-changes-made",
        key: "warning",
        variant: "warning",
        message: "You must change at least one of Term or Mileage.",
      });
      return;
    }

    // Mileage must not exceed limit
    if (totalMileage > maximumMileageLimit) {
      setErrorAlert({
        id: "exceeded-mx-mileage",
        key: "danger",
        variant: "danger",
        message:
          "You have exceeded the allowed total mileage. Please amend your selection.",
      });
      return;
    }

    // Total mileage must be more than highestRecorded mileage
    if (
      highestRecordedMileage &&
      highestRecordedMileage > 0 &&
      totalMileage < highestRecordedMileage
    ) {
      setErrorAlert({
        id: "total-mileage-less-than-heighest-recorded-mileage",
        key: "danger",
        variant: "danger",
        message:
          "Total mileage is less than current highest recorded mileage. Please update.",
      });
      return;
    }

    if (!errorAlert) {
      newSelectedContractTerm = data.contractTerm;
      newSelectedContractMileage = data.mileagePerYear;
      onFormSubmit({ ...data, ...{ totalMileage: totalMileage } });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate name="TermMileageForm">
        {minimumTerm > 57 && (
          <Alert variant="danger">
            We cannot extend your Contract Term. Our minimum contract extension
            is 3 months and this will take you over our maximum term of 60
            months.
          </Alert>
        )}
        <ContractTermField
          terms={generateTerms()}
          labelText={
            <span>
              Contract term{" "}
              <em className={`fw-normal ${magicStrings.maskInformation}`}>
                (Min {minimumTerm}, Max {maximumTerm} months)
              </em>
            </span>
          }
          disabled={minimumTerm > 57}
        />
        <MileagePerYearField mileages={generateMileages()} />
        <TableStripedColumns
          colConfig={[
            {
              width: 8,
            },
            {
              width: 4,
            },
          ]}
          tableData={[
            [
              <span>
                Total mileage{" "}
                <em className={`fw-normal ${magicStrings.maskInformation}`}>
                  (Max {formatNumber(maximumMileageLimit, 0, "N/A")})
                </em>
              </span>,
              <span>
                <strong>{formatNumber(totalMileage, 0, "N/A")}</strong>
              </span>,
            ],
          ]}
        />
        {errorAlert ? (
          <Alert variant={errorAlert.variant}>{errorAlert.message}</Alert>
        ) : (
          ""
        )}

        {calculateQuoteError?.localErrorMessage && (
          <Alert variant="danger">
            {calculateQuoteError.localErrorMessage}
          </Alert>
        )}

        <div className="d-grid gap-2">
          <Button type="submit" id="btnTermMileageFormSubmit">
            Calculate
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

TermMileageForm.propTypes = {
  minimumTerm: PropTypes.number,
  maximumTerm: PropTypes.number,
  minimumMileageLimit: PropTypes.number,
  maximumMileageLimit: PropTypes.number,
  currentContractTerm: PropTypes.number,
  currentContractMileage: PropTypes.number,
  newSelectedContractTerm: PropTypes.number,
  newSelectedContractMileage: PropTypes.number,
  calculateQuoteError: PropTypes.object,
  onFormSubmit: PropTypes.func,
};
export default TermMileageForm;
