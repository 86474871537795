import * as React from "react";
import PropTypes from "prop-types";

const EmptyStateIcon = ({ textContent, title, ...props }) => (
  <div className="mx-auto d-flex flex-column text-center">
    {textContent ? <h2 className="mb-4">{textContent}</h2> : null}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="374"
      viewBox="0 0 563 376"
      aria-labelledby={title}
      role="img"
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <g
        style={{
          opacity: 0.307,
        }}
      >
        <path
          d="M83.8 179.6H97v111.3H83.2z"
          style={{
            fill: "#d8d8d8",
          }}
          transform="translate(382.465 85.103)"
        />
        <path
          d="M0 179.6h179.6V0H0z"
          style={{
            fill: "#c7c8ca",
          }}
          transform="translate(382.465 85.103)"
        />
        <path
          d="M130.8 64.7c0-5.4-4.4-9.8-9.8-9.8s-9.8 4.4-9.8 9.8c0 4.3 2.8 8 6.7 9.3v12.4H92.7V73.8c3.7-1.4 6.4-4.9 6.4-9.1 0-5.4-4.4-9.8-9.8-9.8s-9.8 4.4-9.8 9.8c0 4.3 2.8 8 6.7 9.3v12.4H61V73.8c3.8-1.3 6.5-4.9 6.5-9.2 0-5.4-4.4-9.8-9.8-9.8-5.3.1-9.7 4.5-9.7 9.9 0 4.2 2.7 7.9 6.5 9.2v30.6c-3.8 1.3-6.5 4.9-6.5 9.2 0 5.4 4.4 9.8 9.8 9.8s9.8-4.4 9.8-9.8c0-4.3-2.7-7.9-6.5-9.2V92.8h25.2v11.6c-3.9 1.3-6.7 4.9-6.7 9.2 0 5.4 4.4 9.8 9.8 9.8s9.8-4.4 9.8-9.8c0-4.2-2.6-7.8-6.4-9.1V92.8h31.7v-19c3.6-1.4 6.3-5 6.3-9.1"
          style={{
            fill: "#fefefe",
          }}
          transform="translate(382.465 85.103)"
        />
      </g>
      <g
        style={{
          opacity: 0.3785,
        }}
      >
        <path
          d="M63.7 136.6h10.1v84.7H63.3z"
          style={{
            fill: "#d8d8d8",
          }}
          transform="translate(73 154.733)"
        />
        <path
          d="M0 136.6h136.6V0H0z"
          style={{
            fill: "#c7c8ca",
          }}
          transform="translate(73 154.733)"
        />
        <path
          d="M52.1 33.6 52 49.8v.6L32.9 64.9l2.2 2.3L52 55.9V103.3h33.3V56.1L102 67.2l2.2-2.3-18.9-14.3v-.4l-.1-16.6H52.1zm3.5 16.5h26.1l-4.2 10.2H59.8l-4.2-10.2zm22.3 15.3 4.1-8.7V75h-4.1v-9.6zm-22.5-8.7 4.1 8.7V75h-4.1V56.7zm22.5 27.4v-5.9H82v13.5l-4.1-7.6zm-22.5-5.9h4.1v5.9l-4.1 7.7V78.2zm4.9 9.9H77l3.2 5.4H57.1l3.2-5.4z"
          style={{
            fill: "#fefefe",
          }}
          transform="translate(73 154.733)"
        />
      </g>
      <path
        d="M15.1 363c-3.4 0-6.1 2.7-6.1 6.1 0 3.4 2.7 6.1 6.1 6.1s6.1-2.7 6.1-6.1c-.1-3.4-2.8-6.1-6.1-6.1M78.1 363c-3.4 0-6.1 2.7-6.1 6.1 0 3.4 2.7 6.1 6.1 6.1s6.1-2.7 6.1-6.1c-.1-3.4-2.8-6.1-6.1-6.1"
        style={{
          fill: "#9b9b9b",
        }}
      />
      <path
        d="M62.2 350.8V339H74l8.8 11.8H62.2zm-32.2.1L43.3 339h14.4v11.8l-27.7.1zm46.3-16.6H41.9l-22.5 16.6L0 357.3v14.6h5.6c-.2-.7-.3-1.5-.3-2.3 0-5.4 4.4-9.8 9.7-9.8 5.4 0 9.7 4.4 9.7 9.8 0 .8-.1 1.6-.3 2.3h44.4c-.2-.7-.3-1.5-.3-2.3 0-5.4 4.4-9.8 9.7-9.8 5.4 0 9.7 4.4 9.7 9.8 0 .8-.1 1.6-.3 2.3h4.6v-21l-15.9-16.6z"
        style={{
          fill: "#9b9b9b",
        }}
      />
      <path
        d="M107.8 232H125v144h-18z"
        style={{
          fill: "#d8d8d8",
        }}
        transform="translate(182)"
      />
      <path
        d="M0 232h232V0H0z"
        style={{
          fill: "#c7c8ca",
        }}
        transform="translate(182)"
      />
      <path
        d="M121.3 152.7c-3.4 3.6-9 3.8-12.6.4s-36.3-46-36.3-46 44.9 29.6 48.5 32.9c3.5 3.4 3.7 9.1.4 12.7"
        style={{
          fill: "#eee",
        }}
        transform="translate(182)"
      />
      <path
        d="M184.3 110c-13.8-24.7-39.9-40.1-68.1-40.1-28.1 0-54.2 15.5-68.1 40.4l-2.1 3.8 18.6 10.6 4.4-7.6-10.9-6.2c5.5-8.6 12.8-15.8 21.1-21.2l6.5 11.3 3.8-2.2-6.6-11.4c9.4-5.2 20.1-8.2 31.1-8.6v13.4h4.4V78.8c11.1.4 21.7 3.3 31.2 8.5l-6.7 11.5 3.8 2.2 6.6-11.4c8.3 5.3 15.5 12.4 21 20.9l-11 6.4 4.4 7.6 18.6-10.8-2-3.7zM55.8 132.8h-8.4c-1.7 0-2.9.1-3.8.8-.8.7-1.2 1.6-1.4 3.4-.2 1.9-.3 4.7-.3 6.9v1.5c0 2.2.1 4.9.3 6.9.2 1.7.5 2.7 1.3 3.4.8.7 2 .9 3.9.9h8.4c.8 0 .9-.2.9-.9v-2c0-.7-.1-.9-.9-.9h-8c-.8 0-1.1-.2-1.2-1-.2-1.9-.3-3.6-.3-5.5h8.2c.8 0 .9-.2.9-.9v-2c0-.7-.1-.9-.9-.9h-8.1c0-1.8.1-3.4.2-4.7.1-.9.5-1.2 1.2-1.2h8c.8 0 .9-.2.9-.9v-2c0-.7-.1-.9-.9-.9M188.9 132.8h-9c-1.2 0-2.5.3-3.3 1.1-.7.8-1 1.7-1 3.2v18.5c0 .8.2.9 1 .9h2.6c.8 0 1-.2 1-.9v-9.2h8.2c.7 0 .9-.2.9-.9v-2c0-.7-.2-.9-.9-.9h-8.2v-4.9c0-.5.1-.8.3-.9.2-.2.4-.3.8-.3h7.7c.7 0 1-.2 1-.9v-2c-.2-.6-.4-.8-1.1-.8"
        style={{
          fill: "#eee",
        }}
        transform="translate(182)"
      />
    </svg>
  </div>
);

EmptyStateIcon.propTypes = {
  textContent: PropTypes.string,
  title: PropTypes.string,
};

export { EmptyStateIcon };
export default EmptyStateIcon;
