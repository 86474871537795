import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  TitleField,
  FirstNameField,
  LastNameField,
  DobField,
  NationalityField,
  EmailAddressField,
} from "../../fields";

import * as fieldSchemas from "../../schemas/fields";

const PersonalDetailsForm = ({
  isSoleTrader,
  partnerType,
  defaultValues,
  onFormSubmit,
}) => {
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.titleSelectSchema,
        ...fieldSchemas.firstNameSchema,
        ...fieldSchemas.lastNameSchema,
        ...fieldSchemas.dobSchema,
        ...fieldSchemas.nationalitySchema,
        ...fieldSchemas.emailAddressSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    data.dobDay = parseInt(data.dobDay);
    data.dobMonth = parseInt(data.dobMonth);
    data.dobYear = parseInt(data.dobYear);
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="personalDetailsForm"
      >
        <h2>{partnerType ? partnerType : ""}</h2>
        {isSoleTrader ? (
          <>
            <TitleField />
            <FirstNameField disabled={true} />
            <LastNameField disabled={true} />
            <DobField disabled={true} />
            <NationalityField />
            <EmailAddressField />
          </>
        ) : (
          <>
            <TitleField />
            <FirstNameField />
            <LastNameField />
            <DobField />
            <NationalityField />
            <EmailAddressField />
          </>
        )}

        <div className="d-grid gap-2">
          <Button
            className="mt-3"
            type="submit"
            id="btnPersonalDetailsFormSubmit"
          >
            Continue to address details
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};
export default PersonalDetailsForm;
