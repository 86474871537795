import { Link } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
const NotAllowedPage = () => {
  return (
    <BasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
    >
      <TitleWithIcon
        title="Unfortunately you can't end this contract early"
        variant="info"
      />
      <h3 className="text-primary pt-3 pb-1">What's the problem?</h3>
      <p>
        The terms of your contract for this vehicle don't allow for early
        termination. You'll find full details in the terms and conditions of
        your agreement. If you have any questions or need help with your
        repayments please call us on{" "}
        <Link to="tel:0800 302 9160">0800 302 9160</Link> and we'll look at your
        options with you. Lines are open 8am - 5:30pm Monday - Friday, and 9am -
        12pm Saturday.
      </p>

      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default NotAllowedPage;
