import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import PayeeDetailsForm from "../../../../component/forms/payments/card-payment/payee-details/PayeeDetailsForm";
import useAuth from "../../../../hooks/use-auth/useAuth";
import BasePage from "../../../basepages/basepage/BasePage";
import CenterAlignedContainer from "../../../../component/center-aligned-container/CenterAlignedContainer";
import usePayeeDetails from "./hooks/usePayeeDetails";
import { useInitialisePayment } from "../../../../api/hooks/card-payment";
import {
  sessionKey,
  transactionKey,
  iframeSrc,
  step,
} from "../../../../redux/features/payments/cardPaymentSlice";
import steps from "../card-payment-steps";
import PageHeading from "../../../../component/page-heading/PageHeading";
import magicStrings from "../../../../utils/magic-string";

const PayeeDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isStepChecked, setIsStepChecked] = useState(false);
  const currentStep = useSelector((state) => state.cardPayment.step);
  const { auth } = useAuth();
  const { getGatewayPayload } = usePayeeDetails();

  const selectedInvoices = useSelector(
    (state) => state.cardPayment.selectedInvoices
  );

  useEffect(() => {
    if (!isStepChecked) {
      if (currentStep < steps.payeeDetails) {
        navigate("../invoice-selection", { replace: true });
      } else if (currentStep > steps.payeeDetails) {
        dispatch(step(steps.payeeDetails));
      }
      setIsStepChecked(true);
    }
  }, [currentStep, dispatch, navigate, isStepChecked]);

  const onPaymentInitialisationSuccess = (data) => {
    dispatch(step(steps.paymentGateway));
    dispatch(sessionKey(data.sessionKey));
    dispatch(transactionKey(data.transactionKey));
    if (typeof data.iframe === "string") {
      data.iframe = data.iframe.replace("<iframe ", "<iframe height='350px;'");
    }
    dispatch(iframeSrc(data.iframe));
    navigate("../payment-gateway");
  };

  const onPaymentInitialisationFailure = () => {
    //todo: Need to handle payment initialisation failure
  };

  const { mutate } = useInitialisePayment(
    onPaymentInitialisationSuccess,
    onPaymentInitialisationFailure
  );

  const onFormSubmit = (data) => {
    const payload = getGatewayPayload(data, selectedInvoices, auth);
    mutate(payload);
  };

  return (
    <BasePage pageTitle="Make a payment">
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Ready to make a payment?</PageHeading>
          <p>
            Please enter the cardholder name exactly as it appears on the card
            you're using to make this payment.
          </p>
        </div>
      </CenterAlignedContainer>
      <CenterAlignedButtonContainer>
        <PayeeDetailsForm onFormSubmit={onFormSubmit}>
          <div className="my-4">
            <p className="mt-0">
              We will send an email confirmation to{" "}
              <strong className={`${magicStrings.maskInformation}`}>
                {auth?.user?.emailAddress}
              </strong>
              .
            </p>
            <Link to="/profile" className="mb-3">
              Change email address
            </Link>
          </div>
        </PayeeDetailsForm>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default PayeeDetailsPage;
