import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const EditIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    aria-label={title}
    viewBox="0 0 32.5 32.5"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <rect
        id="Edit_Active-icon_svg__a"
        width={32}
        height={32}
        x={0}
        y={0}
        rx={16}
      />
      <path
        id="Edit_Active-icon_svg__c"
        d="m11.716 7.517.766.766-7.55 7.55h-.766v-.766l7.55-7.55Zm3-5.017a.834.834 0 0 0-.584.242l-1.525 1.525 3.125 3.125 1.525-1.525a.83.83 0 0 0 0-1.175l-1.95-1.95a.818.818 0 0 0-.591-.242Zm-3 2.658-9.217 9.217V17.5h3.125l9.217-9.217-3.125-3.125Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="Edit_Active-icon_svg__b" fill="#fff">
          <use xlinkHref="#Edit_Active-icon_svg__a" />
        </mask>
        <path
          fill="currentColor"
          d="M0 0h32v32H0z"
          mask="url(#Edit_Active-icon_svg__b)"
        />
      </g>
      <use
        xlinkHref="#Edit_Active-icon_svg__c"
        fill="#FFF"
        transform="translate(6 6)"
      />
    </g>
  </svg>
);

EditIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { EditIcon };
export default EditIcon;
