import { useQuery, useMutation } from "@tanstack/react-query";
import apiKeys from "../error-handling/api-keys";
import queryKeys from "../react-query/queryKeys";
import queryClient from "../react-query/query-client";
import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";

const getBankDetailBySortcode = async (axios, sortCode) => {
  return axios
    .get(`/payment/customer/bank/lookup?sortCode=${sortCode}`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.directDebit),
    })
    .then((res) => {
      return res.data;
    });
};

const getDirectDebitMandate = async (axios) => {
  return axios
    .get(`/payment/directdebit/mandate`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.directDebit),
    })
    .then((res) => {
      return res.data;
    });
};

const submitDirectDebitDetails = async (axios, payload) => {
  return axios
    .post("/payment/directdebit/mandate", payload, {
      headers: attachApiKeyHeaderToRequest(apiKeys.directDebit),
    })
    .then((res) => {
      return res.data;
    });
};

const useBankDetailBySortcode = (sortCode) => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const {
    data = fallback,
    isError,
    refetch,
  } = useQuery(
    queryKeys.directDebit.bankDetail(sortCode),
    () => getBankDetailBySortcode(axios, sortCode),
    {
      enabled: false,
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
    }
  );
  return { bankDetail: data, isError, refetch };
};

const useDirectDebitMandate = () => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(
    queryKeys.directDebit.mandate,
    () => getDirectDebitMandate(axios),
    { cacheTime: 0, staleTime: 0 }
  );
  return { mandate: data, isError, errorResponse: error?.response?.data };
};

const useSubmitDirectDebitDetails = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((payload) => submitDirectDebitDetails(axios, payload), {
    onSuccess: (data) => {
      queryClient.resetQueries(queryKeys.accountBalance);
      onSuccess(data);
    },
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

export {
  useBankDetailBySortcode,
  useDirectDebitMandate,
  useSubmitDirectDebitDetails,
};
