import { useState, useContext } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdatePersonalDetails } from "../../../api/hooks/auth";
import PhoneNumbersFormFields from "../phone-numbers-form-fields/PhoneNumbersFormFields";
import phoneNumbersSchema, {
  phoneNumbersSchemaDependencies,
} from "../schemas/phone-numbers-schema";
import { emailAddressSchema } from "../schemas/fields/personal-details-schema";
import { addressLine1Schema } from "../schemas/fields/address-schema";
import AuthContext from "../../../providers/auth-provider/AuthProvider";
import { setItem } from "../../../storage/SessionStorage";
import useAuth from "../../../hooks/use-auth/useAuth";
import {
  AddressLine1Field,
  AddressLine2Field,
  AddressLine3Field,
  CityField,
  CountyField,
  EmailAddressField,
  PostCodeField,
} from "../fields";

import { postCodeSchema } from "../schemas/fields";
import magicStrings from "../../../utils/magic-string";

const PersonalDetailsForm = ({ scrollTop }) => {
  const { setAuth } = useContext(AuthContext);
  const { auth } = useAuth();
  const [successMessage, showSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [updatedProfile, setUpdatedProfile] = useState();

  const userProfile = auth.user;

  const fullName =
    userProfile?.title +
    " " +
    userProfile?.firstName +
    " " +
    userProfile?.lastName;
  const counterParty = userProfile?.counterParty?.code;
  const companyName = userProfile?.company?.companyName;
  const companyAddressLine1 = userProfile?.company?.addressLine1;
  const companyAddressLine2 = userProfile?.company?.addressLine2;
  const companyAddressLine3 = userProfile?.company?.addressLine3;
  const companyCity = userProfile?.company?.city;
  const companyCounty = userProfile?.company?.county;
  const companyPostCode = userProfile?.company?.postCode;

  const schema = yup.object().shape(
    {
      ...phoneNumbersSchema,
      ...emailAddressSchema,
      ...addressLine1Schema,
      ...postCodeSchema,
    },
    [...phoneNumbersSchemaDependencies]
  );
  const defaultValues = {
    mobileNumber: userProfile?.mobileNumber,
    workNumber: userProfile?.workNumber,
    homeNumber: userProfile?.homeNumber,
    emailAddress: userProfile?.emailAddress,
    addressLine1: userProfile?.userAddress?.addressLine1,
    addressLine2: userProfile?.userAddress?.addressLine2,
    addressLine3: userProfile?.userAddress?.addressLine3,
    city: userProfile?.userAddress?.city,
    county: userProfile?.userAddress?.county,
    postCode: userProfile?.userAddress?.postCode,
  };
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onUpdateSuccessful = () => {
    showSuccessMessage(true);
    setAuth({
      user: { ...userProfile, ...updatedProfile },
      accessToken: auth.accessToken,
    });
    setItem("user", { ...userProfile, ...updatedProfile });
    scrollTop();
  };

  const onUpdateFailure = (error) => {
    setErrorMessage(error?.localErrorMessage);
    scrollTop();
  };

  const { mutate } = useUpdatePersonalDetails(
    onUpdateSuccessful,
    onUpdateFailure
  );

  const onSubmit = (data) => {
    setErrorMessage("");
    showSuccessMessage(false);
    let updatedProfileData = {
      mobileNumber: data.mobileNumber,
      workNumber: data.workNumber,
      homeNumber: data.homeNumber,
      emailAddress: data.emailAddress,
      userAddress: {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        addressLine3: data.addressLine3,
        city: data.city,
        county: data.county,
        postCode: data.postCode,
      },
    };
    setUpdatedProfile(updatedProfileData);
    mutate(updatedProfileData);
  };

  return (
    <>
      {successMessage && (
        <Alert
          variant="success"
          onClose={() => showSuccessMessage(false)}
          dismissible
        >
          Thank you. Your details have now been successfully updated.
        </Alert>
      )}
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}
      <h2 className="text-primary mb-4">Personal details</h2>
      <div className="border-top border-bottom mt-3">
        <Row className="py-4">
          <Col className="fw-medium col-md-6 col-12">Full name</Col>
          <Col
            className={`text-md-end col-md-6 col-12 ${magicStrings.maskInformation}`}
          >
            {fullName}
          </Col>
        </Row>
      </div>
      {counterParty === 11 && (
        <div className="border-bottom">
          <Row className="py-4">
            <Col className="fw-medium col-md-6 col-12">Trading as</Col>
            <Col
              className={`text-md-end col-md-6 col-12 ${magicStrings.maskInformation}`}
            >
              {companyName}
            </Col>
          </Row>
        </div>
      )}
      {counterParty !== 11 && counterParty !== 21 && (
        <>
          <div className="border-bottom">
            <Row className="py-4">
              <Col className="fw-medium col-md-6 col-12">Company name</Col>
              <Col
                className={`text-md-end col-md-6 col-12 ${magicStrings.maskInformation}`}
              >
                {companyName}
              </Col>
            </Row>
          </div>

          <div className="border-bottom">
            <Row className="py-4">
              <Col className="fw-medium col-md-6 col-12">Company address</Col>
              <Col className="text-md-end col-md-6 col-12">
                <div className={`${magicStrings.maskInformation}`}>
                  {companyAddressLine1}
                </div>
                <div className={`${magicStrings.maskInformation}`}>
                  {companyAddressLine2}
                </div>
                <div className={`${magicStrings.maskInformation}`}>
                  {companyAddressLine3}
                </div>
                <div className={`${magicStrings.maskInformation}`}>
                  {companyCity}
                </div>
                <div className={`${magicStrings.maskInformation}`}>
                  {companyCounty}
                </div>
                <div className={`${magicStrings.maskInformation}`}>
                  {companyPostCode}
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
      <Row>
        <Col lg={6} md={8} sm={12}>
          <FormProvider {...methods}>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className="mt-4"
              name="personalDetailsForm"
            >
              <PhoneNumbersFormFields />

              <EmailAddressField />
              <AddressLine1Field />
              <AddressLine2Field />
              <AddressLine3Field />
              <CityField />
              <CountyField />

              <PostCodeField showSearchButton={false} />

              <div className="d-grid gap-2">
                <Button
                  className="px-5 mt-5"
                  type="submit"
                  id="btnUpdatePersonalDetails"
                >
                  Update
                </Button>
              </div>
            </Form>
          </FormProvider>
        </Col>
      </Row>
    </>
  );
};

export default PersonalDetailsForm;
