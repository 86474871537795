const documentsErrorMessages = {};
documentsErrorMessages["1740001"] =
  "The name you have entered does not match the name on your document. Please check and amend.";
documentsErrorMessages["1740401"] = "Order not found.";
documentsErrorMessages["1740402"] = "Document not found.";

documentsErrorMessages["1750001"] =
  "An unexpected error has occurred. Please try again later.";

documentsErrorMessages["1799901"] =
  "Something went wrong. Please try again later.";

export default documentsErrorMessages;
