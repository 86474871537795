import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import {
  EmploymentStatusField,
  OccupationField,
  EmployerNameField,
  TimeAtEmploymentField,
} from "../fields";
import AddressLookup from "../address-lookup/AddressLookup";
import {
  useAddressesByPostcode,
  useAddressByLineId,
} from "../../../api/hooks/addresses";
import * as fieldSchemas from "../schemas/fields";
import { useEffect, useState } from "react";

const EmploymentDetailsForm = ({ defaultValues, onFormSubmit }) => {
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.employmentStatusSchema,
        occupation: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.occupationSchema.occupation
              : schema.notRequired();
          }),
        employerName: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.employerNameSchema.employerName
              : schema.notRequired();
          }),
        postCode: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.postCodeSchema.postCode
              : schema.notRequired();
          }),
        selectAddress: yup
          .string()
          .when(
            ["isAddressManuallyEntered", "employmentStatus"],
            ([isAddressManuallyEntered, employmentStatus], schema) => {
              return !isAddressManuallyEntered &&
                [1, 2, 9].includes(employmentStatus)
                ? fieldSchemas.selectAddressSchema.selectAddress
                : schema.notRequired();
            }
          ),
        addressLine1: yup
          .string()
          .when(
            ["isAddressManuallyEntered", "employmentStatus"],
            ([isAddressManuallyEntered, employmentStatus], schema) => {
              return isAddressManuallyEntered &&
                [1, 2, 9].includes(employmentStatus)
                ? fieldSchemas.addressLine1Schema.addressLine1
                : schema.notRequired();
            }
          ),
        addressLine2: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.addressLine2Schema.addressLine2
              : schema.notRequired();
          }),
        addressLine3: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.addressLine3Schema.addressLine3
              : schema.notRequired();
          }),
        city: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.citySchema.city
              : schema.notRequired();
          }),
        county: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.countySchema.county
              : schema.notRequired();
          }),
        timeAtEmploymentYears: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.timeAtAddressSchema.timeAtAddressYears
              : schema.notRequired();
          }),
        timeAtEmploymentMonths: yup
          .string()
          .when(["employmentStatus"], ([employmentStatus], schema) => {
            return [1, 2, 9].includes(employmentStatus)
              ? fieldSchemas.timeAtEmploymentSchema.timeAtEmploymentMonths
              : schema.notRequired();
          }),
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const selectedEmploymentStatus = watch("employmentStatus");
  const watchSelectAddress = watch("selectAddress");
  const {
    refetch,
    addresses,
    isSuccess: isAddressesByPostcodeSuccess,
  } = useAddressesByPostcode(watch("postCode"));
  const { address } = useAddressByLineId(watchSelectAddress);

  const [isEmployed, setIsEmployed] = useState(() => {
    return [1, 2, 9, "1", "2", "9"].includes(defaultValues?.employmentStatus);
  });

  useEffect(() => {
    setIsEmployed([1, 2, 9, "1", "2", "9"].includes(selectedEmploymentStatus));
  }, [errors, selectedEmploymentStatus]);

  const onSubmit = (data) => {
    if (isEmployed) {
      onFormSubmit(data);
    } else {
      onFormSubmit({ employmentStatus: data.employmentStatus });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="EmploymentDetailsForm"
      >
        <EmploymentStatusField />
        {isEmployed && (
          <>
            <OccupationField />
            <EmployerNameField />
            <AddressLookup
              addresses={addresses}
              refetchAddresses={refetch}
              addressDetails={address}
              isAddressFetchSucessful={isAddressesByPostcodeSuccess}
            />
            <TimeAtEmploymentField />
          </>
        )}
        <div className="d-grid gap-2">
          <Button
            className="mt-3"
            type="submit"
            id="EmploymentDetailsFormSubmitBtn"
          >
            Continue to financial details
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

EmploymentDetailsForm.propTypes = {
  defaultValues: PropTypes.shape({
    employmentStatus: PropTypes.number,
    occupation: PropTypes.string,
    employerName: PropTypes.string,
    postCode: PropTypes.string,
    isAddressManuallyEntered: PropTypes.bool,
    selectAddress: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    timeAtEmploymentYears: PropTypes.number,
    timeAtEmploymentMonths: PropTypes.number,
  }),
  onFormSubmit: PropTypes.func,
};
export default EmploymentDetailsForm;
