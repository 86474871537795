import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";

const NewDirectDebitPage = () => {
  return (
    <BasePage pageTitle="Manage Direct Debit">
      <Container className="py-md-5 py-4">
        <Row className="text-center">
          <Col sm={12} md={3}>
            <h2 className="m-0">Set up a Direct Debit</h2>
          </Col>
          <Col sm={12} md={4} className="my-4 my-md-0">
            Direct Debit is the easiest way to stay on top of your payments.
          </Col>
          <Col sm={12} md={5}>
            <div className="d-flex justify-content-center gap-2">
              <Button
                as={Link}
                to="/payments/new-direct-debit/setup-direct-debit"
                id="btnSetupDirectDebit"
                className="text-decoration-none flex-fill mt-2 btn-lg"
                style={{ maxWidth: "20rem" }}
              >
                Setup Direct Debit
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </BasePage>
  );
};

export default NewDirectDebitPage;
