const transactionStatusDetails = (currentStatus) => {
  const statusDetails = [
    {
      status: "Paid",
      variant: "success",
      tooltipContent: "You've made this payment",
    },
    {
      status: "Credit",
      variant: "success",
      tooltipContent: "You've made this payment",
    },
    {
      status: "Due",
      variant: "info",
      tooltipContent: "This payment is due now",
    },
    {
      status: "Pending",
      variant: "info",
      tooltipContent:
        "Your Direct Debit for this payment is scheduled for collection",
    },
    {
      status: "Overdue",
      variant: "warning",
      tooltipContent:
        "This payment is now overdue as the invoice was raised over a month ago",
    },
    {
      status: "Dispute",
      variant: "danger",
      tooltipContent:
        "You disputed this payment, so it's on hold while we look into it",
    },
    {
      status: "Disputed",
      variant: "danger",
      tooltipContent:
        "You disputed this payment, so it's on hold while we look into it",
    },
    {
      status: "Pay Hol",
      variant: "secondary",
      tooltipContent:
        "This payment is on hold while you take a payment holiday",
    },
    {
      status: "Pay Plan",
      variant: "pay-plan",
      tooltipContent:
        "We've agreed a payment plan with you and have sent a letter explaining how it works",
    },
  ];
  return statusDetails.find(
    (statusDetail) =>
      currentStatus &&
      statusDetail.status.toUpperCase() === currentStatus.toUpperCase()
  );
};

export { transactionStatusDetails };
