import * as yup from "yup";
import { Form, Button, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { trimStartOnChange } from "../../../utils/form/form-text-utils";
import useAuth from "../../../hooks/use-auth/useAuth";
import * as fieldSchemas from "../schemas/fields/password-schema";
import { emailAddressSchema } from "../schemas/fields/personal-details-schema";
import { PasswordAndConfirmPasswordFields } from "../fields/password-fields";
import { EmailAddressField } from "../fields";
import { getItem } from "../../../storage/SessionStorage";

const UserCredentialForm = ({ onFormSubmit }) => {
  const { auth } = useAuth();

  const defaultValues = {
    emailAddress: auth.user?.email ?? getItem("temp_email"),
  };

  const schema = yup.object().shape({
    ...emailAddressSchema,
    emailAsAlias: yup.boolean(),
    alias: yup.string().when("emailAsAlias", {
      is: false,
      then: () => yup.string().required("User alias is required."),
      otherwise: () => yup.string().notRequired(),
    }),
    ...fieldSchemas.userCurrentPasswordSchema,
    ...fieldSchemas.userConfirmNewPasswordSchema,
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  let checkboxValue = watch("emailAsAlias", false);

  const onSubmit = (data) => {
    if (Boolean(checkboxValue) === true) {
      data.alias = data.emailAddress;
    }
    onFormSubmit(data);
  };

  return (
    <>
      <Row>
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className="mt-4"
            name="userCredentialForm"
          >
            <EmailAddressField />

            <Form.Group
              className="mb-3"
              controlId="alias"
              hidden={checkboxValue}
            >
              <Form.Label>User alias</Form.Label>
              <Form.Control
                type="string"
                maxLength={255}
                isInvalid={errors.alias}
                {...register("alias", {
                  onChange: trimStartOnChange,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.alias?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="emailAsAlias">
              <Form.Check
                type="checkbox"
                label="Use email address as alias"
                {...register("emailAsAlias")}
                isInvalid={errors.emailAsAlias}
              />
              <Form.Control.Feedback
                type="invalid"
                className={errors.emailAsAlias ? "d-block" : "d-none"}
              >
                {errors.emailAsAlias?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <PasswordAndConfirmPasswordFields
              passwordControlId="currentPassword"
              passwordLabelText="Password"
              passwordConfirmControlId="confirmNewPassword"
              passwordConfirmLabelText="Confirm password"
            />

            <div className="d-grid gap-2">
              <Button
                className="px-5 mt-5"
                type="submit"
                id="btnUpdatePassword"
              >
                Update
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Row>
    </>
  );
};

export default UserCredentialForm;
