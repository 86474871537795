import PropTypes from "prop-types";
import { GenericRadioSelectField} from "./field-utils/field-utils.js";

export const ForgotLoginRadioField = ({ data, ...props }) => {
  return (
    <GenericRadioSelectField
      controlId="forgotLoginRadio"
      options={data}
      {...props}
    />
  );
};
ForgotLoginRadioField.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};
