import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const DocumentIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    style={{
      verticalAlign: "text-top",
    }}
    role="img"
    viewBox="0 0 19 17"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M1 9h1.889V7H1v2Zm0 4h1.889v-2H1v2Zm0-8h1.889V3H1v2Zm3.778 4H18V7H4.778v2Zm0 4H18v-2H4.778v2Zm0-10v2H18V3H4.778Z"
    />
  </svg>
);

DocumentIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
export { DocumentIcon };
export default DocumentIcon;
