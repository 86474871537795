import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const CloseDdIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    viewBox="0 0 1000 1000"
    role="img"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M500 10C229.4 10 10 229.4 10 500c0 270.7 219.4 490 490 490 270.7 0 490-219.3 490-490 0-270.6-219.3-490-490-490zm0 914.7C265.8 924.7 75.3 734.2 75.3 500S265.8 75.3 500 75.3 924.7 265.8 924.7 500 734.2 924.7 500 924.7z" />
      <path d="M680.8 273 501.3 452.6 340.2 273c-12.7-12.7-33.5-12.7-46.2 0-12.7 12.7-12.7 33.5 0 46.2l161.1 179.6-182.1 182c-12.7 12.7-12.7 33.5 0 46.2 12.7 12.7 33.5 12.7 46.2 0l179.6-179.5L659.9 727c12.7 12.7 33.5 12.7 46.1 0 12.7-12.7 12.7-33.5 0-46.2L544.9 501.3 727 319.2c12.7-12.7 12.7-33.5 0-46.2s-33.4-12.8-46.2 0z" />
    </g>
  </svg>
);

CloseDdIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
export { CloseDdIcon };
export default CloseDdIcon;
