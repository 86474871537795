import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const MailIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    aria-label={title}
    viewBox="0 0 20 20"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="mail-icon_svg__a"
        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2Zm0 14H4V8l8 5 8-5v10Zm-8-7L4 6h16l-8 5Z"
      />
    </defs>
    <use
      xlinkHref="#mail-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-2 -4)"
    />
  </svg>
);

MailIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { MailIcon };
export default MailIcon;
