import { useNavigate } from "react-router-dom";
import BasePage from "../../../basepages/basepage/BasePage";
import DirectDebitForm from "../../../../component/forms/payments/direct-debit/DirectDebitForm";

const SetupDirectDebitPage = () => {
  const navigate = useNavigate();

  const onFormSubmit = () => {
    navigate("/payments/new-direct-debit/review-direct-debit");
  };

  const onFormCancel = () => {
    navigate("/payments/new-direct-debit");
  };

  return (
    <BasePage pageTitle="Set up a Direct Debit">
      <DirectDebitForm
        onFormSubmit={onFormSubmit}
        onFormCancel={onFormCancel}
      />
    </BasePage>
  );
};

export default SetupDirectDebitPage;
