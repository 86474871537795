import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";

const getTransactions = async (axios) => {
  return axios.get("/transactions").then((res) => {
    return res.data;
  });
};

const useTransactions = () => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const { data = fallback, isError } = useQuery(
    queryKeys.transactions,
    () => getTransactions(axios),
    { cacheTime: 10 * 60 * 1000, staleTime: 10 * 60 * 1000 }
  );
  return {
    transactions: data,
    isError,
  };
};

export { useTransactions };
