import { useEffect, useState, useMemo } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import AddressLookup from "../../address-lookup/AddressLookup";
import { PhoneNumbersFormFields, EmailAddressField } from "../../fields";
import {
  useAddressesByPostcode,
  useAddressByLineId,
} from "../../../../api/hooks/addresses";
import * as fieldSchemas from "../../schemas/fields";
import { trimStartOnChange } from "../../../../utils/form/form-text-utils";

const ContactAndCollectionDetailsForm = ({
  onFormSubmit,
  userProfile,
  collectionAddress,
  trackChanges = false,
}) => {
  const schema = yup.object().shape(
    {
      contactName: yup.string().required("Contact name is required.").max(50),
      ...fieldSchemas.phoneNumbersSchema,
      ...fieldSchemas.emailAddressSchema,
      ...fieldSchemas.postCodeSchema,
      isAddressManuallyEntered: yup.bool(),
      selectAddress: yup
        .string()
        .when(
          ["isAddressManuallyEntered"],
          ([isAddressManuallyEntered], schema) => {
            return !isAddressManuallyEntered
              ? fieldSchemas.selectAddressSchema.selectAddress
              : schema.notRequired();
          }
        ),
      addressLine1: yup
        .string()
        .when(
          ["isAddressManuallyEntered"],
          ([isAddressManuallyEntered], schema) => {
            return isAddressManuallyEntered
              ? fieldSchemas.addressLine1Schema.addressLine1
              : schema.notRequired();
          }
        ),
      ...fieldSchemas.addressLine2Schema,
      ...fieldSchemas.addressLine3Schema,
      ...fieldSchemas.citySchema,
      ...fieldSchemas.countySchema,
    },
    [...fieldSchemas.phoneNumbersSchemaDependencies]
  );

  const [hasChanges, setHasChanges] = useState(false);

  const defaultValues = useMemo(() => {
    return { ...userProfile, ...collectionAddress };
  }, [userProfile, collectionAddress]);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitted, isValidating, isDirty },
    watch,
  } = methods;

  const watchPostcode = watch("postCode");
  const watchSelectAddress = watch("selectAddress");
  const {
    refetch,
    addresses,
    isSuccess: isAddressesByPostcodeSuccess,
  } = useAddressesByPostcode(watchPostcode);
  const { address } = useAddressByLineId(watchSelectAddress);

  useEffect(() => {
    setHasChanges(false);
    const currentValues = getValues();

    const fields = [
      "contactName",
      "emailAddress",
      "homeNumber",
      "workNumber",
      "mobileNumber",
      "addressLine1",
      "addressLine2",
      "addressLine3",
      "county",
      "postCode",
    ];

    fields.forEach((field) => {
      if (currentValues[field] !== defaultValues[field] || !trackChanges) {
        setHasChanges(true);
      }
    });
  }, [defaultValues, trackChanges, isDirty, isValidating, getValues]);

  const onSubmit = (data) => {
    if (hasChanges === true || defaultValues.length <= 0 || !trackChanges) {
      onFormSubmit(data);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="collectionAndContactForm"
      >
        <Form.Group className="mb-3" controlId="contactName">
          <Form.Label>Collection contact name</Form.Label>
          <Form.Control
            type="string"
            maxLength={50}
            isInvalid={errors.contactName}
            {...register("contactName", {
              onChange: trimStartOnChange,
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.contactName?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <PhoneNumbersFormFields maxLength={15} />
        <EmailAddressField maxLength={50} />
        <AddressLookup
          addresses={addresses}
          refetchAddresses={refetch}
          addressDetails={address}
          isAddressFetchSucessful={isAddressesByPostcodeSuccess}
        />
        <div className="d-grid gap-2 py-3">
          {!hasChanges && isSubmitted && trackChanges && (
            <Alert variant="danger">
              You haven't made any changes to your contact and collection
              details. To proceed please update your details.
            </Alert>
          )}
          <Button className="px-5" type="submit" id="btnFormSubmit">
            Continue
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

export default ContactAndCollectionDetailsForm;
