const ordersErrorMessages = {};
ordersErrorMessages["1540401"] = "Order not found.";

ordersErrorMessages["1550001"] =
  "An unexpected error has occurred. Please try again later.";

ordersErrorMessages["1599901"] =
  "Something went wrong. Please try again later.";

export default ordersErrorMessages;
