const contractAmendsSteps = {
  intro: [0],
  customerServices: [10],
  applicationDeclined: [10],
  applicationReferred: [10],
  applicationSuccess: [10],
  viewSummary: [18],
  signAgreement: [19],
  confirmNextSteps: [20],
  exit: 100,
  newQuote: {
    vehicleSelection: [1, 1],
    calculateQuote: [1, 2],
    viewQuote: [1, 3],
    saveQuote: [1, 4],
    personalDetails: [2, 1],
    addressDetails: [2, 2],
    employmentDetails: [2, 3],
    financialDetails: [2, 4],
    reviewApplication: [3, 1],
  },
  existingQuotes: [1, 1],
  bch: {
    soleTrader: {
      personalDetails: [2, 1, 1],
      currentAddress: [2, 1, 2],
      captureAddress: [2, 1, 3],
      reviewApplication: [3, 1],
    },
    limitedCompany: {
      partners: [
        {
          addNewPartner: [2, 2, 1, 0],
          validateExistingPartner: [2, 2, 2, 0],
          personalDetails: [2, 2, 3, 0],
          currentAddress: [2, 2, 4, 0],
          captureAddress: [2, 2, 5, 0],
        },
        {
          addNewPartner: [2, 2, 1, 1],
          validateExistingPartner: [2, 2, 2, 1],
          personalDetails: [2, 2, 3, 1],
          currentAddress: [2, 2, 4, 1],
          captureAddress: [2, 2, 5, 1],
        },
        {
          addNewPartner: [2, 2, 1, 2],
          validateExistingPartner: [2, 2, 2, 2],
          personalDetails: [2, 2, 3, 2],
          currentAddress: [2, 2, 4, 2],
          captureAddress: [2, 2, 5, 2],
        },
      ],

      partnerError: [2, 2, 20],
      reviewApplication: [3, 1],
    },
  },
};

export default contractAmendsSteps;
