import { Link } from "react-router-dom";
import FooterBasePage from "../basepages/footer-basepage/FooterBasePage";
import PageHeading from "../../component/page-heading/PageHeading";

const AccessibilityPage = () => {
  return (
    <FooterBasePage pageTitle="Accessibility" pageSubTitle="Using our website.">
      <p>
        We're committed to providing a website that is accessible to all of our
        customers and we work hard to make sure that this website is accessible
        and usable by people of all abilities.
      </p>
      <hr className="my-5" />
      <PageHeading align="left">Further help and advice</PageHeading>
      <p>
        If you have problems using a computer because of a disability or
        impairment then we recommend that you visit{" "}
        <a
          href="https://www.abilitynet.org.uk/"
          target="_blank"
          rel="noopener noreferrer"
          className="border-bottom border-white"
        >
          AbilityNet
        </a>{" "}
        My Computer My Way website which provides lots of advice on how to make
        your computer easier to use. AbilityNet are experts in the field of
        computing and disability and they provide a free service to individuals
        with disabilities to assess their computing needs (call AbilityNet free
        on
        <Link to="tel: 0800 269 545" className="h6 text-primary">
          {" "}
          0800 269 545{" "}
        </Link>
        for more information).
      </p>
      <h2 className="h3">
        How to get the most accessible experience from this website
      </h2>
      <p>
        Our website can be viewed on a range of different screen sizes and you
        can vary the text site using your browser.
      </p>
      <h2 className="h3">Accessibility limitations</h2>
      <p>
        Due to certain constraints, we have had to include technologies and
        features that may cause accessibility issues. These may include video
        content provided via a 3rd party. We are making every effort to overcome
        these limitations by improving their accessibility and failing that, can
        provide accessible alternatives.
      </p>
      <h2 className="h3">Accessibility guidelines</h2>
      <p>
        We work towards AA level of the Web Content Accessibility Guidelines
        2.0. These guidelines are the internationally recognised benchmark for
        building accessible websites and explain how to make websites more
        accessible for people with disabilities.
      </p>
      <p>
        Equally important to us is that, by working to these guidelines, we also
        make our website more user-friendly for all our customers.
      </p>
      <h2 className="h3">Web standards and technologies</h2>
      <p>
        We've built this website to conform to W3C standards for HTML and CSS
        and these technologies are used throughout the site.
      </p>
      <p>
        If you are using an older browser, some sections of the site might not
        work or display properly. It's important to keep your browser up-to-date
        for the best possible experience online. Find out{" "}
        <a
          href="https://www.lloydsbank.com/help-guidance/accessibility/accessing-our-website.html"
          className="border-bottom border-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          how to upgrade your browser
        </a>
        .
      </p>
      <p>
        This website uses Flash and PDFs although these technologies are not
        relied upon and the website works perfectly well without them.
      </p>
      <p className="mb-5">
        Some areas of the website will require the use of JavaScript, you will
        be prompted to use if required.
      </p>
    </FooterBasePage>
  );
};

export default AccessibilityPage;
