import { Table } from "react-bootstrap";
import BasePage from "../../basepages/basepage/BasePage";
import PageHeading from "../../../component/page-heading/PageHeading";

const IncomeAndExpenditureInformationPage = () => {
  return (
    <BasePage
      pageTitle="Contract amendment"
      pageSubTitle="Request to change your contract term and/or mileage."
    >
      <PageHeading align="left">
        <strong>Guiding you through customer financial details</strong>
      </PageHeading>
      <p>
        Its important when we're talking about your income and expenditure that
        you're recording the correct figures.
      </p>
      <p>
        To help make it easier for you, here's a list of all the allowable
        income and expenditure types. If its not listed in this guide, then you
        cannot include it in your application.
      </p>
      <p>
        We've put any extra information or explanation you might need in{" "}
        <em>italics.</em>
      </p>

      <h2 className="pt-3">Gross annual income</h2>
      <p>
        If you are currently receiving a reduced income, e.g. due to reduced
        hours or a government support inititative, you should include your
        current income.{" "}
      </p>

      <Table responsive="md" bordered className="table-container">
        <tbody>
          <tr style={{ width: "25%" }}>
            <td className="align-middle">
              <p>
                <strong>Employment</strong>
              </p>
            </td>

            <td>
              <ul className="mb-1">
                <li>
                  <strong>Wage or salary</strong> - From both full and part-time
                  employment before tax.
                  <br />
                  <em>
                    If student loan repayments are being made, you must provide
                    what your income is after these repayments have been
                    deducted.{" "}
                  </em>
                </li>
                <li>
                  <strong>Regular commission or overtime</strong> - This can be
                  used, but only if it's received regularly and unlikely to be
                  withdrawn.
                </li>
                <li>
                  <strong>Permanent allowances</strong> - Allowed if they are
                  included in your regular salary.
                  <br />
                  <br />
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Self-employment (Sole Trader / Partnership)</strong>
              </p>
            </td>
            <td>
              <ul>
                <li>
                  This needs to be drawn from the business into a personal
                  account.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <p>
                <strong>Pension</strong>
              </p>
            </td>
            <td>
              <ul>
                <li>
                  This can be from a private, company or state pension.
                  <br />
                  <em>
                    It's important to make sure the correct frequency for the
                    pension payment is used, e.g. the state pension is paid
                    every four weeks, not monthly.
                  </em>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <p>
                <strong>Student earnings</strong>
              </p>
            </td>
            <td>
              <ul>
                <li>
                  <strong>Full-time students</strong>
                  - Any income from part-time or contractual work can be
                  included from either employment or self-employment.
                  <br />
                  <em>Student Loans must not be included as earnings.</em>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <p>
                <strong>Rental income</strong>
              </p>
            </td>
            <td>
              <ul>
                <li>
                  This can be from buy-to-let properties or lodgers.
                  <br />
                  <em>
                    The rental income is after the deduction of all costs for
                    the property such as property maintenance, insurance, ground
                    rent, agency fees etc. but not including the mortgage. Any
                    mortgage payments for rental properties must be included in
                    the expenditure section.
                  </em>
                  <br />
                  <br />
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Investments</strong>
              </p>
            </td>
            <td>
              <ul>
                <li>Any regular investment income, including dividends.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <p>
                <strong>Maintenance payments</strong>
              </p>
            </td>
            <td>
              <ul>
                <li>
                  Regularly received maintenance payments that are subject to a
                  formal agreement e.g. Court Order or through the Child
                  Maintenance Service.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <p>
                <strong>Government support</strong>
              </p>
              <p>
                <em>
                  <strong>
                    All of these are only allowed where you are employed,
                    self-employed or retired
                  </strong>
                </em>
              </p>
            </td>
            <td>
              <p>
                <strong>
                  The following are only{" "}
                  <em>
                    allowed if your employment status is employed, self-employed
                    or retired
                  </em>
                </strong>
              </p>
              <ul>
                <li>
                  <strong>Universal credit. </strong>
                </li>
                <li>
                  <strong>Working tax credit.</strong>{" "}
                </li>
                <li>
                  <strong>Child tax credit </strong>
                  - This can only included if you use it towards the cost of
                  childcare. This can't be used if you have no childcare costs.
                  <br />
                  <em>
                    This is not the same as child benefit, which is not an
                    allowable income.
                  </em>
                  <br />
                  <em>
                    You can combine working tax credit and child tax credit if
                    you don't know the separate amounts for each.
                  </em>
                </li>
                <li>
                  If Child Tax Credit is more than 70% of the childcare costs,
                  only input 70% of the childcare costs.
                </li>
                <li>
                  If the Child Tax credit is less than 70% of childcare costs,
                  input the full child tax credit amount.
                </li>
                <li>
                  <strong>Housing Benefit</strong>- Use the full amount of this
                  benefit but ensure you provide the full rental payment in the
                  expenditure section.
                </li>
                <li>
                  <strong>Foster Care Reward Payment</strong>
                  <br />
                  <em>
                    You should only use the reward payment amount., Do not
                    include the foster care allowance as this is paid to cover
                    the cost of caring for the child.
                  </em>
                </li>
                <li>
                  <strong>Pension Credit.</strong>
                </li>
                <li>
                  <strong>Reduced Earnings Allowance.</strong>
                </li>
                <li>
                  <strong>
                    Employment and Support Allowance (ESA or EESA).
                  </strong>
                </li>
                <li>
                  <strong>Income Support.</strong>
                </li>
                <li>
                  <strong>Industrial injuries Disablement Benefit.</strong>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>

      <br />
      <p>
        <strong>Monthly expenditure</strong>
      </p>
      <p>
        When you're working out your monthly expenditure, it's important to
        remember not all costs are paid on a monthly basis. To help you make
        sure you've got the right numbers here's a quick tip on how to work out
        what the monthly cost is:
      </p>
      <ul>
        <li>Weekly payments - multiply by 52 and then divide by 12.</li>
        <li>Fortnightly payments - multiply by 26 and then divide by 12.</li>
        <li>4-weekly payments - multiply by 13 and then divide by 12.</li>
        <li>
          For less regular payments, work out the annual cost and divide by 12
        </li>
      </ul>
      <Table responsive="md" bordered className="table-container">
        <tbody>
          <tr>
            <td className="align-middle">
              <p>
                <strong>Mortgage/Rent/Housing costs</strong>
              </p>
            </td>
            <td>
              <p>
                Please enter your share only. For example - if you have a joint
                mortgage for £1,000 and you pay half, then only include your
                share as £500.
              </p>
              <p>
                If you do not pay mortgage, rent or housing costs each month
                please enter '£0'
              </p>
              <p>Include:</p>
              <ul>
                <li>Only the amount that you personally pay.</li>
                <li>
                  The total amount you pay towards both a mortgage and rent.
                </li>
                <li>
                  All mortgage and rent payments you make for properties in the
                  UK and abroad
                  <br />
                  <em>including any buy-to-let properties</em>.
                </li>
                <li>
                  Any ground rent, site fees or maintenance or service charges
                  that you pay for.
                </li>
                <li>
                  Money you pay to live in someone's property (for example to a
                  landlord, letting agency, parents, or friend).
                </li>
              </ul>
              <br />
              <p>Don't include:</p>
              <ul>
                <li>
                  Ground rent or service charges if your landlord pays for it
                </li>
                <li>Any household bills.</li>
                <li>
                  Any bills included in your rent - only enter the amount for
                  accommodation.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <p>
                <strong>Other expenditure</strong>
              </p>
            </td>
            <td>
              <p>
                Your monthly share of childcare costs, maintenance costs, school
                fees or university fees.
              </p>

              <br />
              <p>Include:</p>
              <ul>
                <li>
                  Childcare payments for regular childcare of your child or
                  children.
                </li>
                <li>
                  School, college, or university tuition fees, for you or anyone
                  else, that you have committed to pay.
                </li>
                <li>
                  Child maintenance - money you pay to support children you have
                  with a previous partner.
                </li>
                <li>
                  Spousal maintenance - money you pay to support a previous
                  partner.
                </li>
                <li>
                  Your student loan repayments - which your employer doesn't
                  take from your pay.
                </li>
              </ul>
              <br />

              <p>Don't include:</p>
              <ul>
                <li>Occasional childcare costs, such as babysitting.</li>
                <li>
                  Any other childcare expenses (for example entertainment, food,
                  clothes).
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>
    </BasePage>
  );
};
export default IncomeAndExpenditureInformationPage;
