import { Link } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import ClockIcon from "../../../component/icons/ClockIcon";
const AmendmentUnavailablePage = () => {
  return (
    <BasePage
      pageTitle="Book a collection"
      pageSubTitle="How to return your vehicle when a contract ends."
    >
      <div className="text-center">
        <ClockIcon title="Success" className="text-warning" size="lg" />
        <h2 className="text-secondary h1 mt-3">
          Vehicle collection due within the next 48 hours
        </h2>
        <hr />
      </div>

      <p className="mt-5">
        We're due to collect this vehicle in the next 48 hours, so if you make
        any changes to the arrangements you may be charged a fee.
      </p>
      <p>
        If you do need to make changes, please call us on{" "}
        <Link to="tel:0344 241 5335">0344 241 5335</Link>. Lines are open Monday
        - Friday between 9am - 5pm. We're closed on weekends and bank holidays.
      </p>

      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default AmendmentUnavailablePage;
