// All direct debit api error need to map in the range of of 1640001 to 1699999
// The number is divided into 3 parts
// First part is the api key range, for direct debit will always be 16
// Second part is the http error status code e.g 400
// Last part is the identifier for the unique error message we want to display.
// Two server api error codes are okay to be mapped to single error code, if required.

const directDebitErrorCodeMappings = {
  //Bad request
  400: {
    9002: 1640000,
    9003: 1640001,
  },
  401: {},
  // Internal server error
  500: { 2000: 1650000 },
  403: {},
  // default is used to handle the case where it is an unknown http status code
  default: {
    default: 1699901,
    // This default is the absolute fallback error code for direct debit api.
  },
};

export default directDebitErrorCodeMappings;
