import {
  GenericNumberField,
  GenericSelectField,
  GenericTextArea,
} from "./field-utils/field-utils.js";
import * as fieldData from "./data/field-data.js";
import PropTypes from "prop-types";

// Financial Details

export const GrossIncomeField = (props) => {
  return (
    <GenericNumberField
      controlId="grossIncome"
      labelText="Gross annual income"
      {...props}
    />
  );
};

export const ShareOfRentField = (props) => {
  return (
    <GenericNumberField
      controlId="shareOfRent"
      labelText="Your share of monthly mortgage/rent"
      {...props}
    />
  );
};

export const ShareOfChildCostsField = (props) => {
  return (
    <GenericNumberField
      controlId="shareOfChildCosts"
      labelText="Your monthly share of regular childcare costs, spousal/child maintenance and school fees"
      {...props}
    />
  );
};

export const ExpectedChangesField = ({ options, ...props }) => {
  return (
    <GenericSelectField
      controlId="expectedChanges"
      labelText="Are there any expected changes in your personal circumstances that would result in you being unable to meet your monthly rental payments?"
      options={options ?? fieldData.yesNoOptions}
      placeholderOptionText="Please select"
      ariaText="Are there any expected changes in your personal circumstances that would result in you being unable to meet your monthly rental payments?"
      {...props}
    />
  );
};
export const DescriptionOfExpectedChanges = (props) => {
  return (
    <GenericTextArea
      controlId="descriptionExpectedChanges"
      labelText="Please briefly describe what the expected changes will be, the approximate date of when you believe this may occur and what impact, if any, it may have on your ability to maintain your monthly rental payments"
      {...props}
    />
  );
};

ExpectedChangesField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};
