import { useState, useEffect } from "react";
import { Row, Alert, Button } from "react-bootstrap";
import TilesBasePage from "../basepages/tiles-basepage/TilesBasePage";
import Tile from "../../component/tile/Tile";
import ArrowDownIcon from "../../component/icons/ArrowDownIcon";
import EmptyStateIcon from "../../component/icons/EmptyStateIcon";
import { useDocuments } from "../../api/hooks/documents";
import MessageBox from "../../component/modals/message-box/MessageBox";
import { useSupportingDocument } from "../../api/hooks/invoices";
import { useOrderDocumentsAll } from "../../api/hooks/orders";
import { useContractAmendAgreement } from "../../api/hooks/contracts";
import { formatDate } from "../../utils/pages/date-format-utils";
import { openPdfDocument } from "../../utils/pdf/pdf-utils";
import magicStrings from "../../utils/magic-string";

const DocumentsPage = () => {
  const [pdfContent, setPdfContent] = useState();
  const [maxDocument, setMaxDocument] = useState(6);
  const [openPopup, setOpenPopup] = useState(false);
  const [documentDetail, setDocumentDetail] = useState({});
  const { documents, isError: isMyDocumentsError } = useDocuments();
  const { isError: isInvoiceDocumentError, refetch: invoiceDocumentRefetch } =
    useSupportingDocument(
      documentDetail?.referenceNumber,
      documentDetail?.documentName
    );
  const { isDocumentError, refetch } = useOrderDocumentsAll(
    documentDetail?.orderId,
    documentDetail?.documentType
  );
  const { isError, refetch: caDocumentRefetch } = useContractAmendAgreement(
    documentDetail?.contractId,
    documentDetail?.caNumber
  );

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  useEffect(() => {
    if (documentDetail?.caNumber) {
      caDocumentRefetch().then((response) => {
        if (response.isError) {
          scrollToTop();
        } else {
          setOpenPopup(true);
          setPdfContent(response.data.agreementDocument);
        }
      });
    }
  }, [caDocumentRefetch, documentDetail?.caNumber, documentDetail?.contractId]);

  const downloadContractAmendsDocument = (caNumber) => {
    if (caNumber) {
      documents.forEach((element) => {
        if (
          element.name.toUpperCase() === "CONTRACT_AMENDMENT" &&
          element.details.contractAmendment.caNumber.toString() === caNumber
        ) {
          setDocumentDetail({
            caNumber: element.details.contractAmendment.caNumber,
            contractId: element.details.contractAmendment.contractId,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (documentDetail?.orderId) {
      refetch().then((response) => {
        if (response.isError) {
          scrollToTop();
        } else {
          setOpenPopup(true);
          setPdfContent(response.data.content);
        }
      });
    }
  }, [refetch, documentDetail?.orderId]);

  const downloadOrderDocument = (orderId) => {
    setDocumentDetail({ orderId: orderId, documentType: "ALL" });
  };

  useEffect(() => {
    if (documentDetail?.documentName) {
      invoiceDocumentRefetch().then((response) => {
        if (response.isError) {
          scrollToTop();
        } else {
          setOpenPopup(true);
          setPdfContent(response.data.content);
        }
      });
    }
  }, [
    invoiceDocumentRefetch,
    documentDetail?.referenceNumber,
    documentDetail?.documentName,
  ]);

  const downloadInvoiceDocument = (referenceNumber) => {
    if (referenceNumber) {
      documents.forEach((element) => {
        const documentName = element.name.toUpperCase();
        if (
          documentName !== "CONTRACT_AMENDMENT" &&
          documentName !== "HISTORICAL_ORDERS" &&
          element.details.financialSupport.referenceNumber === referenceNumber
        ) {
          setDocumentDetail({
            referenceNumber: element.details.financialSupport.referenceNumber,
            documentName:
              Array.isArray(element?.associatedDocuments) &&
              element.associatedDocuments.length > 0
                ? element.associatedDocuments[0].documentName
                : null,
          });
        }
      });
    }
  };

  const openPdf = (buttonId) => {
    setOpenPopup(false);
    if (buttonId === 1) {
      openPdfDocument(
        pdfContent,
        documentDetail?.documentType === "ALL"
          ? `ALL_${documentDetail?.orderId}`
          : "MyDocuments.pdf"
      );
    }
    setDocumentDetail({});
  };

  const loadMoreDocument = () => {
    setMaxDocument(maxDocument + 6);
  };

  const childrenElement = (doc) => {
    if (doc.name === "CONTRACT_AMENDMENT") {
      const vehicleReg = doc.details.contractAmendment.vehicleReg;
      const proposedTerm = doc.details.contractAmendment.proposedTerm;
      const proposedMileage = doc.details.contractAmendment.proposedMileage;
      const signedDate = formatDate(
        doc.details.contractAmendment.agreementDate
      );
      return (
        <>
          <div>
            <strong className={`${magicStrings.maskInformation}`}>
              {vehicleReg}
            </strong>
          </div>
          <div className={`${magicStrings.maskInformation}`}>
            Date signed: {signedDate}
          </div>
          <div className={`${magicStrings.maskInformation}`}>
            {proposedTerm} months
          </div>
          <div className={`${magicStrings.maskInformation}`}>
            {proposedMileage} miles
          </div>
        </>
      );
    } else if (doc.name === "HISTORICAL_ORDERS") {
      return (
        <div className={`${magicStrings.maskInformation}`}>
          Date signed: {formatDate(doc.details.order.dateSigned, "ddMMyyyy")}
        </div>
      );
    } else {
      const vehicleReg = doc.details.financialSupport.vehicleReg;
      const status = doc.details.financialSupport.status;
      const transDate = formatDate(
        doc.details.financialSupport.transactionDate
      );
      return (
        <>
          <div>
            <strong className={`${magicStrings.maskInformation}`}>
              {vehicleReg}
            </strong>
          </div>
          {doc.name !== "PAYMENT_HOLIDAY_SCHEDULE" && (
            <div className={`${magicStrings.maskInformation}`}>
              Status: {status}
            </div>
          )}
          <div className={`${magicStrings.maskInformation}`}>
            Date of issue: {transDate}
          </div>
        </>
      );
    }
  };

  const documentDetails = (doc) => {
    let cardId,
      cardTitle,
      children,
      onDownloadDocument,
      btnDisabled = false;
    if (doc.name === "CONTRACT_AMENDMENT") {
      cardId = doc.details.contractAmendment.caNumber;
      cardTitle = doc.type;
      children = childrenElement(doc);
      onDownloadDocument = downloadContractAmendsDocument;
    } else if (
      doc.name === "PAYMENT_PLAN_SCHEDULE" ||
      doc.name === "PAYMENT_HOLIDAY_SCHEDULE" ||
      doc.name === "PAYMENT_PLAN_MISSED_PAYMENT" ||
      doc.name === "PAYMENT_PLAN_CANCELLED"
    ) {
      cardId = doc.details.financialSupport.referenceNumber;
      cardTitle = doc.type;
      children = childrenElement(doc);
      onDownloadDocument = downloadInvoiceDocument;
      btnDisabled = doc.associatedDocuments.length === 0 ? true : false;
    } else if (doc.name === "HISTORICAL_ORDERS") {
      cardId = doc.details.order.orderId;
      cardTitle =
        doc.type === "MHA"
          ? "Master Hire Agreement"
          : "Addendum to my Master Hire Agreement";
      children = childrenElement(doc);
      onDownloadDocument = downloadOrderDocument;
    }
    return { cardId, cardTitle, children, btnDisabled, onDownloadDocument };
  };

  return (
    <TilesBasePage
      pageTitle="My documents"
      pageSubTitle="View and download your important documents."
    >
      <Row className="my-3">
        {(isError ||
          isMyDocumentsError ||
          isInvoiceDocumentError ||
          isDocumentError) && (
          <Alert variant="danger">
            We are sorry but we are unable to retrieve your documents. Please
            try again and if the problem continues contact us.
          </Alert>
        )}
        {documents && documents.length > 0 ? (
          <>
            <MessageBox
              title="Document ready"
              show={openPopup}
              onClose={openPdf}
              buttons={["Open", "Cancel"]}
            >
              Your document is ready. Please press "Open" to download and view
              it.
            </MessageBox>
            {documents.slice(0, maxDocument).map((document) => {
              const {
                cardId,
                cardTitle,
                children,
                btnDisabled,
                onDownloadDocument,
              } = documentDetails(document);
              return (
                <Tile
                  key={cardId}
                  cardId={cardId.toString()}
                  cardTitle={cardTitle}
                  cardLinkContent="Download document"
                  isLink={false}
                  callBack={onDownloadDocument}
                  isCardLinkDisabled={btnDisabled}
                >
                  {children}
                </Tile>
              );
            })}
          </>
        ) : (
          <EmptyStateIcon
            title="No document"
            textContent="You currently have no documents"
          />
        )}
        {maxDocument < documents?.length && (
          <div className="mx-auto text-center">
            <Button
              id="btnLoadMore"
              variant="link"
              className="text-decoration-none text-primary my-3"
              onClick={loadMoreDocument}
            >
              <h2 className="h3 p-0 m-0">Load more</h2>
              <ArrowDownIcon title="Load more" size="xs" />
            </Button>
          </div>
        )}
      </Row>
    </TilesBasePage>
  );
};

export default DocumentsPage;
