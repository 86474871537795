import { Accordion, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import { pluraliseText } from "../../../utils/form/form-text-utils";
import { formatCurrency } from "../../../utils/pages/number-format-utils";
import * as fieldData from "../../forms/fields/data/field-data";
import { formatDate } from "../../../utils/pages/date-format-utils";
import magicStrings from "../../../utils/magic-string";

const ContractAmendmentsPreview = ({
  onRouteToEdit,
  personalDetails,
  addressDetails,
  employmentDetails,
  financialDetails,
}) => {
  const onEdit = (page) => {
    onRouteToEdit(page);
  };

  return (
    <>
      <Accordion className="border-top my-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Personal details</Accordion.Header>
          <Accordion.Body>
            <dl className="row">
              <dt className="col-sm-6">Title</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {personalDetails?.title ?? ""}
              </dd>
              <dt className="col-sm-6">First name</dt>
              <dd
                className={`col-sm-6 text-sm-end text-break ${magicStrings.maskInformation}`}
              >
                {personalDetails?.firstName ?? ""}
              </dd>
              <dt className="col-sm-6">Last name</dt>
              <dd
                className={`col-sm-6 text-sm-end text-break ${magicStrings.maskInformation}`}
              >
                {personalDetails?.lastName ?? ""}
              </dd>
              <dt className="col-sm-6">Date of birth</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {personalDetails?.dobDay &&
                personalDetails?.dobMonth &&
                personalDetails?.dobYear
                  ? formatDate(
                      DateTime.utc(
                        personalDetails.dobYear,
                        personalDetails.dobMonth,
                        personalDetails.dobDay
                      ).toISO()
                    )
                  : ""}
              </dd>
              <dt className="col-sm-6">Marital status</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {
                  (
                    fieldData.maritalStatuses[
                      fieldData.maritalStatuses.findIndex(
                        (option) =>
                          option.value.toString() ===
                          personalDetails?.maritalStatus?.toString()
                      )
                    ] ?? { text: "" }
                  ).text
                }
              </dd>
              <dt className="col-sm-6">No of dependants</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {personalDetails?.numOfDependants ?? ""}
              </dd>
              <dt className="col-sm-6">Accommodation</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {
                  (
                    fieldData.accommodations[
                      fieldData.accommodations.findIndex(
                        (option) =>
                          option.value.toString() ===
                          personalDetails?.accommodation?.toString()
                      )
                    ] ?? { text: "" }
                  ).text
                }
              </dd>
              <dt className="col-sm-6">Home number</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {personalDetails?.homeNumber ?? ""}
              </dd>
              <dt className="col-sm-6">Mobile number</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {personalDetails?.mobileNumber ?? ""}
              </dd>
              <dt className="col-sm-6">Work number</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {personalDetails?.workNumber ?? ""}
              </dd>
              <dt className="col-sm-4">Email address</dt>
              <dd
                className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
              >
                {personalDetails?.emailAddress ?? ""}
              </dd>
            </dl>
            <Col xs={12} className="text-center pt-1 pb-3">
              <Link
                to="#"
                id="lnkPersonalDetails"
                onClick={() => {
                  onEdit({ id: 0, page: "Personal Details" });
                }}
              >
                Edit personal details
              </Link>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Address details</Accordion.Header>
          <Accordion.Body>
            {addressDetails.map((address, index) => {
              return (
                <dl className="row" key={"address" + parseInt(index + 1)}>
                  {index === 0 && (
                    <h3 className="h5 fw-bold text-decoration-underline">
                      Current address details
                    </h3>
                  )}
                  {index > 0 && (
                    <>
                      <Col xs="12" role="presentation">
                        <hr />
                      </Col>
                      <h3 className="h5 fw-bold text-decoration-underline">
                        Address {" " + parseInt(index + 1) + " "} details
                      </h3>
                    </>
                  )}
                  <dt className="col-sm-4">Address line 1</dt>
                  <dd
                    className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {address?.addressLine1 ?? ""}
                  </dd>
                  <dt className="col-sm-4">Address line 2</dt>
                  <dd
                    className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {address?.addressLine2 ?? ""}
                  </dd>
                  <dt className="col-sm-4">Address line 3</dt>
                  <dd
                    className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {address?.addressLine3 ?? ""}
                  </dd>
                  <dt className="col-sm-4">Town/City</dt>
                  <dd
                    className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {address?.city ?? ""}
                  </dd>
                  <dt className="col-sm-4">County</dt>
                  <dd
                    className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {address?.county ?? ""}
                  </dd>
                  <dt className="col-sm-6">Postcode</dt>
                  <dd
                    className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
                  >
                    {address?.postCode ?? ""}
                  </dd>
                  <dt className="col-sm-6">Time at address</dt>
                  <dd
                    className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
                  >
                    {(address.timeAtAddressYears ?? 0) +
                      " Year" +
                      pluraliseText(address.timeAtAddressYears) +
                      " " +
                      (address.timeAtAddressMonths ?? 0) +
                      " Month" +
                      pluraliseText(address.timeAtAddressMonths)}
                  </dd>
                </dl>
              );
            })}
            <Col xs={12} className="text-center pt-1 pb-3">
              <Link
                to="#"
                id="lnkAddressDetails"
                onClick={() => {
                  onEdit({ id: 1, page: "Address Details" });
                }}
              >
                Edit address details
              </Link>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Employment details</Accordion.Header>
          <Accordion.Body>
            <dl className="row">
              <dt className="col-sm-6">Employment status</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {
                  (
                    fieldData.employmentStatuses[
                      fieldData.employmentStatuses.findIndex(
                        (status) =>
                          status.value.toString() ===
                          employmentDetails.employmentStatus?.toString()
                      )
                    ] ?? { text: "" }
                  ).text
                }
              </dd>
              {["1", "2", "9"].includes(
                employmentDetails.employmentStatus.toString()
              ) && (
                <>
                  <dt className="col-sm-6">Occupation</dt>
                  <dd
                    className={`col-sm-6 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {employmentDetails?.occupation ?? ""}
                  </dd>
                  <dt className="col-sm-6">Employer name</dt>
                  <dd
                    className={`col-sm-6 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {employmentDetails?.employerName ?? ""}
                  </dd>
                  <dt className="col-sm-4">Address line 1</dt>
                  <dd
                    className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {employmentDetails?.addressLine1 ?? ""}
                  </dd>
                  <dt className="col-sm-4">Address line 2</dt>
                  <dd
                    className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {employmentDetails?.addressLine2 ?? ""}
                  </dd>
                  <dt className="col-sm-4">Address line 3</dt>
                  <dd
                    className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {employmentDetails?.addressLine3 ?? ""}
                  </dd>
                  <dt className="col-sm-6">Town/City</dt>
                  <dd
                    className={`col-sm-6 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {employmentDetails?.city ?? ""}
                  </dd>
                  <dt className="col-sm-6">County</dt>
                  <dd
                    className={`col-sm-6 text-sm-end text-break ${magicStrings.maskInformation}`}
                  >
                    {employmentDetails?.county ?? ""}
                  </dd>
                  <dt className="col-sm-6">Postcode</dt>
                  <dd
                    className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
                  >
                    {employmentDetails?.postCode ?? ""}
                  </dd>
                  <dt className="col-sm-6">Time at employment</dt>
                  <dd
                    className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
                  >
                    {(employmentDetails.timeAtEmploymentYears ?? 0) +
                      " Year" +
                      pluraliseText(employmentDetails.timeAtEmploymentYears) +
                      " " +
                      (employmentDetails.timeAtEmploymentMonths ?? 0) +
                      " Month" +
                      pluraliseText(employmentDetails.timeAtEmploymentMonths)}
                  </dd>
                </>
              )}
            </dl>
            <Col className="text-center pt-1 pb-3">
              <Link
                to="#"
                id="lnkEmploymentDetails"
                onClick={() => {
                  onEdit({ id: 2, page: "Employment Details" });
                }}
              >
                Edit employment details
              </Link>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Financial details</Accordion.Header>
          <Accordion.Body>
            <dl className="row">
              <dt className="col-sm-6">Gross annual income</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {formatCurrency(financialDetails.grossIncome) ?? ""}
              </dd>
              <dt className="col-sm-6">Share of mortgage or rent</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {formatCurrency(financialDetails.shareOfRent) ?? ""}
              </dd>
              <dt className="col-sm-6">Child care costs</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {formatCurrency(financialDetails.shareOfChildCosts) ?? ""}
              </dd>
              <dt className="col-sm-6">Change of circumstances</dt>
              <dd
                className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
              >
                {
                  (
                    fieldData.yesNoOptions[
                      fieldData.yesNoOptions.findIndex(
                        (option) =>
                          option.value.toString() ===
                          financialDetails?.expectedChanges?.toString()
                      )
                    ] ?? { text: "" }
                  ).text
                }
              </dd>
              {financialDetails.expectedChanges &&
                financialDetails.expectedChanges === 1 && (
                  <>
                    <dt className="col-sm-4">Description</dt>
                    <dd
                      className={`col-sm-8 text-sm-end text-break  ${magicStrings.maskInformation}`}
                    >
                      {financialDetails?.descriptionExpectedChanges ?? ""}
                    </dd>
                  </>
                )}
            </dl>
            <Col xs={12} className="text-center pt-1 pb-3">
              <Link
                to="#"
                id="lnkFinancialDetails"
                onClick={() => {
                  onEdit({ id: 3, page: "Financial Details" });
                }}
              >
                Edit financial details
              </Link>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default ContractAmendmentsPreview;
