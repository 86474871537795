import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import BasePage from "../../basepages/basepage/BasePage";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import { step } from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import { formatDate } from "../../../utils/pages/date-format-utils";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";

const ApplicationSuccessPage = () => {
  const isExistingQuote = useSelector(
    (state) => state.contractAmends.isExistingQuote
  );
  useContractAmendsStepCheck(
    steps.applicationSuccess,
    isExistingQuote ? "../existing-quotes" : "../review-application"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contract = useSelector((state) => state.contractAmends.contractDetails);

  const amendmentDetails = useSelector(
    (state) => state.contractAmends.proposedAmendment
  );

  let expiryDate = amendmentDetails?.caExpiry
    ? amendmentDetails.caExpiry
    : amendmentDetails?.expiryDate;

  const onSignContractClicked = () => {
    dispatch(step(steps.viewSummary));
    navigate("../view-summary");
  };

  return (
    <BasePage pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <div className="text-center">
        <PageHeading>Application approved</PageHeading>
        <p>
          We've completed our review and we're delighted to inform you that we
          have approved your application.{" "}
        </p>
        <div className="h3 fw-bold mb-0">Registration:</div>
        <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
          {contract?.vehicle?.registrationNumber}
        </div>
        <div className={`${magicStrings.maskInformation}`}>
          {contract?.vehicle?.description}
        </div>

        <div className="h3 fw-bold mb-0 mt-4">Quote reference:</div>
        <div className={`display-6 mb-1 ${magicStrings.maskInformation}`}>
          {amendmentDetails?.caNumber}
        </div>

        <div className="h3 fw-bold mt-4 mb-0">Quote expiry:</div>
        <div className={`display-6 ${magicStrings.maskInformation}`}>
          {formatDate(expiryDate)}
        </div>
      </div>
      <br />
      <h2 className="h3 text-primary">Next Steps</h2>
      <p>To proceed with your contract amendment: </p>
      <ul>
        <li>Review your amended contract.</li>
        <li>
          Accept and sign your amended contract by clicking the button below.
        </li>
      </ul>
      <p>
        When you've signed your amended contract, we'll apply the new terms and
        let you know when they're due to come into effect.
      </p>
      <p>
        <strong>
          Please accept your new terms and conditions before your quote expiry
          date. If we don't hear from you, you'll have to apply for a new
          amendment.
        </strong>
      </p>
      <CenterAlignedButtonContainer>
        <Button onClick={onSignContractClicked} id="btnSignMyAmendedContract">
          Sign my amended contract
        </Button>
        <BackToHomeButton />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ApplicationSuccessPage;
