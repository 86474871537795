import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import BasePage from "../../basepages/basepage/BasePage";

const MoneyWorriesNonRegulatedPage = () => {
  return (
    <BasePage
      pageTitle="Financial worries"
      pageSubTitle="We're here to support you if you need help."
    >
      <p>
        If your business is falling behind with vehicle finance payments, or
        you're worried it might, please get in touch with us as we may be able
        to help. The sooner you let us know, the quicker we can help get you
        back on track.
      </p>
      <p>
        We'll talk with you to understand your situation, and get a better
        picture of your business and the challenges you're facing.
      </p>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Support for larger businesses</Accordion.Header>
          <Accordion.Body>
            <p>
              If you're a larger business, for example with more than 20 fleet
              vehicles, please get in touch with your Account Manager directly
              if you need to talk about your account or repayments. They'll
              guide you to the right support, which may be different to the
              support below.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            What to do if you're a smaller business
          </Accordion.Header>
          <Accordion.Body>
            <p className="mb-1">
              <strong>Call us</strong>
            </p>
            <p>
              Please call us on{" "}
              <strong>
                <Link to="tel:0800 302 9160">0800 302 9160</Link> and select
                option 3{" "}
              </strong>
              so we can talk through your options together. We're here from
              8.30am - 5.30pm, Monday - Friday, except bank holidays. Calls are
              free from UK landlines and mobile phones.
            </p>
            <p className="mb-1">Please have these things to hand:</p>

            <ul>
              <li>Your account number or fleet number.</li>
              <li>The registration for your vehicle.</li>
              <li>A pen and paper to jot things down.</li>
            </ul>

            <p className="pt-3 mb-1">
              <strong>What to expect</strong>
            </p>

            <p>
              How we can help depends on your situation and what stage you are
              at in your agreement. Sometimes we're not able to help you with
              your payments, but we can tell you about charities and
              organisations who can support you. There's more below about
              independent support that's available.
            </p>

            <p className="mb-1">
              Here are some examples of support we may be able to give you,
              after we talk through your circumstances:
            </p>

            <ul>
              <li>
                A temporary payment plan could help you get back on track, if
                you're eligible for this.
              </li>
              <li>
                We may be able to give you some time to get independent help and
                advice by placing a short-term hold on the number of
                communications we send you. If we can offer this, you'll still
                need to make your monthly payments so you don't miss any, but
                you can take the time and space to get some independent help and
                advice, and make sure your priority payments are up to date.
              </li>
              <li>
                Explain how you can return the vehicle to us and what this means
                for your agreement.
              </li>
            </ul>

            <p>
              If you call us, it may take a bit of time depending on what you
              need help with and the support we can give you. We'll ask
              questions about your situation, and you can let us know at any
              time if you don't feel comfortable talking about this.
            </p>

            <p className="pt-3 mb-1">
              <strong>Other ways to get in touch</strong>
            </p>

            <ul>
              <li>
                <p className="mb-1">
                  Email us at{" "}
                  <Link to="mailto:cpt@lexautolease.co.uk">
                    cpt@lexautolease.co.uk
                  </Link>{" "}
                  - we'll reply as quickly as we can, usually 2 to 5 working
                  days after we've received your email.
                </p>
                <ul className="list-unstyled">
                  <li>
                    Email is not a secure way to get in touch, so please don't
                    email us any personal information such as your account
                    numbers. We won't be responsible for any data you send that
                    is lost in transit to us.
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <p className="mb-1">
                  Or write to us at Lex Customer Financial Assistance Team, Lex
                  Autolease, Heathside Park, Heathside Park Road, Stockport, SK3
                  0RB.
                </p>
                <ul className="list-unstyled">
                  <li>
                    If you write to us, please tell us how you'd like us to
                    reply. If you'd like us to call or email you, make sure you
                    include your phone number or email address in the letter.
                    We'll reply as quickly as we can, usually in around 2 to 8
                    working days, depending on how you ask us to reply. It's
                    quickest to ask us to email you or call you back.
                  </li>
                </ul>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <h2 className="mt-9">Independent support</h2>

      <p>
        If you'd like to speak to someone else, there are charities and other
        organisations that can help with financial advice. Most 0800 phone
        numbers are free to call from UK landlines and mobiles.
      </p>

      <Accordion>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Business Debtline</Accordion.Header>
          <Accordion.Body>
            <p>
              Business Debtline is a free, confidential debt advice service for
              the self-employed and small businesses. You can access Business
              Debtline by phone, webchat, or get advice through the website.
            </p>
            <p>
              Call <Link to="tel:0800 197 6026">0800 197 6026</Link>.
            </p>
            <p>
              <a
                href="https://www.businessdebtline.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Business Debtline
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>MoneyHelper</Accordion.Header>
          <Accordion.Body>
            <p>Guidance from MoneyHelper</p>
            <ul>
              <li>
                Government-backed with guidance on benefits, tax, pensions,
                homes and savings.
              </li>
              <li>Money calculators, helpful articles and regular updates.</li>
              <li>Support available online, over the phone or face-to-face.</li>
            </ul>
            <p>
              Call <Link to="tel:0800 138 7777">0800 138 7777</Link> for money
              guidance.
            </p>
            <p>Lines open Monday - Friday between 8am - 6pm.</p>
            <p>
              <a
                href="https://www.moneyhelper.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit MoneyHelper
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Citizens Advice</Accordion.Header>
          <Accordion.Body>
            <p>Help from Citizens Advice</p>
            <ul>
              <li>
                Free advice on a range of topics, including benefits, debt,
                housing and consumer rights.
              </li>
              <li>Help with managing your money.</li>
              <li>
                Practical and impartial support online, in person or over the
                phone.
              </li>
            </ul>
            <p>
              Call <Link to="tel:0800 144 8848">0800 144 8848</Link> or{" "}
              <Link to="tel:0800 702 2020">0800 702 2020</Link> if you're in
              Wales.
            </p>
            <p>Lines open Monday - Friday between 9am - 5pm.</p>
            <p>
              <a
                href="https://www.citizensadvice.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Citizens Advice
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Mental Health and Money Advice</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Clear, practical advice and support for people experiencing
                mental health and money worries.
              </li>
              <li>
                Specialist knowledge of benefits, managing money and mental
                health care.
              </li>
              <li>Award-winning UK-wide online advice service.</li>
            </ul>
            <p>
              <a
                href="https://www.mentalhealthandmoneyadvice.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Mental Health and Money Advice
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>PayPlan</Accordion.Header>
          <Accordion.Body>
            <p>Free debt advice and support</p>
            <ul>
              <li>Free, confidential debt advice.</li>
              <li>A personalised action plan for your money.</li>
              <li>Help available online or by phone.</li>
            </ul>
            <p>
              Call <Link to="tel:0800 280 2816">0800 280 2816</Link>.
            </p>
            <p>Lines open Monday - Friday 8am - 8pm, and Saturday 9am - 3pm.</p>
            <p>
              <a
                href="https://www.payplan.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit PayPlan
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>National Debtline</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Free, confidential debt advice.</li>
              <li>Guides, fact sheets, budgeting tools and sample letters.</li>
              <li>Advice via phone and online.</li>
            </ul>
            <p>
              Call <Link to="tel:0808 808 4000">0808 808 4000</Link>.
            </p>
            <p>
              Lines open Monday - Friday 9am - 8pm, and Saturday 9.30am - 1pm.
            </p>
            <p>
              <a
                href="https://www.nationaldebtline.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit National Debtline
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>StepChange Debt Charity</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Free, impartial advice to get your finances back on track.
              </li>
              <li>A wide range of practical debt solutions.</li>
              <li>Support online 24/7 or by phone.</li>
            </ul>
            <p>
              Call <Link to="tel:0800 138 1111">0800 138 1111</Link>.
            </p>
            <p>Lines open Monday - Friday 8am - 8pm, and Saturday 8am - 4pm.</p>
            <p>
              <a
                href="https://www.stepchange.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit StepChange
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </BasePage>
  );
};

export default MoneyWorriesNonRegulatedPage;
