import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Questionnaire from "../../../../component/questionnaire/Questionnaire";
import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import { step } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";
import CollectionStepper from "../../collection-stepper/CollectionStepper";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import PageHeading from "../../../../component/page-heading/PageHeading";

const Question1Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useVehicleCollectionStepCheck(
    steps.roadWorthyQuestion1,
    "../../vehicle-selection"
  );

  const onActionClicked = (result) => {
    dispatch(step(steps.roadWorthyQuestion2));
    if (result === 2) {
      navigate("../question2");
    } else if (result === 1) {
      navigate("../personalised-plate");
    }
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      <PageHeading align="left">Arranging a collection</PageHeading>
      <p className="mb-5">
        We need to ask you some questions to check that your vehicle is
        roadworthy and ready to be collected. If a collection is arranged and
        the vehicle is unsafe to drive your collection will be cancelled and you
        will be charged a fee.
      </p>
      <h3>Question 1 of 6: Does the vehicle have a personalised plate?</h3>
      <p className="pb-5">
        If the vehicle has a personalised plate you will need to arrange for
        this to be removed prior to collection. A standard registration plate
        will need to be assigned and fixed to the vehicle.
      </p>
      <Questionnaire
        option1Title="Yes"
        option2Title="No"
        option1Content="It has a personalised plate and I would like to arrange removal."
        option2Content="It doesn't have a personalised plate."
        option1ActionContent="Yes"
        option2ActionContent="No"
        actionCallback={onActionClicked}
      />
    </WizardBasePage>
  );
};

export default Question1Page;
