import { Button, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../contract-amends-stepper/ContractAmendsStepper";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import QuoteDetailsPreview from "../../../component/contract-amends/quote-details-preview/QuoteDetailsPreview";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import { useContractAmendDetails } from "../../../api/hooks/contracts";
import useSaveQuote from "./hooks/useSaveQuote";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import useAuth from "../../../hooks/use-auth/useAuth";
import { reset } from "../../../redux/features/contract-amends/contractAmendsSlice";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const SaveQuotePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const counterParty = auth?.user?.counterParty?.code;
  const isExistingQuote = useSelector(
    (state) => state.contractAmends.isExistingQuote
  );

  useContractAmendsStepCheck(
    steps.newQuote.saveQuote,
    isExistingQuote ? "../existing-quotes" : "../view-quote"
  );
  const contractId = useSelector(
    (state) => state.contractAmends.selectedContractId
  );
  const savedAmendment = useSelector(
    (state) => state.contractAmends.proposedAmendment
  );
  const amendmentStatus = savedAmendment.currentStatus
    ? savedAmendment.currentStatus.toLowerCase()
    : "";

  const contract = useSelector((state) => state.contractAmends.contractDetails);

  const {
    contractAmendDetails: caDetails,
    isError,
    errorResponse,
  } = useContractAmendDetails(contractId, savedAmendment?.caNumber);

  const { saveAmendmentDetails, navigateToPage } = useSaveQuote();

  const onContinue = () => {
    saveAmendmentDetails(caDetails, counterParty, dispatch);
    navigateToPage(counterParty, amendmentStatus, navigate, dispatch);
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}

      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Your quote</PageHeading>
          <p>Here is your quote reference and quote expiry date.</p>
          <br />
          <p className="h3 mb-0">Quote reference:</p>
          <p className={`display-6 mb-3 ${magicStrings.maskInformation}`}>
            {savedAmendment.caNumber}
          </p>
          <p>
            This quote will expire on the{" "}
            {/* Depending on new or saved quote the expiry comes in different fields */}
            <strong className={`${magicStrings.maskInformation}`}>
              {DateTime.fromISO(
                savedAmendment.caExpiry
                  ? savedAmendment.caExpiry
                  : savedAmendment.expiryDate
              ).toFormat("dd/MM/yyyy")}
              *
            </strong>
            .
          </p>
        </div>
      </CenterAlignedContainer>
      <br />
      {amendmentStatus === "outstanding information" && (
        <div className="text-center mb-5">
          <h3 className="text-primary">We still need some details from you</h3>
          <p>
            To continue with your contract amendment, we need to update some of
            the details we hold for your business. We'll use these details to
            assess creditworthiness, ensure the product is right for you, and
            confirm the identity of the responsible business owner/manager(s).
          </p>
          <p>
            <strong>
              Our online tool is designed for single vehicle requests. If you're
              looking to proceed with quotes for multiple vehicle contracts,
              please call us on{" "}
              <Link to="tel:0344 879 6633">0344 879 6633</Link> so we can
              arrange for a combined quotation and a single credit application.
            </strong>
          </p>
          <h4>What happens next?</h4>
          <p>
            Usually, we can complete our checks and give you an answer within a
            day or two. However, we may need to ask you for additional details
            to help us process your request. This can take a few days, so we
            recommend you complete your application at least 5 days before your
            quote is due to expire.
          </p>
          <h4>What happens if your application is approved?</h4>
          <p>
            If we approve your application, you'll be able to review and sign
            your amended contract. You'll need to sign it before your quote
            expires or you'll have to apply for a new amendment.
          </p>
          <br />
        </div>
      )}
      {counterParty !== 21 && amendmentStatus === "ready to dispatch" && (
        <>
          <div className="text-center mb-5">
            <h3 className="text-primary">
              Your new amendment contract is ready
            </h3>
            <h4>To proceed with your contract amendment:</h4>
            <ul>
              <li>Review your amended contract.</li>
              <li>
                Accept and sign your amended contract by clicking the button
                below.
              </li>
              <p>
                When you've signed your amended contract, we'll apply the new
                terms and let you know when they're due to come into effect.
              </p>
              <p>
                <strong>
                  Please accept your new terms and conditions before your quote
                  expiry date. If we don't hear from you, you'll have to apply
                  for a new amendment.
                </strong>
              </p>
            </ul>
          </div>
        </>
      )}
      {caDetails && caDetails.contractAmendmentDetail && (
        <QuoteDetailsPreview
          currentContract={contract}
          proposedAmendment={caDetails.contractAmendmentDetail}
        />
      )}
      <CenterAlignedButtonContainer>
        <Button onClick={onContinue} disabled={isError}>
          Continue to finance
        </Button>
        <BackToHomeButton onButtonClicked={() => dispatch(reset())} />
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default SaveQuotePage;
