const vehicleCollectionSteps = {
  intro: 1,
  vehicleSelection: 2,
  roadWorthyQuestion1: 3,
  roadWorthyQuestion2: 4,
  roadWorthyQuestion3: 5,
  roadWorthyQuestion4: 6,
  roadWorthyQuestion5: 7,
  roadWorthyQuestion6: 8,
  collectionContactDetails: 9,
  collectionDate: 10,
  summary: 11,
  collectionSuccess: 12,
  collectionFailure: 12,
  existingBookings: {
    listOfBookings: 2,
    summary: 3,
    editDetails: 4,
    editDate: 4,
    cancel: 4,
    cancelSuccess: 5,
    cancelFailure: 5,
  },
};

export default vehicleCollectionSteps;
