import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TickOutlineIcon from "../../../icons/TickOutlineIcon";
import CloseDdIcon from "../../../icons/CloseDdIcon";
import NotificationBanner from "../../../notification-banner/NotificationBanner";
import { trimStartOnChange } from "../../../../utils/form/form-text-utils";
import { useBankDetailBySortcode } from "../../../../api/hooks/direct-debit";
import DirectDebitFormSchema from "./direct-debit-form-schema";
import AddressLookup from "../../address-lookup/AddressLookup";
import { bankAccountAndAddressDetails } from "../../../../redux/features/payments/directDebitSlice";
import {
  useAddressesByPostcode,
  useAddressByLineId,
} from "../../../../api/hooks/addresses";
import magicStrings from "../../../../utils/magic-string";

const DirectDebitForm = ({ onFormSubmit, onFormCancel }) => {
  const dispatch = useDispatch();
  const methods = useForm({
    resolver: yupResolver(DirectDebitFormSchema),
    mode: "onTouched",
    reValidate: "onChange",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
    setValue,
    clearErrors,
  } = methods;

  const [bankDetailValidation, setBankDetailValidation] = useState(false);
  const watchSortcode = watch("sortCode");
  const {
    bankDetail,
    isError,
    refetch: refetchBankDetail,
  } = useBankDetailBySortcode(watchSortcode);
  useEffect(() => {
    setBankDetailValidation(false);
    clearErrors([
      "bankName",
      "branchName",
      "postCode",
      "selectAddress",
      "addressLine1",
      "authorisedSignatory",
      "signature",
      "authoriseDebit",
    ]);
  }, [watchSortcode, clearErrors]);

  useEffect(() => {
    setValue("isBankDetailNotFound", isError);
    setValue("bankDetails", bankDetail);
  }, [isError, bankDetail, setValue]);

  const watchPostcode = watch("postCode");
  const watchSelectAddress = watch("selectAddress");
  const {
    refetch,
    addresses,
    isSuccess: isAddressesByPostcodeSuccess,
  } = useAddressesByPostcode(watchPostcode);
  const { address } = useAddressByLineId(watchSelectAddress);

  const searchBankDetailBySortcode = async () => {
    const isValidBankDetailEntered = await trigger([
      "accountName",
      "accountNumber",
      "sortCode",
    ]).then((res) => res);

    if (isValidBankDetailEntered) {
      refetchBankDetail();
      setBankDetailValidation(true);
    }
  };

  const onSubmit = (data) => {
    const addressLine1 = data.isBankDetailNotFound
      ? data.addressLine1
      : data.bankDetails.bankAddress.line1;

    const addressLine2 = data.isBankDetailNotFound
      ? data.addressLine2
      : data.bankDetails.bankAddress.line2;

    const addressLine3 = data.isBankDetailNotFound
      ? data.addressLine3
      : data.bankDetails.bankAddress.line3;

    const addressLine4 = data.isBankDetailNotFound
      ? data.city
      : data.bankDetails.bankAddress.line4;

    const addressLine5 = data.isBankDetailNotFound
      ? data.county
      : data.bankDetails.bankAddress.line5;

    const postCode = data.isBankDetailNotFound
      ? data.postCode
      : data.bankDetails.bankAddress.postcode;

    dispatch(
      bankAccountAndAddressDetails({
        bankAccount: {
          accountNumber: data.accountNumber,
          accountName: data.accountName,
          bank: {
            sortCode: data.sortCode,
            bankName: data.isBankDetailNotFound
              ? data?.bankName
              : data.bankDetails?.bankName,
            branchName: data.isBankDetailNotFound
              ? data?.branchName
              : data.bankDetails?.branchName,
            bankAddress: {
              line1: addressLine1,
              line2: addressLine2,
              line3: addressLine3,
              line4: addressLine4,
              line5: addressLine5,
              postcode: postCode,
            },
          },
        },
      })
    );
    onFormSubmit();
  };

  const goBackToPreviousPage = () => {
    onFormCancel();
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="directDebitForm"
        autoComplete="off"
      >
        <div className="d-flex flex-column col-12 col-sm-8 col-lg-6 col-xl-5">
          <Form.Group className="mb-3" controlId="accountName">
            <Form.Label>Account name</Form.Label>
            <Form.Control
              type="string"
              maxLength={100}
              isInvalid={errors.accountName}
              {...register("accountName", {
                onChange: trimStartOnChange,
              })}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.accountName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="accountNumber">
            <Form.Label>Account number</Form.Label>
            <Form.Control
              type="string"
              maxLength={8}
              isInvalid={errors.accountNumber}
              {...register("accountNumber", {
                onChange: trimStartOnChange,
              })}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.accountNumber?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4" controlId="sortCode">
            <Form.Label>Sort code</Form.Label>
            <Form.Control
              type="string"
              maxLength={6}
              isInvalid={errors.sortCode}
              {...register("sortCode", {
                onChange: trimStartOnChange,
              })}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.sortCode?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-grid mb-3">
            <Button
              type="button"
              id="btnValidateBankDetails"
              onClick={searchBankDetailBySortcode}
            >
              Validate bank details
            </Button>
          </div>
          {bankDetailValidation && (
            <>
              {bankDetail && bankDetail?.bankAddress && (
                <NotificationBanner variant="success" size="sm">
                  <div className="h3">
                    <TickOutlineIcon
                      className="text-success mb-1"
                      title="Bank details found icon"
                      size="sm"
                    />
                    <span className="px-2">Bank details found</span>
                  </div>
                  <div className={`${magicStrings.maskInformation}`}>
                    <div className="h4 mt-2">{bankDetail.bankName}</div>
                    {bankDetail.branchName ? bankDetail.branchName : ""}
                    {bankDetail.bankAddress.line1
                      ? `, ${bankDetail.bankAddress.line1}`
                      : ""}
                    {bankDetail.bankAddress.line2
                      ? `, ${bankDetail.bankAddress.line2}`
                      : ""}
                    {bankDetail.bankAddress.line3
                      ? `, ${bankDetail.bankAddress.line3}`
                      : ""}
                    {bankDetail.bankAddress.line4
                      ? `, ${bankDetail.bankAddress.line4}`
                      : ""}
                    {bankDetail.bankAddress.line5
                      ? `, ${bankDetail.bankAddress.line5}`
                      : ""}
                    {bankDetail.bankAddress.postcode
                      ? `, ${bankDetail.bankAddress.postcode}`
                      : ""}
                  </div>
                </NotificationBanner>
              )}
              {isError && (
                <>
                  <NotificationBanner variant="warning" size="sm">
                    <div className="h3">
                      <CloseDdIcon
                        className="text-warning mb-1"
                        title="Bank details not found icon"
                        size="sm"
                      />
                      <span className="px-2">Bank details not found</span>
                    </div>
                    <p>
                      We have not been able to validate your account details. If
                      you are sure they are correct, please provide your bank
                      details to proceed.
                    </p>
                  </NotificationBanner>
                  <Form.Group className="mb-3" controlId="bankName">
                    <Form.Label>Bank name</Form.Label>
                    <Form.Control
                      type="string"
                      maxLength={50}
                      isInvalid={errors.bankName}
                      {...register("bankName", {
                        onChange: trimStartOnChange,
                      })}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.bankName?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="branchName">
                    <Form.Label>Branch name</Form.Label>
                    <Form.Control
                      type="string"
                      maxLength={50}
                      isInvalid={errors.branchName}
                      {...register("branchName", {
                        onChange: trimStartOnChange,
                      })}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.branchName?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <AddressLookup
                    addresses={addresses}
                    refetchAddresses={refetch}
                    addressDetails={address}
                    isAddressFetchSucessful={isAddressesByPostcodeSuccess}
                  />
                </>
              )}
              <div className="mt-2">
                <Form.Group className="mb-4">
                  <Form.Check
                    type="checkbox"
                    id="chkboxAuthorisedSignatory"
                    {...register("authorisedSignatory")}
                    isInvalid={errors.authorisedSignatory}
                    label="I confirm that I'm the account holder or an authorised signatory to the account."
                  ></Form.Check>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Check
                    type="checkbox"
                    id="chkboxSignature"
                    {...register("signature")}
                    isInvalid={errors.signature}
                    label="I confirm the account requires only one signature."
                  ></Form.Check>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Check
                    type="checkbox"
                    id="chkboxAuthoriseDebit"
                    {...register("authoriseDebit")}
                    isInvalid={errors.authoriseDebit}
                    label="I confirm I am able to authorise debits from the account."
                  ></Form.Check>
                  <Form.Control.Feedback
                    className={
                      errors.authorisedSignatory ||
                      errors.signature ||
                      errors.authoriseDebit
                        ? "d-block"
                        : "d-none"
                    }
                    type="invalid"
                  >
                    {errors.authorisedSignatory?.message ||
                      errors.signature?.message ||
                      errors.authoriseDebit?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="d-grid mt-2 mb-3">
                  <Button className="px-5" type="submit" id="btnFormSubmit">
                    Continue
                  </Button>
                </div>
              </div>
            </>
          )}
          <div className="d-grid mt-2 mb-3">
            <Button
              variant="outline-primary"
              className="px-5"
              type="button"
              id="btnCancel"
              onClick={goBackToPreviousPage}
            >
              Cancel
            </Button>
          </div>
          <p className="mb-3">
            If you have any questions please visit our{" "}
            <Link to="/help">Payments FAQs</Link> page.
          </p>
        </div>
      </Form>
    </FormProvider>
  );
};

export default DirectDebitForm;
