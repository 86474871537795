import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import PropTypes from "prop-types";

import Header from "../../../component/header/Header";
import Footer from "../../../component/footer/Footer";
import HeroBanner from "../../../component/hero-banner/HeroBanner";
import BackToLogin from "../../../component/back-to-login/BackToLogin";
import { onTrackLink } from "../../../utils/analytics/analytics-utils";
import useAuth from "../../../hooks/use-auth/useAuth";
import useLogout from "../../../hooks/use-logout/useLogout";
import magicStrings from "../../../utils/magic-string";

const BasePage = ({
  pageTitle,
  pageSubTitle,
  showTilesContainer = false,
  showWizardContainer = false,
  tilesContent,
  wizardContent,
  showBackToLoginLink = false,
  showMainContainer = true,
  isMainContainerFluid = false,
  showScrollProgress = false,
  children,
}) => {
  let isLoggedIn = false;
  const { auth } = useAuth();
  const logout = useLogout();

  // Logout user out if they have not navigated away from the page after 20 minutes
  useEffect(() => {
    if (auth?.user) {
      const timeoutId = setTimeout(() => {
        logout(magicStrings.inactivityTimeout);
      }, 20 * 60 * 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [auth, logout]);

  return (
    <div
      onClick={onTrackLink}
      role="status"
      aria-live="polite"
      aria-atomic="true"
    >
      <Link to="#mainContent" className="visually-hidden-focusable">
        Skip to main content
      </Link>
      <Header showScrollProgress={showScrollProgress} />
      <Container
        fluid
        id="mainContent"
        className="g-0 overflow-hidden main-container"
      >
        <div>
          <HeroBanner title={pageTitle} subTitle={pageSubTitle}></HeroBanner>

          {/* Wizard container */}
          {showWizardContainer && (
            <Container id="wizardContainer" fluid className="bg-white">
              {wizardContent}
            </Container>
          )}

          {/* Back to login */}
          {!isLoggedIn && showBackToLoginLink && (
            <Container id="backToLoginContainer" className="p-0">
              <BackToLogin />
            </Container>
          )}

          {/* Tiles container */}
          {/* Need to handle padding/margin once we have a page with tiles */}
          {showTilesContainer && (
            <Container
              id="tilesContainer"
              className="mt-6 mb-6 mb-sm-0 px-sm-0 px-4"
            >
              {tilesContent}
            </Container>
          )}

          {/* Main container */}
          {showMainContainer && (
            <>
              {isMainContainerFluid ? (
                <Container fluid id="mainContainer">
                  {children}
                </Container>
              ) : (
                <Container
                  id="mainContainer"
                  className="bg-white mt-sm-8 mb-sm-8 mb-0 px-lg-10 py-lg-9 p-md-9 px-4 py-6"
                >
                  {children}
                </Container>
              )}
            </>
          )}
        </div>
        <Footer />
      </Container>
    </div>
  );
};

BasePage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  showTilesContainer: PropTypes.bool,
  showWizardContainer: PropTypes.bool,
  showMainContainer: PropTypes.bool,
  pageTitle: PropTypes.string,
  pageSubTitle: PropTypes.string,
};

export default BasePage;
