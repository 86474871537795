import { Route, Routes } from "react-router-dom";

import IntroductionPage from "../pages/contract-amendment/introduction/IntroductionPage";
import VehicleSelectionPage from "../pages/contract-amendment/vehicle-selection/VehicleSelectionPage";
import CalculateQuotePage from "../pages/contract-amendment/calculate-quote/CalculateQuotePage";
import ViewQuotePage from "../pages/contract-amendment/view-quote/ViewQuotePage";
import SaveQuotePage from "../pages/contract-amendment/save-quote/SaveQuotePage";
import PersonalDetailsPage from "../pages/contract-amendment/personal-details/PersonalDetailsPage";
import AddressDetailsPage from "../pages/contract-amendment/address-details/AddressDetailsPage";
import EmploymentDetailsPage from "../pages/contract-amendment/employment-details/EmploymentDetailsPage";
import FinancialDetailsPage from "../pages/contract-amendment/financial-details/FinancialDetailsPage";
import ReviewApplicationPage from "../pages/contract-amendment/review-application/ReviewApplicationPage";
import ApplicationDeclinedPage from "../pages/contract-amendment/application-declined/ApplicationDeclinedPage";
import ApplicationReferredPage from "../pages/contract-amendment/application-referred/ApplicationReferredPage";
import ApplicationSuccessPage from "../pages/contract-amendment/application-success/ApplicationSuccessPage";
import SignAgreementPage from "../pages/contract-amendment/sign-agreement/SignAgreementPage";
import ConfirmNextStepsPage from "../pages/contract-amendment/confirm-next-steps/ConfirmNextStepsPage";

import BchPersonalDetailsPage from "../pages/contract-amendment/bch/personal-details/PersonalDetailsPage";
import BchReviewApplicationPage from "../pages/contract-amendment/bch/review-application/ReviewApplicationPage";
import BchApplicationDeclinedPage from "../pages/contract-amendment/bch/application-declined/ApplicationDeclinedPage";
import AddNewPartnerPage from "../pages/contract-amendment/bch/add-new-partner/AddNewPartnerPage";
import ValidateExistingPartnerPage from "../pages/contract-amendment/bch/validate-existing-partner/ValidateExistingPartnerPage";
import CaptureAddressPage from "../pages/contract-amendment/bch/capture-address/CaptureAddressPage";
import CurrentAddressPage from "../pages/contract-amendment/bch/current-address/CurrentAddressPage";
import PartnerErrorPage from "../pages/contract-amendment/bch/partner-error/PartnerErrorPage";
import CustomerServicesPage from "../pages/contract-amendment/customer-services/CustomerServicesPage";
import ExistingQuotesPage from "../pages/contract-amendment/existing-quotes/ExistingQuotesPage";
import ErrorNotFoundPage from "../pages/error/page-not-found/ErrorPageNotFoundPage";
import IncomeAndExpenditureInformationPage from "../pages/contract-amendment/income-and-expenditure-information/IncomeAndExpenditureInformationPage";
import ViewSummaryPage from "../pages/contract-amendment/view-summary/ViewSummaryPage";

const ContractAmendsRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<ErrorNotFoundPage />} />
      <Route index element={<IntroductionPage />} />
      <Route path="vehicle-selection" element={<VehicleSelectionPage />} />
      <Route path="calculate-quote" element={<CalculateQuotePage />} />
      <Route path="view-quote" element={<ViewQuotePage />} />
      <Route path="save-quote" element={<SaveQuotePage />} />
      <Route path="personal-details" element={<PersonalDetailsPage />} />
      <Route path="address-details" element={<AddressDetailsPage />} />
      <Route path="employment-details" element={<EmploymentDetailsPage />} />
      <Route path="financial-details" element={<FinancialDetailsPage />} />
      <Route path="review-application" element={<ReviewApplicationPage />} />
      <Route
        path="application-declined"
        element={<ApplicationDeclinedPage />}
      />
      <Route path="application-success" element={<ApplicationSuccessPage />} />
      <Route
        path="application-referred"
        element={<ApplicationReferredPage />}
      />
      <Route path="sign-agreement" element={<SignAgreementPage />} />
      <Route path="confirm-next-steps" element={<ConfirmNextStepsPage />} />
      <Route path="customer-services" element={<CustomerServicesPage />} />
      <Route path="existing-quotes" element={<ExistingQuotesPage />} />
      <Route path="view-summary" element={<ViewSummaryPage />} />
      <Route path="bch">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route path="personal-details" element={<BchPersonalDetailsPage />} />
        <Route path="add-new-partner" element={<AddNewPartnerPage />} />
        <Route
          path="validate-existing-partner"
          element={<ValidateExistingPartnerPage />}
        />
        <Route path="capture-address" element={<CaptureAddressPage />} />
        <Route path="current-address" element={<CurrentAddressPage />} />
        <Route
          path="review-application"
          element={<BchReviewApplicationPage />}
        />
        <Route
          path="application-declined"
          element={<BchApplicationDeclinedPage />}
        />
        <Route path="partner-error" element={<PartnerErrorPage />} />
      </Route>
      <Route
        path="income-and-expenditure-information"
        element={<IncomeAndExpenditureInformationPage />}
      />
    </Routes>
  );
};

export default ContractAmendsRoutes;
