import { Route, Routes } from "react-router-dom";

import VehicleCollectionIntroductionPage from "../pages/vehicle-collection/introduction/VehicleCollectionIntroductionPage";
import VehicleCollectionVehicleSelectionPage from "../pages/vehicle-collection/vehicle-selection/VehicleCollectionVehicleSelectionPage";
import Question1Page from "../pages/vehicle-collection/road-worthiness-questions/question1/Question1Page";
import Question2Page from "../pages/vehicle-collection/road-worthiness-questions/question2/Question2Page";
import Question3Page from "../pages/vehicle-collection/road-worthiness-questions/question3/Question3Page";
import Question4Page from "../pages/vehicle-collection/road-worthiness-questions/question4/Question4Page";
import Question5Page from "../pages/vehicle-collection/road-worthiness-questions/question5/Question5Page";
import Question6Page from "../pages/vehicle-collection/road-worthiness-questions/question6/Question6Page";
import PersonalisedPlatePage from "../pages/vehicle-collection/road-worthiness-questions/personalised-plate/PersonalisedPlatePage";
import VehicleNotRoadWorthyPage from "../pages/vehicle-collection/road-worthiness-questions/vehicle-not-road-worthy/VehicleNotRoadWorthyPage";
import ContactAndCollectionDetailsPage from "../pages/vehicle-collection/contact-and-collection-details/ContactAndCollectionDetailsPage";
import CollectionDatePage from "../pages/vehicle-collection/collection-date/CollectionDatePage";
import CollectionSummaryPage from "../pages/vehicle-collection/collection-summary/CollectionSummaryPage";
import CancelCollectionSuccessPage from "../pages/vehicle-collection/existing/cancel-collection-success/CancelCollectionSuccessPage";
import CancelCollectionFailurePage from "../pages/vehicle-collection/existing/cancel-collection-failure/CancelCollectionFailurePage";
import CollectionSuccessPage from "../pages/vehicle-collection/collection-success/CollectionSuccessPage";
import CollectionFailurePage from "../pages/vehicle-collection/collection-failure/CollectionFailurePage";
import CollectionInProgressPage from "../pages/vehicle-collection/collection-in-progress/CollectionInProgressPage";
import AmendmentUnavailablePage from "../pages/vehicle-collection/amendment-unavailable/AmendmentUnavailablePage";
import ExistingCollectionsPage from "../pages/vehicle-collection/existing/existing-collections/ExistingCollectionsPage";
import ExistingCollectionSummaryPage from "../pages/vehicle-collection/existing/collection-summary/CollectionSummaryPage";
import CancelCollectionPage from "../pages/vehicle-collection/existing/cancel-collection/CancelCollectionPage";
import ExistingContactAndCollectionDetailsPage from "../pages/vehicle-collection/existing/contact-and-collection-details/ContactAndCollectionDetailsPage";
import ErrorNotFoundPage from "../pages/error/page-not-found/ErrorPageNotFoundPage";

const VehicleCollectionRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<ErrorNotFoundPage />} />
      <Route index element={<VehicleCollectionIntroductionPage />} />
      <Route
        path="vehicle-selection"
        element={<VehicleCollectionVehicleSelectionPage />}
      />
      <Route path="existing-bookings">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route index element={<ExistingCollectionsPage />} />
        <Route path="summary" element={<ExistingCollectionSummaryPage />} />
        <Route path="cancel-collection" element={<CancelCollectionPage />} />
        <Route
          path="cancel-success"
          element={<CancelCollectionSuccessPage />}
        />
        <Route
          path="cancel-failure"
          element={<CancelCollectionFailurePage />}
        />
        <Route
          path="edit-details"
          element={<ExistingContactAndCollectionDetailsPage />}
        />
        <Route path="edit-date" element={<CollectionDatePage />} />
      </Route>

      <Route path="road-worthiness">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route path="question1" element={<Question1Page />} />
        <Route path="question2" element={<Question2Page />} />
        <Route path="question3" element={<Question3Page />} />
        <Route path="question4" element={<Question4Page />} />
        <Route path="question5" element={<Question5Page />} />
        <Route path="question6" element={<Question6Page />} />
        <Route path="personalised-plate" element={<PersonalisedPlatePage />} />
        <Route path="not-road-worthy" element={<VehicleNotRoadWorthyPage />} />
      </Route>
      <Route
        path="contact-and-collection-details"
        element={<ContactAndCollectionDetailsPage />}
      />
      <Route path="collection-date" element={<CollectionDatePage />} />
      <Route path="collection-summary" element={<CollectionSummaryPage />} />

      <Route path="success" element={<CollectionSuccessPage />} />
      <Route path="failure" element={<CollectionFailurePage />} />
      <Route path="in-progress" element={<CollectionInProgressPage />} />
      <Route
        path="amendment-unavailable"
        element={<AmendmentUnavailablePage />}
      />
    </Routes>
  );
};

export default VehicleCollectionRoutes;
