import { Accordion, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import nationalityData from "../../../../component/forms/fields/data/nationalities.json";
import { titles } from "../../../forms/fields/data/field-data";
import { formatDate } from "../../../../utils/pages/date-format-utils";
import magicStrings from "../../../../utils/magic-string";

const ContractAmendmentsPreview = ({ onEdit, partners }) => {
  const getNationality = (id) => {
    const nationalityItem = nationalityData.find(
      (n) => n.id.toString() === id.toString()
    );
    return nationalityItem?.text;
  };

  const getTitleText = (id) => {
    const titleItem = titles.find((t) => t.id === id);
    return titleItem?.text;
  };

  return (
    <>
      <Accordion className="border-top my-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Personal and address details</Accordion.Header>
          <Accordion.Body>
            {Array.isArray(partners) &&
              partners.length > 0 &&
              partners.map((partner, index) => {
                return (
                  <div key={index}>
                    <div
                      className={`h3 text-primary mb-4  text-break ${magicStrings.maskInformation}`}
                    >{`${getTitleText(partner.personalDetails?.title)} ${
                      partner.personalDetails.firstName
                    } ${partner.personalDetails.lastName}`}</div>
                    <dl className="row">
                      <dt className="col-sm-6">Date of birth</dt>
                      <dd
                        className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
                      >
                        {partner.personalDetails?.dobDay &&
                        partner.personalDetails?.dobMonth &&
                        partner.personalDetails?.dobYear
                          ? formatDate(
                              DateTime.utc(
                                partner.personalDetails.dobYear,
                                partner.personalDetails.dobMonth,
                                partner.personalDetails.dobDay
                              ).toISO()
                            )
                          : ""}
                      </dd>
                      <dt className="col-sm-6">Nationality</dt>
                      <dd
                        className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}
                      >
                        {getNationality(partner.personalDetails.nationality)}
                      </dd>
                      <dt className="col-sm-4">Email address</dt>
                      <dd
                        className={`col-sm-8 text-sm-end text-break ${magicStrings.maskInformation}`}
                      >
                        {partner.personalDetails.emailAddress}
                      </dd>
                      <dt className="col-sm-4">Address</dt>
                      <dd className="col-sm-8 text-sm-end text-break">
                        {partner.addressDetails?.addressLine1 && (
                          <div className={`${magicStrings.maskInformation}`}>
                            {partner.addressDetails?.addressLine1}
                          </div>
                        )}
                        {partner.addressDetails?.addressLine2 && (
                          <div className={`${magicStrings.maskInformation}`}>
                            {partner.addressDetails?.addressLine2}
                          </div>
                        )}
                        {partner.addressDetails?.addressLine3 && (
                          <div className={`${magicStrings.maskInformation}`}>
                            {partner.addressDetails?.addressLine3}
                          </div>
                        )}
                        {partner.addressDetails?.city && (
                          <div className={`${magicStrings.maskInformation}`}>
                            {partner.addressDetails?.city}
                          </div>
                        )}
                        {partner.addressDetails?.county && (
                          <div className={`${magicStrings.maskInformation}`}>
                            {partner.addressDetails?.county}
                          </div>
                        )}
                        {partner.addressDetails?.postCode && (
                          <div className={`${magicStrings.maskInformation}`}>
                            {partner.addressDetails?.postCode}
                          </div>
                        )}
                      </dd>
                    </dl>
                  </div>
                );
              })}
            <Col xs={12} className="text-center pt-1 pb-3">
              <Link to="#" id="lnkPersonalDetails" onClick={onEdit}>
                Edit personal details
              </Link>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default ContractAmendmentsPreview;
