const forgotDetailsErrorMessages = {};
forgotDetailsErrorMessages["1340101"] =
  "Sorry, we can't identify you from the information you've given. Please check all fields are correct and submit the form again. If you're still having problems with the form after checking your details are correct, please call Customer Service on 0344 879 6633. Our phone lines are open Monday - Friday between 9am - 5pm. We're closed on weekends and bank holidays.";

forgotDetailsErrorMessages["1350001"] =
  "An unexpected error has occurred. Please try again later.";

forgotDetailsErrorMessages["1399901"] =
  "Something went wrong. Please try again later.";

export default forgotDetailsErrorMessages;
