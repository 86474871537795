import { Route, Routes } from "react-router-dom";
import PaymentsPage from "../pages/payments/PaymentsPage";
import PaymentHolidaysPage from "../pages/payments/payment-holidays/PaymentHolidaysPage";
import NewDirectDebitPage from "../pages/payments/new-direct-debit/NewDirectDebitPage";
import SetupDirectDebitPage from "../pages/payments/new-direct-debit/setup-direct-debit/SetupDirectDebitPage";
import ReviewDirectDebitPage from "../pages/payments/new-direct-debit/review-direct-debit/ReviewDirectDebitPage";
import NewDirectDebitConfirmedPage from "../pages/payments/new-direct-debit/confirmed/NewDirectDebitConfirmedPage";
import ExistingDirectDebitPage from "../pages/payments/existing-direct-debit/ExistingDirectDebitPage";
import ChangeBankDetailsPage from "../pages/payments/existing-direct-debit/change-bank-details/ChangeBankDetailsPage";
import ReviewBankDetailsPage from "../pages/payments/existing-direct-debit/review-bank-details/ReviewBankDetailsPage";
import ExistingDirectDebitConfirmedPage from "../pages/payments/existing-direct-debit/confirmed/ExistingDirectDebitConfirmedPage";
import CreditBalancePage from "../pages/payments/card-payment/credit-balance/CreditBalancePage";
import InvoiceSelectionPage from "../pages/payments/invoice-selection/InvoiceSelectionPage";
import PaymentSuccessPage from "../pages/payments/card-payment/payment-success/PaymentSuccessPage";
import ErrorNotFoundPage from "../pages/error/page-not-found/ErrorPageNotFoundPage";
import PayeeDetailsPage from "../pages/payments/card-payment/payee-details/PayeeDetailsPage";
import MakeAPaymentPage from "../pages/payments/card-payment/make-a-payment/MakeAPaymentPage";
import NoPaymentRequiredPage from "../pages/payments/card-payment/no-payment-required/NoPaymentRequiredPage";

import PaymentFailurePage from "../pages/payments/card-payment/payment-failure/PaymentFailurePage";
import PaymentGatewayPage from "../pages/payments/card-payment/payment-gateway/PaymentGatewayPage";
const PaymentRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<ErrorNotFoundPage />} />
      <Route index element={<PaymentsPage />} />
      <Route path="payment-holidays" element={<PaymentHolidaysPage />} />
      <Route path="make-a-payment" element={<MakeAPaymentPage />} />
      <Route path="new-direct-debit">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route index element={<NewDirectDebitPage />} />
        <Route path="setup-direct-debit" element={<SetupDirectDebitPage />} />
        <Route path="review-direct-debit" element={<ReviewDirectDebitPage />} />
        <Route path="confirmed" element={<NewDirectDebitConfirmedPage />} />
      </Route>
      <Route path="existing-direct-debit">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route index element={<ExistingDirectDebitPage />} />
        <Route path="change-bank-details" element={<ChangeBankDetailsPage />} />
        <Route path="review-bank-details" element={<ReviewBankDetailsPage />} />
        <Route
          path="confirmed"
          element={<ExistingDirectDebitConfirmedPage />}
        />
      </Route>
      <Route path="card-payment">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route path="payment-gateway" element={<PaymentGatewayPage />} />
        <Route path="invoice-selection" element={<InvoiceSelectionPage />} />
        <Route path="payee-details" element={<PayeeDetailsPage />} />
        <Route path="credit-balance" element={<CreditBalancePage />} />
        <Route path="payment-success" element={<PaymentSuccessPage />} />
        <Route path="no-payment-required" element={<NoPaymentRequiredPage />} />
        <Route path="payment-failure" element={<PaymentFailurePage />} />
      </Route>
    </Routes>
  );
};

export default PaymentRoutes;
