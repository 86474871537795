import { DateTime } from "luxon";

import steps from "../../contract-amends-steps";
import * as fieldData from "../../../../component/forms/fields/data/field-data";

import {
  step,
  piPersonalDetails,
  piAddressDetails,
  piEmploymentDetails,
  piFinancialDetails,
  isSoleTrader,
  partnerType,
  partners,
  currentPartnerIndex,
  isPrivateIndividual,
  deletedPartners,
} from "../../../../redux/features/contract-amends/contractAmendsSlice";

const extractPersonalDetails = (details) => {
  let personalDetails = {};
  if (details && details.personalDetails) {
    const dob = DateTime.fromISO(details.personalDetails.dateOfBirth);
    personalDetails = {
      title: details.personalDetails.title,
      firstName: details.personalDetails.forename,
      lastName: details.personalDetails.surname,
      maritalStatus: details.personalDetails.maritalStatus,
      numOfDependants: details.personalDetails.numberOfDependents,
      accommodation: details.personalDetails.accommodationStatus,
      mobileNumber: details.personalDetails.mobile,
      homeNumber: details.personalDetails.homeTelephone,
      workNumber: details.personalDetails.workTelephoneNo,
      emailAddress: details.personalDetails.emailAddress,
      dobDay: dob ? dob.day : undefined,
      dobMonth: dob ? dob.month : undefined,
      dobYear: dob ? dob.year : undefined,
    };
  }
  return personalDetails;
};

const extractEmploymentDetails = (details) => {
  let employmentDetails = {};
  if (
    details.currentEmploymentStatus &&
    Array.isArray(details?.employments) &&
    details.employments.length > 0
  ) {
    const firstEmployment = details.employments[0];
    employmentDetails = {
      employmentStatus: details.currentEmploymentStatus,
      occupation: firstEmployment.occupation,
      employerName: firstEmployment.employer,
      postCode: firstEmployment?.address?.postcode,
      isAddressManuallyEntered: true,
      addressLine1: firstEmployment?.address?.line1,
      addressLine2: firstEmployment?.address?.line2,
      addressLine3: firstEmployment?.address?.line3,
      city: firstEmployment?.address?.city,
      county: firstEmployment?.address?.county,
      timeAtEmploymentYears: firstEmployment.yearsAtEmployment,
      timeAtEmploymentMonths: firstEmployment.monthsAtEmployment,
    };
  }
  return employmentDetails;
};

const extractAddressDetails = (details) => {
  const addresses = [];
  if (
    Array.isArray(details?.personalDetails?.addresses) &&
    details.personalDetails.addresses.length > 0
  ) {
    details.personalDetails.addresses.forEach((address) => {
      addresses.push({
        addressLine1: address.line1,
        addressLine2: address.line2,
        addressLine3: address.line3,
        county: address.county,
        postCode: address.postcode,
        city: address.city,
        timeAtAddressYears: address.yearsAtAddress,
        timeAtAddressMonths: address.monthsAtAddress,
        isAddressManuallyEntered: true,
      });
    });
  }
  return addresses;
};

const extractPartnerType = (details) => {
  let partnerType = "";
  if (typeof details === "object") {
    partnerType = details?.partnerDetails?.partnerType
      ? details.partnerDetails.partnerType
      : "";
  }
  return partnerType;
};

const getTitleId = (title) => {
  const titleObj = fieldData.titles.find(
    (t) => t.text.toLowerCase() === title?.toLowerCase()
  );

  return titleObj?.id;
};

const extractBchPartners = (details, counterParty) => {
  const partners = [];
  if (typeof details === "object") {
    if (
      Array.isArray(details?.partnerDetails?.partners) &&
      details.partnerDetails.partners.length > 0
    ) {
      details.partnerDetails.partners.forEach((partner, index) => {
        const dob = DateTime.fromISO(partner.dateOfBirth);
        //Need to extract title here
        const partnerToSave = {
          personalDetails: {
            title: getTitleId(partner.title),
            firstName: partner.forename,
            lastName: partner.surname,
            emailAddress: partner.emailAddress,
            nationality: partner.nationalityCode,
            dobDay: dob.day,
            dobMonth: dob.month,
            dobYear: dob.year,
            genderCode: partner.genderCode,
            partnerNum: index + 1,
          },
          addressDetails: {
            addressLine1: partner.line1,
            addressLine2: partner.line2,
            addressLine3: partner.line3,
            county: partner.county,
            postCode: partner.postcode,
            city: partner.city,
            timeAtAddressYears: partner.yearsAtAddress,
            timeAtAddressMonths: partner.monthsAtAddress,
            isAddressManuallyEntered: true,
          },
        };
        if ((counterParty === 11 && index === 0) || counterParty !== 11) {
          // Need to only save first partner details if it is a sole trader (counterparty = 11)
          partners.push(partnerToSave);
        }
      });
    }
  }
  return partners;
};

const saveAmendmentDetails = (details, counterParty, dispatch) => {
  if (counterParty === 21) {
    dispatch(isPrivateIndividual(true));
    dispatch(piPersonalDetails(extractPersonalDetails(details)));
    dispatch(piAddressDetails(extractAddressDetails(details)));
    dispatch(piEmploymentDetails(extractEmploymentDetails(details)));
    dispatch(piFinancialDetails({})); // We don't need to pre-populate financial details
  } else {
    dispatch(isPrivateIndividual(false));
    dispatch(partnerType(extractPartnerType(details)));
    dispatch(partners(extractBchPartners(details)));
    dispatch(isSoleTrader(counterParty === 11));
    dispatch(currentPartnerIndex(0));
    dispatch(deletedPartners([]));
  }
};

const navigateToPage = (counterParty, amendmentStatus, navigate, dispatch) => {
  if (counterParty === 21) {
    dispatch(step(steps.newQuote.personalDetails));
    navigate("../personal-details");
  } else if (
    counterParty === 11 &&
    /outstanding information/i.test(amendmentStatus)
  ) {
    dispatch(step(steps.bch.soleTrader.personalDetails));
    navigate("../bch/personal-details");
  } else if (/outstanding information/i.test(amendmentStatus)) {
    dispatch(step(steps.bch.limitedCompany.partners[0].addNewPartner));
    navigate("../bch/add-new-partner", {
      state: { checkPartners: true, partnerIndex: 0 },
    });
  } else if (
    counterParty !== 21 &&
    /existingapplicationinprogress/i.test(amendmentStatus)
  ) {
    dispatch(step(steps.customerServices));
    navigate("../customer-services");
  } else {
    dispatch(step(steps.viewSummary));
    navigate("../view-summary");
  }
};

const useSaveQuote = () => {
  return { saveAmendmentDetails, navigateToPage };
};

export default useSaveQuote;
