import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const ClockIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    viewBox="0 0 20 20"
    role="img"
    aria-label={title}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="Clock-icon_svg__a"
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2ZM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67V7Z"
      />
    </defs>
    <use
      xlinkHref="#Clock-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-2 -2)"
    />
  </svg>
);

ClockIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { ClockIcon };
export default ClockIcon;
