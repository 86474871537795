import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Row, Col, Card, Alert } from "react-bootstrap";
import BasePage from "../basepages/basepage/BasePage";
import useAuth from "../../hooks/use-auth/useAuth";
import Divider from "../../component/divider/Divider";
import MessageBox from "../../component/modals/message-box/MessageBox";
import magicStrings from "../../utils/magic-string";

const IndexPage = () => {
  const { auth, setAuth } = useAuth();
  const status = auth?.user?.status;
  const [showHelp, setShowHelp] = useState(() => {
    return false;
  });
  const [warningMessage, setWarningMessage] = useState();
  const logoutReason = useLocation().state?.logoutReason;

  useEffect(() => {
    if (logoutReason) {
      if (logoutReason === magicStrings.inactivityTimeout) {
        setWarningMessage("Your session has expired.");
      } else if (logoutReason === magicStrings.accountLocked) {
        setWarningMessage(
          "Your account has been locked as we have not been able to validate your identity. Someone from our online support team will be in touch."
        );
      } else if (logoutReason === magicStrings.sessionExpired) {
        setWarningMessage(
          "An unexpected error has occurred. You've been logged out."
        );
      }
    }
  }, [logoutReason]);

  useEffect(() => {
    if (status) {
      setAuth({});
    }
  }, [status, setAuth]);

  return (
    <BasePage
      pageTitle="Manage My Vehicle"
      pageSubTitle="Sign your new vehicle agreement and schedule, view invoices, update personal details and more."
    >
      <MessageBox
        title="We are here to help"
        show={showHelp}
        onClose={() => {
          setShowHelp(false);
        }}
        buttons={["Close"]}
      >
        If you are struggling to use or register for the Manage My Vehicle
        portal please call{" "}
        <Link to="tel: 0344 879 6633" target="_blank">
          <strong>0344 879 6633</strong>
        </Link>{" "}
        option 6. Our opening hours are Monday - Friday, 9am - 5pm. We're closed
        on weekends and bank holidays.
      </MessageBox>
      {warningMessage && <Alert variant="danger">{warningMessage}</Alert>}
      <Row>
        <Col xs={12} lg={5}>
          <Card className="h-100">
            <Card.Title className="px-0 px-md-3 text-primary">
              Register
            </Card.Title>
            <Card.Body className="px-0 px-md-3">
              If this is your first time logging in, please select Register.
            </Card.Body>
            <Card.Footer className="bg-transparent px-0 px-md-3">
              <div className="d-grid gap-2">
                <Link
                  to="/register"
                  className="btn btn-primary text-decoration-none"
                  id="lnkRegister"
                >
                  Register
                </Link>
              </div>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={12} lg={2}>
          <div className="h-100 px-3 mb-5 mb-lg-0">
            <Divider breakpoint="lg">or</Divider>
          </div>
        </Col>
        <Col xs={12} lg={5}>
          <Card className="h-100">
            <Card.Title className="px-0 px-md-3 text-primary">Login</Card.Title>
            <Card.Body className="px-0 px-md-3">
              If you have already registered, please select Login.
            </Card.Body>
            <Card.Footer className="bg-transparent px-0 px-md-3">
              <div className="d-grid gap-2">
                <Link
                  to="/login"
                  className="btn btn-outline-primary text-decoration-none"
                  id="lnkLogin"
                >
                  Login
                </Link>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <p className="my-5 text-center">
        Having trouble logging in? Check our{" "}
        <Link to="/faqs" id="lnkFaqs">
          <strong>Frequently Asked Questions</strong>
        </Link>{" "}
        for help.
      </p>
      <p className="my-5 text-center">
        <Link to="#" id="lnkHelp" onClick={() => setShowHelp(true)}>
          We are here to help
        </Link>
        .
      </p>
    </BasePage>
  );
};

export default IndexPage;
