import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import QuoteDetails from "../../../component/early-termination/quote-details/QuoteDetails";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import Stepper from "../../../component/stepper/Stepper";
import {
  nextStep,
  etQuoteDetails,
} from "../../../redux/features/early-termination/earlyTerminationSlice";
import { contractDetails as vcContractDetails } from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../steps-data.json";
import { useStepper } from "../hooks/useStepper";
import { useEtQuoteDetails } from "../hooks/useQuoteDetails";
import { useContract } from "../../../api/hooks/contracts";
import magicStrings from "../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const QuoteDetailsPage = ({ isETNewQuote = true }) => {
  const dispatch = useDispatch();
  const currentStep = 2;
  const activeStep = useSelector((state) => state.earlyTermination.activeStep);
  const contractId = useSelector(
    (state) => state.earlyTermination.contractDetails.contractId
  );

  const etContractDetails = useSelector(
    (state) => state.earlyTermination.contractDetails
  );

  const { contract, isError, errorResponse } = useContract(contractId);

  const quoteDetails = useEtQuoteDetails({ contract });

  const etQuoteNumber = quoteDetails?.quoteNumber;

  useStepper({ activeStep, currentStep });

  const onClickContinueHandler = () => {
    dispatch(vcContractDetails(etContractDetails));
    dispatch(nextStep());
  };

  useEffect(() => {
    dispatch(etQuoteDetails({ quoteNumber: etQuoteNumber }));
  }, [dispatch, etQuoteNumber]);

  return (
    <WizardBasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
      wizardContent={
        <Stepper
          steps={steps}
          activeStep={activeStep}
          onClickHandler={() => {}}
          mode="number"
          showCompletionStep="number"
        />
      }
    >
      {isError ? (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse.localErrorMessage)}
        </Alert>
      ) : (
        <>
          <Container>
            <QuoteDetails quoteDetails={quoteDetails} />
            <Row>
              <Col>
                <h3 className="text-primary mt-3">
                  If you end your contract this month
                </h3>
                <p>
                  If you decide to end your contract early and return the
                  vehicle by{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteAExpiryDateByDayAndMonth}
                  </strong>
                  , the Early Termination Charge (ETC) will be{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteAEtCost} ({quoteDetails?.vatLabel})
                  </strong>
                  .
                </p>
                {!isETNewQuote && (
                  <p>
                    Any payments you may have missed on your account aren't
                    included in the ETC, so you'll also need to repay any
                    outstanding balance you may have. And if you had a payment
                    holiday, you'll need to catch up with your payments as
                    agreed when you took it - we'll explain more about this in
                    the next step.
                  </p>
                )}

                <p>
                  You'll also need to pay this month's rental charge of{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.totalMonthlyRentalCharge} (
                    {quoteDetails?.vatLabel})
                  </strong>
                  , which we're due to collect by Direct Debit on or around{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteADdDateByDayAndMonth}
                  </strong>
                  .
                </p>

                <p>
                  If your vehicle has more than{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteAEtMileage}
                  </strong>{" "}
                  miles on the clock, we'll charge you for excess mileage at{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.excessMileageCharge} pence per mile, (
                    {quoteDetails?.vatLabel})
                  </strong>
                  .
                </p>
                <p>
                  If there's any damage to the vehicle above fair wear and tear,
                  you may need to pay additional charges too.
                </p>
              </Col>
            </Row>

            <Row>
              <Col className="mt-3">
                <h3 className="text-primary">
                  If you end your contract next month
                </h3>
                <p>
                  If you wait an extra month and return the vehicle between{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteBStartDateByDayAndMonth}
                  </strong>{" "}
                  and{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteBExpiryDateByDayAndMonth}
                  </strong>
                  , the ETC comes down to{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteBEtCost} ({quoteDetails?.vatLabel})
                  </strong>
                  .
                </p>

                {!isETNewQuote && (
                  <p>
                    Any payments you may have missed aren't included in the ETC,
                    so you'll also need to repay any outstanding balance you may
                    have. And if you had a payment holiday, you'll need to catch
                    up with your payments as agreed when you took it - we'll
                    explain more about this in the next step.
                  </p>
                )}

                <p>
                  This cost does not include your{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteBExpiryDateByMonth}
                  </strong>{" "}
                  rental payment, which is due to be collected by Direct Debit
                  on or around{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteBDdDateByDayAndMonth}
                  </strong>
                  .
                </p>

                <p>
                  If your vehicle's total mileage of greater than{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.quoteBEtMileage}
                  </strong>{" "}
                  miles, we'll also charge you for excess mileage at{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {quoteDetails?.excessMileageCharge} pence per mile, (
                    {quoteDetails?.vatLabel})
                  </strong>
                  .
                </p>

                <p>
                  If there's any damage to the vehicle above fair wear and tear,
                  you may need to pay additional charges too.
                </p>
              </Col>
            </Row>
          </Container>

          <CenterAlignedButtonContainer>
            <Button
              as={Link}
              to={`${
                isETNewQuote
                  ? "/early-termination/new-quote/accept-quote"
                  : "/early-termination/existing-quote/accept-quote"
              }`}
              id="btnEtContinue"
              variant="primary"
              className="text-decoration-none"
              onClick={onClickContinueHandler}
            >
              Continue
            </Button>
            <BackToHomeButton />
          </CenterAlignedButtonContainer>
        </>
      )}
    </WizardBasePage>
  );
};

QuoteDetailsPage.propTypes = {
  isETNewQuote: PropTypes.bool,
};

export default QuoteDetailsPage;
