import { Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import CollectionStepper from "../collection-stepper/CollectionStepper";
import steps from "../vehicle-collection-steps";
import useVehicleCollectionStepCheck from "../hooks/useVehicleCollectionStepCheck";
import { useSubmitVehicleCollections } from "../../../api/hooks/vehicle-collection";
import magicStrings from "../../../utils/magic-string";
import PageHeading from "../../../component/page-heading/PageHeading";

const CollectionSummaryPage = () => {
  const contractDetails = useSelector(
    (state) => state.vehicleCollection.contractDetails
  );
  const contactDetails = useSelector(
    (state) => state.vehicleCollection.contactDetails
  );
  const collectionAddress = useSelector(
    (state) => state.vehicleCollection.collectionAddress
  );
  const collectionDate = useSelector(
    (state) => state.vehicleCollection.collectionDate
  );
  const navigate = useNavigate();

  useVehicleCollectionStepCheck(steps.summary, "../collection-date");

  const schema = yup.object().shape({
    areTermsAccepted: yup.bool().oneOf([true], "Terms must be accepted."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const onAmendRequest = () => {
    navigate(-2);
  };

  const onUpdateSuccessful = () => {
    navigate("/vehicle-collection/success");
  };
  const onUpdateFailure = (error) => {
    navigate("/vehicle-collection/failure", { state: { error } });
  };

  const { mutate } = useSubmitVehicleCollections(
    onUpdateSuccessful,
    onUpdateFailure
  );

  const onSubmitRequest = () => {
    let collectionData = {
      collectionDate: collectionDate,
      contactName: contactDetails.contactName,
      contactEmail: contactDetails.emailAddress,
      contactMobileNumber: contactDetails.mobileNumber,
      contactWorkNumber: contactDetails.workNumber,
      contactHomeNumber: contactDetails.homeNumber,
      addressLine1: collectionAddress.addressLine1,
      addressLine2: collectionAddress.addressLine2,
      addressLine3: collectionAddress.addressLine3,
      addressLine4: collectionAddress.city,
      addressLine5: collectionAddress.county,
      postcode: collectionAddress.postCode,
      collectionNotes: "",
    };

    mutate({
      contractId: contractDetails.contractId,
      collectionData: collectionData,
    });
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      <PageHeading align="left">Collection summary details</PageHeading>
      <p>Please review your collection details before submitting.</p>
      <hr />

      <dl className="row">
        <dt className="col-sm-6">Vehicle registration:</dt>
        <dd className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}>
          {contractDetails?.vehicle?.registrationNumber}
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
        <dt className="col-sm-6">Contact name:</dt>
        <dd
          className={`col-sm-6 text-sm-end text-break ${magicStrings.maskInformation}`}
        >
          {contactDetails.contactName}
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
        <dt className="col-sm-6">Contact number(s):</dt>
        <dd className="col-sm-6 text-sm-end">
          {contactDetails.mobileNumber && (
            <div className={`${magicStrings.maskInformation}`}>
              {contactDetails.mobileNumber}
            </div>
          )}
          {contactDetails.homeNumber && (
            <div className={`${magicStrings.maskInformation}`}>
              {contactDetails.homeNumber}
            </div>
          )}
          {contactDetails.workNumber && (
            <div className={`${magicStrings.maskInformation}`}>
              {contactDetails.workNumber}
            </div>
          )}
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
        <dt className="col-sm-6">Contact email:</dt>
        <dd
          className={`col-sm-6 text-sm-end text-break ${magicStrings.maskInformation}`}
        >
          {contactDetails.emailAddress}
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
        <dt className="col-sm-6">Collection address:</dt>
        <dd className="col-sm-6 text-sm-end text-break">
          {collectionAddress.addressLine1 && (
            <div className={`${magicStrings.maskInformation}`}>
              {collectionAddress.addressLine1}
            </div>
          )}
          {collectionAddress.addressLine2 && (
            <div className={`${magicStrings.maskInformation}`}>
              {collectionAddress.addressLine2}
            </div>
          )}
          {collectionAddress.addressLine3 && (
            <div className={`${magicStrings.maskInformation}`}>
              {collectionAddress.addressLine3}
            </div>
          )}
          {collectionAddress.city && (
            <div className={`${magicStrings.maskInformation}`}>
              {collectionAddress.city}
            </div>
          )}
          {collectionAddress.county && (
            <div className={`${magicStrings.maskInformation}`}>
              {collectionAddress.county}
            </div>
          )}
          {collectionAddress.postCode && (
            <div className={`${magicStrings.maskInformation}`}>
              {collectionAddress.postCode}
            </div>
          )}
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
        <dt className="col-sm-6">Collection date:</dt>
        <dd className={`col-sm-6 text-sm-end ${magicStrings.maskInformation}`}>
          {DateTime.fromFormat(collectionDate, "yyyy-MM-dd").toFormat(
            "dd MMMM yyyy"
          )}
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
      </dl>
      <p className="mb-5">
        <strong>
          It is my responsibility to ensure the vehicle is safe to drive at the
          time of collection and notify you of any changes at least 48 hours
          before my booking date.
        </strong>
      </p>

      <Form
        onSubmit={handleSubmit(onSubmitRequest)}
        noValidate
        name="collectionSummaryForm"
      >
        <div className="d-flex flex-column gap-3 col-12 col-sm-8 col-lg-6 col-xl-5 mx-auto">
          <Form.Group className="mb-3 me-3" controlId="acceptTerms">
            <Form.Check
              type="checkbox"
              label="I accept and understand the statement."
              {...register("areTermsAccepted")}
              isInvalid={errors.areTermsAccepted}
            ></Form.Check>
            <Form.Control.Feedback
              type="invalid"
              className={errors.areTermsAccepted ? "d-block" : "d-none"}
            >
              {errors.areTermsAccepted?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-grid gap-2">
            <Button className="px-5" type="submit" id="btnFormSubmit">
              Submit request
            </Button>
          </div>
          <div className="d-grid gap-2">
            <Button
              variant="outline-primary"
              className="px-5"
              type="button"
              id="btnAmendRequest"
              onClick={onAmendRequest}
            >
              Amend request
            </Button>
          </div>
        </div>
      </Form>
    </WizardBasePage>
  );
};

export default CollectionSummaryPage;
