import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import BasePage from "../../basepages/basepage/BasePage";
import PhoneIcon from "../../../component/icons/PhoneIcon";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import useSupportNeedsStepCheck from "./hooks/useSupportNeedsStepCheck";
import steps from "./support-needs-steps";
import {
  reset,
  step,
} from "../../../redux/features/support-needs/supportNeedsSlice";
import PageHeading from "../../../component/page-heading/PageHeading";

const AccessibilitySupportPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useSupportNeedsStepCheck(steps.home, steps.home, "/help/accessibility-home");

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const onSupportNeeds = () => {
    dispatch(step(steps.supportNeeds));
    navigate("/help/support-needs");
  };

  return (
    <BasePage
      pageTitle="Help and support"
      pageSubTitle="Find help when you need it."
    >
      <PageHeading align="left">Accessibility and support</PageHeading>
      <p>
        Please tell us more about yourself. This helps us to make sure we're
        supporting you in the best way.
      </p>
      <hr className="my-5" />
      <h2>Do you need immediate support?</h2>
      <p className="mb-1">
        Please call us if you need help right away. We can support you by doing
        things like:
      </p>
      <ul>
        <li>
          Sending letters to you in different formats: including large print,
          Braille or audio.
        </li>
        <li>Making phone calls easier for you.</li>
        <li>
          Updating any changes in your circumstances that are helpful for us to
          know about.
        </li>
      </ul>

      <CenterAlignedButtonContainer>
        <Button href="tel:08000684025" target="_blank">
          <PhoneIcon
            title="Phone"
            className="align-center me-3 d-inline-block"
          />
          0800 068 4025
        </Button>
      </CenterAlignedButtonContainer>
      <p>
        If getting in touch with us by email is better for you, then you can
        also reach us at{" "}
        <Link to="mailto: customer.services@lexautolease.co.uk">
          customer.services@lexautolease.co.uk
        </Link>
        .
      </p>

      <hr className="my-5" />
      <h2>Easy guide list of terms</h2>
      <p>
        If you need support with the words used in your invoice, take a look at
        our 'easy guide list of terms' to help you understand. If you'd like a
        printed copy of the document sent to you in the post, please call us on{" "}
        <Link to="tel: 0344 879 6633">0344 879 6633</Link>, (Open Monday -
        Friday, 9am - 5pm). We're closed on weekends and bank holidays.
      </p>
      <p>
        <Link
          target="_blank"
          to="https://www.lexautolease.co.uk/content/bbp/repositories/64bd0369-dde0-4429-bd8f-59ed913de60d?path=%2FPDF%2Flexeasyread.pdf"
          rel="noopener noreferrer"
        >
          View our easy guide list of terms here {">"}
        </Link>{" "}
        (PDF, 726 KB). Link will open in new window.
      </p>

      <hr className="my-5" />
      <h2>Help using digital devices</h2>
      <p>
        Not sure where to start with using digital devices? Our Digital Helpline
        can support you with the basics of getting set up and learning how to
        use them. In partnership with the <strong>Digital Helpline,</strong>{" "}
        we're offering free 1-2-1 training sessions - tailor-made to your needs.
        Please refer to the digital helpline at{" "}
        <Link to="tel: 0345 222 0333">0345 222 0333</Link>.
      </p>
      <hr className="my-5" />

      <h2>Tell us about your individual needs.</h2>
      <p>
        Do you have any accessibility needs, disabilities or are you going
        through some difficult life events? If so, telling us about these means
        we can do things to help make life a bit easier for you.
      </p>
      <p>
        Perhaps you want to tell us how you'd like us to contact you. You can
        let us know if your needs change at any time.
      </p>
      <p>
        We'll always keep the information you tell us confidential and only use
        it to help you. It won't affect any products you have with us, helping
        you to feel safe sharing your needs.
      </p>

      <CenterAlignedButtonContainer>
        <Button
          onClick={onSupportNeeds}
          variant="outline-primary"
          className="text-decoration-none"
          size="md"
        >
          Your support needs
        </Button>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default AccessibilitySupportPage;
