import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ContactAndCollectionDetailsForm from "../../../component/forms/vehicle-collection/contact-and-collection-details/ContactAndCollectionDetailsForm";
import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import {
  step,
  collectionAddress,
  contactDetails,
} from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../vehicle-collection-steps";
import useAuth from "../../../hooks/use-auth/useAuth";
import CollectionStepper from "../collection-stepper/CollectionStepper";
import useVehicleCollectionStepCheck from "../hooks/useVehicleCollectionStepCheck";
import PageHeading from "../../../component/page-heading/PageHeading";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";

const ContactAndCollectionDetailsPage = () => {
  let userProfileForForm;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedContactDetails = useSelector(
    (state) => state.vehicleCollection.contactDetails
  );
  const savedCollectionAddress = useSelector(
    (state) => state.vehicleCollection.collectionAddress
  );
  const { auth } = useAuth();
  const profile = auth.user;
  if (savedContactDetails?.contactName) {
    // Populating data from the local saved data
    userProfileForForm = {
      ...savedContactDetails,
    };
  }
  if (!userProfileForForm && (profile?.firstName || profile?.lastName)) {
    // Populating data from the api as this is the first time page.
    userProfileForForm = {
      contactName: `${profile.firstName} ${profile.lastName}`,
      emailAddress: profile.emailAddress,
      homeNumber: profile?.homeNumber,
      workNumber: profile?.workNumber,
      mobileNumber: profile?.mobileNumber,
    };
  }

  useVehicleCollectionStepCheck(
    steps.collectionContactDetails,
    "../road-worthiness/question6"
  );

  const onFormSubmit = (data) => {
    dispatch(
      contactDetails({
        contactName: data.contactName,
        workNumber: data.workNumber,
        mobileNumber: data.mobileNumber,
        homeNumber: data.homeNumber,
        emailAddress: data.emailAddress,
      })
    );
    dispatch(
      collectionAddress({
        postCode: data.postCode,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        addressLine3: data.addressLine3,
        city: data.city,
        county: data.county,
        isAddressManuallyEntered: data.isAddressManuallyEntered,
        selectAddress: data.selectAddress,
      })
    );
    dispatch(step(steps.collectionDate));
    navigate("../collection-date");
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      <PageHeading align="center">Contact and collection details</PageHeading>
      <CenterAlignedContainer>
        <p className="text-center mb-3">
          To collect your vehicle, we need the contact details of the person we
          will be collecting the vehicle from and the address where the vehicle
          will be located.
        </p>
      </CenterAlignedContainer>
      <CenterAlignedButtonContainer>
        <div>
          {userProfileForForm && (
            <ContactAndCollectionDetailsForm
              userProfile={userProfileForForm}
              collectionAddress={savedCollectionAddress}
              onFormSubmit={onFormSubmit}
            />
          )}
        </div>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default ContactAndCollectionDetailsPage;
