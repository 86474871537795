const formatCurrency = (val, fallback = "") => {
  if (typeof val === "undefined" || val === null || val === "") {
    return fallback;
  } else {
    let currencyNumberFormat = currencyNumberFormatter(val);
    return currencyNumberFormat;
  }
};

const formatNumber = (val, fractionDigits = 2, fallback = "") => {
  if (typeof val === "undefined" || val === null || val === "") {
    return fallback;
  } else {
    return new Intl.NumberFormat("en-GB", {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(val);
  }
};

const currencyNumberFormatter = (number) => {
  let numberParseFloat = parseFloat(number);
  const numberFormatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  number = numberFormatter.format(numberParseFloat);
  return number;
};

export { formatCurrency, formatNumber };
