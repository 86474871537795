// All users api error need to map in the range of of 1240001 to 1299999
// The number is divided into 3 parts
// First part is the api key range, for users will always be 12
// Second part is the http error status code e.g 400
// Last part is the identifier for the unique error message we want to display.
// Two server api error codes are okay to be mapped to single error code, if required.

const usersErrorCodeMappings = {
  400: {},
  401: {
    UNEXPECTED_ERROR: 1240100,
    USER_SET_UP: 1240101,
    CODE_INVALID_FLEET: 1240102,
    CODE_INVALID_VEHICLEREG: 1240103,
    CODE_INVALID_FLEET_VEHICLEREG_COMBINATION: 1240104,
    CODE_INVALID_CONTACT_DETAILS: 1240105,
    CODE_INVALID_SALES_CHANNELS: 1240106,
    CODE_LASI_ACCOUNT: 1240107,
    INVALID_USERNAME_PASSWORD: 1240108,
    EXPIRED_PASSWORD: 1240109,
    USER_NOT_FOUND: 1240110,
    CODE_INVALID_ALIAS: 1240111,
    CODE_EXISTING_ACCOUNT_LOCKED: 1240112,
  },
  500: {
    DEFAULT_ERROR: 1250000,
  },
  403: {
    USER_LOCKED: 1240300,
    USER_LOCKED_IIQ: 1240301,
  },
  // default is used to handle the case where it is an unknown http status code
  default: {},
};

export default usersErrorCodeMappings;
