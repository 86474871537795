import { Link } from "react-router-dom";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import useAuth from "../../hooks/use-auth/useAuth";

const BackToLogin = () => {
  const { auth } = useAuth();

  return auth?.user ? null : (
    <Link to="/" className="mt-6 mb-sm-0 px-4 px-sm-0 d-inline-block">
      <ArrowLeftIcon title="Back to login" size="xs" />
      <span className="ps-1">Back to login</span>
    </Link>
  );
};

export default BackToLogin;
