// All users related api error for forgot details has need to map in the range of 1340001 to 1399999
// The number is divided into 3 parts
// First part is the api key range, for users forgot details will always be 13
// Second part is the http error status code e.g 400
// Last part is the identifier for the unique error message we want to display.
// Two server api error codes are okay to be mapped to single error code, if required.

const forgotDetailsErrorCodeMappings = {
  401: {
    default: 1340101,
  },
  500: {
    default: 1350001,
  },
  // default is used to handle the case where it is an unknown http status code
  default: {
    default: 1399901,
    // This default is the absolute fallback error code for users forgot details api.
  },
};

export default forgotDetailsErrorCodeMappings;
