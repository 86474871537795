import liveContractsErrorMessages from "./live-contracts";
import usersErrorMessages from "./users";
import directDebitErrorMessages from "./direct-debit";
import forgotDetailsErrorMessages from "./forgot-details";
import contractAmendmentsErrorMessages from "./contract-amendments";
import profileErrorMessages from "./profile";
import ordersErrorMessages from "./orders";
import documentsErrorMessages from "./documents";
import globalErrorMessages from "./global";

const errorMesages = {
  ...liveContractsErrorMessages,
  ...usersErrorMessages,
  ...directDebitErrorMessages,
  ...forgotDetailsErrorMessages,
  ...contractAmendmentsErrorMessages,
  ...profileErrorMessages,
  ...ordersErrorMessages,
  ...documentsErrorMessages,
  ...globalErrorMessages,
};

export default errorMesages;
