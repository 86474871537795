import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const AlertOutlineIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    viewBox="0 0 22 20"
    role="img"
    aria-label={title}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="warning-amber-icon_svg__a"
        d="m12 6.49 7.53 13.01H4.47L12 6.49Zm0-3.99-11 19h22l-11-19Zm1 14h-2v2h2v-2Zm0-6h-2v4h2v-4Z"
      />
    </defs>
    <use
      xlinkHref="#warning-amber-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-1 -2)"
    />
  </svg>
);

AlertOutlineIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { AlertOutlineIcon };
export default AlertOutlineIcon;
