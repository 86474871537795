import { Row, Col, Card, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Divider from "../divider/Divider";

const Questionnaire = ({
  option1Title,
  option1Content,
  option1ActionContent,
  option2Title,
  option2Content,
  option2ActionContent,
  actionCallback,
}) => {
  return (
    <Row>
      <Col xs={12} lg={5}>
        <Card className="h-100">
          <Card.Title className="px-0 text-primary">{option1Title}</Card.Title>
          <Card.Body className="px-0">{option1Content}</Card.Body>
          <Card.Footer className="bg-transparent px-0">
            <div className="d-grid gap-2">
              <Button
                variant="outline-primary"
                size="lg"
                id="btnOption1ActionBtn"
                onClick={() => actionCallback(1)}
              >
                {option1ActionContent}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Col>
      <Col xs={12} lg={2}>
        <div className="h-100 px-3 mb-5 mb-lg-0">
          <Divider breakpoint="lg">or</Divider>
        </div>
      </Col>
      <Col xs={12} lg={5}>
        <Card className="h-100">
          <Card.Title className="px-0 text-primary">{option2Title}</Card.Title>
          <Card.Body className="px-0">{option2Content}</Card.Body>
          <Card.Footer className="bg-transparent px-0">
            <div className="d-grid gap-2">
              <Button
                variant="outline-primary"
                size="lg"
                id="btnOption2ActionBtn"
                onClick={() => actionCallback(2)}
              >
                {option2ActionContent}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

Questionnaire.propTypes = {
  option1Title: PropTypes.node.isRequired,
  option1Content: PropTypes.node.isRequired,
  option1ActionContent: PropTypes.string.isRequired,
  option2Title: PropTypes.node.isRequired,
  option2Content: PropTypes.node.isRequired,
  option2ActionContent: PropTypes.string.isRequired,
  actionCallback: PropTypes.func.isRequired,
};

export default Questionnaire;
