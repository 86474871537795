import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const CloseCookiesIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    role="img"
    viewBox="0 0 20 20"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M0 0h20v20H0z" />
      <g fill="#000000" stroke="#000000">
        <path d="M14.465 15.17 4.943 5.544a.426.426 0 0 1 0-.598.415.415 0 0 1 .592 0l9.522 9.627a.426.426 0 0 1 0 .597.414.414 0 0 1-.592 0Z" />
        <path d="M4.943 15.17a.426.426 0 0 1 0-.597l9.522-9.627a.415.415 0 0 1 .592 0 .426.426 0 0 1 0 .598L5.535 15.17a.414.414 0 0 1-.592 0Z" />
      </g>
    </g>
  </svg>
);

CloseCookiesIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { CloseCookiesIcon };
export default CloseCookiesIcon;
