import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import BasePage from "../../../basepages/basepage/BasePage";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { formatCurrency } from "../../../../utils/pages/number-format-utils";
import steps from "../card-payment-steps";
import { step } from "../../../../redux/features/payments/cardPaymentSlice";
import PageHeading from "../../../../component/page-heading/PageHeading";
import magicStrings from "../../../../utils/magic-string";

const CreditBalancePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentStep = useSelector((state) => state.cardPayment.step);
  const creditInvoices = useSelector(
    (state) => state.cardPayment.creditInvoices
  );

  useEffect(() => {
    if (currentStep < steps.creditBalance) {
      navigate("../invoice-selection", { replace: true });
    }
  }, [currentStep, navigate]);

  const creditBalance = useState(() => {
    let balance = 0.0;
    if (Array.isArray(creditInvoices)) {
      creditInvoices.forEach((invoice) => {
        balance = balance + Math.abs(parseFloat(invoice.outstandingBalance));
      });
    }
    return balance;
  });

  const onClickHandler = () => {
    dispatch(step(steps.payeeDetails));
    navigate("../payee-details", { replace: true });
  };
  return (
    <BasePage pageTitle="Make a payment">
      <PageHeading align="left">Your account is in credit</PageHeading>
      <br />
      <p>
        Good news. Your account has a positive balance. If you like, we can put
        this towards your next invoice to reduce the amount you need to pay.
      </p>
      <p>
        Your balance is :{" "}
        <strong className={`${magicStrings.maskInformation}`}>
          {formatCurrency(creditBalance)}
        </strong>
      </p>
      <p>
        If you'd like to use this balance as part of your next payment, call us
        on <Link to="tel:0800 096 1435">0800 096 1435</Link> when you're ready
        to pay. One of our advisers will deduct this amount from your invoice.
      </p>

      <CenterAlignedButtonContainer>
        <Button
          id="btnCreditBalanceCheck"
          className="text-decoration-none"
          variant="primary"
          onClick={onClickHandler}
        >
          Continue without credit
        </Button>
        <BackToHomeButton>Cancel</BackToHomeButton>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default CreditBalancePage;
