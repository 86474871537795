import axios from "../axios/axios";
import apiKeys from "../error-handling/api-keys";
import { useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";
import useAuth from "../../hooks/use-auth/useAuth";

const validateUserCredentials = async (axiosPrivate, payload) => {
  const { data } = await axiosPrivate.post("/auth/login", payload, {
    headers: attachApiKeyHeaderToRequest(apiKeys.users),
  });
  return data;
};

const updatePersonalDetails = async (axiosPrivate, payload) => {
  const { data } = await axiosPrivate.put("/profile", payload, {
    headers: attachApiKeyHeaderToRequest(apiKeys.profile),
  });
  return data;
};

const changePassword = async (axiosPrivate, payload) => {
  const { data } = await axiosPrivate.put("/profile/password", payload, {
    headers: attachApiKeyHeaderToRequest(apiKeys.profile),
  });
  return data;
};

const refreshToken = async () => {
  const { data } = await axios.post("/auth/refresh_token");
  return data;
};

const forgotPassword = async (axiosPrivate, payload) => {
  const { data } = await axiosPrivate.post("/auth/forgot/password", payload, {
    headers: attachApiKeyHeaderToRequest(apiKeys.forgotDetails),
  });
  return data;
};

const forgotUserId = async (axiosPrivate, payload) => {
  const { data } = await axiosPrivate.post("/auth/forgot/username", payload, {
    headers: attachApiKeyHeaderToRequest(apiKeys.forgotDetails),
  });
  return data;
};

const forgotUserIdAndPassword = async (axiosPrivate, payload) => {
  const { data } = await axiosPrivate.post(
    "/auth/forgot/username-password",
    payload,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.forgotDetails),
    }
  );
  return data;
};

const logout = async (accessToken) => {
  const { data } = await axios.delete("/auth/logout", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};

const useValidateUserCredentials = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(
    (payload) => validateUserCredentials(axiosPrivate, payload),
    {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

const useChangePassword = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation((payload) => changePassword(axiosPrivate, payload), {
    onSuccess: (data) => onSuccess(data),
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

const useUpdatePersonalDetails = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(
    (payload) => updatePersonalDetails(axiosPrivate, payload),
    {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

const useRefreshToken = (onSuccess, onError) => {
  return useMutation(refreshToken, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

const useForgotPassword = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation((payload) => forgotPassword(axiosPrivate, payload), {
    onSuccess: (data) => onSuccess(data),
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

const useForgotUserId = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation((payload) => forgotUserId(axiosPrivate, payload), {
    onSuccess: (data) => onSuccess(data),
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

const useForgotUserIdAndPassword = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(
    (payload) => forgotUserIdAndPassword(axiosPrivate, payload),
    {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

const useServerLogout = (onSuccess, onError) => {
  const { auth } = useAuth();
  return useMutation(() => logout(auth?.accessToken), {
    onSuccess: onSuccess,
    onError: onError,
  });
};

export {
  useValidateUserCredentials,
  useChangePassword,
  useRefreshToken,
  useServerLogout,
  useForgotPassword,
  useForgotUserId,
  useForgotUserIdAndPassword,
  useUpdatePersonalDetails,
};
