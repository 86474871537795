import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const PaymentHolidayIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    viewBox="0 0 30 30"
    aria-label={title}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="payment-holiday-icon_svg__a"
        d="M12.75 22.667h2.833V11.333H12.75v11.334ZM17 2.833C9.18 2.833 2.833 9.18 2.833 17c0 7.82 6.347 14.167 14.167 14.167 7.82 0 14.167-6.347 14.167-14.167C31.167 9.18 24.82 2.833 17 2.833Zm0 25.5c-6.248 0-11.333-5.085-11.333-11.333S10.752 5.667 17 5.667c6.247 0 11.333 5.085 11.333 11.333 0 6.247-5.085 11.333-11.333 11.333Zm1.417-5.666h2.833V11.333h-2.833v11.334Z"
      />
    </defs>
    <use
      xlinkHref="#payment-holiday-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-2 -2)"
    />
  </svg>
);

PaymentHolidayIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { PaymentHolidayIcon };
export default PaymentHolidayIcon;
