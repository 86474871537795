import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";

import { step } from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";

/**
 * Ensures the current step in vehicle collection journey.
 * Redirects the user to
 * @param {number} stepToCheck - Step to check that page needs to be.
 * @param {string} redirectionPath - Path to redirect if the saved step is less than minStep.
 */
const useVehicleCollectionStepCheck = (stepToCheck, redirectionPath) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStep = useSelector((state) => state.vehicleCollection.step);

  useEffect(() => {
    if (!currentStep || currentStep < stepToCheck) {
      if (currentStep === 0) {
        navigate("/vehicle-collection", { replace: true });
      } else {
        navigate(redirectionPath, { replace: true });
      }
    } else if (currentStep !== stepToCheck) {
      dispatch(step(stepToCheck));
    }
  }, [navigate, currentStep, dispatch, stepToCheck, redirectionPath]);
};

export default useVehicleCollectionStepCheck;
