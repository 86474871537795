import { createSlice } from "@reduxjs/toolkit";

import revertAll from "../../actions/revert-all/revertAll";

const initialState = {
  step: 0,
  supportNeeds: [],
  selectedSupportNeeds: [],
  deselectedSupportNeeds: [],
};

export const supportNeedsSlice = createSlice({
  name: "supportNeeds",
  initialState,
  reducers: {
    step: (state, action) => {
      state.step = action.payload;
    },

    supportNeeds: (state, action) => {
      state.supportNeeds = action.payload;
    },
    selectedSupportNeeds: (state, action) => {
      state.selectedSupportNeeds = action.payload;
    },
    deselectedSupportNeeds: (state, action) => {
      state.deselectedSupportNeeds = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  step,
  supportNeeds,
  selectedSupportNeeds,
  deselectedSupportNeeds,
  reset,
} = supportNeedsSlice.actions;

export default supportNeedsSlice.reducer;
