const apiKeys = {
  liveContracts: "LIVECONTRACTS", // All live contracts api error need to map in the range of 1040001 to 1099999
  contractAmendments: "CONTRACTAMENDMENTS", // All contract amendments api error need to map in the range of 1140001 to 1199999
  users: "USERS", // All users related api error need to map in the range of 1240001 to 1299999
  forgotDetails: "FORGOTDETAILS", // All users related api error for forgot details has need to map in the range of 1340001 to 1399999
  profile: "PROFILE", // All users related api error for profile has need to map in the range of 1440001 to 1499999
  orders: "ORDERS", // All orders related api error need to map in the range of 1540001 to 1599999
  directDebit: "DIRECTDEBIT", // All direct debit related api error need to map in the range of 1640001 to 1699999
  documents: "DOCUMENTS", // All documents related api error need to map in the range of 1740001 to 1799999

  ___global___: "GLOBAL",
};

export default apiKeys;
