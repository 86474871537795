import PropTypes from "prop-types";

const PageHeading = ({
  variant = "primary",
  align = "center",
  children,
  className = "",
}) => {
  return (
    <h2
      id="pageHeading"
      className={`${
        variant === "primary" ? "text-primary" : "text-secondary"
      } ${align === "center" ? "text-center" : "text-left"} mb-3 ${className}`}
    >
      {children}
    </h2>
  );
};
PageHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  variant: PropTypes.oneOf(["primary", "secondary"]),
  align: PropTypes.oneOf(["center", "left"]),
};
export default PageHeading;
