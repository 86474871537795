import { Link } from "react-router-dom";
import BasePage from "../../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import TitleWithIcon from "../../../../component/title-with-icon/TitleWithIcon";
const CancelCollectionFailurePage = () => {
  return (
    <BasePage
      pageTitle="Amend a collection"
      pageSubTitle="Amend an existing vehicle collection booking."
    >
      <TitleWithIcon
        title="Something went wrong with your request"
        variant="failure"
      />

      <p className="mt-4">
        Unfortunately we have been unable to complete your cancellation request
        online.
      </p>
      <p>
        To complete this request please call us on{" "}
        <Link to="tel: 0344 879 6633">0344 879 6633</Link> option 5. Lines are
        open Monday - Friday between 9am - 5pm. We're closed on weekends and
        bank holidays.
      </p>

      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default CancelCollectionFailurePage;
