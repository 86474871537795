import { Link, useNavigate } from "react-router-dom";
import BasePage from "../basepages/basepage/BasePage";
import LoginForm from "../../component/forms/login/LoginForm";
import { Alert } from "react-bootstrap";
import AuthContext from "../../providers/auth-provider/AuthProvider";
import { useContext, useState } from "react";
import { useValidateUserCredentials } from "../../api/hooks/auth";
import { setItem } from "../../storage/SessionStorage";
import PageHeading from "../../component/page-heading/PageHeading";
import CenterAlignedButtonContainer from "../../component/center-aligned-button-container/CenterAlignedButtonContainer";

const LoginPage = () => {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const onLoginSuccessful = (data) => {
    let nextPage = "/";
    setItem("user", data?.user);
    setAuth({ user: data?.user, accessToken: data?.accessToken });
    const status = data?.user?.status.toUpperCase();
    if (status === "PENDINGCHECK") {
      nextPage = "/iiq";
    } else if (status === "PENDINGPASSWORD") {
      nextPage = "/user-credential";
    } else if (status === "PENDINGIDENTITYCHECK") {
      nextPage = "/verification";
    } else if (status === "OK") {
      nextPage = "/home";
    }
    navigate(nextPage, { replace: true });
  };

  const onLoginFailure = (error) => {
    setErrorMessage(error?.localErrorMessage);
  };

  const { mutate } = useValidateUserCredentials(
    onLoginSuccessful,
    onLoginFailure
  );

  const onSubmit = (data) => {
    setErrorMessage("");
    mutate(data);
  };

  return (
    <BasePage
      pageTitle="Manage My Vehicle"
      pageSubTitle="Sign your new vehicle agreement and schedule, view invoices, update personal details and more."
    >
      <PageHeading align="center">Existing customer</PageHeading>
      <div className="text-center">
        <p>
          Enter your original user ID or alias and the password that you
          created.
        </p>
      </div>
      <CenterAlignedButtonContainer>
        <div>
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage("")}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
          <LoginForm onFormSubmit={onSubmit} />
          <p className="mt-5">
            <Link to="/forgot-login-details" className="text-primary">
              Forgotten your details?
            </Link>{" "}
          </p>
          <p>
            Don't have a password?{" "}
            <Link to="/register" className="text-primary">
              Register here
            </Link>
            .{" "}
          </p>
          <p>
            Having trouble logging in? Refer to our{" "}
            <Link to="/faqs" className="text-primary">
              Frequently Asked Questions
            </Link>{" "}
            for assistance.
          </p>
        </div>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default LoginPage;
