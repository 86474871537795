import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import pageList from "../../utils/mmv-pages.json";

const UnsavedDataPromptHelper = () => {
  const location = useLocation();

  const onWindowBeforeUnload = (e) => {
    const pageData = pageList.find((e) => e.path === location.pathname);
    if (pageData && pageData.promptToSave) {
      e.preventDefault();
      // This value does not work but setting it for older browsers
      e.returnValue =
        "You have unsaved changes, are you sure you want to navigate away?";
    }

    return null;
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("beforeunload", onWindowBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", onWindowBeforeUnload);
      };
    }
  });
};

export default UnsavedDataPromptHelper;
