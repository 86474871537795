import { Route, Routes } from "react-router-dom";

import MoneyWorriesNonRegulatedPage from "../pages/help/money-worries-non-regulated/MoneyWorriesNonRegulatedPage";
import MoneyWorriesPage from "../pages/help/money-worries/MoneyWorriesPage";
import AccessibilitySupportPage from "../pages/help/accessibility-support/AccessibilitySupportPage";
import SupportNeedsPage from "../pages/help/accessibility-support/SupportNeedsPage";
import ConfirmSupportNeedsPage from "../pages/help/accessibility-support/ConfirmSupportNeedsPage";
import SupportNeedsSuccessPage from "../pages/help/accessibility-support/SupportNeedsSuccessPage";
import HelpPage from "../pages/help/HelpPage";
import ErrorNotFoundPage from "../pages/error/page-not-found/ErrorPageNotFoundPage";

const HelpRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<ErrorNotFoundPage />} />
      <Route index element={<HelpPage />} />
      <Route
        path="money-worries-non-regulated"
        element={<MoneyWorriesNonRegulatedPage />}
      />
      <Route path="money-worries" element={<MoneyWorriesPage />} />
      <Route path="accessibility-home" element={<AccessibilitySupportPage />} />
      <Route path="support-needs" element={<SupportNeedsPage />} />
      <Route path="support-needs">
        <Route path="*" element={<ErrorNotFoundPage />} />
        <Route path="confirm" element={<ConfirmSupportNeedsPage />} />
        <Route path="thank-you" element={<SupportNeedsSuccessPage />} />
      </Route>
    </Routes>
  );
};

export default HelpRoutes;
