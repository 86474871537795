const cardPaymentSteps = {
  invoiceSelection: 1,
  payeeDetails: 2,
  paymentGateway: 3,
  noPaymentRequired: 3,
  creditBalance: 3,
  success: 10,
  failure: 10,
};

export default cardPaymentSteps;
