import * as yup from "yup";

// Password Schema

export const currentPasswordSchema = {
  currentPassword: yup.string().required("Current password is required."),
};

export const newPasswordSchema = {
  newPassword: yup
    .string()
    .required("New password is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{8,})/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character (!@#%^&*)."
    ),
};
export const confirmNewPasswordSchema = {
  confirmNewPassword: yup
    .string()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .oneOf([yup.ref("newPassword"), null], "Passwords do not match.")
    .required("Confirm new password is required."),
};

export const userCurrentPasswordSchema = {
  currentPassword: yup
    .string()
    .required("Password is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&*])(?=.{8,})/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character (!@#%^&*)."
    ),
};

export const userConfirmNewPasswordSchema = {
  confirmNewPassword: yup
    .string()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .oneOf([yup.ref("currentPassword"), null], "Passwords do not match.")
    .required("Confirm password is required."),
};
