import * as yup from "yup";

export const vehicleRegOrFleetSchema = {
  fleetNumber: yup.string().when(["vehicleReg"], ([vehicleReg], schema) => {
    return !vehicleReg || vehicleReg.length === 0
      ? schema
          .required("Vehicle registration or fleet number is required.")
          .trim()
      : schema.notRequired();
  }),
  vehicleReg: yup.string().when(["fleetNumber"], ([fleetNumber], schema) => {
    return !fleetNumber || fleetNumber.length === 0
      ? schema
          .required("Vehicle registration or fleet number is required.")
          .trim()
      : schema.notRequired();
  }),
};

export const vehicleRegOrFleetSchemaDependencies = [
  ["vehicleReg", "fleetNumber"],
];

export const isExistingUserSchema = {
  isExistingUser: yup.string().required(),
};

export const areRegisterTermsAcceptedSchema = {
  areRegisterTermsAccepted: yup.bool().oneOf([true], "Terms must be accepted."),
};
