import { Link } from "react-router-dom";
import PageHeading from "../../component/page-heading/PageHeading";
import FooterBasePage from "../basepages/footer-basepage/FooterBasePage";

const ContactusPage = () => {
  return (
    <FooterBasePage pageTitle="Contact us" pageSubTitle="We're here to help.">
      <PageHeading align="left">Need to talk to us?</PageHeading>
      <p>
        You can contact us on
        <Link to="tel: 0344 879 6633" className="h6 text-primary">
          {" "}
          0344 879 6633
        </Link>
        , our opening hours are Monday - Friday between 9.00am - 5.30pm. We're
        closed on weekends and bank holidays,
      </p>
      <ul className="list-unstyled ps-0">
        <li>Press 1 for a purchase price.</li>
        <li>Press 2 vehicle collections or to end contract early.</li>
        <li>Press 3 for a copy invoice or for financial difficulty.</li>
        <li>
          Press 4 vehicle breakdown, report an accident, replacement tyres or
          glass.
        </li>
        <li>Press 5 for MOT bookings, replacement vehicles.</li>
        <li>
          Press 6 for foreign travel, vehicle taxation, fines & penalties, DVLA
          documents and cherished plates.
        </li>
        <li>Press 7 for Customer Service queries or complaints.</li>
      </ul>
    </FooterBasePage>
  );
};

export default ContactusPage;
