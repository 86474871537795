// All live contracts api error need to map in the range of 1040001 to 1099999
// The number is divided into 3 parts
// First part is the api key range, for contrats will always be 10
// Second part is the http error status code e.g 400
// Last part is the identifier for the unique error message we want to display.
// Two server api error codes are okay to be mapped to single error code, if required.

const liveContractsErrorCodeMappings = {
  400: {
    8015: 1040001, // InvalidCollectionDate
    8022: 1040002, // CollectionAmendNotAllowed
  },
  500: {
    default: 1050001,
  },
  // default is used to handle the case where it is an unknown http status code
  default: {
    default: 1099901,
    // This default is the absolute fallback error code for cotracts api.
  },
};

export default liveContractsErrorCodeMappings;
