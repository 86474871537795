import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

import BasePage from "../../../basepages/basepage/BasePage";
import usePaymentGateway from "./hooks/usePaymentGateway";
import steps from "../card-payment-steps";
import { step } from "../../../../redux/features/payments/cardPaymentSlice";
import PageHeading from "../../../../component/page-heading/PageHeading";

const PaymentGatewayPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isStepChecked, setIsStepChecked] = useState(false);
  const iframe = useSelector((state) => state.cardPayment.iframeSrc);
  const currentStep = useSelector((state) => state.cardPayment.step);

  const onPaymentSuccess = () => {
    dispatch(step(steps.success));
    navigate("../payment-success", { replace: true });
  };

  const onPaymentFailure = () => {
    dispatch(step(steps.failure));
    navigate("../payment-failure", { replace: true });
  };

  const onSetIframeHeight = (height) => {
    try {
      if (height > 250) {
        const cardDiv = document.getElementById("cardPaymentContainer");
        const iframe = cardDiv.getElementsByTagName("iframe")[0];
        iframe.height = `${height}px`;
      }
    } catch (e) {}
  };

  usePaymentGateway(onPaymentSuccess, onPaymentFailure, onSetIframeHeight);

  useEffect(() => {
    try {
      if (!isStepChecked) {
        if (currentStep < steps.paymentGateway) {
          navigate("../payee-details", { replace: true });
        } else {
          const script = document.createElement("script");
          // Need to get the source from config
          // script.src = "https://hpc.uat.freedompay.com/api/v1.4/cdn/hpc_min.js";
          script.src = window?.envConfig?.paymentGatewayUrl;
          const cardDiv = document.getElementById("cardPaymentContainer");
          cardDiv.appendChild(script);
        }
        setIsStepChecked(true);
      }
    } catch (e) {}
  }, [currentStep, navigate, isStepChecked]);

  return (
    <BasePage pageTitle="Capture card details">
      <PageHeading>Enter your card details</PageHeading>

      <p className="lead text-center">
        Online payments can be made with debit cards only. We don't accept
        credit cards.
      </p>
      {currentStep === steps.paymentGateway && (
        <div
          id="cardPaymentContainer"
          className="text-center py-3"
          dangerouslySetInnerHTML={{ __html: iframe }}
        />
      )}
      <p className="text-center lead">
        After submitting, please do not refresh or press the back button on your
        browser until payment is confirmed.
      </p>
    </BasePage>
  );
};

export default PaymentGatewayPage;
