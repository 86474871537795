import BasePage from "../basepage/BasePage";
import PropTypes from "prop-types";

const WizardBasePage = ({
  pageTitle,
  pageSubTitle,
  wizardContent,
  children,
  isMainContainerFluid = false,
  showScrollProgress = false,
}) => {
  return (
    <BasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      showWizardContainer={true}
      wizardContent={wizardContent}
      isMainContainerFluid={isMainContainerFluid}
      showScrollProgress={showScrollProgress}
    >
      {children}
    </BasePage>
  );
};

BasePage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  wizardContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  pageTitle: PropTypes.string.isRequired,
  pageSubTitle: PropTypes.string,
};

export default WizardBasePage;
