const liveContractsErrorMessages = {};

// 1040001 and 1040002 messages are not used/allowed to display the error messages in front-end screens
liveContractsErrorMessages["1040001"] = "Collection date is invalid";
liveContractsErrorMessages["1040002"] = "Collection amendment is not allowed";
// 1040001 and 1040002 messages are not used/allowed to display the error messages in front-end screens

liveContractsErrorMessages["1050001"] =
  "An unexpected error has occurred. Please try again later.";

liveContractsErrorMessages["1099901"] =
  "Sorry, we are unable to retrieve your contract or vehicle details. Please try again later.";

export default liveContractsErrorMessages;
