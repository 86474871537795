import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import magicStrings from "../../../utils/magic-string";

const VehicleSelectionForm = ({
  vehicles = [],
  onFormSubmit,
  submitButtonText = "Submit",
}) => {
  const schema = yup.object({
    vehicle: yup.string().required("Vehicle is required."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => onFormSubmit(data);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      name="vehicleSelectionForm"
    >
      <Form.Group className="mb-4" controlId="vehicle">
        <Form.Label>Select a vehicle</Form.Label>
        <Form.Select
          className={`${magicStrings.maskInformation}`}
          aria-label="Select a vehicle"
          {...register("vehicle")}
          isInvalid={errors.vehicle}
        >
          <option value="">{`${vehicles.length} Vehicle${
            vehicles.length > 1 ? "s" : ""
          } found`}</option>
          {vehicles.map((vehicle, index) => (
            <option value={vehicle.id} key={index}>
              {vehicle.registrationNumber}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.vehicle?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-grid gap-2">
        <Button
          disabled={vehicles.length === 0}
          id="btnVehicleSelectionFormSubmit"
          type="submit"
          className="px-5"
        >
          {submitButtonText}
        </Button>
      </div>
    </Form>
  );
};

VehicleSelectionForm.propTypes = {
  vehicles: PropTypes.array.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default VehicleSelectionForm;
