import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

const NotificationBanner = ({
  size = "lg",
  variant = "success",
  isFullHeight = false,
  children,
}) => {
  return (
    <Card
      className={`shadow-sm notification-banner-border border-${variant} ${
        size === "lg" ? "my-4" : size === "sm" ? "p-2 mb-3 mt-2" : ""
      } ${isFullHeight ? "h-100" : ""}`}
    >
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

NotificationBanner.propTypes = {
  size: PropTypes.oneOf(["lg", "sm"]),
  variant: PropTypes.oneOf(["success", "warning", "info"]),
  isFullHeight: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default NotificationBanner;
