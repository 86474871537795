import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Stepper from "../../../component/stepper/Stepper";
import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import PageHeading from "../../../component/page-heading/PageHeading";
import { useOrder } from "../../../api/hooks/orders";
import {
  reviewSteps,
  orderDetails,
  reset,
} from "../../../redux/features/order-review/orderReviewSlice";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import {
  formatCurrency,
  formatNumber,
} from "../../../utils/pages/number-format-utils";
import magicStrings from "../../../utils/magic-string";
import stepsPageData from "../review/orders-review-steps-data.json";
import ordersErrorMessages from "../../../api/error-handling/error-messages/orders";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const OrderDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderId = useSelector(
    (state) => state?.orderReview?.orderDetails?.orderId
  );

  const islastDocumentSigned = useSelector(
    (state) => state?.orderReview?.lastDocumentSigned
  );

  // Get order details from API
  const { order, isError, errorResponse } = useOrder(orderId);

  useEffect(() => {
    if (!orderId || islastDocumentSigned) {
      dispatch(reset());
      navigate("/orders", { replace: true });
    }
  }, [orderId, dispatch, navigate, islastDocumentSigned]);

  const sortOrder = (stepSequence) => {
    return (a, b) => {
      if (a[stepSequence] > b[stepSequence]) {
        return 1;
      } else if (a[stepSequence] < b[stepSequence]) {
        return -1;
      }
      return 0;
    };
  };

  const steps = [
    {
      documentType: "OD",
      documentDescription: "Order details",
      sequence: "0",
      customIcon: "DocumentIcon",
      persistIcon: true,
    },
    ...(order?.documents ?? []),
  ];

  steps.forEach((step, index) => {
    const checkSequenceKey = "sequence" in steps[index];
    if (checkSequenceKey === true) {
      // Replace sequence with step
      steps[index].step = steps[index].sequence;
      delete steps[index].sequence;
      steps[index].step = parseInt(steps[index].step);

      // Replace documentDescription with description
      if (stepsPageData[steps[index].documentType])
        steps[index].description =
          stepsPageData[steps[index].documentType].documentDescription;

      delete steps[index].documentDescription;
    }
    return steps;
  });

  steps.sort(sortOrder("step"));

  const onSignAgreement = () => {
    dispatch(reviewSteps(steps));
    dispatch(orderDetails(order));
    navigate("/orders/order-review/document/1");
  };

  return (
    <WizardBasePage
      pageTitle="Review order"
      pageSubTitle="Please check the summary of your current vehicle order below before starting the sign online process."
      wizardContent={
        <Stepper
          steps={steps}
          activeStep={1}
          onClickHandler={() => {}}
          mode="number"
          showCompletionStep="tick"
        />
      }
    >
      <PageHeading align="left">Order summary</PageHeading>
      <p className="mt-3 mb-4">
        We'll take you through a series of screens and documents - please take
        the time to read these. We'll ask you to sign these documents on the
        final page.
      </p>

      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}

      {!isError && order.length <= 0 && <>{ordersErrorMessages[1540401]}</>}

      {(!isError || order.length <= 0) && (
        <>
          <dl className="table-list-row">
            <Row>
              <dt>Order number</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {order?.orderNumber ?? "N/A"}
              </dd>
            </Row>

            <Row>
              <dt>Vehicle description</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {order?.vehicleDescription ?? "N/A"}
              </dd>
            </Row>

            <Row>
              <dt>Product description</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {order?.productDescription ?? "N/A"}
              </dd>
            </Row>

            <Row>
              <dt>Maintenance included</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {typeof order?.maintenanceIncluded == "undefined"
                  ? "N/A"
                  : order?.maintenanceIncluded
                  ? "Yes"
                  : "No"}
              </dd>
            </Row>

            <Row>
              <dt>Term (months)</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {order?.term ?? "N/A"}
              </dd>
            </Row>

            <Row>
              <dt>Miles per annum</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {formatNumber(order?.miles, 0, "N/A")}
              </dd>
            </Row>

            <Row>
              <dt>Total contract mileage</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {formatNumber(order?.totalMileage, 0, "N/A")}
              </dd>
            </Row>

            <Row>
              <dt>Initial rental</dt>

              <dd className={`${magicStrings.maskInformation}`}>
                {formatCurrency(order?.initialRental, "N/A")}
              </dd>
            </Row>

            <Row>
              <dt>Monthly rental</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {formatCurrency(order?.monthlyRental, "N/A")}
              </dd>
            </Row>

            <Row>
              <dt>Excess mileage charge (pence per mile)</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {order?.excessMileageCharge ?? "N/A"}
              </dd>
            </Row>
            {order?.estimatedSalesValue && (
              <Row>
                <dt>Estimate sales value</dt>
                <dd className={`${magicStrings.maskInformation}`}>
                  {formatCurrency(order?.estimatedSalesValue, "N/A")}
                </dd>
              </Row>
            )}
          </dl>

          {!order?.documents?.length && (
            <Alert variant="warning" className="my-4">
              There was a problem processing your documents, this may be because
              your credit application has expired, please contact your Sales
              Introducer.
            </Alert>
          )}
        </>
      )}

      <CenterAlignedButtonContainer>
        <Button
          onClick={onSignAgreement}
          disabled={isError || order?.documents <= 0}
        >
          Sign agreement
        </Button>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default OrderDetailsPage;
