import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import VehicleSelectionForm from "../../../../component/forms/vehicle-selection/VehicleSelectionForm";
import Stepper from "../../../../component/stepper/Stepper";
import {
  useContracts,
  useEarlyTerminationDetails,
} from "../../../../api/hooks/contracts";
import {
  contractDetails,
  nextStep,
} from "../../../../redux/features/early-termination/earlyTerminationSlice";
import steps from "../../steps-data.json";
import { useStepper } from "../../hooks/useStepper";
import PageHeading from "../../../../component/page-heading/PageHeading";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { getSanitisedErrorMessage } from "../../../../api/error-handling/transform-error";

const VehicleSelectionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contracts, isError, errorResponse } = useContracts();
  const [isEarlyTerminationError, setEarlyTerminationError] = useState({});
  const currentStep = 1;

  const activeStep = useSelector((state) => state.earlyTermination.activeStep);

  useStepper({ activeStep, currentStep });
  const onSuccessful = () => {
    dispatch(nextStep());
    navigate("/early-termination/new-quote/quote-details");
  };
  const onFailure = (error) => {
    setEarlyTerminationError(error);
  };

  const { mutate } = useEarlyTerminationDetails(onSuccessful, onFailure);

  const onFormSubmit = (selectedContract) => {
    const contract = contracts.find(
      // eslint-disable-next-line
      (c) => c.contractId == selectedContract.vehicle
    );
    dispatch(contractDetails(contract));

    if (contract.etStatus === "Allowed") {
      mutate({ contractId: contract.contractId });
    } else if (contract.etStatus === "NotAllowed") {
      navigate("/early-termination/not-allowed");
    } else if (contract.etStatus === "ExistingET") {
      dispatch(nextStep());
      navigate("/early-termination/existing-quote/quote-details");
    } else if (contract.etStatus === "NotRequired") {
      navigate("/early-termination/not-required");
    } else if (contract.etStatus === "Referred") {
      navigate("/early-termination/referred");
    }
  };

  return (
    <WizardBasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
      wizardContent={
        <Stepper
          steps={steps}
          activeStep={activeStep}
          onClickHandler={() => {}}
          mode="number"
          showCompletionStep="number"
        />
      }
    >
      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}

      {!isError && isEarlyTerminationError?.localErrorMessage && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(isEarlyTerminationError?.localErrorMessage)}
        </Alert>
      )}

      <PageHeading className="h3" align="center">
        Which vehicle would you like to return early?{" "}
      </PageHeading>
      <p className="text-center">
        Depending on your contract, in some cases you may not be able to return
        a vehicle early. You can find full details in the terms and conditions
        of your agreement.
      </p>

      <CenterAlignedButtonContainer>
        <VehicleSelectionForm
          vehicles={contracts?.map((c) => ({
            id: c.contractId,
            registrationNumber: c.vehicle.registrationNumber,
          }))}
          onFormSubmit={onFormSubmit}
          submitButtonText="Continue"
          buttonSize="md"
        />
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default VehicleSelectionPage;
