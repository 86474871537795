import { useState, Fragment } from "react";
import { useNavigate } from "react-router";
import { Collapse, Alert, Button, Row, Col } from "react-bootstrap";
import BasePage from "../basepages/basepage/BasePage";
import ArrowDownIcon from "../../component/icons/ArrowDownIcon";
import EmptyStateIcon from "../../component/icons/EmptyStateIcon";
import { useTransactions } from "../../api/hooks/transactions";
import { useGetAccountBalance } from "../../api/hooks/account";
import magicStrings from "../../utils/magic-string";
import RoundedPill from "../../component/rounded-pill/RoundedPill";
import { transactionStatusDetails } from "./transaction-data";
import PageHeading from "../../component/page-heading/PageHeading";
import HomeBanner from "../../component/notification-banner/home-banner/HomeBanner";

const TransactionsPage = () => {
  const navigate = useNavigate();
  const [maxTransaction, setMaxTransaction] = useState(10);
  const [openCollapse, setOpenCollapse] = useState();
  const { transactions, isError } = useTransactions();
  const { data: balanceDetails, isError: isAccountBalanceError } =
    useGetAccountBalance();
  const activeTransactions = Array.isArray(transactions)
    ? transactions.filter((active) => active.transactionCategory === "Active")
    : null;
  const historicTransactions = Array.isArray(transactions)
    ? transactions.filter(
        (historic) => historic.transactionCategory === "Historic"
      )
    : null;

  const loadMoreTransaction = () => {
    setMaxTransaction(maxTransaction + 10);
  };

  const expandCollapse = (e) => {
    const elementId = e.target.id.split("-view")[0];
    setOpenCollapse(elementId === openCollapse ? "" : elementId);
  };

  const convertDateFormat = (date) => {
    const transactionDate = new Date(date);
    return (
      String(transactionDate.getDate()).padStart(2, "0") +
      "-" +
      String(transactionDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      transactionDate.getFullYear()
    );
  };

  const twoDecimalDivider = (params) => {
    return Math.abs(params)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const referenceNoValidation = (transaction) => {
    const referenceNumber = transaction.referenceNumber
      ? transaction.referenceNumber
      : "N/A";
    const bgColor = openCollapse === referenceNumber ? "bg-light" : "bg-white";
    const customClass = transaction.status ? "col-sm-7" : "col-sm-7 my-3";
    return { referenceNumber, bgColor, customClass };
  };

  const onPayClicked = () => {
    navigate("/payments/make-a-payment");
  };

  const accordionIcon = (transaction, view = "desktop") => {
    const referenceNo = transaction.referenceNumber;
    return (
      <Button
        id={`${referenceNo}-view-${view}`}
        variant="link"
        className="text-decoration-none text-secondary"
        onClick={(event) => expandCollapse(event)}
      >
        <ArrowDownIcon
          title="Accordion Icon"
          pointerEvents="none"
          className={`collapse-icon-transition ${
            openCollapse === referenceNo ? "collapse-reverse-transition" : ""
          }`}
          size="xs"
        />
      </Button>
    );
  };

  const tooltipOverlay = (statusInfo, mobileView = false) => {
    return (
      <>
        {statusInfo && statusInfo.tooltipContent && (
          <RoundedPill
            variant={statusInfo?.variant}
            toolTip={statusInfo?.tooltipContent}
            isMobileView={mobileView}
          >
            {statusInfo.status}
          </RoundedPill>
        )}
      </>
    );
  };

  const transactionDetails = (transactions, category = "active") => {
    const dataTestId =
      category === "active" ? "activeTransaction" : "historicTransaction";
    return (
      <table className="table">
        <thead>
          <tr className="d-none d-lg-table-row text-start fw-bold border-top">
            <th width="25%" className="text-start py-4">
              Date
            </th>
            <th width="25%" className="py-4">
              Description
            </th>
            <th width="21%" className="py-4">
              Status
            </th>
            <th width="21%" className="py-4">
              Amount
            </th>
            <th width="8%" aria-hidden="true"></th>
          </tr>
          <tr className="d-table-row d-lg-none fw-bold border-top">
            <th className="mt-4 py-3" colSpan="3">
              Date
            </th>
            <th className="text-end py-3" colSpan="2">
              <div className="px-5 mx-3">Amount</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions
            .slice(
              0,
              category === "historic" ? maxTransaction : transactions.length
            )
            .map((transaction, index) => {
              const statusInfo = transactionStatusDetails(transaction.status);
              const transactionDate = convertDateFormat(
                transaction.transactionDate
              );
              const dueDate = transaction.dueDate
                ? convertDateFormat(transaction.dueDate)
                : "N/A";
              const totalAmount = twoDecimalDivider(transaction.totalAmount);
              const outstandingBalance = twoDecimalDivider(
                transaction.outstandingBalance
              );
              const { referenceNumber, bgColor, customClass } =
                referenceNoValidation(transaction);

              return (
                <Fragment key={index}>
                  <tr className="d-table-row d-lg-none">
                    <td colSpan="5" className={`py-3 ${bgColor}`}>
                      <div className="d-flex justify-content-between">
                        <div className={`${magicStrings.maskInformation}`}>
                          {transactionDate}
                        </div>
                        <div className="text-end">
                          <div
                            className={`px-5 pound d-inline-block ${magicStrings.maskInformation}`}
                          >
                            {totalAmount}
                          </div>
                          <div className="d-inline-block">
                            {accordionIcon(transaction, "mobile")}
                          </div>
                        </div>
                      </div>
                      <Collapse in={openCollapse === referenceNumber}>
                        <dl className="py-1 my-0 row">
                          <dt className="col-sm-5">Description</dt>
                          <dd
                            className={`col-sm-7 ${magicStrings.maskInformation}`}
                          >
                            {transaction.description}
                          </dd>
                          <dt className="col-sm-5">Status</dt>
                          <dd className={customClass}>
                            {tooltipOverlay(statusInfo, true)}
                          </dd>
                          <dt className="col-sm-5">Transaction type</dt>
                          <dd
                            className={`col-sm-7 ${magicStrings.maskInformation}`}
                          >
                            {transaction.type}
                          </dd>
                          <dt className="col-sm-5">Outstanding amount</dt>
                          <dd
                            className={`col-sm-7 pound ${magicStrings.maskInformation}`}
                          >
                            {outstandingBalance}
                          </dd>
                          <dt className="col-sm-5">Due date</dt>
                          <dd
                            className={`col-sm-7 ${magicStrings.maskInformation}`}
                          >
                            {dueDate}
                          </dd>
                          <dt className="col-sm-5">Reference</dt>
                          <dd
                            className={`col-sm-7 ${magicStrings.maskInformation}`}
                          >
                            {referenceNumber}
                          </dd>
                        </dl>
                      </Collapse>
                    </td>
                  </tr>
                  <tr
                    data-testid={dataTestId}
                    className={`d-none d-lg-table-row text-start align-middle`}
                  >
                    <td
                      className={`py-4 ${bgColor}  ${
                        openCollapse === referenceNumber ? "border-0" : ""
                      }`}
                    >
                      <div className={`${magicStrings.maskInformation}`}>
                        {transactionDate}
                      </div>
                    </td>
                    <td
                      className={`${bgColor} ${
                        openCollapse === referenceNumber ? "border-0" : ""
                      }`}
                    >
                      <div className={`${magicStrings.maskInformation}`}>
                        {transaction.description}
                      </div>
                    </td>
                    <td
                      className={`${bgColor} ${
                        openCollapse === referenceNumber ? "border-0" : ""
                      }`}
                    >
                      <div className={transaction.status ? "w-75" : "p-3"}>
                        {tooltipOverlay(statusInfo)}
                      </div>
                    </td>
                    <td
                      className={`${bgColor} ${
                        openCollapse === referenceNumber ? "border-0" : ""
                      }`}
                    >
                      <div className={`pound ${magicStrings.maskInformation}`}>
                        {totalAmount}
                      </div>
                    </td>
                    <td
                      className={`align-top py-4 ${bgColor} ${
                        openCollapse === referenceNumber ? "border-0" : ""
                      }`}
                    >
                      {accordionIcon(transaction)}
                    </td>
                  </tr>
                  <tr className="d-none d-lg-table-row">
                    <td
                      colSpan={5}
                      className={`py-0 px-2  ${bgColor} ${
                        openCollapse === referenceNumber ? "" : "border-0"
                      }`}
                    >
                      <Collapse in={openCollapse === referenceNumber}>
                        <Row>
                          <Col md={3}>
                            <dl className="mt-4">
                              <dt className="small">Transaction type</dt>
                              <dd className={`${magicStrings.maskInformation}`}>
                                {transaction.type}
                              </dd>
                            </dl>
                          </Col>
                          <Col md={3}>
                            <dl className="mt-4">
                              <dt className="small">Outstanding amount</dt>
                              <dd
                                className={`pound ${magicStrings.maskInformation}`}
                              >
                                {outstandingBalance}
                              </dd>
                            </dl>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <Col md={5}>
                                <dl className="mt-4">
                                  <dt className="small">Due date</dt>
                                  <dd
                                    className={`${magicStrings.maskInformation}`}
                                  >
                                    {dueDate}
                                  </dd>
                                </dl>
                              </Col>
                              <Col md={5}>
                                <dl className="mt-4">
                                  <dt className="small">Reference</dt>
                                  <dd
                                    className={`${magicStrings.maskInformation}`}
                                  >
                                    {referenceNumber}
                                  </dd>
                                </dl>
                              </Col>
                              <Col md={2}></Col>
                            </Row>
                          </Col>
                        </Row>
                      </Collapse>
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          {category === "historic" && maxTransaction < transactions.length && (
            <tr>
              <td colSpan="5">
                <div className="mx-auto text-center">
                  <Button
                    id="btnLoadMore"
                    variant="link"
                    className="text-decoration-none text-primary"
                    onClick={loadMoreTransaction}
                  >
                    <h2 className="h4 p-0 m-0">Load more</h2>
                    <ArrowDownIcon title="Load more" size="xs" />
                  </Button>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <BasePage
      pageTitle="Transaction history"
      pageSubTitle="Find your invoices and track your payments."
    >
      {!isAccountBalanceError &&
        balanceDetails &&
        (balanceDetails.paymentMethod || balanceDetails.balanceStatus) &&
        !(
          balanceDetails.balanceStatus === "NotDue" ||
          balanceDetails.paymentMethod === "SpecialDirectDebit" ||
          balanceDetails.paymentMethod === "Litigation"
        ) && (
          <div className="mb-5">
            <HomeBanner
              balanceDetails={balanceDetails}
              onPayClicked={onPayClicked}
              isHomePage={false}
            />
          </div>
        )}
      {isError && (
        <Alert variant="danger">
          We are sorry but we are unable to retrieve your transactions. Please
          try again and if the problem continues contact us.
        </Alert>
      )}
      {(activeTransactions && activeTransactions.length !== 0) ||
      (historicTransactions && historicTransactions.length !== 0) ? (
        <>
          <PageHeading align="left">Transaction history</PageHeading>
          <p>Find your invoices and track your payments.</p>
        </>
      ) : (
        ""
      )}
      {activeTransactions && activeTransactions.length > 0 ? (
        <>
          <h2 className="mt-5 text-primary">Active transactions</h2>
          {transactionDetails(activeTransactions)}
        </>
      ) : (
        ""
      )}
      {historicTransactions && historicTransactions.length > 0 ? (
        <>
          <h2 className="mt-5 text-primary">Historical transactions</h2>
          {transactionDetails(historicTransactions, "historic")}
        </>
      ) : (
        ""
      )}
      {(activeTransactions && activeTransactions.length !== 0) ||
      (historicTransactions && historicTransactions.length !== 0) ? (
        <>
          <h2 className="text-primary mt-5">Transaction status</h2>
          <p>
            Here is some information to help you understand the status of your
            transactions:
          </p>
          <p className="mb-1">
            <span className="fw-bold">Due:</span> This payment is due now
          </p>
          <p className="mb-1">
            <span className="fw-bold">Overdue:</span> This payment is now
            overdue as the invoice was raised over a month ago
          </p>
          <p className="mb-1">
            <span className="fw-bold">Paid:</span> You've made this payment
          </p>
          <p className="mb-1">
            <span className="fw-bold">Dispute:</span> You disputed this payment,
            so it's on hold while we look into it
          </p>
          <p className="mb-1">
            <span className="fw-bold">Pay Hol:</span> This payment is on hold
            while you take a payment holiday
          </p>
          <p className="mb-1">
            <span className="fw-bold">Pending:</span> Your Direct Debit for this
            payment is scheduled for collection
          </p>
          <p className="mb-1">
            <span className="fw-bold">Pay Plan:</span> We've agreed a payment
            plan with you and have sent a letter explaining how it works
          </p>
          <p className="mb-1">
            <span className="fw-bold">Credit:</span> You've made this payment
          </p>
        </>
      ) : (
        <EmptyStateIcon
          title="No transaction"
          textContent="You currently have no transactions."
        />
      )}
    </BasePage>
  );
};

export default TransactionsPage;
