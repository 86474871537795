import { ErrorBoundary } from "react-error-boundary";

const ErrorBoundaryFallBack = ({ error }) => {
  // we are using inline styles as errors may occur before styles/classes have been loaded
  const centerStyle = {
    margin: "4rem 2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  };
  return (
    <div role="alert" style={centerStyle}>
      <h2 style={{ marginBottom: "1rem" }}>
        We can't process your request at the moment
      </h2>
      <p>
        Please try again in a few minutes. If you find the same issue, please
        call us and our customer support team will help you.
      </p>
      <p>
        You can call us on <a href="tel:0344 879 6633">0344 879 6633</a>. Lines
        are open Monday - Friday between 9am - 5.30pm. We are closed weekends
        and bank holidays.
      </p>
      <p style={{ display: "none" }}>{error?.message}</p>

      <a href="/">Home</a>
    </div>
  );
};

const ErrorBoundaryProvider = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallBack}>
      {props.children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryProvider;
