import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { VerifyDigitsField } from "../fields";
import * as fieldSchemas from "../schemas/fields";

const IdVerificationForm = ({ onFormSubmit }) => {
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.verifyDigitsSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    onFormSubmit(
      data?.verifyDigit1.toString() +
        data?.verifyDigit2.toString() +
        data?.verifyDigit3.toString() +
        data?.verifyDigit4.toString()
    );
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="IdVerificationForm"
      >
        <VerifyDigitsField />
        <div className="d-grid gap-2">
          <Button
            type="submit"
            className="mt-3"
            id="btnIdVerificationFormSubmit"
          >
            Submit answer
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

IdVerificationForm.propTypes = {
  onFormSubmit: PropTypes.func,
};
export default IdVerificationForm;
