import { Link, useLocation } from "react-router-dom";

import BasePage from "../../basepages/basepage/BasePage";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";

const CustomerServicesPage = () => {
  useContractAmendsStepCheck(steps.customerServices, "../save-quote");
  const errorCode = useLocation().state?.error.localCode;
  return (
    <BasePage pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <div className="text-center">
        {errorCode === 1140002 && (
          <>
            <TitleWithIcon
              title="Sorry - we can't make this change"
              variant="failure"
            />

            <p className="mt-5">
              The contract amend you have requested would take you 60 months
              over the vehicle registration date. You can select a new term or
              read our FAQs about informal extension.
            </p>
          </>
        )}

        {errorCode === 1140003 && (
          <>
            <TitleWithIcon
              title="Sorry - we can't make this change"
              variant="failure"
            />

            <p className="mt-5">
              We can't change your contract because you have reached the limit
              for maintenance to your car. You can still see out the rest of
              your agreed term.
            </p>
          </>
        )}

        {(errorCode === 1140001 ||
          (errorCode !== 1140002 && errorCode !== 1140003)) && (
          <>
            <TitleWithIcon title="We need to talk to you" variant="failure" />
            <p className="mt-5">
              We aren't able to complete your contract amendment request online
              at this time.
              <br />
              Please call us on{" "}
              <Link to="tel:0344 879 6633" target="_blank">
                0344 879 6633
              </Link>
              .
            </p>
            <p>
              Phone lines are open Monday - Friday between 9.00am - 5.30pm.
              We're closed on weekends and bank holidays.
            </p>
          </>
        )}
      </div>
      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default CustomerServicesPage;
