import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const BackToHomeButton = ({
  children = "Home",
  variant = "outline-primary",
  onButtonClicked,
}) => {
  return (
    <Button
      data-testid="back-to-home-button"
      as={Link}
      to="/home"
      id="btnBackToHome"
      variant={variant}
      className="text-decoration-none"
      onClick={() => {
        if (typeof onButtonClicked === "function") {
          onButtonClicked();
        }
      }}
    >
      {children}
    </Button>
  );
};
BackToHomeButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  variant: PropTypes.oneOf(["primary", "outline-primary"]),
  onButtonClicked: PropTypes.func,
};
export default BackToHomeButton;
