import { useQuery, useMutation } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";
import queryClient from "../react-query/query-client";
import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import apiKeys from "../error-handling/api-keys";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";
import { DateTime } from "luxon";

const getCollectionSlots = async (axios, type, date, months) => {
  return axios
    .get(
      `/vehicle/collections/slots?type=${type}&date=${date}&months=${months}`,
      {
        headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
      }
    )
    .then((res) => res.data);
};

const submitVehicleCollection = async (axios, contract) => {
  const { data } = await axios.post(
    `/contracts/${contract.contractId}/collections`,
    contract.collectionData,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
    }
  );
  return data;
};

const updateVehicleCollection = async (
  axios,
  contractId,
  triggerId,
  payload
) => {
  const { data } = await axios.put(
    `/contracts/${contractId}/collections/${triggerId}`,
    payload,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
    }
  );
  return data;
};

const cancelCollection = async (axios, contractId) => {
  const { data } = await axios.delete(`/contracts/${contractId}/collections`, {
    headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
  });
  return data;
};

const useUnavailableSlots = (date) => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(
    queryKeys.vehicleCollection.availableSlots(date),
    () => getCollectionSlots(axios, "Runner", date, 3),
    {
      select: (data) => {
        const slotsToReturn = [];
        const startDate = DateTime.fromFormat(date, "yyyy-MM-dd");
        for (let i = 0; i < 180; i++) {
          const dateToExclude = startDate.plus({ days: i });
          if (Array.isArray(data)) {
            const anySlotsFound = data.find((d) => {
              if (d.availableCapacity > 0) {
                const dateToMatch = DateTime.fromISO(d.date);
                if (
                  dateToMatch.year === dateToExclude.year &&
                  dateToMatch.month === dateToExclude.month &&
                  dateToMatch.day === dateToExclude.day
                ) {
                  return true;
                }
              }
              return false;
            });
            if (!anySlotsFound) {
              slotsToReturn.push({ date: dateToExclude.toISO() });
            }
          }
        }

        return slotsToReturn;
      },
    }
  );

  return {
    slots: data,
    isError,
    errorResponse: error?.response?.data,
  };
};

const useSubmitVehicleCollections = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((contract) => submitVehicleCollection(axios, contract), {
    onSuccess: (data) => {
      queryClient.resetQueries(queryKeys.contracts);
      onSuccess(data);
    },
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

const useUpdateVehicleCollections = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    ({ contractId, triggerId, payload }) =>
      updateVehicleCollection(axios, contractId, triggerId, payload),
    {
      onSuccess: (data) => {
        queryClient.resetQueries(queryKeys.contracts);
        onSuccess(data);
      },
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

const useCancelCollection = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((contractId) => cancelCollection(axios, contractId), {
    onSuccess: () => {
      queryClient.resetQueries(queryKeys.contracts);
      onSuccess();
    },
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

export {
  useUnavailableSlots,
  useSubmitVehicleCollections,
  useCancelCollection,
  useUpdateVehicleCollections,
};
