import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Col, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import BasePage from "../../../basepages/basepage/BasePage";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import steps from "../../vehicle-collection-steps";
import { formatDate } from "../../../../utils/pages/date-format-utils";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import EditIcon from "../../../../component/icons/EditIcon";
import {
  step,
  collectionDate,
} from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import { useUpdateVehicleCollections } from "../../../../api/hooks/vehicle-collection";
import magicStrings from "../../../../utils/magic-string";

const CollectionSummaryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCollectionDateModified, setIsCollectionDateModified] =
    useState(false);

  useVehicleCollectionStepCheck(steps.existingBookings.summary, "../");

  const contractDetails = useSelector(
    (state) => state.vehicleCollection.contractDetails
  );
  const updatedCollectionDetails = useSelector(
    (state) => state.vehicleCollection.updatedCollectionDetails
  );
  const selectedCollection = updatedCollectionDetails
    ? updatedCollectionDetails
    : contractDetails?.latestCollection;

  const newCollectionDate = useSelector(
    (state) => state.vehicleCollection.collectionDate
  );

  const onEditDate = () => {
    if (contractDetails && contractDetails.canAmendCollection) {
      dispatch(step(steps.existingBookings.editDate));
      dispatch(collectionDate(selectedCollection.collectionDate));
      navigate("../edit-date");
    }
  };

  const onEditContactAndCollectionDetails = () => {
    if (contractDetails && contractDetails.canAmendCollection) {
      dispatch(step(steps.existingBookings.editDetails));
      navigate("../edit-details");
    }
  };

  const onCancelCollection = () => {
    dispatch(step(steps.existingBookings.cancel));
    navigate("../cancel-collection");
  };

  const schema = yup.object().shape({
    areTermsAccepted: yup.bool().oneOf([true], "Terms must be accepted."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (!isCollectionDateModified && selectedCollection && newCollectionDate) {
      const currentDate = DateTime.fromISO(selectedCollection.collectionDate);
      const newSelectedDate = DateTime.fromISO(newCollectionDate);
      if (currentDate.isValid && newSelectedDate.isValid) {
        if (
          currentDate.year !== newSelectedDate.year ||
          currentDate.month !== newSelectedDate.month ||
          currentDate.day !== newSelectedDate.day
        ) {
          setIsCollectionDateModified(true);
        }
      }
    }
  }, [isCollectionDateModified, newCollectionDate, selectedCollection]);

  const onUpdateSuccessful = () => {
    navigate("/vehicle-collection/success");
  };

  const onUpdateFailure = (error) => {
    if (error.localCode === 1040002) {
      navigate("/vehicle-collection/amendment-unavailable");
    } else {
      navigate("/vehicle-collection/failure", { state: { error } });
    }
  };

  const { mutate } = useUpdateVehicleCollections(
    onUpdateSuccessful,
    onUpdateFailure
  );

  const onSubmitRequest = () => {
    let collectionData = {
      collectionDate: isCollectionDateModified
        ? newCollectionDate
        : selectedCollection.collectionDate,
      contactName: selectedCollection.contactName,
      contactEmail: selectedCollection.contactEmail,
      contactMobileNumber: selectedCollection.contactMobileNumber,
      contactWorkNumber: selectedCollection.contactWorkNumber,
      contactHomeNumber: selectedCollection.contactHomeNumber,
      addressLine1: selectedCollection.addressLine1,
      addressLine2: selectedCollection.addressLine2,
      addressLine3: selectedCollection.addressLine3,
      addressLine4: selectedCollection.addressLine4,
      addressLine5: selectedCollection.addressLine5,
      postcode: selectedCollection.postcode,
      collectionNotes: "",
    };

    mutate({
      contractId: contractDetails.contractId,
      triggerId: selectedCollection.triggerId,
      payload: collectionData,
    });
  };

  return (
    <BasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
    >
      <p className="display-6 text-center mb-5">
        {updatedCollectionDetails || isCollectionDateModified
          ? "New booking details"
          : "Collection summary"}
      </p>
      {contractDetails && !contractDetails.canAmendCollection && (
        <>
          <p>
            We're due to collect this vehicle in the next 48 hours, so if you
            make any changes to the arrangements you may be charged a fee.
          </p>
          <p>
            If you do need to make changes, please call us on{" "}
            <Link to="tel: 0344 879 6633"> 0344 879 6633</Link>. Lines are open
            Monday - Friday between 9am - 5pm. We're closed on weekends and bank
            holidays.
          </p>
        </>
      )}

      <dl className="row">
        <dt className="col-sm-4">Vehicle registration:</dt>
        <dd className="col-sm-8 text-sm-end">
          <div className={`${magicStrings.maskInformation}`}>
            {contractDetails?.vehicle?.registrationNumber}
          </div>
          <div className={`${magicStrings.maskInformation}`}>
            {contractDetails?.vehicle?.make}
          </div>
          <div className={`${magicStrings.maskInformation}`}>
            {contractDetails?.vehicle?.model}
          </div>
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
        <dt className="col-sm-6 h3 text-primary mb-4">
          Contact and collection details
        </dt>
        <dd className="col-sm-6 text-sm-end">
          <div
            onClick={onEditContactAndCollectionDetails}
            aria-disabled={
              !contractDetails || !contractDetails.canAmendCollection
            }
            style={{
              cursor:
                contractDetails && contractDetails.canAmendCollection
                  ? "pointer"
                  : "pe-none",
            }}
          >
            <EditIcon
              title="Edit contact and collection details"
              opacity={
                contractDetails && contractDetails.canAmendCollection ? 1 : 0.7
              }
              className="text-primary"
              size="md"
            />
          </div>
        </dd>

        <dt className="col-sm-4 mb-sm-0 mb-md-3">Contact name:</dt>
        <dd
          className={`col-sm-8 text-sm-end mb-3 text-break ${magicStrings.maskInformation}`}
        >
          {selectedCollection?.contactName}
        </dd>
        {selectedCollection?.contactMobileNumber && (
          <>
            <dt className="col-sm-4 mb-sm-0 mb-md-3">Mobile number:</dt>
            <dd
              className={`col-sm-8 text-sm-end mb-3 ${magicStrings.maskInformation}`}
            >
              {selectedCollection.contactMobileNumber}
            </dd>
          </>
        )}
        {selectedCollection?.contactHomeNumber && (
          <>
            <dt className="col-sm-4 mb-sm-0 mb-md-3">Home number:</dt>
            <dd
              className={`col-sm-8 text-sm-end mb-3 ${magicStrings.maskInformation}`}
            >
              {selectedCollection.contactHomeNumber}
            </dd>
          </>
        )}
        {selectedCollection?.contactWorkNumber && (
          <>
            <dt className="col-sm-4 mb-sm-0 mb-md-3">Work number:</dt>
            <dd
              className={`col-sm-8 text-sm-end mb-3 ${magicStrings.maskInformation}`}
            >
              {selectedCollection.contactWorkNumber}
            </dd>
          </>
        )}

        <dt className="col-sm-4 mb-sm-0 mb-md-3">Contact email:</dt>
        <dd
          className={`col-sm-8 text-sm-end mb-3 text-break ${magicStrings.maskInformation}`}
        >
          {selectedCollection?.contactEmail}
        </dd>

        <dt className="col-sm-4">Collection address:</dt>
        <dd className="col-sm-8 text-sm-end  text-break">
          {selectedCollection?.addressLine1 && (
            <div className={`${magicStrings.maskInformation}`}>
              {selectedCollection.addressLine1}
            </div>
          )}
          {selectedCollection?.addressLine2 && (
            <div className={`${magicStrings.maskInformation}`}>
              {selectedCollection.addressLine2}
            </div>
          )}
          {selectedCollection?.addressLine3 && (
            <div className={`${magicStrings.maskInformation}`}>
              {selectedCollection.addressLine3}
            </div>
          )}
          {selectedCollection?.addressLine4 && (
            <div className={`${magicStrings.maskInformation}`}>
              {selectedCollection.addressLine4}
            </div>
          )}
          {selectedCollection?.addressLine5 && (
            <div className={`${magicStrings.maskInformation}`}>
              {selectedCollection.addressLine5}
            </div>
          )}
          {selectedCollection?.postCode && (
            <div className={`${magicStrings.maskInformation}`}>
              {selectedCollection.postCode}
            </div>
          )}
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
        <dt className="col-sm-6 h3 text-primary mb-4">Collection date</dt>
        <dd className="col-sm-6 text-sm-end">
          <div
            onClick={onEditDate}
            aria-disabled={
              !contractDetails || !contractDetails.canAmendCollection
            }
            style={{
              cursor:
                contractDetails && contractDetails.canAmendCollection
                  ? "pointer"
                  : "pe-none",
            }}
          >
            <EditIcon
              title="Edit collection date"
              opacity={
                contractDetails && contractDetails.canAmendCollection ? 1 : 0.7
              }
              className="text-primary"
              size="md"
            />
          </div>
        </dd>

        <dt className="col-sm-4 mb-sm-0 mb-md-3">Collection date:</dt>
        <dd className={`col-sm-8 text-sm-end ${magicStrings.maskInformation}`}>
          {isCollectionDateModified
            ? formatDate(newCollectionDate)
            : formatDate(selectedCollection?.collectionDate)}
        </dd>
        <Col xs="12" role="presentation">
          <hr />
        </Col>
      </dl>
      <p className="mb-5">
        <strong>
          It is your responsibility to make sure the vehicle is safe to drive at
          the time of collection and to let us know about any changes at least 2
          working days before your booking date.
        </strong>
      </p>

      {!updatedCollectionDetails && !isCollectionDateModified && (
        <CenterAlignedButtonContainer>
          <Button
            onClick={onCancelCollection}
            variant="outline-primary"
            id="btnCancelCollection"
            disabled={!contractDetails || !contractDetails.canAmendCollection}
          >
            Cancel collection
          </Button>
          <BackToHomeButton />
        </CenterAlignedButtonContainer>
      )}

      {(updatedCollectionDetails || isCollectionDateModified) && (
        <Form
          onSubmit={handleSubmit(onSubmitRequest)}
          noValidate
          name="collectionSummaryForm"
        >
          <div className="d-flex flex-column gap-3 col-12 col-sm-8 col-lg-6 col-xl-5 mx-auto">
            <Form.Group className="mb-3 me-3" controlId="acceptTerms">
              <Form.Check
                type="checkbox"
                label="I accept and understand the statement."
                {...register("areTermsAccepted")}
                isInvalid={errors.areTermsAccepted}
              ></Form.Check>
              <Form.Control.Feedback
                type="invalid"
                className={errors.areTermsAccepted ? "d-block" : "d-none"}
              >
                {errors.areTermsAccepted?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button className="px-5" type="submit" id="btnFormSubmit">
                Submit changes
              </Button>
            </div>
            <BackToHomeButton />
          </div>
        </Form>
      )}
    </BasePage>
  );
};

export default CollectionSummaryPage;
