export const employmentStatuses = [
  { id: 1, value: 1, text: "Employed (full time permanent)" },
  { id: 2, value: 2, text: "Employed (part time permanent)" },
  { id: 9, value: 9, text: "Temporary employment" },
  { id: 3, value: 3, text: "Retired" },
  { id: 8, value: 8, text: "Home maker" },
  { id: 4, value: 4, text: "Student" },
  { id: 5, value: 5, text: "Unemployed" },
];

export const yesNoOptions = [
  { id: 1, value: 1, text: "Yes" },
  { id: 2, value: 2, text: "No" },
];

export const titles = [
  {
    id: 1,
    value: 1,
    text: "Mr",
  },
  {
    id: 2,
    value: 2,
    text: "Sir",
  },
  {
    id: 3,
    value: 3,
    text: "Mrs",
  },
  {
    id: 4,
    value: 4,
    text: "Rev",
  },
  {
    id: 5,
    value: 5,
    text: "Dr",
  },
  {
    id: 6,
    value: 6,
    text: "Ms",
  },
  {
    id: 7,
    value: 7,
    text: "Miss",
  },
];

export const maritalStatuses = [
  { id: 1, value: 1, text: "Cohabitating" },
  { id: 2, value: 2, text: "Divorced" },
  { id: 3, value: 3, text: "Married" },
  { id: 4, value: 4, text: "Other" },
  { id: 5, value: 5, text: "Separated" },
  { id: 6, value: 6, text: "Single" },
  { id: 7, value: 7, text: "Unknown" },
  { id: 8, value: 8, text: "Widowed" },
];

export const accommodations = [
  { id: 1, value: 1, text: "Living with parents" },
  { id: 2, value: 2, text: "Living with partner" },
  { id: 3, value: 3, text: "Owned outright" },
  { id: 4, value: 4, text: "Owned with mortgage" },
  { id: 5, value: 5, text: "Rented" },
];
