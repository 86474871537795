import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../contract-amends-stepper/ContractAmendsStepper";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import {
  step,
  piEmploymentDetails,
  reset,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import EmploymentDetailsForm from "../../../component/forms/contract-amendment/EmploymentDetailsForm";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import PageHeading from "../../../component/page-heading/PageHeading";

const EmploymentDetailsPage = () => {
  useContractAmendsStepCheck(
    steps.newQuote.employmentDetails,
    "../address-details"
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const employmentDetails = useSelector(
    (state) => state.contractAmends.privateIndividual.employmentDetails
  );

  const onFormSubmit = (data) => {
    dispatch(piEmploymentDetails(data));
    dispatch(step(steps.newQuote.financialDetails));
    navigate("../financial-details");
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Employment details</PageHeading>
          <p>
            Let us know your current employment status. If you have more than
            one job, please list your main source of income.
          </p>
        </div>
      </CenterAlignedContainer>
      <CenterAlignedButtonContainer>
        <EmploymentDetailsForm
          onFormSubmit={onFormSubmit}
          defaultValues={employmentDetails}
        />
        <BackToHomeButton onButtonClicked={() => dispatch(reset())} />
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default EmploymentDetailsPage;
