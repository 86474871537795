import * as yup from "yup";
import { DateTime } from "luxon";

// Personal Details Fields Schema

export const titleSchema = {
  title: yup.string().required("Title is required."),
};

export const titleSelectSchema = {
  title: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .min(1, "Select a valid title option.")
    .max(7, "Select a valid title option.")
    .required("Title is required."),
};

export const firstNameSchema = {
  firstName: yup.string().required("First name is required."),
};

export const lastNameSchema = {
  lastName: yup.string().required("Last name is required."),
};

export const dobSchema = {
  dobDay: yup
    .string()
    .required("Enter a valid date of birth.")
    .matches(/^[0-9]{1,2}$/, "Enter a valid date of birth."),
  dobMonth: yup
    .string()
    .required("Enter a valid date of birth.")
    .matches(/^[0-9]{1,2}$/, "Enter a valid date of birth."),
  dobYear: yup
    .string()
    .required("Enter a valid date of birth.")
    .matches(/^[0-9]{4}$/, "Enter a valid date of birth."),
  dateOfBirth: yup.string().test({
    name: "dateOfBirth",
    exclusive: false,
    params: {},
    message: "Enter a valid date of birth.",
    test: function () {
      const d = this.parent.dobDay;
      const m = this.parent.dobMonth;
      const y = this.parent.dobYear;
      if (!isNaN(d) && !isNaN(m) && !isNaN(y) && d > 0 && m > 0 && y > 1899) {
        const parsedDate = DateTime.utc(parseInt(y), parseInt(m), parseInt(d));
        if (parsedDate.isValid) {
          if (parsedDate < DateTime.now()) {
            return true;
          }
        }
      }
      return false;
    },
  }),
};

export const maritalStatusSchema = {
  maritalStatus: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .min(1, "Select a valid marital status.")
    .max(8, "Select a valid marital status.")
    .required("Select a marital status option."),
};

export const numOfDependantsSchema = {
  numOfDependants: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .min(0, "Enter a valid value for number of dependants.")
    .max(99, "Enter a valid value for number of dependants.")
    .integer("Enter a valid value for number of dependants.")
    .required("Number of dependants is required."),
};

export const accommodationSchema = {
  accommodation: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .min(1, "Select a valid accommodation option.")
    .max(5, "Select a valid accommodation option.")
    .required("Select an accommodation option."),
};

const phoneNumberMinLength = (numberType, minLength) => {
  return {
    name: "minPhoneLength",
    exclusive: true,
    params: { numberType, minLength },
    message:
      // Using [[MINLENGTH]] placeholder as an indicator that this is minlengh error.
      // Do not remove the [[MINLENGTH]] placeholder as it is removed when displaying message.
      `[[MINLENGTH]]Please enter a valid ${numberType}, minimum ${minLength} digits.`,
    test: (value) =>
      value == null || value.length === 0 || value.length >= minLength,
  };
};

export const phoneNumbersSchema = {
  mobileNumber: yup
    .string()
    .when(["workNumber", "homeNumber"], ([workNumber, homeNumber], schema) => {
      return !workNumber && !homeNumber
        ? schema.required().test(phoneNumberMinLength("mobile number", 11))
        : schema.notRequired().test(phoneNumberMinLength("mobile number", 11));
    }),
  workNumber: yup
    .string()
    .when(
      ["mobileNumber", "homeNumber"],
      ([mobileNumber, homeNumber], schema) => {
        return !mobileNumber && !homeNumber
          ? schema.required().test(phoneNumberMinLength("work number", 11))
          : schema.notRequired().test(phoneNumberMinLength("work number", 11));
      }
    ),
  homeNumber: yup
    .string()
    .when(
      ["mobileNumber", "workNumber"],
      ([mobileNumber, workNumber], schema) => {
        return !mobileNumber && !workNumber
          ? schema
              .required(
                "Please enter at least one valid mobile number, work number or home number."
              )
              .test(phoneNumberMinLength("home number", 11))
          : schema.notRequired().test(phoneNumberMinLength("home number", 11));
      }
    ),
};

export const phoneNumbersSchemaDependencies = [
  ["mobileNumber", "homeNumber"],
  ["mobileNumber", "workNumber"],
  ["homeNumber", "workNumber"],
];

export const nationalitySchema = {
  nationality: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .required("Nationality is required."),
};

export const emailAddressSchema = {
  emailAddress: yup
    .string()
    .required("Email address is required.")
    .matches(
      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "A valid email is required."
    ),
};
