import { Button, Form } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import addressSchema from "../../../schemas/address-schema";
import AddressLookup from "../../../address-lookup/AddressLookup";
import {
  FirstNameField,
  LastNameField,
  MobileNumberField,
} from "../../../fields";
import {
  useAddressByLineId,
  useAddressesByPostcode,
} from "../../../../../api/hooks/addresses";
import { firstNameSchema, lastNameSchema } from "../../../schemas/fields";

const PayeeDetailsForm = ({ onFormSubmit, children }) => {
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        mobileNumber: yup
          .string()
          .matches(/^(|\d)+$/, "Please enter a valid mobile number."),
        ...addressSchema,
        ...firstNameSchema,
        ...lastNameSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { watch, handleSubmit } = methods;

  const watchSelectAddress = watch("selectAddress");
  const {
    refetch,
    addresses,
    isSuccess: isAddressesByPostcodeSuccess,
  } = useAddressesByPostcode(watch("postCode"));
  const { address } = useAddressByLineId(watchSelectAddress);

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="captureAddressForm"
      >
        <FirstNameField />
        <LastNameField />
        <MobileNumberField />
        <AddressLookup
          addresses={addresses}
          refetchAddresses={refetch}
          addressDetails={address}
          isAddressFetchSucessful={isAddressesByPostcodeSuccess}
        />
        {children}
        <div className="d-grid gap-2">
          <Button type="submit">Continue</Button>
        </div>
      </Form>
    </FormProvider>
  );
};

export default PayeeDetailsForm;
