import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const CardIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    aria-label={title}
    viewBox="0 0 34 28"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="card-icon_svg__a"
        d="M33.333 6.667H6.667A3.308 3.308 0 0 0 3.35 10l-.017 20a3.322 3.322 0 0 0 3.334 3.333h26.666A3.322 3.322 0 0 0 36.667 30V10a3.322 3.322 0 0 0-3.334-3.333Zm0 23.333H6.667V20h26.666v10Zm0-16.667H6.667V10h26.666v3.333Z"
      />
    </defs>
    <use
      xlinkHref="#card-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-3 -6)"
    />
  </svg>
);

CardIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { CardIcon };
export default CardIcon;
