import * as yup from "yup";
import addressSchema from "../../schemas/address-schema";
import * as fieldSchemas from "../../schemas/fields";

const DirectDebitFormSchema = yup.object().shape({
  accountName: yup
    .string()
    .required("Please enter the name on the account.")
    .matches(
      /^[a-zA-Z0-9\-.,/()'\s]+$/,
      "An account name can only accept the following characters: a-z A-Z 0-9 / . , - ' ( )"
    ),
  accountNumber: yup
    .string()
    .required("Please enter your account number.")
    .matches(/^[0-9]{8}$/, "Account number should be 8 digit number."),
  sortCode: yup
    .string()
    .required("Please enter your sort code.")
    .matches(/^[0-9]{6}$/, "Sort code should be 6 digit number."),
  isBankDetailNotFound: yup.bool(),
  bankName: yup
    .string()
    .when(["isBankDetailNotFound"], ([isBankDetailNotFound], schema) => {
      return isBankDetailNotFound === true
        ? schema
            .required("Please enter valid bank name.")
            .matches(
              /^[a-zA-Z0-9\-.,/()'\s]+$/,
              "Please enter valid bank name."
            )
        : schema.notRequired();
    }),
  branchName: yup
    .string()
    .when(["isBankDetailNotFound"], ([isBankDetailNotFound], schema) => {
      return isBankDetailNotFound === true
        ? schema
            .required("Please enter valid branch name.")
            .matches(
              /^[a-zA-Z0-9\-.,/()'\s]+$/,
              "Please enter valid branch name."
            )
        : schema.notRequired();
    }),
  ...addressSchema,
  postCode: yup
    .string()
    .when(["isBankDetailNotFound"], ([isBankDetailNotFound], schema) => {
      return isBankDetailNotFound === true
        ? fieldSchemas.postCodeSchema.postCode
        : schema.notRequired();
    }),
  selectAddress: yup
    .string()
    .when(
      ["isBankDetailNotFound", "isAddressManuallyEntered"],
      ([isBankDetailNotFound, isAddressManuallyEntered], schema) => {
        return isBankDetailNotFound === true && !isAddressManuallyEntered
          ? fieldSchemas.selectAddressSchema.selectAddress
          : schema.notRequired();
      }
    ),
  authorisedSignatory: yup.bool().oneOf([true], "Terms must be accepted."),
  signature: yup.bool().oneOf([true], "Terms must be accepted."),
  authoriseDebit: yup.bool().oneOf([true], "Terms must be accepted."),
});

export default DirectDebitFormSchema;
