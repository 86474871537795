import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import AcceptQuoteForm from "../../../component/forms/early-termination/AcceptQuoteForm";
import Stepper from "../../../component/stepper/Stepper";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import PageHeading from "../../../component/page-heading/PageHeading";
import { reset } from "../../../redux/features/early-termination/earlyTerminationSlice";
import steps from "../steps-data.json";
import { useStepper } from "../hooks/useStepper";
import PaymentHolidayFinancialSupportTable from "../../../component/payment-holiday/PaymentHolidayFinancialSupportTable";
import { usePaymentHolidayFinancialSupport } from "../../../api/hooks/account";
import magicStrings from "../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const AcceptQuotePage = ({ isETNewQuote = true }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorResponseForEtAccept, setErrorResponseForEtAccept] = useState({});
  const { paymentHolidayFinancialSupport, isError } =
    usePaymentHolidayFinancialSupport();

  const currentStep = 3;

  const activeStep = useSelector((state) => state.earlyTermination.activeStep);

  const etQuoteNumber = useSelector(
    (state) => state.earlyTermination.etQuoteDetails.quoteNumber
  );

  useStepper({ activeStep, currentStep });

  const isETQuoteAccepted = useSelector(
    (state) => state.earlyTermination.etQuoteAccepted
  );
  useEffect(() => {
    if (isETQuoteAccepted === true) {
      dispatch(reset());
      navigate("/early-termination");
    }
  }, [dispatch, navigate, isETQuoteAccepted]);

  return (
    <WizardBasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
      wizardContent={
        <Stepper
          steps={steps}
          activeStep={activeStep}
          onClickHandler={() => {}}
          mode="number"
          showCompletionStep="number"
        />
      }
    >
      {errorResponseForEtAccept?.localErrorMessage && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponseForEtAccept.localErrorMessage)}
        </Alert>
      )}

      <section className="text-center">
        <PageHeading>Your quote number</PageHeading>
        <p>
          <strong className={`${magicStrings.maskInformation}`}>
            {etQuoteNumber}
          </strong>
        </p>
      </section>

      <section className="pt-3">
        <h3>
          Some important things to remember about ending your contract early
        </h3>

        <ul>
          <li>
            You'll still need to pay your usual monthly rental amount for the
            month you return your vehicle. This amount is on top of the Early
            Termination Charge (ETC) and isn't included in your quote.
          </li>
          <li>
            If you're behind with your payments or you've had a payment holiday,
            you'll need to pay the outstanding amount on top of your ETC. We
            don't include outstanding payments in your quote. You can find
            details of any outstanding invoices on the{" "}
            <Link to="/transactions">Transaction page</Link>.
          </li>
          <li>
            If you've used more than your agreed mileage allowance, or if there
            is any damage to the vehicle, we will send you a bill after you
            return it. Again, this amount will be on top of your ETC and won't
            be shown in your quote. You can find out more about these charges on
            our <Link to="/help">FAQs page</Link>.
          </li>
          <li>
            If you don't return your vehicle on or before an agreed date, you
            will need to request a new quote.
          </li>
          <li>
            If you accept a quote but don't return your vehicle, you'll stay on
            your current contract and won't need to pay the ETC.
          </li>
        </ul>
      </section>

      {isError && (
        <Alert variant="warning">
          Sorry, we are unable to retrieve your payment holiday details at this
          time. Please try again later. Please note if you've had a payment
          holiday, you'll need to pay the outstanding amount in addition to any
          contract change or early termination charge.
        </Alert>
      )}

      {!isError && paymentHolidayFinancialSupport?.totalAmount > 0 && (
        <section className="my-4">
          <h3 className="text-primary">About your payment holiday</h3>
          {isETNewQuote && (
            <>
              <p>
                You previously asked to take a break from your monthly payments.
                There's still an outstanding balance which you'll need to catch
                up with, even if you return your vehicle early. This balance
                isn't included in your Early Termination quote.
              </p>
              <p>
                Don't worry, ending a contract early doesn't mean that you have
                to settle your payment holiday balance earlier than planned or
                all at once. You can catch up with your payments as agreed when
                you took your payment holiday. For example, if you took a 6
                month payment holiday, you'll pay this back over 6 months,
                starting from the month after your final contract payment is
                due. Or, if you can afford to, you can choose to pay it back in
                full after your contract ends.
              </p>
              <p>
                You can find out how to make your payments{" "}
                <Link to="/payments/payment-holidays">here</Link>.
              </p>
            </>
          )}

          {!isETNewQuote && (
            <>
              <p>
                You asked to take a break from your monthly payments. There's
                still an outstanding balance which you'll need to catch up with,
                even if you return your vehicle early. This balance isn't
                included in your Early Termination quote.
              </p>
              <p>
                Don't worry, ending a contract early doesn't mean that you have
                to settle your payment holiday balance earlier than planned. You
                can catch up with your payments as agreed when you took your
                payment holiday.
              </p>
              <p>
                You can find out how to make your payments{" "}
                <Link to="/payments/payment-holidays">here</Link>.
              </p>
            </>
          )}
          <PaymentHolidayFinancialSupportTable
            paymentHolidayFinancialSupportData={paymentHolidayFinancialSupport}
          />
        </section>
      )}

      {isETNewQuote ? (
        <AcceptQuoteForm
          showErrorMessage={(errorResponse) => {
            window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
            setErrorResponseForEtAccept(errorResponse);
          }}
        />
      ) : (
        <CenterAlignedButtonContainer>
          <Button
            as={Link}
            to="/early-termination/thank-you"
            id="btnEtSavedQuoteAccept"
            variant="primary"
            className="text-decoration-none"
          >
            Continue
          </Button>
          <BackToHomeButton />
        </CenterAlignedButtonContainer>
      )}
    </WizardBasePage>
  );
};

AcceptQuotePage.propTypes = {
  isETNewQuote: PropTypes.bool,
};

export default AcceptQuotePage;
