import { Row, Col } from "react-bootstrap";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import CalendarMonth from "./CalendarMonth";

const Calendar = ({
  year,
  month,
  numberOfMonths = 3,
  showWeekends = true,
  minDate = "",
  maxDate = "",
  unavailableDates = [],
  selectedDate = "",
  onDateSelected,
}) => {
  const minAllowedDate = minDate
    ? DateTime.fromFormat(minDate, "yyyy-MM-dd").startOf("day")
    : DateTime.utc(-271821, 3, 20);

  const maxAllowedDate = maxDate
    ? DateTime.fromFormat(maxDate, "yyyy-MM-dd").startOf("day")
    : DateTime.utc(271821, 3, 20);
  let disallowedDates = [];

  if (Array.isArray(unavailableDates) && unavailableDates.length > 0) {
    unavailableDates.forEach((d) => {
      disallowedDates.push(DateTime.fromISO(d));
    });
  }

  const currentSelectedDate = selectedDate
    ? DateTime.fromFormat(selectedDate, "yyyy-MM-dd")
    : DateTime.utc(-271821, 3, 20);

  return (
    <Row>
      {[...Array(numberOfMonths).keys()].map((item, index) => {
        let startDate = DateTime.utc(year, month).plus({ month: item });
        return (
          <Col sm={12} md={6} lg={4} className="px-5" key={index}>
            <CalendarMonth
              year={startDate.year}
              month={startDate.month}
              showWeekends={showWeekends}
              minDate={minAllowedDate}
              maxDate={maxAllowedDate}
              unavailableDates={disallowedDates}
              selectedDate={currentSelectedDate}
              onDateSelected={onDateSelected}
            />
          </Col>
        );
      })}
    </Row>
  );
};

Calendar.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).isRequired,
  showWeekends: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onDateSelected: PropTypes.func.isRequired,
  unavailableDates: PropTypes.array,
};

export default Calendar;
