import { Alert } from "react-bootstrap";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import useRegistrationStepCheck from "../hooks/useRegistrationStepCheck";
import AuthContext from "../../../providers/auth-provider/AuthProvider";
import { useSubmitIdVerificationDetails } from "../../../api/hooks/id-verification";
import IdVerificationForm from "../../../component/forms/register/IdVerificationForm";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";

const IdVerificationPage = () => {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const IDENTITYRETRYCHECK =
    "Unfortunately the answers provided have not been validated, please try again.";

  useRegistrationStepCheck("PENDINGIDENTITYCHECK");

  const errorMapping = (message) => {
    setErrorMessage(message);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const onSuccessful = (data) => {
    const status = data?.user?.status.toUpperCase();
    if (status === "IDENTITYRETRYCHECK") {
      errorMapping(IDENTITYRETRYCHECK);
    } else if (status === "OK") {
      setAuth({});
      navigate("/confirmation", { replace: true });
    }
  };

  const onFailure = (error) => {
    if (error?.localCode === 1240301) {
      setAuth({});
      navigate("/", {
        state: { logoutReason: magicStrings.accountLocked },
      });
    } else {
      errorMapping(error?.localErrorMessage);
    }
  };

  const { mutate } = useSubmitIdVerificationDetails(onSuccessful, onFailure);

  const onFormSubmit = (data) => {
    setErrorMessage("");
    const request = { accountNumber: data };
    mutate(request);
  };

  return (
    <BasePage
      pageTitle="ID verification"
      pageSubTitle="Answer security questions to proceed."
    >
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}
      <div className="text-center">
        <CenterAlignedContainer>
          <PageHeading>Why we are asking questions</PageHeading>
          <p className="mb-4">
            In order to confirm your identity, enter the last 4 numbers from
            your bank account that we use for your Direct Debit
          </p>
          <h3 className="h4">Don't pay by Direct Debit?</h3>
          <p>
            Please enter the last 4 numbers of the bank account of your previous
            Direct Debit or the account you provided for your Direct Debit when
            you first took your vehicle finance agreement
          </p>
          <hr />
        </CenterAlignedContainer>
      </div>

      <CenterAlignedButtonContainer>
        <IdVerificationForm onFormSubmit={onFormSubmit} />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default IdVerificationPage;
