import { Row, Col } from "react-bootstrap";

import TableStripedColumns from "../../table/TableStripedColumns";
import {
  formatCurrency,
  formatNumber,
} from "../../../utils/pages/number-format-utils";
import { formatDate } from "../../../utils/pages/date-format-utils";
import magicStrings from "../../../utils/magic-string";

const QuoteDetailsPreview = ({ currentContract, proposedAmendment }) => {
  const vatText = currentContract.vatInclusive ? "including" : "excluding";

  const tableData = [
    [
      "Contact term",
      `${currentContract.term} months`,
      `${proposedAmendment.proposedTerm} months`,
    ],
    [
      "Annual mileage",
      formatNumber(currentContract.maxAnnualMileage, 0),
      formatNumber(
        (proposedAmendment.proposedMileage / proposedAmendment.proposedTerm) *
          12,
        0
      ),
    ],
    [
      "Total mileage",
      formatNumber(currentContract.totalMileage, 0),
      formatNumber(proposedAmendment.proposedMileage, 0),
    ],
    [
      "Term end date",
      formatDate(currentContract.endDate),
      formatDate(proposedAmendment.proposedTermEnd),
    ],
    [
      "Remaining payments",
      currentContract.remainingPayments,
      proposedAmendment.proposedPaymentsRemaining,
    ],
    [
      `Lease payment (${vatText} VAT)`,
      formatCurrency(currentContract.monthlyRental, magicStrings.notApplicable),
      formatCurrency(
        proposedAmendment.proposedLeasePayment,
        magicStrings.notApplicable
      ),
    ],
    [
      `Service charge (${vatText} VAT)`,
      formatCurrency(currentContract.serviceRental, magicStrings.notApplicable),
      formatCurrency(
        proposedAmendment.proposedServicePayment,
        magicStrings.notApplicable
      ),
    ],
    [
      `Excess mileage (pence per mile ${vatText} VAT)`,
      currentContract.excessMileageCharge >= 0
        ? `${formatNumber(currentContract.excessMileageCharge)} ppm`
        : magicStrings.notApplicable,
      proposedAmendment.proposedExcessMileageCharge >= 0
        ? `${formatNumber(proposedAmendment.proposedExcessMileageCharge)} ppm`
        : magicStrings.notApplicable,
    ],
  ];

  return (
    <>
      <Row>
        <Col xs={4} />
        <Col xs={4}>
          <div className="text-primary fw-bold text-end px-3">Current</div>
        </Col>
        <Col xs={4}>
          <div className="text-primary fw-bold text-end px-3">New</div>
        </Col>
      </Row>
      <TableStripedColumns
        colConfig={[{ width: 4 }, { width: 4 }, { width: 4 }]}
        tableData={tableData}
      />
    </>
  );
};

export default QuoteDetailsPreview;
