import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Alert } from "react-bootstrap";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../contract-amends-stepper/ContractAmendsStepper";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import {
  step,
  contractDetails,
  selectedTerm,
  selectedMileage,
  proposedAmendment,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import {
  useContract,
  useContractAmendLimits,
  useContractAmendCalculateQuote,
} from "../../../api/hooks/contracts";
import TableStripedColumns from "../../../component/table/TableStripedColumns";
import TermMileageForm from "../../../component/forms/contract-amendment/TermMileageForm";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import {
  formatCurrency,
  formatNumber,
} from "../../../utils/pages/number-format-utils";
import { formatDate } from "../../../utils/pages/date-format-utils";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const getTableData = (contract) => {
  const data = [
    ["Current term", `${contract.term} months`],
    ["Annual mileage", formatNumber(contract.maxAnnualMileage, 0, "N/A")],
    ["Total mileage", formatNumber(contract.totalMileage, 0, "N/A")],
    ["Term end date", formatDate(contract.endDate)],
    ["Remaining payments", contract.remainingPayments],
  ];
  return data;
};

const CalculateQuotePage = () => {
  useContractAmendsStepCheck(
    steps.newQuote.calculateQuote,
    "../vehicle-selection"
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [calculateQuoteError, setCalculateQuoteError] = useState({});

  const contractId = useSelector(
    (state) => state.contractAmends.selectedContractId
  );
  const currentSelectedTerm = useSelector(
    (state) => state.contractAmends.selectedTerm
  );
  const currentSelectedMileage = useSelector(
    (state) => state.contractAmends.selectedMileage
  );

  const { contract, isError, errorResponse } = useContract(contractId);
  const {
    contractLimits,
    isError: isContractAmendLimitsError,
    errorResponse: contractAmendLimitsErrorResponse,
  } = useContractAmendLimits(contractId);

  const onCalculateQuoteSuccess = (data) => {
    dispatch(contractDetails(contract));
    dispatch(proposedAmendment(data));
    dispatch(step(steps.newQuote.viewQuote));
    navigate("../view-quote");
  };

  const onCalculateQuoteFailure = (error) => {
    if (
      error.localCode === 1140001 ||
      error.localCode === 1140002 ||
      error.localCode === 1140003
    ) {
      dispatch(step(steps.customerServices));
      navigate("../customer-services", { state: { error } });
    } else {
      setCalculateQuoteError(error);
    }
  };

  const { mutate } = useContractAmendCalculateQuote(
    onCalculateQuoteSuccess,
    onCalculateQuoteFailure
  );

  const onFormSubmit = (data) => {
    dispatch(selectedMileage(parseInt(data.mileagePerYear)));
    dispatch(selectedTerm(parseInt(data.contractTerm)));
    mutate({
      contractId,
      proposedAmendment: {
        proposedTerm: parseInt(data.contractTerm),
        proposedMileage: parseInt(data.totalMileage),
      },
    });
  };
  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <PageHeading>Select new term and mileage</PageHeading>

      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse.localErrorMessage)}
        </Alert>
      )}

      {!isError && isContractAmendLimitsError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(
            contractAmendLimitsErrorResponse.localErrorMessage
          )}
        </Alert>
      )}

      {!isError && !isContractAmendLimitsError && (
        <>
          <div className="text-center">
            <div className="h3 fw-bold mb-0">Registration:</div>
            <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
              {contract?.vehicle?.registrationNumber}
            </div>
            <div className={`${magicStrings.maskInformation}`}>
              {contract?.vehicle?.description}
            </div>
            <div className="h3 fw-bold mb-0 mt-5">Total rental payment:</div>
            <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
              {formatCurrency(contract?.totalMonthlyRentalCharge, "NA")}
            </div>
            <div>
              {contract?.vatInclusive ? "Including VAT" : "Excluding VAT"}
            </div>
          </div>
          <br />
          <h3 className="text-primary fw-bold text-center">
            Your current contract
          </h3>
          <CenterAlignedContainer>
            <TableStripedColumns tableData={getTableData(contract)} />
          </CenterAlignedContainer>
          <br />
          <h3 className="text-primary fw-bold text-center">Your amendment</h3>
          <p className="text-center">Change your term and/or your mileage</p>

          <CenterAlignedButtonContainer>
            {contractLimits &&
              !contractLimits.__isDefault &&
              contract &&
              !contract.__isDefault && (
                <TermMileageForm
                  minimumTerm={contractLimits.minimumTerm}
                  maximumTerm={contractLimits.maximumTerm}
                  minimumMileageLimit={contractLimits.minimumMileageLimit}
                  maximumMileageLimit={contractLimits.maximumMileageLimit}
                  currentContractTerm={contract.term}
                  currentContractMileage={contract.maxAnnualMileage}
                  newSelectedContractMileage={
                    currentSelectedMileage ? currentSelectedMileage : null
                  }
                  newSelectedContractTerm={
                    currentSelectedTerm
                      ? currentSelectedTerm
                      : contract.term < contractLimits.minimumTerm
                      ? contractLimits.minimumTerm
                      : null
                  }
                  highestRecordedMileage={
                    contract?.vehicle?.heighestRecordedMileage
                  }
                  calculateQuoteError={calculateQuoteError}
                  onFormSubmit={onFormSubmit}
                />
              )}
          </CenterAlignedButtonContainer>
        </>
      )}
    </WizardBasePage>
  );
};

export default CalculateQuotePage;
