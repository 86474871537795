import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { formatCurrency } from "../../../../utils/pages/number-format-utils";
import BasePage from "../../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import TitleWithIcon from "../../../../component/title-with-icon/TitleWithIcon";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import useAuth from "../../../../hooks/use-auth/useAuth";
import { reset } from "../../../../redux/features/payments/cardPaymentSlice";
import steps from "../card-payment-steps";
import magicStrings from "../../../../utils/magic-string";

const PaymentSuccessPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isStepChecked, setIsStepChecked] = useState(false);
  const currentStep = useSelector((state) => state.cardPayment.step);
  const selectedInvoices = useSelector(
    (state) => state.cardPayment.selectedInvoices
  );
  const [amountPaid] = useState(() => {
    let totalAmount = 0.0;
    selectedInvoices.forEach((invoice) => {
      totalAmount = totalAmount + parseFloat(invoice.totalAmount);
    });
    return totalAmount;
  });

  const { auth } = useAuth();
  const emailAddress = auth?.user?.emailAddress;
  const referenceID = useSelector((state) => state.cardPayment.transactionKey);

  const onHomeButtonClicked = () => {
    dispatch(reset());
  };

  useEffect(() => {
    if (!isStepChecked) {
      setIsStepChecked(true);
      if (currentStep < steps.success) {
        navigate("/payments");
      }
    }
  }, [currentStep, navigate, isStepChecked]);

  return (
    <BasePage pageTitle="Make a payment">
      <TitleWithIcon title="Payment successful" variant="success" />
      <br />
      <div className="text-center">
        <p>
          We've sent confirmation of this payment to:{" "}
          <strong className={`${magicStrings.maskInformation}`}>
            {emailAddress}
          </strong>
        </p>
        <p>
          Please note that it can take up to one working day for your account
          balance to show this payment.
        </p>
        <p>
          Payment reference :{" "}
          <strong className={`${magicStrings.maskInformation}`}>
            {referenceID}
          </strong>
        </p>
        <p>
          Amount paid :{" "}
          <strong className={`${magicStrings.maskInformation}`}>
            {formatCurrency(amountPaid)}
          </strong>
        </p>
      </div>

      <CenterAlignedButtonContainer>
        <BackToHomeButton
          variant="primary"
          onButtonClicked={onHomeButtonClicked}
        />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default PaymentSuccessPage;
