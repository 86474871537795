import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import TickSignedIcon from "../../component/icons/TickSignedIcon";
import * as Icons from "../icons";

const Stepper = ({
  steps,
  mode = "number",
  activeStep,
  showCompletionStep,
  onClickHandler,
}) => {
  // While iterating through steps, don't count icons in display number (i.e. 1-2-persistedIcon-3-4 rather than 1-2-persistedIcon-4-5)
  let currentPersistedIcons = 0;
  return (
    <>
      <Container className="py-3">
        <Row className="d-flex justify-content-center">
          {steps?.map(
            ({ step, description, customIcon, persistIcon = false }, index) => {
              let indexStep = index + 1;

              if (persistIcon) {
                currentPersistedIcons += 1;
              }
              let displayNumber = indexStep - currentPersistedIcons;
              return (
                <Fragment key={step}>
                  <Col className="text-center p-0 stepper-list-block d-sm-inline-block d-flex justify-content-center">
                    <Row>
                      <Col>
                        <div className="d-flex flex-row h-100 align-items-center">
                          <div className="flex-grow-1 stepper-left-line  d-none d-sm-block">
                            <hr className="me-2" />
                          </div>

                          <div
                            className={`text-center step-count rounded-circle border justify-content-center d-inline-flex align-items-center ${
                              activeStep >= indexStep
                                ? "bg-primary active-step border-primary " +
                                  (!persistIcon || !(customIcon in Icons)
                                    ? "text-white"
                                    : "text-primary")
                                : "bg-secondary-light-contrast-fix"
                            } ${
                              persistIcon &&
                              customIcon in Icons &&
                              "border-0 bg-transparent"
                            }`}
                            data-status={
                              activeStep >= indexStep
                                ? "complete"
                                : "incomplete"
                            }
                            data-testid={`step${step}`}
                            onClick={() =>
                              onClickHandler(indexStep, activeStep)
                            }
                          >
                            {mode === "number" &&
                              showCompletionStep === "number" && (
                                <span className="h5 mb-0">
                                  {customIcon in Icons
                                    ? React.createElement(Icons[customIcon], {
                                        size: "sm",
                                        title: "step-icon-" + displayNumber,
                                      })
                                    : displayNumber}
                                </span>
                              )}
                            {mode === "number" &&
                              showCompletionStep === "tick" &&
                              activeStep > indexStep && (
                                <span className="h5 mb-0">
                                  {customIcon in Icons && persistIcon ? (
                                    React.createElement(Icons[customIcon], {
                                      size: "sm",
                                      title: "step-icon-" + displayNumber,
                                    })
                                  ) : (
                                    <TickSignedIcon title="Signed" size="sm" />
                                  )}
                                </span>
                              )}

                            {mode === "number" &&
                              showCompletionStep === "tick" &&
                              activeStep <= indexStep && (
                                <span className="h5 mb-0">
                                  {customIcon in Icons
                                    ? React.createElement(Icons[customIcon], {
                                        size: "sm",
                                        title: "step-icon-" + displayNumber,
                                      })
                                    : displayNumber}
                                </span>
                              )}
                          </div>

                          <div className="flex-grow-1 stepper-right-line d-none d-sm-block">
                            <hr className="ms-2" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="d-none d-md-block">
                      <Col>
                        <span
                          className={`h5 d-inline-block text-center justify-content-center step-label pt-2 ${
                            customIcon in Icons
                              ? "border-0"
                              : activeStep >= indexStep
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          {description}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Fragment>
              );
            }
          )}
        </Row>
      </Container>
    </>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array,
  mode: PropTypes.oneOf(["none", "number"]),
  activeStep: PropTypes.number,
  showCompletionStep: PropTypes.oneOf(["none", "number", "tick"]),
  onClickHandler: PropTypes.func,
};

export default Stepper;
