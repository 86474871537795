import { Alert, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../contract-amends-stepper/ContractAmendsStepper";
import {
  step,
  proposedAmendment,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import QuoteDetailsPreview from "../../../component/contract-amends/quote-details-preview/QuoteDetailsPreview";
import { formatCurrency } from "../../../utils/pages/number-format-utils";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { useContractAmendSaveQuote } from "../../../api/hooks/contracts";
import PageHeading from "../../../component/page-heading/PageHeading";
import PaymentHolidayFinancialSupportTable from "../../../component/payment-holiday/PaymentHolidayFinancialSupportTable";
import { usePaymentHolidayFinancialSupport } from "../../../api/hooks/account";
import magicStrings from "../../../utils/magic-string";

const ViewQuotePage = () => {
  useContractAmendsStepCheck(steps.newQuote.viewQuote, "../calculate-quote");

  const { paymentHolidayFinancialSupport, isError } =
    usePaymentHolidayFinancialSupport();

  const contractId = useSelector(
    (state) => state.contractAmends.selectedContractId
  );
  const savedAmendment = useSelector(
    (state) => state.contractAmends.proposedAmendment
  );
  const contract = useSelector((state) => state.contractAmends.contractDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSaveAndContinue = () => {
    mutate({
      contractId,
      proposedAmendment: {
        proposedTerm: savedAmendment.proposedTerm,
        proposedMileage: savedAmendment.proposedMileage,
      },
    });
  };

  const onSaveQuoteSuccess = (data) => {
    dispatch(proposedAmendment(data));
    dispatch(step(steps.newQuote.saveQuote));
    navigate("../save-quote");
  };

  const onSaveQuoteFailure = () => {
    dispatch(step(steps.customerServices));
    navigate("../customer-services");
  };

  const { mutate } = useContractAmendSaveQuote(
    onSaveQuoteSuccess,
    onSaveQuoteFailure
  );

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <PageHeading>New rental</PageHeading>
      <div className="text-center">
        <div className="h3 fw-bold mb-0">Registration:</div>
        <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
          {contract?.vehicle?.registrationNumber}
        </div>
        <div className={`${magicStrings.maskInformation}`}>
          {contract?.vehicle?.description}
        </div>
        <div className="h3 fw-bold mt-4 mb-0">Total rental payment:</div>
        <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
          {formatCurrency(savedAmendment?.proposedRentalPayment)}
        </div>
        <div className="mb-3">
          {savedAmendment?.vatInclusive ? "including VAT" : "excluding VAT"}
        </div>
      </div>
      <br />
      <p className="text-center">
        Please note: We'll offer you the same new rental price if you change
        your contract over the phone.
      </p>
      <hr />
      <br />
      <QuoteDetailsPreview
        currentContract={contract}
        proposedAmendment={savedAmendment}
      />
      {contract.serviceRental > 0 &&
        savedAmendment.proposedServicePayment === 0 && (
          <p>
            Your monthly service charge is now{" "}
            <span className={`${magicStrings.maskInformation}`}>
              &pound;0.00
            </span>
            . This is because of a recent change you made to your contract. But
            don't worry, your service is still included.
          </p>
        )}
      {/informalextension/i.test(contract.eocStatus) && (
        <>
          <h3 className="text-primary">Informal Extension</h3>
          <p>
            You are currently in an informal contract extension because your
            original contract end date has passed. This means you're paying your
            monthly rental in arrears. When you lease your vehicle on a formal
            contract, we will bill you in advance.
          </p>
          <p>
            If you continue with your contract amendment, we'll send you an
            invoice for the rental accrued between your last payment period and
            the beginning of your amended rental period.{" "}
            <strong>
              This may mean you have two rentals due for payment close together.
            </strong>
          </p>
        </>
      )}

      {isError && (
        <Alert variant="warning">
          Sorry, we are unable to retrieve your payment holiday details at this
          time. Please try again later. Please note if you've had a payment
          holiday, you'll need to pay the outstanding amount in addition to any
          contract change or early termination charge.
        </Alert>
      )}

      {!isError && paymentHolidayFinancialSupport?.totalAmount > 0 && (
        <section className="my-4">
          <h3 className="text-primary">
            You have a payment holiday on your account
          </h3>
          <p>
            We haven't included payments accrued during your vehicle payment
            holiday in your contract amendment.
          </p>
          <p>
            You'll still owe any remaining balance from your payment holiday
            once you've returned your vehicle. You can make early repayments -
            visit <Link to="/payments/payment-holidays">Payment holidays</Link>{" "}
            to find out how.
          </p>

          <PaymentHolidayFinancialSupportTable
            paymentHolidayFinancialSupportData={paymentHolidayFinancialSupport}
          />
        </section>
      )}

      <CenterAlignedButtonContainer>
        <Button onClick={onSaveAndContinue}>Save and continue</Button>
        <Button variant="outline-primary" onClick={() => navigate(-1)}>
          Recalculate
        </Button>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default ViewQuotePage;
