import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const BudgetingIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    aria-label={title}
    viewBox="0 0 18 18"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="budgeting-icon_svg__a"
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm0 16H5V5h14v14ZM7 10h2v7H7v-7Zm4-3h2v10h-2V7Zm4 6h2v4h-2v-4Z"
      />
    </defs>
    <use
      xlinkHref="#budgeting-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-3 -3)"
    />
  </svg>
);

BudgetingIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { BudgetingIcon };
export default BudgetingIcon;
