import { GenericSelectField } from "./field-utils/field-utils";
import PropTypes from "prop-types";

// Quote/Vehicle Detais Fields

export const VehicleSelectField = ({ vehicles, ...props }) => {
  return (
    <GenericSelectField
      controlId="vehicleSelect"
      labelText="Select a Vehicle"
      options={vehicles ?? []}
      placeholderOptionText="Please select a vehicle"
      ariaText="Please select a vehicle"
      {...props}
    />
  );
};

VehicleSelectField.propTypes = {
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};

export const ContractTermField = ({ terms, ...props }) => {
  return (
    <GenericSelectField
      controlId="contractTerm"
      labelText="Contract term"
      options={terms ?? []}
      ariaText="Please select a contract term"
      {...props}
    />
  );
};

ContractTermField.propTypes = {
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};

export const MileagePerYearField = ({ mileages, ...props }) => {
  return (
    <GenericSelectField
      controlId="mileagePerYear"
      labelText="Annual mileage"
      options={mileages ?? []}
      {...props}
    />
  );
};

MileagePerYearField.propTypes = {
  mileages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
    })
  ),
};
