import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const InvoiceDocumentIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    className="invoice-document-icon_svg__svgDocument"
    role="img"
    viewBox="0 0 10 13"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      d="M6.25 0h-5C.562 0 .006.585.006 1.3L0 11.7c0 .715.556 1.3 1.244 1.3H8.75c.688 0 1.25-.585 1.25-1.3V3.9L6.25 0zM7.5 10.4h-5V9.1h5v1.3zm0-2.6h-5V6.5h5v1.3zM5.625 4.55V.975L9.063 4.55H5.624z"
    />
  </svg>
);

InvoiceDocumentIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { InvoiceDocumentIcon };
export default InvoiceDocumentIcon;
