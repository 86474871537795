import { Link } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";

const MoneyWorriesPage = () => {
  return (
    <BasePage
      pageTitle="Money worries"
      pageSubTitle="We're here to support you if you need help."
    >
      <p>
        If you're finding it difficult to make your vehicle finance payments,
        there are things we can do to help. The sooner you let us know, the
        quicker we can help get you back on track.
      </p>
      <p>
        Please call us <Link to="tel:0800 015 4140">0800 015 4140</Link> so we
        can talk through your options together. We're here from 8.30am - 5.30pm,
        Monday - Friday, except bank holidays. Calls are free from UK landlines
        and mobile phones.
      </p>

      <hr className="my-5" />

      <h2>Before you call us</h2>
      <p>
        If you can, complete our online{" "}
        <a
          href="https://apply.lloydsbank.co.uk/sales-content/cwa/l/gforms/F234/?utm_source=email&amp;utm_medium=vurl&amp;utm_campaign=lexincomeform#//?b=la"
          target="_blank"
          rel="noopener noreferrer"
        >
          budget calculator
        </a>{" "}
        to tell us about your income and spending first. It'll save you time
        when you call and help us look at the support we may be able to give
        you. Make a note of your reference number when you've completed the
        form, and tell us this when you call.
      </p>

      <p className="mb-1">
        If you'd rather call us without using our budgeting tool, please have
        these things to hand:
      </p>

      <ul>
        <li>Your account number or fleet number.</li>
        <li>The registration for your vehicle.</li>
        <li>How much money you have coming in each month.</li>
        <li>How much money you spend each month.</li>
        <li>What you pay to others.</li>
        <li>A pen and paper to jot things down.</li>
      </ul>

      <hr className="my-5" />

      <h2>What to expect</h2>
      <p className="mb-1">To help you, we will:</p>
      <ul>
        <li>Talk through your money to see what's worrying you.</li>
        <li>Let you know about the help you may be able to get.</li>
        <li>Tell you where you can find extra help.</li>
      </ul>

      <p>
        How we can help depends on your situation and what stage you are at in
        your agreement. Sometimes we're not able to help you with your payments,
        but we can tell you about charities and organisations who can support
        you. Find out what free,{" "}
        <a
          href="https://www.lexautolease.co.uk/independent-help"
          target="_blank"
          rel="noopener noreferrer"
        >
          independent help and advice
        </a>{" "}
        is available.
      </p>

      <p className="mb-1">
        Here are some examples of support we may be able to give you, after we
        talk through your circumstances:
      </p>

      <ul>
        <li>
          A temporary payment plan could help you get back on track, if you're
          eligible for this.
        </li>
        <li>
          We may be able to give you some time to get independent help and
          advice by placing a short-term hold on the number of communications we
          send you. If we can offer this, you'll still need to make your monthly
          payments so you don't miss any, but you can take the time and space to
          get some independent help and advice, and make sure your priority
          payments are up to date.
        </li>
        <li>
          Explain how you can return the vehicle to us and what this means for
          your agreement.
        </li>
      </ul>
      <p>
        If you call us, it may take a bit of time depending on what you need
        help with and the support we can give you. We'll ask questions about
        your situation, and you can let us know at any time if you don't feel
        comfortable talking about this.
      </p>

      <hr className="my-5" />

      <h2>Other ways to get in touch</h2>
      <ul>
        <li>
          <p className="mb-1">
            Email us at{" "}
            <Link to="mailto:cpt@lexautolease.co.uk">
              cpt@lexautolease.co.uk
            </Link>{" "}
            - we'll reply as quickly as we can, usually 2 to 5 working days
            after we've received your email.
          </p>

          <ul className="list-unstyled">
            <li>
              Email is not a secure way to get in touch, so please don't email
              us any personal information such as your account numbers. We won't
              be responsible for any data you send that is lost in transit to
              us.
            </li>
          </ul>
        </li>
        <li>
          <p className="mb-1">
            Or write to us at Lex Customer Financial Assistance Team, Lex
            Autolease, Heathside Park, Heathside Park Road, Stockport, SK3 0RB.
          </p>

          <ul className="list-unstyled">
            <li>
              If you write to us, please tell us how you'd like us to reply. If
              you'd like us to call or email you, make sure you include your
              phone number or email address in the letter. We'll reply as
              quickly as we can, usually in around 2 to 8 working days,
              depending on how you ask us to reply. It's quickest to ask us to
              email you or call you back.
            </li>
          </ul>
        </li>
      </ul>
    </BasePage>
  );
};

export default MoneyWorriesPage;
