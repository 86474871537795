import { Button, Col, Row, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { getItem } from "../../../storage/SessionStorage";
import { useNavigate } from "react-router";

const CauraPromoBanner = () => {
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);
  const [isCauraEnabled] = useState(() => {
    return window?.envConfig?.isCauraEnabled;
  });

  const onCauraClicked = () => {
    navigate("/promo-caura");
  };

  useEffect(() => {
    if (!showBanner && isCauraEnabled === "1") {
      const isCauraUser = getItem("isCaura");
      if (!isCauraUser) {
        setShowBanner(true);
      }
    }
  }, [showBanner]);

  return (
    <>
      {showBanner && (
        <Card className="p-2">
          <div>
            <Row>
              <Col
                lg={{ span: 6, offset: 1 }}
                md={5}
                className="mt-4  d-sm-block d-lg-block "
              >
                <div className=" text-center word-break p-md-4 mt-2">
                  <h2>
                    <b>Run your car the easier way</b>
                  </h2>
                  <p>
                    Renew insurance, book MOTs and repairs, pay city and toll
                    charges and much more, all in one app
                  </p>
                </div>
                <div className=" text-center p-3">
                  <Button
                    id="btnCaura"
                    variant="primary"
                    onClick={() => onCauraClicked()}
                  >
                    Learn about Caura
                  </Button>
                </div>
              </Col>
              <Col lg={5} md={7} className="d-none d-sm-block ">
                <div className="text-center p-sm-1">
                  <img
                    src="/images/caura/caurammvbanner.png"
                    title="Caura"
                    width="auto"
                    max-width="100%"
                    height="auto"
                    fluid
                  ></img>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      )}
    </>
  );
};

export default CauraPromoBanner;
