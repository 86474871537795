import {
  GenericCheckbox,
  GenericTextField,
} from "./field-utils/field-utils.js";

export const SignHereTextField = (props) => {
  return (
    <GenericTextField
      maxLength={100}
      controlId="signature"
      labelText="Sign here"
      {...props}
    />
  );
};

export const SignHereCheckbox = (props) => {
  return (
    <GenericCheckbox
      controlId="isSignAgreementChecked"
      labelText="By clicking to sign, you are agreeing to your amended contract and its terms and conditions."
      {...props}
    />
  );
};

export const PersonalDetailsTermsCheckbox = (props) => {
  return (
    <GenericCheckbox
      controlId="personalDetailsTermsAccepted"
      labelText="I understand the changes I have made to my personal details will be updated on my account once I submit my application."
      {...props}
    />
  );
};

export const CreditCheckTermsCheckbox = (props) => {
  return (
    <GenericCheckbox
      controlId="creditCheckTermsAccepted"
      labelText="I understand that, by submitting this application, I am agreeing for Lex Autolease to perform searches with credit reference agencies. I also understand that these checks will be recorded on my credit profile. "
      {...props}
    />
  );
};
