import * as yup from "yup";

export const supportNeedsSchema = {
  supportNeedsChecklist: yup
    .array()
    .transform((value, originalvalue) => {
      return !(originalvalue instanceof Array) || originalvalue.length < 1
        ? null
        : value;
    })
    .required("Please choose an option"),
};

export const confirmSupportNeedsTermsSchema = {
  confirmSupportNeedsTermsAccepted: yup
    .bool()
    .oneOf([true], "Terms must be accepted."),
};
