import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { clear } from "../../storage/SessionStorage";
import useAuth from "../use-auth/useAuth";
import { useServerLogout } from "../../api/hooks/auth";
import revertAll from "../../redux/actions/revert-all/revertAll";
import queryClient from "../../api/react-query/query-client";

const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogoutSucessful = () => {
    navigate("/");
  };

  const onLogoutFailure = () => {
    navigate("/");
  };
  const { mutate } = useServerLogout(onLogoutSucessful, onLogoutFailure);

  const logout = (reason) => {
    navigate("/", { state: { logoutReason: reason } });
    setAuth({});
    clear();
    queryClient.clear();
    dispatch(revertAll());
    mutate();
  };

  return logout;
};

export default useLogout;
