import * as yup from "yup";

// Quote/Vehicle Detais Fields Schema

export const vehicleSelectSchema = {
  vehicleSelect: yup.string(),
};

export const contractTermSchema = {
  contractTerm: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .integer()
    .required("Contract Term is required"),
};

export const mileagePerYearSchema = {
  mileagePerYear: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .integer()
    .required("Milage per year is required"),
};
