import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import ContactAndCollectionDetailsForm from "../../../../component/forms/vehicle-collection/contact-and-collection-details/ContactAndCollectionDetailsForm";
import steps from "../../vehicle-collection-steps";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import BasePage from "../../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import {
  step,
  updatedCollectionDetails as dispatchUpdatedCollectionDetails,
} from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import PageHeading from "../../../../component/page-heading/PageHeading";
import CenterAlignedContainer from "../../../../component/center-aligned-container/CenterAlignedContainer";

const ExistingContactAndCollectionDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useVehicleCollectionStepCheck(
    steps.existingBookings.editDetails,
    "../summary"
  );

  const contractDetails = useSelector(
    (state) => state.vehicleCollection.contractDetails
  );
  const updatedCollectionDetails = useSelector(
    (state) => state.vehicleCollection.updatedCollectionDetails
  );
  const selectedCollection = updatedCollectionDetails
    ? updatedCollectionDetails
    : contractDetails?.latestCollection;

  const userProfileForForm = {
    ...selectedCollection,
    emailAddress: selectedCollection?.contactEmail,
    homeNumber: selectedCollection?.contactHomeNumber,
    mobileNumber: selectedCollection?.contactMobileNumber,
    workNumber: selectedCollection?.contactWorkNumber,
    isAddressManuallyEntered: true,
    city: selectedCollection?.addressLine4,
    county: selectedCollection?.addressLine5,
    postCode: selectedCollection?.postcode,
  };
  const savedCollectionAddress = {};

  const onFormSubmit = (data) => {
    const detailsToSave = {
      ...selectedCollection,
      contactName: data?.contactName,
      contactEmail: data?.emailAddress,
      contactHomeNumber: data?.homeNumber,
      contactMobileNumber: data?.mobileNumber,
      contactWorkNumber: data?.workNumber,
      addressLine1: data?.addressLine1,
      addressLine2: data?.addressLine2,
      addressLine3: data?.addressLine3,
      addressLine4: data?.city,
      addressLine5: data?.county,
      postcode: data?.postCode,
    };

    dispatch(dispatchUpdatedCollectionDetails(detailsToSave));
    dispatch(step(steps.existingBookings.summary));
    navigate(-1);
  };

  return (
    <BasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
    >
      <PageHeading>Contact and collection details</PageHeading>
      <CenterAlignedContainer>
        <p className="text-center mb-3">
          To collect your vehicle, we need the contact details of the person we
          will be collecting the vehicle from and the address where the vehicle
          will be located.
        </p>
      </CenterAlignedContainer>

      <CenterAlignedButtonContainer>
        <ContactAndCollectionDetailsForm
          userProfile={userProfileForForm}
          collectionAddress={savedCollectionAddress}
          onFormSubmit={onFormSubmit}
          trackChanges={true}
        />
        <BackToHomeButton className="mt-0" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ExistingContactAndCollectionDetailsPage;
