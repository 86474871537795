import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import BasePage from "../../basepages/basepage/BasePage";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import steps from "../contract-amends-steps";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import { formatDate } from "../../../utils/pages/date-format-utils";
import { useContract } from "../../../api/hooks/contracts";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import TableStripedColumns from "../../../component/table/TableStripedColumns";
import {
  formatNumber,
  formatCurrency,
} from "../../../utils/pages/number-format-utils";
import { reset } from "../../../redux/features/contract-amends/contractAmendsSlice";
import { Alert } from "react-bootstrap";
import magicStrings from "../../../utils/magic-string";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const ConfirmNextStepsPage = () => {
  const dispatch = useDispatch();

  useContractAmendsStepCheck(steps.confirmNextSteps, "../sign-agreement");

  const selectedContractId = useSelector(
    (state) => state.contractAmends.selectedContractId
  );
  const contractAgreement = useSelector(
    (state) => state.contractAmends.contractAgreement
  );

  const { contract, isError, errorResponse } = useContract(selectedContractId);

  const getTableData = (currentContract) => {
    const vatText = currentContract.vatInclusive ? "including" : "excluding";
    const data = [
      ["Contract term", `${currentContract.term} months`],
      ["Annual mileage", formatNumber(currentContract.maxAnnualMileage, 0)],
      ["Total mileage", formatNumber(currentContract.totalMileage, 0)],
      ["Term end date", formatDate(currentContract.endDate)],
      ["Remaining payments", currentContract.remainingPayments],
      [
        `Lease payment (${vatText} VAT)`,
        formatCurrency(currentContract.monthlyRental),
      ],
      [
        `Service charge (${vatText} VAT)`,
        formatCurrency(currentContract.serviceRental),
      ],
      [
        `Excess mileage (pence per mile ${vatText} VAT)`,
        `${formatNumber(currentContract.excessMileageCharge)} ppm`,
      ],
    ];
    return data;
  };

  const onBackToHome = () => {
    dispatch(reset());
  };

  return (
    <BasePage pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <TitleWithIcon
        title="Thank you for signing your amended contract"
        variant="success"
      />

      <p className="text-center mt-5 mb-4">We've implemented your new terms.</p>

      <h2 className="h3 text-primary">Next steps</h2>
      <p>
        We'll generate the first invoice for your amended contract on{" "}
        <span className={`${magicStrings.maskInformation}`}>
          {formatDate(contractAgreement?.nextBillingDate)}.
        </span>
      </p>
      {contractAgreement && contractAgreement.ddDate && (
        <p>
          We'll collect your Direct Debit on or around{" "}
          <span className={`${magicStrings.maskInformation}`}>
            {formatDate(contractAgreement.ddDate)}.
          </span>
        </p>
      )}
      {contract &&
        contract.eocStatus &&
        contract.eocStatus.toLowerCase() === "informal extension" && (
          <>
            <br />
            <h2 className="h3 text-primary">
              What happens if you've been in an informal extension period?
            </h2>
            <p>
              We'll raise a rental adjustment invoice for the time between your
              last informal extension rental payment and your first rental
              invoice under your amended contract.
            </p>
            <p>
              If you have any questions about your informal extension period,
              you can speak to a member of our team on{" "}
              <Link to="tel:0344 879 6633">0344 879 6633</Link> option 6. Our
              phone lines are open Monday - Friday between 9am - 5pm. We're
              closed on weekends and bank holidays.
            </p>
          </>
        )}
      <br />
      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}
      {!isError && contract && (
        <>
          <h2 className="h3 text-primary">Your new contract</h2>
          <p>This is a summary of your new contract.</p>
          <TableStripedColumns tableData={getTableData(contract)} />
        </>
      )}

      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" onButtonClicked={onBackToHome} />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ConfirmNextStepsPage;
