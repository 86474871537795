import TilesBasePage from "../basepages/tiles-basepage/TilesBasePage";
import CauraPromoTiles from "./CauraPromoTiles";

import { Col, Container, Row } from "react-bootstrap";

const CauraPromoPage = () => {
  return (
    <TilesBasePage
      pageTitle="Drive smarter with Caura"
      pageSubTitle="Everything you need to run your car, in one handy place"
    >
      <div className="d-flex d-column flex-wrap">
        <Container className="bg-white mt-sm-8 mt-8 mb-sm-4 px-lg-10 py-lg-9 p-md-9 px-4 py-6">
          <Row className="p-2">
            <Col lg={6}>
              <div>
                <h2 className="h2 text-primary mx-1 p-2">
                  <strong>Run your car the easier way</strong>
                </h2>
                <p className="p-1 mx-2">
                  Caura makes running your car a breeze.<br></br>Here’s a quick
                  overview of just some of the things you can do, from one app:
                </p>
                <ul>
                  <li className="text-primary ">
                    <strong>Keep your car in tip-top shape</strong>
                  </li>
                  <p>
                    Set reminders for servicing and MOTs, find and book an
                    appointment at your nearest approved garage in just a few
                    taps.
                  </p>
                  <li className="text-primary">
                    <strong>Keep track of all your driving documents</strong>
                  </li>
                  <p>Store your driving documents in one handy place.</p>
                  <li className=" text-primary">
                    <strong>Exclusive offers, just for you</strong>
                  </li>
                  <p>
                    Save upto 50% on car insurance with personalised offers, and
                    get free £500 excess protection when you buy policy in the
                    app.
                  </p>
                  <li className="text-primary">
                    <strong>Make penalty charges a thing of the past</strong>
                  </li>
                  <p>
                    Pay toll, emission and city charges instantly and securely
                    with just two taps.
                  </p>
                  <li className="text-primary">
                    <strong>Find and book parking spaces</strong>
                  </li>
                  <p>
                    Track down local parking spots quickly and pre-book spaces
                    at all major UK airports.
                  </p>
                </ul>
              </div>
            </Col>
            <Col md={6} xs={12} className="d-flex my-xl-auto ">
              <div className="text-center p-1  d-block">
                <img
                  src="/images/caura/confettigif.gif"
                  title="LexCaura"
                  className=""
                  fluid
                  width="70%"
                  height="auto"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="bg-white mt-sm-6  mb-sm-6 px-lg-8 py-lg-1 p-md-9 px-4 py-6 order-md-last order-first">
          <CauraPromoTiles />
        </Container>
      </div>
    </TilesBasePage>
  );
};

export default CauraPromoPage;
