import apiKeys from "./api-keys";
import errorCodeMappings from "./error-structure";
import errorMesages from "./error-messages";
import { UNIVERSAL_ERROR_CODE } from "./error-structure/global";

const supportedHttpStatusCodes = [
  400, 401, 404, 403, 405, 500, 501, 502, 503, 504,
];
const reasonCodeLookupApis = [
  apiKeys.users,
  apiKeys.forgotDetails,
  apiKeys.profile,
  apiKeys.orders,
  apiKeys.documents,
];

const getApiKeyFromError = (apiError) => {
  let apiKey = apiKeys.___global___;
  if (apiError?.response?.config?.headers) {
    if (
      typeof apiError?.response?.config?.headers["x-local-api-key"] === "string"
    ) {
      const apiKeyInResponse =
        apiError?.response?.config?.headers["x-local-api-key"];
      const keyMatch = Object.entries(apiKeys).find(
        (e) => Array.isArray(e) && e.length > 1 && e[1] === apiKeyInResponse
      );
      if (keyMatch) {
        apiKey = apiKeyInResponse;
      }
    }
  }
  return apiKey;
};

const getFirstErrorFromResponse = (apiError) => {
  let firstErrorToReturn = {
    code: UNIVERSAL_ERROR_CODE, // global code to handle generic errors
    reasonCode: "DATA_API_ERR_500",
    message: "Internal server error",
  };
  if (
    Array.isArray(apiError?.response?.data) &&
    apiError.response.data.length > 0
  ) {
    const firstError = apiError.response.data[0];
    if (typeof firstError === "object") {
      if (Object.hasOwn(firstError, "code")) {
        firstErrorToReturn = apiError.response.data[0];
      }
    }
  }
  return firstErrorToReturn;
};

const getMappedErrorCode = (apiKey, apiError, statusCode) => {
  const isStatusCodeLookup = reasonCodeLookupApis.includes(apiKey);
  const lookupProp = isStatusCodeLookup ? "reasonCode" : "code";
  let errorCode = 0;
  if (typeof errorCodeMappings[apiKey] === "object") {
    const currentApiMappings = errorCodeMappings[apiKey];
    if (typeof currentApiMappings[statusCode.toString()] === "object") {
      const currentApiMapping = currentApiMappings[statusCode.toString()];
      if (
        typeof currentApiMapping[apiError[lookupProp].toString()] === "number"
      ) {
        errorCode = currentApiMapping[apiError[lookupProp].toString()];
      } else if (typeof currentApiMapping["default"] === "number") {
        errorCode = currentApiMapping["default"];
      } else if (typeof currentApiMappings["default"] === "object") {
        const currentApiDefaultMappings = currentApiMappings["default"];
        if (
          typeof currentApiDefaultMappings[apiError[lookupProp].toString()] ===
          "number"
        ) {
          errorCode =
            currentApiDefaultMappings[apiError[lookupProp].toString()];
        } else if (typeof currentApiDefaultMappings["default"] === "number") {
          errorCode = currentApiDefaultMappings["default"];
        }
      }
    }

    if (errorCode === 0 && typeof currentApiMappings["default"] === "object") {
      const currentApiMapping = currentApiMappings["default"];
      if (
        typeof currentApiMapping[apiError[lookupProp].toString()] === "number"
      ) {
        errorCode = currentApiMapping[apiError[lookupProp].toString()];
      } else if (typeof currentApiMapping["default"] === "number") {
        errorCode = currentApiMapping["default"];
      }
    }
  }
  if (errorCode === 0) {
    errorCode = UNIVERSAL_ERROR_CODE;
  }
  return errorCode;
};

const getMappedErrorMessage = (errorCode) => {
  if (errorMesages[`${errorCode}`]) {
    return errorMesages[`${errorCode}`];
  }
  return errorMesages[`${UNIVERSAL_ERROR_CODE}`];
};

const transformApiError = (apiError) => {
  if (!apiError?.response?.data?.localCode) {
    let apiKey = getApiKeyFromError(apiError);
    let firstApiError = getFirstErrorFromResponse(apiError);
    let httpStatusCode = apiError?.response?.status
      ? apiError?.response?.status
      : 500;
    if (!supportedHttpStatusCodes.includes(httpStatusCode)) {
      httpStatusCode = 500;
    }
    const mappedErrorCode = getMappedErrorCode(
      apiKey,
      firstApiError,
      httpStatusCode
    );
    firstApiError["localCode"] = mappedErrorCode;
    firstApiError["localErrorMessage"] = getMappedErrorMessage(mappedErrorCode);
    apiError.response.data = firstApiError;
  }
};

const getSanitisedErrorMessage = (errorMessage) => {
  if (typeof errorMessage === "string" && errorMessage.trim().length > 0) {
    return errorMessage;
  } else {
    return errorMesages[`${UNIVERSAL_ERROR_CODE}`];
  }
};

export { transformApiError, getSanitisedErrorMessage };
