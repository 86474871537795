// All orders related api error has need to map in the range of 1540001 to 1599999
// The number is divided into 3 parts
// First part is the api key range, for orders will always be 15
// Second part is the http error status code e.g 400
// Last part is the identifier for the unique error message we want to display.
// Two server api error codes are okay to be mapped to single error code, if required.

const ordersErrorCodeMappings = {
  404: {
    CODE_ORDER_NOT_FOUND: 1540401,
  },
  500: {
    default: 1550001,
  },
  // default is used to handle the case where it is an unknown http status code
  default: {
    default: 1599901,
    // This default is the absolute fallback error code for orders api.
  },
};

export default ordersErrorCodeMappings;
