import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/use-auth/useAuth";

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const status = auth?.user?.status;

  return status === "OK" ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
