const setLocalStorage = (key, value) => {
    if (typeof(Storage) !== "undefined") {
        localStorage.setItem(key, JSON.stringify(value));
    }
    else {
        console.log("Sorry! No Web Storage support..!");
    }
}

const getLocalStorage = (key) => JSON.parse(localStorage.getItem(key));

export { setLocalStorage, getLocalStorage};
