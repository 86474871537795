import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Questionnaire from "../../../../component/questionnaire/Questionnaire";
import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import { step } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";
import CollectionStepper from "../../collection-stepper/CollectionStepper";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import PageHeading from "../../../../component/page-heading/PageHeading";

const Question6Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useVehicleCollectionStepCheck(steps.roadWorthyQuestion6, "../question5");

  const onActionClicked = (result) => {
    dispatch(step(steps.collectionContactDetails));
    if (result === 1) {
      navigate("../../contact-and-collection-details");
    } else if (result === 2) {
      navigate("../not-road-worthy");
    }
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      <PageHeading align="left">Arranging a collection</PageHeading>
      <p className="mb-5">
        We need to ask you some questions to check that your vehicle is
        roadworthy and ready to be collected. If a collection is arranged and
        the vehicle is unsafe to drive your collection will be cancelled and you
        will be charged a fee.
      </p>
      <h3>Question 6 of 6: Does the vehicle have a working battery?</h3>

      <p className="mb-5">
        If the vehicle is electric or a hybrid the battery should be fully
        charged ready for collection.
      </p>
      <Questionnaire
        option1Title="Yes"
        option2Title="No"
        option1Content="The battery is working."
        option2Content="The battery is not working."
        option1ActionContent="Yes"
        option2ActionContent="No"
        actionCallback={onActionClicked}
      />
    </WizardBasePage>
  );
};

export default Question6Page;
