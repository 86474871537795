import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { Col, Form, InputGroup } from "react-bootstrap";
import { GenericRadioSelectField } from "./field-utils/field-utils.js";

export const VerifyDigitsField = (props) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors, isSubmitted, touchedFields },
  } = methods;

  return (
    <Form.Group>
      <Form.Label className="w-100 h5" id="verifyDigits">
        Enter the following numbers
      </Form.Label>
      <InputGroup className="mb-3 mt-2">
        <Col>
          <Form.Label>5th</Form.Label>
          <Form.Control
            type="number"
            maxLength={1}
            min={0}
            max={9}
            aria-labelledby="verifyDigits"
            isInvalid={errors.verifyDigit1}
            {...register("verifyDigit1")}
            {...props}
          />
        </Col>
        <Col className="ms-3">
          <Form.Label>6th</Form.Label>
          <Form.Control
            type="number"
            maxLength={1}
            min={0}
            max={9}
            aria-labelledby="verifyDigits"
            isInvalid={errors.verifyDigit2}
            {...register("verifyDigit2")}
            {...props}
          />
        </Col>
        <Col className="ms-3">
          <Form.Label>7th</Form.Label>
          <Form.Control
            type="number"
            maxLength={1}
            min={0}
            max={9}
            aria-labelledby="verifyDigits"
            isInvalid={errors.verifyDigit3}
            {...register("verifyDigit3")}
            {...props}
          />
        </Col>
        <Col className="ms-3">
          <Form.Label>8th</Form.Label>
          <Form.Control
            type="number"
            maxLength={1}
            min={0}
            max={9}
            aria-labelledby="verifyDigits"
            isInvalid={errors.verifyDigit4}
            {...register("verifyDigit4")}
            {...props}
          />
        </Col>
        <Form.Control.Feedback
          type="invalid"
          className={`text-start ${
            (isSubmitted ||
              touchedFields["verifyDigit1"] ||
              touchedFields["verifyDigit2"] ||
              touchedFields["verifyDigit3"] ||
              touchedFields["verifyDigit4"]) &&
            (errors.verifyDigit1 ||
              errors.verifyDigit2 ||
              errors.verifyDigit3 ||
              errors.verifyDigit4)
              ? "d-block"
              : "d-none"
          }`}
        >
          {`${
            (isSubmitted ||
              touchedFields["verifyDigit1"] ||
              touchedFields["verifyDigit2"] ||
              touchedFields["verifyDigit3"] ||
              touchedFields["verifyDigit4"]) &&
            (errors.verifyDigit1 ||
              errors.verifyDigit2 ||
              errors.verifyDigit3 ||
              errors.verifyDigit4)
              ? errors.verifyDigit1?.message ??
                errors.verifyDigit2?.message ??
                errors.verifyDigit3?.message ??
                errors.verifyDigit4?.message
              : ""
          }`}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

VerifyDigitsField.propTypes = {};

export const QuestionnaireRadioField = ({
  questionNumber,
  questionText,
  questionHint,
  answers,
  ...props
}) => {
  return (
    <GenericRadioSelectField
      controlId="questionnaireRadio"
      radioText={`Q${questionNumber}. ${questionText}`}
      radioHint={questionHint}
      options={answers}
      {...props}
    />
  );
};

QuestionnaireRadioField.propTypes = {
  questionText: PropTypes.string,
  questionHint: PropTypes.string,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};
