import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const AlertIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    role="img"
    viewBox="0 0 50 50"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M24.53 14 11 37h27.06L24.53 14Zm-2.087 20.06v-3.57h3.503v3.57h-3.503Zm0-5.353v-7.138h3.503v7.138h-3.503Z"
    />
  </svg>
);

AlertIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { AlertIcon };
export default AlertIcon;
