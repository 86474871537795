import { Link } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";

const ReferredPage = () => {
  return (
    <BasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
    >
      <TitleWithIcon
        title="We can't complete your request online"
        variant="info"
      />
      <h3 className="text-primary pt-3 pb-1">What happens next?</h3>
      <p>
        Please call us on <Link to="tel:0344 879 6633">0344 879 6633</Link> and
        one of our Customer Service team will help you. Our lines are open 9am -
        5pm Monday - Friday, 9am - 12pm on Saturday. We're closed on Sundays and
        bank holidays.
      </p>

      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ReferredPage;
