import { Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import VehicleSelectionForm from "../../../component/forms/vehicle-selection/VehicleSelectionForm";
import { useContractsForVehicleCollection } from "../../../api/hooks/contracts";
import {
  step,
  contractDetails,
} from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../vehicle-collection-steps";
import CollectionStepper from "../collection-stepper/CollectionStepper";
import useVehicleCollectionStepCheck from "../hooks/useVehicleCollectionStepCheck";
import PageHeading from "../../../component/page-heading/PageHeading";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const VehicleCollectionVehicleSelectionPage = () => {
  const { contracts, isError, errorResponse } =
    useContractsForVehicleCollection();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useVehicleCollectionStepCheck(steps.vehicleSelection, "../");

  const onFormSubmit = (selectedContract) => {
    const contract = contracts.find(
      // eslint-disable-next-line
      (c) => c.contractId == selectedContract.vehicle
    );
    dispatch(contractDetails(contract));
    dispatch(step(steps.roadWorthyQuestion1));
    navigate("../road-worthiness/question1");
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}
      <PageHeading align="left">Choose vehicle</PageHeading>
      <p>
        Confirm the vehicle you would like to arrange collection for. You will
        only be able to select vehicles that are:
      </p>
      <ul>
        <li>Within 90 days of your contract end date.</li>
        <li>OR your contract end date has already passed.</li>
        <li>
          OR you have accepted the cost of a current Early Termination quote
          (please allow 48 hours from accepting the quotation before arranging
          the collection).
        </li>
      </ul>
      <CenterAlignedButtonContainer>
        <VehicleSelectionForm
          vehicles={contracts.map((c) => ({
            id: c.contractId,
            registrationNumber: c.vehicle.registrationNumber,
          }))}
          onFormSubmit={onFormSubmit}
          submitButtonText="Continue"
        />
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default VehicleCollectionVehicleSelectionPage;
