import BasePage from "../../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import TitleWithIcon from "../../../../component/title-with-icon/TitleWithIcon";
const CancelCollectionSuccessPage = () => {
  return (
    <BasePage
      pageTitle="Amend a collection"
      pageSubTitle="Amend an existing vehicle collection booking."
    >
      <TitleWithIcon
        title="We have cancelled your collection"
        variant="success"
      />

      <p className="mt-4">
        Thanks for telling us that you don't want us to collect your vehicle.
        We've cancelled your booking, so you don't have to do anything else.
      </p>

      <CenterAlignedButtonContainer>
        <BackToHomeButton />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default CancelCollectionSuccessPage;
