import { useSelector } from "react-redux";

import Stepper from "../../../component/stepper/Stepper";
import steps from "../vehicle-collection-steps";

const stepperSteps = [
  {
    step: 1,
    description: "Choose vehicle",
  },
  {
    step: 2,
    description: "Road worthiness",
  },
  {
    step: 3,
    description: "Contact and collection details",
  },
  {
    step: 4,
    description: "Collection date",
  },
  {
    step: 5,
    description: "Booking confirmation",
  },
];

const CollectionStepper = ({ overrideActiveStep }) => {
  const currentStep = useSelector((state) => state.vehicleCollection.step);
  let activeStep = 1;
  if (currentStep === steps.vehicleSelection) {
    activeStep = 1;
  } else if (
    currentStep >= steps.roadWorthyQuestion1 &&
    currentStep <= steps.roadWorthyQuestion6
  ) {
    activeStep = 2;
  } else if (currentStep === steps.collectionContactDetails) {
    activeStep = 3;
  } else if (currentStep === steps.collectionDate) {
    activeStep = 4;
  } else if (currentStep === steps.summary) {
    activeStep = 5;
  }

  if (
    overrideActiveStep &&
    overrideActiveStep >= 1 &&
    overrideActiveStep <= 5
  ) {
    activeStep = overrideActiveStep;
  }

  const onStepperClicked = () => {};

  return (
    <Stepper
      steps={stepperSteps}
      activeStep={activeStep}
      mode="number"
      showCompletionStep="number"
      onClickHandler={onStepperClicked}
    />
  );
};

export default CollectionStepper;
