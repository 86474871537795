import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import TickOutlineIcon from "../../../icons/TickOutlineIcon";
import { findOrdinalByDay } from "../../../../utils/ordinal/ordinal-utils";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import useAuth from "../../../../hooks/use-auth/useAuth";
import { useDirectDebitMandate } from "../../../../api/hooks/direct-debit";
import magicStrings from "../../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../../api/error-handling/transform-error";

const ConfirmedDirectDebit = () => {
  const { auth } = useAuth();
  const profile = auth?.user;
  const { mandate, isError, errorResponse } = useDirectDebitMandate();

  return (
    <>
      {isError ? (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      ) : (
        <div className="text-center">
          <TickOutlineIcon
            title="Direct debit success"
            className="text-primary"
            size="lg"
          />
          <h2 className="my-4">Your Direct Debit is set up</h2>
          <p className="mb-4">
            A confirmation of your Direct Debit instruction has been sent to{" "}
            <Link
              className={`${magicStrings.maskInformation}`}
              to={`mailto:${profile?.email}`}
            >
              {profile?.email}
            </Link>
            .
          </p>
          <p className="border-top pt-4">
            Your Direct Debit payments will now be taken on{" "}
            <strong className={`${magicStrings.maskInformation}`}>
              {findOrdinalByDay(mandate?.deferredDdDay)}
            </strong>{" "}
            of each month.
          </p>
          <p className="border-bottom pb-4">
            To change your collection date please contact us on{" "}
            <Link to="tel:0800 302 9160">0800 302 9160</Link>.
          </p>
          <p className="mt-4">
            You will receive a notification advising you of the amount to be
            taken prior to any Direct Debit being taken.
          </p>
          <p>
            If you have any questions please visit our{" "}
            <Link to="/help">Payments FAQs</Link> page.
          </p>
          <CenterAlignedButtonContainer>
            <BackToHomeButton variant="primary" />
          </CenterAlignedButtonContainer>
        </div>
      )}
    </>
  );
};

export default ConfirmedDirectDebit;
