import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";

import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import {
  pageTitle,
  pageSubTitle,
} from "../../utils/contract-amends-page-utils";
import ContractAmendsStepper from "../../contract-amends-stepper/ContractAmendsStepper";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../../component/center-aligned-container/CenterAlignedContainer";
import {
  partners as dispatchPartners,
  deletedPartners as dispatchDeletedPartners,
  step,
  currentPartnerIndex as dispatchCurrentPartnerIndex,
} from "../../../../redux/features/contract-amends/contractAmendsSlice";
import useContractAmendsStepCheck from "../../hooks/useContractAmendsStepCheck";
import steps from "../../contract-amends-steps";
import PageHeading from "../../../../component/page-heading/PageHeading";
import magicStrings from "../../../../utils/magic-string";

const ValidateExistingPartnerPage = () => {
  let currentPartnerIndex = useSelector(
    (state) => state.contractAmends.currentPartnerIndex
  );
  if (currentPartnerIndex === null) {
    currentPartnerIndex = 0;
  }
  useContractAmendsStepCheck(
    steps.bch.limitedCompany.partners[currentPartnerIndex]
      .validateExistingPartner,
    "../../save-quote"
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const partners = useSelector((state) => state.contractAmends.partners);
  const totalPartners = Array.isArray(partners) ? partners.length : 0;
  const deletedPartners = useSelector(
    (state) => state.contractAmends.deletedPartners
  );

  const partnerPersonalDetails =
    currentPartnerIndex < totalPartners
      ? partners[currentPartnerIndex].personalDetails
      : {};
  const partnerName =
    currentPartnerIndex < totalPartners
      ? `${partnerPersonalDetails.firstName} ${partnerPersonalDetails.lastName}`
      : "";
  const partnerType = useSelector((state) => state.contractAmends.partnerType);

  useEffect(() => {
    if (totalPartners <= 0 || totalPartners < currentPartnerIndex + 1) {
      navigate("../../save-quote");
    }
  }, [currentPartnerIndex, navigate, totalPartners]);

  useEffect(() => {
    if (totalPartners > 0 && totalPartners > currentPartnerIndex) {
      if (
        partners[currentPartnerIndex] &&
        partners[currentPartnerIndex].isNewPartner &&
        !partners[currentPartnerIndex].isNewPartnerSaved
      ) {
        if (currentPartnerIndex === 0) {
          navigate("../../save-quote", { replace: true });
        } else {
          dispatch(dispatchCurrentPartnerIndex(currentPartnerIndex - 1));
          navigate("../validate-existing-partner", { replace: true });
        }
      }
    }
  }, [currentPartnerIndex, navigate, totalPartners, partners, dispatch]);

  const onButtonYesClicked = () => {
    dispatch(
      step(
        steps.bch.limitedCompany.partners[currentPartnerIndex].personalDetails
      )
    );
    navigate("../personal-details");
  };

  const onButtonNoClicked = () => {
    const savedDeletedPartners = [...deletedPartners];
    savedDeletedPartners.push(partners[currentPartnerIndex]);
    dispatch(dispatchDeletedPartners(savedDeletedPartners));
    const savedPartners = [...partners];
    savedPartners.splice(currentPartnerIndex, 1);
    dispatch(dispatchPartners(savedPartners));
    if (savedPartners.length > 0) {
      if (savedPartners.length >= currentPartnerIndex + 1) {
        navigate("../validate-existing-partner", { replace: true });
      }
    } else {
      dispatch(
        step(
          steps.bch.limitedCompany.partners[currentPartnerIndex].addNewPartner
        )
      );
      navigate("../add-new-partner");
    }
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Personal details</PageHeading>
          <p>Please check the address details we hold for you are correct.</p>
        </div>
      </CenterAlignedContainer>
      <CenterAlignedButtonContainer>
        <div>
          <div>
            {partnerType}{" "}
            <strong>{`${currentPartnerIndex + 1} of ${totalPartners}`}</strong>
          </div>
          <hr className="mt-1" />
          <div>
            Is{" "}
            <strong
              className={`text-capitalize ${magicStrings.maskInformation}`}
            >
              {partnerName}
            </strong>{" "}
            still a <strong>{partnerType}</strong> of the business?
          </div>
          <hr className="mt-1" />
        </div>
        <Button id="btnYesStillPartner" onClick={onButtonYesClicked}>
          Yes, continue
        </Button>
        <Button
          id="btnNoNotPartnerAnymore"
          onClick={onButtonNoClicked}
          variant="outline-primary"
        >
          No, not anymore
        </Button>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default ValidateExistingPartnerPage;
