import * as yup from "yup";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import TickOutlineIcon from "../../icons/TickOutlineIcon";
import NotificationBanner from "../../notification-banner/NotificationBanner";
import { TimeAtAddressField } from "../fields";
import AddressLookup from "../address-lookup/AddressLookup";
import {
  useAddressesByPostcode,
  useAddressByLineId,
} from "../../../api/hooks/addresses";
import * as fieldSchemas from "../schemas/fields";
import { useEffect, useState, Fragment } from "react";
import magicStrings from "../../../utils/magic-string";

const AddressDetailsForm = ({
  minimumTimeAtAddressMonths,
  defaultValues,
  onFormSubmit,
}) => {
  const formDefaultValues =
    // Retrieves the first address with postcode, address line 1 or time at address incomplete (All proceeding addresses that match this criteria are discarded)
    defaultValues instanceof Array
      ? defaultValues.find((address) => {
          return (
            !address.addressLine1 ||
            !address.postCode ||
            (address.timeAtAddressMonths === 0 &&
              address.timeAtAddressYears === 0)
          );
        }) ?? {}
      : {};

  const clearFormDefaultValues = {
    postCode: "",
    isAddressManuallyEntered: false,
    selectAddress: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    county: "",
    timeAtAddressMonths: 0,
    timeAtAddressYears: 0,
  };

  const preSavedAddresses =
    // Discards all addresses with postcode, address line 1 or time at address incomplete (All proceeding addresses that match this criteria are discarded)
    defaultValues instanceof Array
      ? defaultValues.filter((address) => {
          if (
            !address.addressLine1 ||
            !address.postCode ||
            (address.timeAtAddressMonths === 0 &&
              address.timeAtAddressYears === 0)
          ) {
            return false;
          } else return true;
        })
      : [];

  const methods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.postCodeSchema,
        selectAddress: yup
          .string()
          .when(
            ["isAddressManuallyEntered"],
            ([isAddressManuallyEntered], schema) => {
              return !isAddressManuallyEntered
                ? fieldSchemas.selectAddressSchema.selectAddress
                : schema.notRequired();
            }
          ),
        addressLine1: yup
          .string()
          .when(
            ["isAddressManuallyEntered"],
            ([isAddressManuallyEntered], schema) => {
              return isAddressManuallyEntered
                ? fieldSchemas.addressLine1Schema.addressLine1
                : schema.notRequired();
            }
          ),
        ...fieldSchemas.addressLine2Schema,
        ...fieldSchemas.addressLine3Schema,
        ...fieldSchemas.citySchema,
        ...fieldSchemas.countySchema,
        ...fieldSchemas.timeAtAddressSchema,
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit, watch, formState, setError, clearErrors } = methods;

  const watchSelectAddress = watch("selectAddress");
  const { refetch, addresses } = useAddressesByPostcode(watch("postCode"));
  const { address } = useAddressByLineId(watchSelectAddress);

  const [savedAddresses, setSavedAddresses] = useState([...preSavedAddresses]);
  const watchTimeAtAddressMonths = watch("timeAtAddressMonths", 0);
  const watchTimeAtAddressYears = watch("timeAtAddressYears", 0);
  const [totalAddressTime, setTotalAddressTime] = useState(0);
  const [maxTimeReached, setMaxTimeReached] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);

  // Calculate total address time when savedAddrees changes
  useEffect(() => {
    let totalMonths = 0;

    if (savedAddresses.length)
      savedAddresses.forEach((address) => {
        let years = address.timeAtAddressYears
          ? parseInt(address.timeAtAddressYears)
          : 0;
        let months = address.timeAtAddressMonths
          ? parseInt(address.timeAtAddressMonths)
          : 0;
        totalMonths += years * 12 + months;
      });

    setTotalAddressTime(totalMonths);
  }, [savedAddresses]);

  // Chek if the total address time given is above minimum required time (i.e. max history needed has been reached)
  useEffect(() => {
    if (
      totalAddressTime &&
      parseInt(totalAddressTime) >= parseInt(minimumTimeAtAddressMonths)
    ) {
      setMaxTimeReached(true);
    } else {
      setMaxTimeReached(false);
    }
  }, [minimumTimeAtAddressMonths, totalAddressTime, formState]);

  // Only display timeataddress error alert if the field is invalid and it is touched or form submitted
  useEffect(() => {
    if (
      (formState.submitCount > 0 && !formState.isSubmitSuccessful) ||
      formState.touchedFields["timeAtAddressYears"] ||
      formState.touchedFields["timeAtAddressMonths"]
    ) {
      // Not the best solution as this should be done in schema.
      // Will have to re-visit this
      if (
        isNaN(watchTimeAtAddressMonths) ||
        isNaN(watchTimeAtAddressYears) ||
        (watchTimeAtAddressMonths <= 0 && watchTimeAtAddressYears <= 0) ||
        watchTimeAtAddressMonths < 0 ||
        watchTimeAtAddressYears < 0 ||
        watchTimeAtAddressMonths > 11 ||
        watchTimeAtAddressYears > 99
      ) {
        setErrorAlert({
          id: "timeataddress-invalid",
          key: "danger",
          variant: "danger",
          message: "Time at address must be 1 month or greater.",
        });
        setError("timeAtAddressYears", { type: "focus" });
        setError("timeAtAddressMonths", { type: "focus" });
      } else {
        setErrorAlert(null);
        clearErrors("timeAtAddressMonths");
        clearErrors("timeAtAddressYears");
      }
    }
  }, [
    watchTimeAtAddressMonths,
    watchTimeAtAddressYears,
    formState.submitCount,
    formState.isSubmitSuccessful,
    formState.touchedFields,
    setError,
    clearErrors,
  ]);

  const handleAddressSave = (data) => {
    if (
      (data.timeAtAddressMonths <= 0 && data.timeAtAddressYears <= 0) ||
      data.timeAtAddressMonths < 0 ||
      data.timeAtAddressYears < 0
    ) {
      setError("timeAtAddressYears", { type: "focus" }, { shouldFocus: true });
      setError("timeAtAddressMonths", { type: "focus" });
      return;
    }
    if (!maxTimeReached) {
      setSavedAddresses([...savedAddresses, data]);
      methods.reset({ ...clearFormDefaultValues });
      setErrorAlert(null);
    }
  };

  const handleAddressRemove = (address) => {
    const currentSavedAddresses = [...savedAddresses];
    const updatedSavedAddresses = currentSavedAddresses.filter(
      (savedAddress) => {
        if (
          savedAddress.postCode === address.postCode &&
          savedAddress.addressLine1 === address.addressLine1
        ) {
          return false;
        } else return true;
      }
    );
    setSavedAddresses(updatedSavedAddresses);
  };

  const onSubmit = () => {
    if (maxTimeReached) {
      onFormSubmit(savedAddresses);
    }
  };

  const monthsToYearMonthText = (timeInMonths) => {
    const years = Math.trunc(parseInt(timeInMonths / 12)) ?? 0;
    const months = parseInt(timeInMonths % 12) ?? 0;
    const yearsText =
      years > 0
        ? years + " year" + (years === 1 ? "" : "s") + (months > 0 ? ", " : " ")
        : "";
    const monthsText =
      months > 0 ? months + " month" + (months <= 1 ? "" : "s ") : "";
    return yearsText + monthsText === "" ? "no" : yearsText + monthsText;
  };

  return (
    <>
      <Row className="">
        {savedAddresses.map((address, index) => {
          return (
            <Col
              xs={12}
              key={"Address-" + (parseInt(index) + 1)}
              className="mb-3"
            >
              <NotificationBanner size="" variant="success" isFullHeight={true}>
                <Fragment key=".0">
                  <div className="d-flex flex-column h-100">
                    <div className="h3">
                      <TickOutlineIcon
                        className="text-success mb-1"
                        title={"Address " + (parseInt(index) + 1)}
                        size="sm"
                      />
                      <span className="px-2">
                        Address {index + 1}
                        {index === 0 ? " (current address)" : ""}
                      </span>
                    </div>
                    <div className="mb-1">
                      <div className={`h4 ${magicStrings.maskInformation}`}>
                        {address.addressLine1}
                      </div>
                      {address.addressLine2 && (
                        <div className={`${magicStrings.maskInformation}`}>
                          {address.addressLine2}
                        </div>
                      )}
                      {address.addressLine3 && (
                        <div className={`${magicStrings.maskInformation}`}>
                          {address.addressLine3}
                        </div>
                      )}
                      <div className={`${magicStrings.maskInformation}`}>
                        {address.city}
                      </div>
                      <div className={`${magicStrings.maskInformation}`}>
                        {address.county}
                      </div>
                      <div className={`${magicStrings.maskInformation}`}>
                        {" "}
                        {address.postCode.toUpperCase()}
                      </div>
                    </div>
                    <div className="d-grid mt-auto">
                      <hr className="mt-1 mb-2" />
                      <div className="h6">
                        {address.timeAtAddressYears ?? 0} year
                        {address.timeAtAddressYears <= 1 ? "" : "s"},&nbsp;
                        {address.timeAtAddressMonths ?? 0} month
                        {address.timeAtAddressMonths <= 1 ? "" : "s"}
                      </div>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        className="px-2"
                        id={"RemoveAddress" + (index + 1) + "Btn"}
                        onClick={() => {
                          handleAddressRemove(address);
                        }}
                      >
                        Remove address
                      </Button>
                    </div>
                  </div>
                </Fragment>
              </NotificationBanner>
            </Col>
          );
        })}
      </Row>
      {!maxTimeReached && formState.isSubmitted && (
        <Alert variant="warning">
          You have provided us with {monthsToYearMonthText(totalAddressTime)}{" "}
          history. Please provide us with at least{" "}
          {monthsToYearMonthText(minimumTimeAtAddressMonths - totalAddressTime)}{" "}
          more history.
        </Alert>
      )}

      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(handleAddressSave)}
          noValidate
          name="AddressDetailsForm"
        >
          {!maxTimeReached && (
            <>
              <AddressLookup
                addresses={addresses ?? { addresses: [] }}
                refetchAddresses={refetch}
                addressDetails={address}
              />
              <TimeAtAddressField />
              {errorAlert ? (
                <Alert variant={errorAlert.variant}>{errorAlert.message}</Alert>
              ) : (
                ""
              )}
              <div className="d-grid gap-2">
                <Button className="mt-3" id="btnAddAddress" type="submit">
                  Add address
                </Button>
              </div>
            </>
          )}
        </Form>
      </FormProvider>

      {maxTimeReached && (
        <div className="d-grid gap-2">
          <Button
            className="mt-3"
            id="btnAddressDetailsFormSubmit"
            onClick={onSubmit}
          >
            Continue to employment details
          </Button>
        </div>
      )}
    </>
  );
};

AddressDetailsForm.propTypes = {
  onFormSubmit: PropTypes.func,
  minimumTimeAtAddressMonths: PropTypes.number,
  defaultValues: PropTypes.arrayOf(
    PropTypes.shape({
      postCode: PropTypes.string,
      isAddressManuallyEntered: PropTypes.bool,
      selectAddress: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      addressLine3: PropTypes.string,
      city: PropTypes.string,
      county: PropTypes.string,
      timeAtAddressMonths: PropTypes.number,
      timeAtAddressYears: PropTypes.number,
    })
  ),
};
export default AddressDetailsForm;
