import { Row } from "react-bootstrap";
import Tile from "../../component/tile/Tile";

const HelpPageTiles = ({ userRegulated }) => {
  return (
    <Row>
      <Tile
        cardId="moneyWorries"
        cardTitle="Money worries"
        cardLinkUrl={
          userRegulated === true
            ? "/help/money-worries"
            : "/help/money-worries-non-regulated"
        }
        cardLinkContent="View support"
        isLink={true}
      >
        See how we can help you to control your finances.
      </Tile>

      <Tile
        cardId="makePayment"
        cardTitle="How to pay"
        cardLinkUrl="/payments/make-a-payment"
        cardLinkContent="How to pay"
        isLink={true}
      >
        See how to make a payment to your account.
      </Tile>
      <Tile
        cardId="accessibilitySupport"
        cardTitle="Accessibility and support"
        cardLinkUrl="/help/accessibility-home"
        cardLinkContent="Find out more"
        isLink={true}
      >
        Please tell us more about yourself. This helps us to make sure we're
        supporting you in the best way.
      </Tile>
    </Row>
  );
};

export default HelpPageTiles;
