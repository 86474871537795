import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForgotUserId } from "../../../api/hooks/auth";
import { DateTime } from "luxon";
import BasePage from "../../basepages/basepage/BasePage";
import ForgotUserIdForm from "../../../component/forms/forgotten-details/forgot-user-id/ForgotUserIdForm";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import PageHeading from "../../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const ForgotUserIdPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const onSuccessful = () => {
    setShowSuccess(true);
  };

  const onFailure = (error) => {
    setShowSuccess(false);
    setErrorMessage(error);
  };

  const { mutate } = useForgotUserId(onSuccessful, onFailure);

  const onFormSubmit = (data) => {
    mutate({
      dateOfBirth: DateTime.utc(
        Number(data.dobYear),
        Number(data.dobMonth),
        Number(data.dobDay)
      ).toISO(),
      firstName: data.firstName,
      fleetNumber: data.fleetNumber,
      lastName: data.lastName,
      vehicleRegistration: data.vehicleReg,
    });
  };

  return (
    <BasePage
      pageTitle="Forgotten user ID or alias"
      pageSubTitle="Get a reminder of your user ID or alias."
    >
      {showSuccess ? (
        <>
          <TitleWithIcon title="We've sent you an email" variant="success" />
          <CenterAlignedContainer>
            <h2 className="h4 mt-5 text-primary fw-bold">
              Forgotten your login details
            </h2>

            <p>
              We've sent you a reminder of your user ID or alias. If you don't
              see it in your email inbox, please check your junk or spam folder.
              If you can't find it, you can ring us on{" "}
              <Link to="tel:0344 879 6633">0344 879 6633</Link>.
            </p>
          </CenterAlignedContainer>

          <CenterAlignedButtonContainer>
            <Button
              as={Link}
              to="/login"
              id="btnLogin"
              className="text-decoration-none"
            >
              Login
            </Button>
          </CenterAlignedButtonContainer>
        </>
      ) : (
        <>
          <PageHeading align="center">Forgotten your login details</PageHeading>
          <p className="text-center mb-4">
            Please confirm your account and personal details so that we can
            issue a reminder of your user ID or alias.
          </p>
          <CenterAlignedButtonContainer className="mb-0 col-sm-12">
            {errorMessage?.localErrorMessage && (
              <Alert variant="danger">
                {getSanitisedErrorMessage(errorMessage.localErrorMessage)}
              </Alert>
            )}
            <ForgotUserIdForm onFormSubmit={onFormSubmit} />
          </CenterAlignedButtonContainer>
        </>
      )}
    </BasePage>
  );
};
export default ForgotUserIdPage;
