import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import BasePage from "../../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import TitleWithIcon from "../../../../component/title-with-icon/TitleWithIcon";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import CardProvidersIcon from "../../../../component/icons/CardProvidersIcon";
import { reset } from "../../../../redux/features/payments/cardPaymentSlice";
import steps from "../card-payment-steps";

const PaymentFailurePage = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.cardPayment.step);
  const [isStepChecked, setIsStepChecked] = useState(false);
  const knownErrorReasons = ["DEC", "ERR", "EXP", "TYP", "GENERIC"];

  let errorCode = useSelector((state) => state.cardPayment.actionCode);
  errorCode = typeof errorCode === "string" ? errorCode.toUpperCase() : "";
  if (!knownErrorReasons.includes(errorCode)) {
    errorCode = "GENERIC";
  }
  const navigate = useNavigate();

  const onClickHandler = () => {
    dispatch(reset());
    navigate("/payments");
  };

  const onHomeButtonClicked = () => {
    dispatch(reset());
  };

  useEffect(() => {
    if (!isStepChecked) {
      setIsStepChecked(true);
      if (currentStep < steps.failure) {
        navigate("/payments");
      }
    }
  }, [navigate, currentStep, isStepChecked]);

  return (
    <BasePage pageTitle="Make a payment">
      {errorCode === "TYP" && (
        <>
          <TitleWithIcon
            title="We don't accept this type of card"
            variant="failure"
          />
          <div className="text-center">
            <p>
              Please note that payments must be in UK pounds sterling (GBP) and
              from a UK registered bank.
            </p>
            <p>
              Here are the types of debit cards you can use to make payment.
            </p>
            <CardProvidersIcon />
          </div>
        </>
      )}
      {errorCode === "DEC" && (
        <>
          <TitleWithIcon
            title="Your bank has declined this payment"
            variant="failure"
          />
          <div className="text-center">
            <p>
              Please call them to find out why, or try to make the payment again
              using a different card.
            </p>
          </div>
        </>
      )}
      {(errorCode === "ERR" || errorCode === "GENERIC") && (
        <>
          <TitleWithIcon
            title="There has been an error processing your payment"
            variant="failure"
          />
          <div className="text-center">
            <p>
              Don't worry, the problem's at our end. We're trying to fix it as
              quickly as we can.
            </p>
            <p>Please try to make this payment again later.</p>
          </div>
        </>
      )}
      {errorCode === "EXP" && (
        <>
          <TitleWithIcon title="This card has expired" variant="failure" />
          <div className="text-center">
            <p>Please try again with a different card.</p>
          </div>
        </>
      )}
      <CenterAlignedButtonContainer>
        <Button
          id="btnPaymentAgain"
          className="text-decoration-none"
          variant="primary"
          onClick={onClickHandler}
        >
          Try payment again
        </Button>
        <BackToHomeButton onButtonClicked={onHomeButtonClicked} />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default PaymentFailurePage;
