import {
  employmentStatuses,
  titles,
} from "../../../component/forms/fields/data/field-data";
import { DateTime } from "luxon";

const getAddresses = (addressDetails) => {
  const addresses = [];
  if (Array.isArray(addressDetails)) {
    addressDetails.forEach((address, index) => {
      addresses.push({
        addressNumber: index + 1,
        overseasOrBfpo: false,
        yearsAtAddress: address.timeAtAddressYears,
        monthsAtAddress: address.timeAtAddressMonths,
        line1: address.addressLine1,
        line2: address.addressLine2 ?? "",
        line3: address.addressLine3 ?? "",
        city: address.city ?? "",
        county: address.county ?? "",
        postcode: address.postCode,
      });
    });
  }
  return addresses;
};

const getEmployments = (employmentDetails, firstAddress) => {
  const isEmployed = [1, 2, 9, "1", "2", "9"].includes(
    employmentDetails.employmentStatus
  );
  if (isEmployed) {
    return [
      {
        employmentNumber: 1,
        occupation: employmentDetails.occupation,
        employer: employmentDetails.employerName,
        yearsAtEmployment: employmentDetails.timeAtEmploymentYears,
        monthsAtEmployment: employmentDetails.timeAtEmploymentMonths,
        address: {
          line1: employmentDetails.addressLine1 ?? "",
          line2: employmentDetails.addressLine2 ?? "",
          line3: employmentDetails.addressLine3 ?? "",
          city: employmentDetails.city ?? "",
          county: employmentDetails.county ?? "",
          postcode: employmentDetails.postCode ?? "",
        },
      },
    ];
  } else {
    const employmentStatusText = employmentStatuses.find(
      (s) => s.id.toString() === employmentDetails.employmentStatus.toString()
    );
    return [
      {
        employmentNumber: 1,
        occupation: employmentStatusText?.text,
        employer: employmentStatusText?.text,
        yearsAtEmployment: 5,
        monthsAtEmployment: 0,
        address: {
          line1: firstAddress.addressLine1 ?? "",
          line2: firstAddress.addressLine2 ?? "",
          line3: firstAddress.addressLine3 ?? "",
          city: firstAddress.city ?? "",
          county: firstAddress.county ?? "",
          postcode: firstAddress.postCode ?? "",
        },
      },
    ];
  }
};

const getPrivateProposal = (piDetails) => {
  return {
    privateProposal: {
      affordabilityPersonalDetails: {
        maritalStatus: piDetails.personalDetails.maritalStatus,
        numberOfDependents: piDetails.personalDetails.numOfDependants,
        accommodationStatus: piDetails.personalDetails.accommodation,
        addresses: getAddresses(piDetails.addressDetails),
        homeTelephone: piDetails.personalDetails.homeNumber ?? "",
        mobile: piDetails.personalDetails.mobileNumber ?? "",
        workTelephoneNo: piDetails.personalDetails.workNumber ?? "",
        emailAddress: piDetails.personalDetails.emailAddress ?? "",
      },
      employments: getEmployments(
        piDetails.employmentDetails,
        piDetails.addressDetails[0]
      ),
      currentEmploymentStatus: piDetails.employmentDetails.employmentStatus,
      affordability: {
        grossAnnualIncome: piDetails.financialDetails.grossIncome,
        mortgageOrRentContribution: piDetails.financialDetails.shareOfRent,
        otherExpenditure: piDetails.financialDetails.shareOfChildCosts,
        expectingCircumstancesChange:
          piDetails.financialDetails.expectedChanges === 1 ? true : false,
        circumstancesChangeText:
          piDetails.financialDetails.expectedChanges === 1
            ? piDetails.financialDetails.descriptionExpectedChanges
            : "",
      },
      profileUpdatePermissionGiven: true,
      customerPermissionGiven: true,
    },
  };
};

const getBusinessProposal = (partners, deletedPartners, maxPartners) => {
  const partnersToReturn = [];
  if (Array.isArray(partners)) {
    partners.forEach((partner, index) => {
      if (index < maxPartners) {
        const title = titles.find(
          (t) => t.id.toString() === partner.personalDetails.title.toString()
        );
        const partnerToAdd = {
          partnerNum: partner.isNewPartner
            ? 0
            : partner.personalDetails.partnerNum,
          status: partner.isNewPartner ? "New" : "Amend",
          title: title?.text ? title.text : "",
          forename: partner.personalDetails.firstName,
          surname: partner.personalDetails.lastName,
          dateOfBirth: DateTime.utc(
            partner.personalDetails.dobYear,
            partner.personalDetails.dobMonth,
            partner.personalDetails.dobDay
          ).toISO(),
          genderCode: partner.personalDetails.genderCode ?? "",
          emailAddress: partner.personalDetails.emailAddress,
          nationalityCode: partner.personalDetails.nationality,
          yearsAtAddress: partner.addressDetails.timeAtAddressYears ?? 0,
          monthsAtAddress: partner.addressDetails.timeAtAddressMonths ?? 0,
          line1: partner.addressDetails.addressLine1 ?? "",
          line2: partner.addressDetails.addressLine2 ?? "",
          line3: partner.addressDetails.addressLine3 ?? "",
          city: partner.addressDetails.city ?? "",
          county: partner.addressDetails.county ?? "",
          postcode: partner.addressDetails.postCode ?? "",
        };
        partnersToReturn.push(partnerToAdd);
      }
    });
  }
  if (Array.isArray(deletedPartners)) {
    deletedPartners.forEach((partner, index) => {
      const title = titles.find(
        (t) => t.id.toString() === partner.personalDetails?.title?.toString()
      );
      const partnerToAdd = {
        partnerNum: partner.personalDetails.partnerNum,
        status: "Delete",
        title: title?.text ? title.text : "",
        forename: partner.personalDetails.firstName,
        surname: partner.personalDetails.lastName,
        dateOfBirth: DateTime.utc(
          partner.personalDetails.dobYear,
          partner.personalDetails.dobMonth,
          partner.personalDetails.dobDay
        ).toISO(),
        genderCode: "string",
        emailAddress: partner.personalDetails.emailAddress,
        nationalityCode: partner.personalDetails.nationality,
        yearsAtAddress: 0,
        monthsAtAddress: 0,
        line1: partner.addressDetails.addressLine1 ?? "",
        line2: partner.addressDetails.addressLine2 ?? "",
        line3: partner.addressDetails.addressLine3 ?? "",
        city: partner.addressDetails.city ?? "",
        county: partner.addressDetails.county ?? "",
        postcode: partner.addressDetails.postCode ?? "",
      };
      partnersToReturn.push(partnerToAdd);
    });
  }
  return {
    businessProposal: {
      partners: partnersToReturn,
    },
  };
};

const getApplicationPayload = (
  counterParty,
  privateIndividualDetails,
  partners,
  deletedPartners,
  isSoleTrader
) => {
  if (counterParty === 21) {
    return {
      ...getPrivateProposal(privateIndividualDetails),
      ...{ businessProposal: null },
    };
  } else {
    return {
      ...getBusinessProposal(partners, deletedPartners, isSoleTrader ? 1 : 3),
      ...{ privateProposal: null },
    };
  }
};

const useReviewApplication = () => {
  return { getApplicationPayload };
};

export default useReviewApplication;
