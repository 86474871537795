import * as React from "react";
import { iconSizeSquare } from "./icons-utils";
import PropTypes from "prop-types";

const WarningIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    role="img"
    viewBox="0 0 22 22"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M11 1c5.522 0 10 4.477 10 10 0 5.522-4.478 10-10 10-5.523 0-10-4.478-10-10C1 5.477 5.477 1 11 1"
      />
      <path
        fill="currentColor"
        d="M9.5 4.81c0-.63.2-.81.9-.81h1.7c.8 0 .9.27.9.81l-.4 6.93c0 .63-.4.72-1 .72h-.9c-.6 0-.9-.09-.9-.72l-.3-6.93Zm1.8 9.27c1.1 0 1.7.45 1.7 1.53v.36c0 .99-.7 1.53-1.7 1.53h-.2c-1.2 0-1.8-.54-1.8-1.53v-.36c0-1.08.8-1.53 1.8-1.53h.2Z"
      />
    </g>
  </svg>
);

WarningIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { WarningIcon };
export default WarningIcon;
