import { createSlice } from "@reduxjs/toolkit";

import revertAll from "../../actions/revert-all/revertAll";

const initialState = {
  step: [0],
  selectedContractId: null,
  selectedTerm: null,
  selectedMileage: null,
  contractDetails: {},
  proposedAmendment: {},
  contractAmendDetails: {},
  privateIndividual: {
    personalDetails: {},
    addressDetails: [],
    financialDetails: {},
    employmentDetails: {},
  },
  isSoleTrader: false,
  isPrivateIndividual: false,
  partnerType: "",
  partners: [],
  deletedPartners: [],
  currentPartnerIndex: null,
  isExistingQuote: false,
  contractAgreement: {},
};

export const contractAmendsSlice = createSlice({
  name: "contractAmends",
  initialState,
  reducers: {
    step: (state, action) => {
      state.step = action.payload;
    },
    contractDetails: (state, action) => {
      state.contractDetails = action.payload;
    },
    selectedTerm: (state, action) => {
      state.selectedTerm = action.payload;
    },
    selectedMileage: (state, action) => {
      state.selectedMileage = action.payload;
    },
    selectedContractId: (state, action) => {
      state.selectedContractId = action.payload;
    },
    proposedAmendment: (state, action) => {
      state.proposedAmendment = action.payload;
    },
    contractAmendDetails: (state, action) => {
      state.contractAmendDetails = action.payload;
    },
    piPersonalDetails: (state, action) => {
      state.privateIndividual.personalDetails = action.payload;
    },
    piFinancialDetails: (state, action) => {
      state.privateIndividual.financialDetails = action.payload;
    },
    piAddressDetails: (state, action) => {
      state.privateIndividual.addressDetails = action.payload;
    },
    piEmploymentDetails: (state, action) => {
      state.privateIndividual.employmentDetails = action.payload;
    },
    isSoleTrader: (state, action) => {
      state.isSoleTrader = action.payload;
    },
    partnerType: (state, action) => {
      state.partnerType = action.payload;
    },
    partners: (state, action) => {
      state.partners = action.payload;
    },
    currentPartnerIndex: (state, action) => {
      state.currentPartnerIndex = action.payload;
    },
    isPrivateIndividual: (state, action) => {
      state.isPrivateIndividual = action.payload;
    },
    isExistingQuote: (state, action) => {
      state.isExistingQuote = action.payload;
    },
    deletedPartners: (state, action) => {
      state.deletedPartners = action.payload;
    },
    contractAgreement: (state, action) => {
      state.contractAgreement = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

// pi = private individual

export const {
  step,
  reset,
  contractDetails,
  selectedContractId,
  selectedMileage,
  selectedTerm,
  proposedAmendment,
  contractAmendDetails,
  piAddressDetails,
  piFinancialDetails,
  piEmploymentDetails,
  piPersonalDetails,
  isSoleTrader,
  partnerType,
  partners,
  currentPartnerIndex,
  isPrivateIndividual,
  isExistingQuote,
  deletedPartners,
  contractAgreement,
} = contractAmendsSlice.actions;

export default contractAmendsSlice.reducer;
