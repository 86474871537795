const setItem = (key, value) => {
  if (sessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  }
  return false;
};

const getItem = (key) => {
  if (sessionStorage) {
    const item = sessionStorage.getItem(key);
    if (typeof item === "undefined") {
      return undefined;
    }
    return JSON.parse(item);
  }
  return null;
};

const removeItem = (key) => {
  if (sessionStorage) {
    sessionStorage.removeItem(key);
  }
};

const clear = () => {
  if (sessionStorage) {
    sessionStorage.clear();
  }
};

export { setItem, getItem, removeItem, clear };
