import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import CollectionStepper from "../../collection-stepper/CollectionStepper";
import { reset } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";

const PersonalisedPlatePage = () => {
  const currentStep = useSelector((state) => state.vehicleCollection.step);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onHomepageClicked = () => {
    dispatch(reset());
    navigate("/home");
  };

  useEffect(() => {
    if (!currentStep || currentStep < steps.roadWorthyQuestion1) {
      navigate("../question1", { replace: true });
    }
  }, [navigate, currentStep]);

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return you vehicle at end of contract."
      wizardContent={<CollectionStepper overrideActiveStep={2} />}
    >
      <p>
        If you wish to keep your personalised plate you will need to arrange for
        it to be removed and have a standard registration plate assigned and
        fixed to the vehicle prior to arranging your collection.
      </p>
      <p>
        <strong>
          If a vehicle is returned with a personalised plate still assigned, it
          will not be possible to return this to you.
        </strong>
      </p>
      <p>
        To arrange for this to be completed please email your request to our
        cherished plate team{" "}
        <a href="mailto: lacherished@bca.com">lacherished@bca.com</a>*.
      </p>
      <p>
        If you do not wish to retain your personalised plate, please continue
        with your booking.
      </p>
      <p className="mb-5">
        *Please note although we provide you with an e-mail address, you need to
        be aware that e-mail is not a secure way to get in touch. If you decide
        to e-mail us, do not quote any sensitive account information such as
        your account numbers, we won't be liable for any lost data you supply.
      </p>
      <div className="d-flex flex-column gap-3 col-12 col-sm-8 col-lg-6 col-xl-5 mx-auto">
        <Button
          id="btnContinueWithBooking"
          as={Link}
          to="../question2"
          className="text-decoration-none"
        >
          Continue with booking
        </Button>
        <Button
          onClick={onHomepageClicked}
          id="btnHome"
          variant="outline-primary"
          className="text-decoration-none"
        >
          Home
        </Button>
      </div>
    </WizardBasePage>
  );
};

export default PersonalisedPlatePage;
