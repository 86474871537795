import { useState } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

const useCollectionDate = () => {
  let minDate = DateTime.now();
  let maxDate = DateTime.now();
  const savedDate = useSelector(
    (state) => state.vehicleCollection.collectionDate
  );

  const [selectedDate, setSelectedDate] = useState(() => {
    return savedDate;
  });
  const [showAdditionalMonth, setShowAdditionalMonth] = useState(false);
  const selectedContract = useSelector(
    (state) => state.vehicleCollection.contractDetails
  );
  const endOfContractDate = DateTime.fromISO(selectedContract.endDate);
  const eocStatus = selectedContract.eocStatus;
  if (eocStatus && eocStatus.match(/informalextension/i)) {
    maxDate = DateTime.now().plus({ days: 90 });
  } else if (eocStatus && eocStatus.match(/earlytermination/i)) {
    maxDate = DateTime.fromISO(selectedContract.etExpiryDate);
  } else if (eocStatus && eocStatus.match(/due/i)) {
    minDate = endOfContractDate.plus({ days: -30 });
    maxDate = endOfContractDate.plus({ days: 60 });
    if (DateTime.now() > minDate) {
      minDate = DateTime.now();
    }
  }

  const calculateShowAdditionalMonth = () => {
    let diffDuration = maxDate
      .diff(DateTime.now().startOf("month"), ["months"])
      .toObject();
    const shouldShowAdditionalMonths = diffDuration.months > 3;
    if (shouldShowAdditionalMonths !== showAdditionalMonth) {
      setShowAdditionalMonth(shouldShowAdditionalMonths);
    }
  };

  const [calendarAtMinDate, setCalendarAtMinDate] = useState(() => true);
  const getSelectedDateWarning = () => {
    if (selectedDate && eocStatus && eocStatus.match(/due/i)) {
      let selectedDateObj = DateTime.fromFormat(selectedDate, "yyyy-MM-dd");
      if (selectedDateObj < endOfContractDate) {
        return "You have chosen a collection date that is prior to your end of contract date. Your full monthly rental is still payable for your final month.";
      } else {
        let diffDuration = selectedDateObj
          .diff(endOfContractDate, ["days"])
          .toObject();
        if (diffDuration.days > 5) {
          return "The date you have selected is more than 5 days after your end of contract date.  If you choose to continue with the booking excess rental charges will apply.";
        }
      }
    }
    return "";
  };
  const [selectedDateWarning, setSelectedDateWarning] = useState(() => {
    return getSelectedDateWarning();
  });

  const tempSelectedDateWarning = getSelectedDateWarning();
  if (tempSelectedDateWarning !== selectedDateWarning) {
    setSelectedDateWarning(tempSelectedDateWarning);
  }

  calculateShowAdditionalMonth();

  return {
    calendarAtMinDate,
    minDate,
    maxDate,
    selectedDate,
    showAdditionalMonth,
    setCalendarAtMinDate,
    setSelectedDate,
    setSelectedDateWarning,
    getSelectedDateWarning,
    selectedDateWarning,
  };
};

export default useCollectionDate;
