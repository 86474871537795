import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const ThumbsUpIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    aria-label={title}
    viewBox="0 0 69 65"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M28.063 6.824c-.321 8.48-1.327 13.45-6.265 20.783-.84-.815-1.976-1.329-3.218-1.329H4.645c-2.533 0-4.645 2.11-4.645 4.64v27.84c0 2.53 2.112 4.64 4.645 4.64H18.58c1.303 0 2.489-.563 3.339-1.45.605.48 1.17.92 1.814 1.305 1.37.82 2.968 1.312 5.008 1.523 4.081.422 10.51.106 25.451.169.534 0 1.064-.192 1.476-.532 2.956-2.409 4.408-4.887 5.08-7.516.259-.14.49-.33.678-.555 2.241-2.8 3.145-6.03 2.757-8.942.125-.101.239-.214.34-.338 2.599-3.246 3.423-7.086 2.49-10.32 1.525-1.976 2.184-4.297 1.936-6.453a7.67 7.67 0 0 0-4.548-6.138 2.332 2.332 0 0 0-.919-.193H44.418c1.02-7.61.241-14.585-2.444-19.308-1.52-2.676-3.843-4.245-6.169-4.592-4.177-.512-7.341 2.42-7.741 6.767v-.001Zm9.87.12c2.116 3.722 3.105 11.04 1.598 18.899-.255 1.334.915 2.75 2.274 2.755h20.999c.868.484 1.41 1.227 1.524 2.223.107.935-.297 2.061-1.234 3.19h-8.129c-1.227 0-2.356 1.095-2.356 2.32 0 1.226 1.13 2.338 2.356 2.321h7.693c.177 1.313-.148 2.964-1.21 4.64h-6.483c-1.227 0-2.356 1.094-2.356 2.32 0 1.226 1.13 2.338 2.356 2.32h4.597c.176 1.314-.148 2.965-1.21 4.64h-3.387c-1.227 0-2.356 1.095-2.356 2.321 0 1.226 1.13 2.337 2.356 2.32h.798c-.476 1.023-1.23 1.93-2.564 3.093-14.096-.043-20.816.185-24-.145-1.64-.17-2.287-.41-3.096-.894-.673-.403-1.603-1.173-2.879-2.2V33.554c7.266-9.702 9.098-16.489 9.484-26.512.726-3.995 4.192-2.338 5.226-.096v-.001ZM4.646 30.919H18.58c-.053 9.217 0 18.55 0 27.84H4.645v-27.84Z"
    />
  </svg>
);

ThumbsUpIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { ThumbsUpIcon };
export default ThumbsUpIcon;
