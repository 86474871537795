import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import TableStripedColumns from "../../../component/table/TableStripedColumns";
import magicStrings from "../../../utils/magic-string";
import PageHeading from "../../../component/page-heading/PageHeading";

const QuoteDetails = ({ quoteDetails }) => {
  let tableData = [
    [
      `Current monthly payment amount (${quoteDetails?.vatLabel})`,
      `${quoteDetails?.totalMonthlyRentalCharge}`,
    ],
    ["Contract end date", `${quoteDetails?.endDate}`],
    [" Number of payments remaining", `${quoteDetails?.remainingPayments}`],
    [
      ` Total amount to pay if you stay on your contract (${quoteDetails?.vatLabel})`,
      `${quoteDetails?.outstandingAmount}`,
    ],
  ];

  return (
    <>
      <Row>
        <Col className="text-center">
          <PageHeading>Your quote</PageHeading>
          <p className="my-3">Quote number</p>
          <p>
            <strong className={`${magicStrings.maskInformation}`}>
              {quoteDetails?.quoteNumber}
            </strong>
          </p>
          <p className="my-3">Your vehicle</p>
          <p>
            <strong className={`${magicStrings.maskInformation}`}>
              {quoteDetails?.registrationNumber} / {quoteDetails?.make} /{" "}
              {quoteDetails?.model}
            </strong>
          </p>
        </Col>
      </Row>

      <TableStripedColumns tableData={tableData} />
    </>
  );
};

QuoteDetails.propTypes = {
  quoteDetails: PropTypes.object.isRequired,
};

export default QuoteDetails;
