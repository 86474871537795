import { Button, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import Calendar from "../../../component/calendar/Calendar";
import CollectionStepper from "../collection-stepper/CollectionStepper";
import { useUnavailableSlots } from "../../../api/hooks/vehicle-collection";
import steps from "../vehicle-collection-steps";
import {
  step,
  collectionDate,
  reset,
} from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import useCollectionDate from "./hooks/useCollectionDate";
import useVehicleCollectionStepCheck from "../hooks/useVehicleCollectionStepCheck";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { useEffect, useState } from "react";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import PageHeading from "../../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const CollectionDatePage = () => {
  const navigate = useNavigate();
  const [isDateChanged, setIsDateChanged] = useState(false);
  const dispatch = useDispatch();
  const {
    calendarAtMinDate,
    minDate,
    maxDate,
    selectedDate,
    showAdditionalMonth,
    setCalendarAtMinDate,
    setSelectedDate,
    selectedDateWarning,
  } = useCollectionDate();

  const {
    isError,
    slots: unavailableSlots,
    errorResponse,
  } = useUnavailableSlots(minDate.toFormat("yyyy-MM-dd"));

  const contractDetails = useSelector(
    (state) => state.vehicleCollection.contractDetails
  );

  const isExisting = useSelector((state) => state.vehicleCollection.isExisting);
  const existingCollectionDate =
    contractDetails?.latestCollection?.collectionDate;

  useVehicleCollectionStepCheck(
    isExisting ? steps.existingBookings.editDate : steps.collectionDate,
    isExisting
      ? "/vehicle-collection/existing-bookings/summary"
      : "/vehicle-collection/contact-and-collection-details"
  );

  const onDateConfirmed = () => {
    if (selectedDate) {
      if (isExisting) {
        dispatch(step(steps.existingBookings.summary));
      } else {
        dispatch(step(steps.summary));
      }
      dispatch(collectionDate(selectedDate));
      if (isExisting) {
        navigate(-1);
      } else {
        navigate("../collection-summary");
      }
    }
  };

  useEffect(() => {
    if (isExisting) {
      const existingDate = DateTime.fromISO(existingCollectionDate);
      const newDate = DateTime.fromISO(selectedDate);
      if (existingDate.isValid && newDate.isValid) {
        if (
          existingDate.year === newDate.year &&
          existingDate.month === newDate.month &&
          existingDate.day === newDate.day
        ) {
          if (isDateChanged) {
            setIsDateChanged(false);
          }
        } else {
          if (!isDateChanged) {
            setIsDateChanged(true);
          }
        }
      } else {
        if (isDateChanged) {
          setIsDateChanged(false);
        }
      }
    }
  }, [isExisting, existingCollectionDate, isDateChanged, selectedDate]);

  const onHomeButtonClicked = () => {
    dispatch(reset());
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={isExisting ? <></> : <CollectionStepper />}
    >
      <PageHeading align="left">Collection date</PageHeading>
      <p>
        Select the date you would like the vehicle to be collected. The person
        handing over the vehicle will need to be available to meet the
        collection agent between 9am and 5pm on this date.
      </p>

      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}

      {!isError && (
        <Calendar
          showWeekends={false}
          numberOfMonths={3}
          year={
            calendarAtMinDate ? minDate.year : minDate.plus({ months: 1 }).year
          }
          month={
            calendarAtMinDate
              ? minDate.month
              : minDate.plus({ months: 1 }).month
          }
          minDate={minDate.toFormat("yyyy-MM-dd")}
          maxDate={maxDate.toFormat("yyyy-MM-dd")}
          onDateSelected={(d) => {
            setSelectedDate(d);
          }}
          selectedDate={selectedDate}
          unavailableDates={unavailableSlots.map((slot) => slot.date)}
        />
      )}

      {showAdditionalMonth && (
        <div className="text-end">
          <Button
            className="px-0 fw-bold"
            variant="link"
            id="btnToggleMonths"
            onClick={() => {
              setCalendarAtMinDate(!calendarAtMinDate);
            }}
          >
            {calendarAtMinDate
              ? "Show next available dates"
              : "Show previous available dates"}
          </Button>
        </div>
      )}

      {selectedDateWarning && (
        <p className="pt-5">
          <strong>{selectedDateWarning}</strong>
        </p>
      )}

      <CenterAlignedButtonContainer>
        <Button
          id="btnDateConfirmed"
          onClick={onDateConfirmed}
          disabled={!selectedDate || isError || (isExisting && !isDateChanged)}
        >
          Confirm
        </Button>
        {isExisting && (
          <BackToHomeButton onButtonClicked={onHomeButtonClicked} />
        )}
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default CollectionDatePage;
