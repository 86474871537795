import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";

const getInvoices = async (axios) => {
  return axios.get("/invoices").then((res) => {
    return res.data;
  });
};

const getSupportingDocument = async (axios, referenceNumber, documentName) => {
  return axios
    .get(`/invoices/${referenceNumber}/documents/${documentName}`)
    .then((res) => {
      return res.data;
    });
};

const useInvoices = () => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const { data = fallback, isError } = useQuery(
    queryKeys.invoices,
    () => getInvoices(axios),
    { cacheTime: 10 * 60 * 1000, staleTime: 10 * 60 * 1000 }
  );
  return {
    invoices: data,
    isError,
  };
};

const useSupportingDocument = (referenceNumber, documentName) => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const {
    data = fallback,
    isError,
    refetch,
  } = useQuery(
    queryKeys.supportingDocument(documentName),
    () => getSupportingDocument(axios, referenceNumber, documentName),
    { cacheTime: 0, staleTime: 0, enabled: false }
  );
  return {
    data,
    isError,
    refetch,
  };
};

export { useInvoices, useSupportingDocument };
