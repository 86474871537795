import { Link } from "react-router-dom";
import { Col, Card, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import magicStrings from "../../utils/magic-string";

const Tile = ({
  cardTitle,
  cardLinkUrl,
  cardLinkContent,
  cardId,
  isLink = true,
  callBack,
  children,
  isCardLinkDisabled = false,
  cardBorder = false,
  counter = -1,
  sm = 12,
  md = 6,
  lg = 4,
  xl = 4,
}) => {
  const tileNavigation = (id) => {
    callBack(id);
  };

  return (
    <Col sm={sm} md={md} lg={lg} xl={xl} className="my-3">
      <div className="position-relative h-100">
        <Card
          className={`text-center py-4 px-4 card h-100 ${
            cardBorder ? "border" : ""
          }`}
        >
          {cardTitle ? (
            <Card.Header className="bg-transparent">
              <h2 className={`h3 ${magicStrings.maskInformation}`}>
                {cardTitle}
              </h2>
            </Card.Header>
          ) : (
            ""
          )}
          <Card.Body className={`pb-4 ${magicStrings.maskInformation}`}>
            {children}
          </Card.Body>
          <Card.Footer className="bg-transparent">
            <div className="d-flex justify-content-center gap-2">
              {isLink ? (
                <Link
                  id={cardId}
                  to={cardLinkUrl}
                  className="btn btn-primary text-decoration-none flex-fill"
                  style={{ maxWidth: "20rem" }}
                >
                  {cardLinkContent}
                </Link>
              ) : (
                <Button
                  id={cardId}
                  disabled={isCardLinkDisabled}
                  className="btn btn-primary text-decoration-none flex-fill"
                  onClick={(e) => tileNavigation(e.target.id)}
                  style={{ maxWidth: "20rem" }}
                >
                  {cardLinkContent}
                </Button>
              )}
            </div>
          </Card.Footer>
        </Card>
        {counter > 0 && (
          <div
            style={{
              width: "2rem",
              height: "2rem",
              top: "-.5rem",
              right: "-.5rem",
            }}
            className={`bg-primary text-white position-absolute rounded-circle d-flex justify-content-center align-items-center ${
              counter > 99 ? "fw-normal" : "fw-bold"
            }`}
          >
            {counter > 99 ? "99+" : counter}
          </div>
        )}
      </div>
    </Col>
  );
};

Tile.propTypes = {
  cardTitle: PropTypes.string,
  cardLinkUrl: PropTypes.string,
  cardLinkContent: PropTypes.string,
  cardId: PropTypes.string,
  isLink: PropTypes.bool,
  callBack: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  isCardLinkDisabled: PropTypes.bool,
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
};

export default Tile;
