import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../error-fallback/ErrorFallback";

const ErrorBoundaryWrapper = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {props.children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
