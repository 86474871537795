import { createSlice } from "@reduxjs/toolkit";
import revertAll from "../../actions/revert-all/revertAll";

const initialState = {
  creditInvoices: [],
  selectedInvoices: [],
  transactionKey: "",
  sessionKey: "",
  iframeSrc: "",
  actionCode: "",
  decision: "",
  hasLoadedPaymentGateway: false,
  step: 0,
};

export const cardPaymentSlice = createSlice({
  name: "cardPayment",
  initialState,
  reducers: {
    creditInvoices: (state, action) => {
      state.creditInvoices = action.payload;
    },
    selectedInvoices: (state, action) => {
      state.selectedInvoices = action.payload;
    },
    transactionKey: (state, action) => {
      state.transactionKey = action.payload;
    },
    sessionKey: (state, action) => {
      state.sessionKey = action.payload;
    },
    iframeSrc: (state, action) => {
      state.iframeSrc = action.payload;
    },
    actionCode: (state, action) => {
      state.actionCode = action.payload;
    },
    decision: (state, action) => {
      state.decision = action.payload;
    },
    hasLoadedPaymentGateway: (state, action) => {
      state.hasLoadedPaymentGateway = action.payload;
    },
    step: (state, action) => {
      state.step = action.payload;
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  creditInvoices,
  reset,
  selectedInvoices,
  iframeSrc,
  sessionKey,
  transactionKey,
  actionCode,
  decision,
  hasLoadedPaymentGateway,
  step,
} = cardPaymentSlice.actions;

export default cardPaymentSlice.reducer;
