import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import BasePage from "../../basepages/basepage/BasePage";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { step } from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../vehicle-collection-steps";

const CollectionFailurePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorCode = useLocation().state?.error.localCode;

  if (!errorCode) {
    navigate("../");
  }

  const onSelectNewDate = () => {
    dispatch(step(steps.collectionFailure));
    navigate("../collection-date", { replace: true });
  };

  return (
    <BasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
    >
      {errorCode === 1040001 ? (
        <>
          <TitleWithIcon
            title="Your collection date is no longer available"
            variant="failure"
          />
          <p className="mt-5 text-center">
            The collection date you selected is no longer available, please
            select a new date to continue.
          </p>
          <CenterAlignedButtonContainer>
            <Button
              onClick={onSelectNewDate}
              id="btnSelectNewDate"
              variant="primary"
              className="text-decoration-none"
            >
              Select new date
            </Button>
          </CenterAlignedButtonContainer>
        </>
      ) : (
        <>
          <TitleWithIcon
            title="Something went wrong with your request"
            variant="failure"
          />
          <p className="mt-5">
            Unfortunately we have been unable to complete your collection
            request online.
          </p>
          <p>
            Please call us on <Link to="tel:0344 879 6633">0344 879 6633</Link>{" "}
            option 5, and they will be happy to arrange a collection for you.
            Our phones are open Monday - Friday between 8.30am - 5.30pm. We're
            closed on weekends and bank holidays.
          </p>

          <CenterAlignedButtonContainer>
            <BackToHomeButton variant="primary" />
          </CenterAlignedButtonContainer>
        </>
      )}
    </BasePage>
  );
};

export default CollectionFailurePage;
