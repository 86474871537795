const trimStartOnChange = (event) => {
  const { value } = event.target;
  event.target.value = value.trimStart();
};

const pluraliseText = (number, apostrophe = false) => {
  if (apostrophe) return number === 1 ? "" : "'s";
  else return number === 1 ? "" : "s";
};

export { trimStartOnChange, pluraliseText };
