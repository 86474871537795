// All contract amendments api error need to map in the range of 1140001 to 1199999
// The number is divided into 3 parts
// First part is the api key range, for contrats amendments will always be 11
// Second part is the http error status code e.g 400
// Last part is the identifier for the unique error message we want to display.
// Two server api error codes are okay to be mapped to single error code, if required.

const contractAmendmentsErrorCodeMappings = {
  400: {
    11057: 1140001, //WorkQueueRuleLimitation
    11076: 1140002, //WorkQueueRuleLimitation149
    11077: 1140003, //WorkQueueRuleLimitation16
  },
  500: {
    default: 1150001,
  },
  // default is used to handle the case where it is an unknown http status code
  default: {
    default: 1199901,
    // This default is the absolute fallback error code for contract amendments api.
  },
};

export default contractAmendmentsErrorCodeMappings;
