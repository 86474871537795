import PageHeading from "../../component/page-heading/PageHeading";
import FooterBasePage from "../basepages/footer-basepage/FooterBasePage";

const LegalPage = () => {
  return (
    <FooterBasePage
      pageTitle="Legal"
      pageSubTitle="Important legal information."
    >
      <PageHeading align="left">
        You should read these conditions carefully.
      </PageHeading>
      <ul>
        <li>
          Who can use this site. The material on this site is directed solely at
          those who are resident in and who access the website from within the
          United Kingdom.
        </li>
        <li>
          Please note, all prices quoted are inclusive of VAT, unless stated
          otherwise.
        </li>
        <li>
          We have tried to make this website as clear, easy to use and as
          accurate as possible. However we disclaim all express or implied
          warranties that this site is accurate, complete or up to date. We will
          have no liability to you if it is not.
        </li>
        <li>
          The fact that a service is shown on this site does not necessarily
          mean it is suitable for you. It is your responsibility to check this.
          This also applies to other people's products or services linked to
          this site (if any).
        </li>
        <li>
          We are not in any way responsible for anything mentioned on or linked
          to this site that someone else is marketing.
        </li>
        <li>
          You may use anything on this site only for your personal information
          in connection with you or your business and for this purpose you may
          print, copy, download or temporarily store extracts from this site.
          You must not alter anything. Any other use is prohibited unless you
          first get our written permission. In particular, no-one may use any
          part of the site on any other website, or link any other website to
          this site, without this permission.
        </li>
        <li>
          This site and these conditions are governed by English law. Any
          disputes arising from this site shall be resolved by the English
          courts unless you can show you accessed the site from Scotland in
          which case the Scottish courts may be used.
        </li>
        <li>
          Certain trademarks and other materials used on this site are the
          copyright of Lex Autolease Limited and Lloyds Banking Group plc. Lex
          Autolease and Lloyds Banking Group are registered trademarks of Lloyds
          Banking Group plc. All rights reserved.
        </li>
      </ul>
      <hr className="my-5" />
      <p>
        Lex Autolease Limited Registered in England and Wales with registered
        number: 1090741 Registered office: 25 Gresham Street, London EC2V 7HN.
      </p>
      <p>
        Lex Autolease Limited is authorised and regulated by the Financial
        Conduct Authority under registration number 723570 for credit-related
        regulated activities and insurance mediation.
      </p>
      <p>
        Authorisation can be checked on the Financial Services Register at{" "}
        <a
          href="https://www.fca.org.uk/"
          target="_blank"
          rel="noopener noreferrer"
          className="border-bottom border-white"
        >
          www.fca.org.uk
        </a>
        .
      </p>
      <p>Vat Number GB 244 1555 76</p>
    </FooterBasePage>
  );
};

export default LegalPage;
