import { createSlice } from "@reduxjs/toolkit";
import revertAll from "../../actions/revert-all/revertAll";

const initialState = {
  bankAccountAndAddressDetails: {
    bankAccount: {
      accountNumber: "",
      accountName: "",
      bank: {
        sortCode: "",
        bankName: "",
        branchName: "",
        bankAddress: {
          line1: "",
          line2: "",
          line3: "",
          line4: "",
          line5: "",
          postcode: "",
        },
      },
    },
  },
};

export const directDebitSlice = createSlice({
  name: "directDebit",
  initialState,
  reducers: {
    bankAccountAndAddressDetails: (state, action) => {
      state.bankAccountAndAddressDetails = action.payload;
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { bankAccountAndAddressDetails, reset } = directDebitSlice.actions;

export default directDebitSlice.reducer;
