import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Alert } from "react-bootstrap";
import BasePage from "../basepages/basepage/BasePage";
import { useContract } from "../../api/hooks/contracts";
import ArrowLeftIcon from "../../component/icons/ArrowLeftIcon";
import {
  formatCurrency,
  formatNumber,
} from "../../utils/pages/number-format-utils";
import { formatDate } from "../../utils/pages/date-format-utils";
import magicStrings from "../../utils/magic-string";
import PageHeading from "../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../api/error-handling/transform-error";

const VehicleDescriptionPage = () => {
  const navigate = useNavigate();
  const contractId = useLocation().state?.contractId;
  useEffect(() => {
    if (!contractId) {
      navigate("/vehicles");
    }
  }, [navigate, contractId]);
  const { contract, isError, errorResponse } = useContract(contractId);
  const vatLabel =
    contract?.vatInclusive === true ? "including VAT" : "excluding VAT";

  return (
    <BasePage
      pageTitle="Vehicle summary"
      pageSubTitle="View a summary of your vehicle contract."
    >
      <Row>
        <Col>
          <Link to="/vehicles" className="d-inline-flex align-items-center">
            <ArrowLeftIcon title="Back to my vehicles" size="xs" />
            <span className="ps-1">Back to my vehicles</span>
          </Link>
        </Col>
      </Row>

      {isError ? (
        <Row>
          <Col className="mt-4">
            <Alert variant="danger">
              {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
            </Alert>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={12} className="mt-5">
              <PageHeading align="left">Vehicle summary</PageHeading>
              <p className="mt-4">Details of your vehicle</p>
            </Col>
            <Col xs={12}>
              <dl className="table-list-row">
                <Row>
                  <dt>Vehicle registration number</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {contract?.vehicle?.registrationNumber}
                  </dd>
                </Row>

                <Row>
                  <dt>Vehicle make</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {contract?.vehicle?.make}
                  </dd>
                </Row>

                <Row>
                  <dt>Vehicle description</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {contract?.vehicle?.description}
                  </dd>
                </Row>

                <Row>
                  <dt>Product description</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {contract?.product}
                  </dd>
                </Row>

                <Row>
                  <dt>Contract term</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {contract?.term}
                  </dd>
                </Row>

                <Row>
                  <dt>Contract annual mileage</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {formatNumber(contract?.maxAnnualMileage, 0, "N/A")}
                  </dd>
                </Row>

                <Row>
                  <dt>Total contract mileage</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {formatNumber(contract?.totalMileage, 0, "N/A")}
                  </dd>
                </Row>

                <Row>
                  <dt>Contract start date</dt>
                  {contract?.startDate && (
                    <dd className={`${magicStrings.maskInformation}`}>
                      {formatDate(contract?.startDate)}
                    </dd>
                  )}
                </Row>

                <Row>
                  <dt>Contract end date</dt>
                  {contract?.endDate && (
                    <dd className={`${magicStrings.maskInformation}`}>
                      {formatDate(contract?.endDate)}
                    </dd>
                  )}
                </Row>

                <Row>
                  <dt>Initial rental ({vatLabel})</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {formatCurrency(contract?.initialRental, "N/A")}
                  </dd>
                </Row>

                <Row>
                  <dt>Monthly rental ({vatLabel})</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {formatCurrency(contract?.totalMonthlyRentalCharge, "N/A")}
                  </dd>
                </Row>

                <Row>
                  <dt>Monthly lease rental ({vatLabel})</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {formatCurrency(contract?.monthlyRental, "N/A")}
                  </dd>
                </Row>

                <Row>
                  <dt>Monthly service rental ({vatLabel})</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {formatCurrency(contract?.serviceRental, "N/A")}
                  </dd>
                </Row>

                <Row>
                  <dt>Excess mileage charge (pence per mile {vatLabel})</dt>
                  <dd className={`${magicStrings.maskInformation}`}>
                    {contract?.excessMileageCharge >= 0
                      ? `${formatNumber(contract.excessMileageCharge)} ppm`
                      : "N/A"}
                  </dd>
                </Row>

                {contract?.product === "Finance Lease" && (
                  <Row>
                    <dt>Estimate sale value ({vatLabel})</dt>
                    <dd className={`${magicStrings.maskInformation}`}>
                      {formatCurrency(contract?.estimatedSaleValue, "N/A")}
                    </dd>
                  </Row>
                )}
              </dl>
            </Col>
          </Row>
        </>
      )}
    </BasePage>
  );
};

export default VehicleDescriptionPage;
