import { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

export const VehicleRegOrFleetNumberField = (props) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
    trigger,
    watch,
  } = methods;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "vehicleReg":
          trigger("fleetNumber", { shouldFocus: false });
          break;
        case "fleetNumber":
          trigger("vehicleReg", { shouldFocus: false });
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  return (
    <Row className="mb-3">
      <Form.Group as={Col} xs={12} sm={5} controlId="vehicleReg">
        <Form.Label>Vehicle registration</Form.Label>
        <Form.Control
          type="string"
          maxLength={10}
          isInvalid={errors.vehicleReg}
          {...props}
          {...register("vehicleReg")}
        />
      </Form.Group>

      <Col xs={12} sm={2}>
        <div className="d-flex aligns-items-center justify-content-start justify-content-sm-center h-100">
          <strong className="align-self-end my-3">OR</strong>
        </div>
      </Col>
      <Form.Group as={Col} xs={12} sm={5} controlId="fleetNumber">
        <Form.Label>Fleet number</Form.Label>
        <Form.Control
          type="string"
          maxLength={10}
          isInvalid={errors.fleetNumber}
          {...props}
          {...register("fleetNumber")}
        />
      </Form.Group>
      <Form.Control.Feedback
        type="invalid"
        className={`${
          errors.vehicleReg || errors.fleetNumber ? "d-block" : "d-none"
        }`}
      >
        {errors.vehicleReg?.message || errors.fleetNumber?.message}
      </Form.Control.Feedback>
    </Row>
  );
};

export const IsExistingUserField = (props) => {
  const methods = useFormContext();
  const { register } = methods;
  return (
    <Form.Group className="my-4">
      <Form.Check
        inline
        id="isExistingUserYes"
        type="radio"
        label="Yes, I have a user ID"
        value="yes"
        {...register("isExistingUser")}
        {...props}
      ></Form.Check>
      <Form.Check
        inline
        id="isExistingUserNo"
        type="radio"
        label="No"
        value="no"
        {...register("isExistingUser")}
        {...props}
      ></Form.Check>
    </Form.Group>
  );
};

export const AcceptRegisterTerms = (props) => {
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
  } = methods;
  return (
    <div className="d-flex flex-row flex-wrap">
      <Form.Group className="mb-3 me-3" controlId="acceptTerms">
        <Form.Check
          type="checkbox"
          label="Accept privacy terms and conditions."
          isInvalid={errors.areRegisterTermsAccepted}
          {...register("areRegisterTermsAccepted")}
          {...props}
        ></Form.Check>
        <Form.Control.Feedback
          type="invalid"
          style={
            errors.areRegisterTermsAccepted
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {errors.areRegisterTermsAccepted?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <div>
        <Link to="/terms-and-conditions" id="lnkTermsAndConditions">
          Learn more
        </Link>
      </div>
    </div>
  );
};
