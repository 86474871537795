import * as yup from "yup";
import * as fieldSchemas from "./fields";

export const addressSchema = {
  ...fieldSchemas.postCodeSchema,
  addressLine1: yup
    .string()
    .when(
      ["isAddressManuallyEntered"],
      ([isAddressManuallyEntered], schema) => {
        return isAddressManuallyEntered
          ? fieldSchemas.addressLine1Schema.addressLine1
          : schema.notRequired();
      }
    ),
  ...fieldSchemas.addressLine2Schema,
  ...fieldSchemas.addressLine3Schema,
  isAddressManuallyEntered: yup.bool(),
  ...fieldSchemas.citySchema,
  ...fieldSchemas.countySchema,
  selectAddress: yup
    .string()
    .when(
      ["isAddressManuallyEntered"],
      ([isAddressManuallyEntered], schema) => {
        return !isAddressManuallyEntered
          ? fieldSchemas.selectAddressSchema.selectAddress
          : schema.notRequired();
      }
    ),
};

export default addressSchema;
