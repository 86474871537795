import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CloseCookiesIcon from "../icons/CloseCookiesIcon";

const HeaderCookies = ({ toggleCookiesHandler, isClose, isLoggedIn }) => {
  return (
    <>
      <Row className={`bg-primary ${isClose || isLoggedIn ? "d-none" : ""}`}>
        <Col>
          <Container className="my-3 p-sm-0">
            <div className="cookies-inner-container d-flex">
              <div className="text-white flex-grow-1 text-md-center text-xs-left">
                <p className="mb-0">
                  managemyvehicle.co.uk and signmydocuments.co.uk use cookies to
                  enhance your experience.{" "}
                  <Link to="/cookies" className="text-white">
                    Find out more about cookies
                  </Link>
                  .
                </p>
              </div>
              <Button className="ms-2 m-0 p-0" onClick={toggleCookiesHandler}>
                <CloseCookiesIcon
                  className="text-white"
                  title="Close cookies"
                  size="sm"
                />
              </Button>
            </div>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default HeaderCookies;
