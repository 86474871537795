import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import {
  pageTitle,
  pageSubTitle,
} from "../../utils/contract-amends-page-utils";
import PersonalDetailsForm from "../../../../component/forms/contract-amendment/bch/PersonalDetailsForm";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../../component/center-aligned-container/CenterAlignedContainer";
import {
  partners as dispatchPartners,
  reset,
  step,
} from "../../../../redux/features/contract-amends/contractAmendsSlice";
import useContractAmendsStepCheck from "../../hooks/useContractAmendsStepCheck";
import steps from "../../contract-amends-steps";
import ContractAmendsStepper from "../../contract-amends-stepper/ContractAmendsStepper";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import { useEffect } from "react";
import PageHeading from "../../../../component/page-heading/PageHeading";

const PersonalDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSoleTrader = useSelector(
    (state) => state.contractAmends.isSoleTrader
  );
  const currentPartnerIndex = useSelector(
    (state) => state.contractAmends.currentPartnerIndex
  );
  const partnerType = useSelector((state) => state.contractAmends.partnerType);
  useContractAmendsStepCheck(
    isSoleTrader
      ? steps.bch.soleTrader.personalDetails
      : currentPartnerIndex
      ? steps.bch.limitedCompany.partners[currentPartnerIndex].personalDetails
      : steps.exit,
    "../../save-quote"
  );

  const partners = useSelector((state) => state.contractAmends.partners);
  const totalPartners =
    Array.isArray(partners) && partners.length > 0 ? partners.length : 0;

  const partnerPersonalDetails =
    totalPartners > 0 && currentPartnerIndex < totalPartners
      ? partners[currentPartnerIndex].personalDetails
      : {};

  const isNewPartner = partners[currentPartnerIndex]?.isNewPartner;
  useEffect(() => {
    if (currentPartnerIndex + 1 < totalPartners) {
      const lastPartnerIndex = totalPartners - 1;
      if (
        partners[lastPartnerIndex] &&
        partners[lastPartnerIndex].isNewPartner &&
        !partners[lastPartnerIndex].isNewPartnerSaved
      ) {
        const partnersToSave = partners.slice(0, -1);
        dispatch(dispatchPartners(partnersToSave));
      }
    }
  }, [totalPartners, dispatch, partners, currentPartnerIndex]);

  const onFormSubmit = (data) => {
    const partnersToSave = [...partners];
    const partnerToSave = { ...partnersToSave[currentPartnerIndex] };
    partnerToSave.personalDetails = { ...data };
    partnersToSave[currentPartnerIndex] = partnerToSave;
    dispatch(dispatchPartners(partnersToSave));
    dispatch(
      step(
        isSoleTrader
          ? steps.bch.soleTrader.captureAddress
          : steps.bch.limitedCompany.partners[currentPartnerIndex]
              .captureAddress
      )
    );
    navigate("../current-address");
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Personal details</PageHeading>
          <p>
            {isNewPartner &&
              `Any changes made to your contract details will be reflected on your account.`}
            {!isNewPartner &&
              `Please check the personal details we hold are correct.`}
          </p>
        </div>
      </CenterAlignedContainer>

      <CenterAlignedButtonContainer>
        <div>
          {!isSoleTrader && (
            <>
              <div>
                <div>
                  {Array.isArray(partners) &&
                  partners.length > 0 &&
                  partners[currentPartnerIndex]?.isNewPartner ? (
                    <strong>New {partnerType}</strong>
                  ) : (
                    <strong>
                      {partnerType}{" "}
                      {`${currentPartnerIndex + 1} of ${totalPartners}`}
                    </strong>
                  )}
                </div>
              </div>
              <hr className="mt-1" />
            </>
          )}
          <PersonalDetailsForm
            onFormSubmit={onFormSubmit}
            defaultValues={partnerPersonalDetails}
            isSoleTrader={isSoleTrader}
          />
        </div>
        <BackToHomeButton onButtonClicked={() => dispatch(reset())} />
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default PersonalDetailsPage;
