import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import {
  step,
  reset,
} from "../../../../redux/features/support-needs/supportNeedsSlice";

const useSupportNeedsStepCheck = (
  minStepToCheck,
  currentStep,
  redirectionPath
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedStep = useSelector((state) => state.supportNeeds.step);

  const selectedNeeds = useSelector(
    (state) => state.supportNeeds.selectedSupportNeeds
  );

  const deselectedNeeds = useSelector(
    (state) => state.supportNeeds.deselectedSupportNeeds
  );

  useEffect(() => {
    if (
      (currentStep ?? storedStep) > minStepToCheck &&
      !(selectedNeeds.length > 0) &&
      !(deselectedNeeds.length > 0)
    ) {
      dispatch(step(minStepToCheck));
      dispatch(reset());
      navigate(redirectionPath, { replace: true });
    }
  }, [
    navigate,
    currentStep,
    storedStep,
    selectedNeeds,
    deselectedNeeds,
    dispatch,
    minStepToCheck,
    redirectionPath,
  ]);
};

export default useSupportNeedsStepCheck;
