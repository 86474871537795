import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import {
  pageTitle,
  pageSubTitle,
} from "../../utils/contract-amends-page-utils";
import ContractAmendsStepper from "../../contract-amends-stepper/ContractAmendsStepper";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../../component/center-aligned-container/CenterAlignedContainer";
import useContractAmendsStepCheck from "../../hooks/useContractAmendsStepCheck";
import steps from "../../contract-amends-steps";
import {
  step,
  currentPartnerIndex as dispatchCurrentPartnerIndex,
} from "../../../../redux/features/contract-amends/contractAmendsSlice";
import PageHeading from "../../../../component/page-heading/PageHeading";
import magicStrings from "../../../../utils/magic-string";

const CurrentAddressPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSoleTrader = useSelector(
    (state) => state.contractAmends.isSoleTrader
  );
  const currentPartnerIndex = useSelector(
    (state) => state.contractAmends.currentPartnerIndex
  );

  const partnerType = useSelector((state) => state.contractAmends.partnerType);
  const partners = useSelector((state) => state.contractAmends.partners);
  const totalPartners = Array.isArray(partners) ? partners.length : 0;
  const partnerAddress =
    totalPartners > 0 && partners[currentPartnerIndex]?.addressDetails
      ? partners[currentPartnerIndex]?.addressDetails
      : null;

  useContractAmendsStepCheck(
    isSoleTrader
      ? steps.bch.soleTrader.currentAddress
      : totalPartners > 0
      ? steps.bch.limitedCompany.partners[currentPartnerIndex].currentAddress
      : steps.exit,
    "../personal-details",
    !isSoleTrader && partnerAddress && !partnerAddress.postCode
  );

  useEffect(() => {
    if (
      !partnerAddress ||
      !partnerAddress.postCode ||
      !partnerAddress.addressLine1
    ) {
      navigate("../capture-address", { replace: true });
    }
  }, [partnerAddress, navigate]);

  const onAddressChange = () => {
    dispatch(
      step(
        isSoleTrader
          ? steps.bch.soleTrader.captureAddress
          : steps.bch.limitedCompany.partners[currentPartnerIndex]
              .captureAddress
      )
    );

    navigate("../capture-address");
  };

  const onAddressCorrect = () => {
    if (isSoleTrader || currentPartnerIndex >= 2) {
      dispatch(
        step(
          isSoleTrader
            ? steps.bch.soleTrader.reviewApplication
            : steps.bch.limitedCompany.reviewApplication
        )
      );
      navigate("../review-application");
    } else {
      if (currentPartnerIndex + 1 < partners.length) {
        dispatch(
          step(
            steps.bch.limitedCompany.partners[currentPartnerIndex + 1]
              .personalDetails
          )
        );
        dispatch(dispatchCurrentPartnerIndex(currentPartnerIndex + 1));
        navigate("../validate-existing-partner");
      } else {
        dispatch(
          step(
            steps.bch.limitedCompany.partners[currentPartnerIndex].addNewPartner
          )
        );
        navigate("../add-new-partner");
      }
    }
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Address details</PageHeading>
          <p>Please check the address details we hold for you are correct.</p>
        </div>
      </CenterAlignedContainer>
      <CenterAlignedButtonContainer>
        <div>
          {!isSoleTrader && totalPartners > 0 && (
            <>
              <div>
                <strong>
                  {partnerType}{" "}
                  {`${currentPartnerIndex + 1} of ${totalPartners}`}
                </strong>
              </div>
              <hr className="mt-1 mb-3" />
            </>
          )}
          <h3 className="text-primary">Is this your current address?</h3>
          <hr className={"mt-1"} />
          {partnerAddress?.addressLine1 && (
            <div className={`${magicStrings.maskInformation}`}>
              {partnerAddress.addressLine1}
            </div>
          )}
          {partnerAddress?.addressLine2 && (
            <div className={`${magicStrings.maskInformation}`}>
              {partnerAddress.addressLine2}
            </div>
          )}
          {partnerAddress?.addressLine3 && (
            <div className={`${magicStrings.maskInformation}`}>
              {partnerAddress.addressLine3}
            </div>
          )}
          {partnerAddress?.city && (
            <div className={`${magicStrings.maskInformation}`}>
              {partnerAddress.city}
            </div>
          )}
          {partnerAddress?.county && (
            <div className={`${magicStrings.maskInformation}`}>
              {partnerAddress.county}
            </div>
          )}
          {partnerAddress?.postCode && (
            <div className={`text-uppercase ${magicStrings.maskInformation}`}>
              {partnerAddress.postCode}
            </div>
          )}
          <hr />
        </div>
        <Button id="btnYesAddressCorrect" onClick={onAddressCorrect}>
          Yes, continue
        </Button>
        <Button
          id="btnNoChangeAddress"
          onClick={onAddressChange}
          variant="outline-primary"
        >
          No, it needs changing
        </Button>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default CurrentAddressPage;
