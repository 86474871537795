const onTrackLink = (e) => {
  // This needs to be refined.
  // Testing first to see if this will work.
  if (window && window.utag && typeof window.utag.link === "function") {
    try {
      if (typeof e?.target?.localName === "string") {
        if (
          e.target.localName.toLowerCase() === "button" ||
          (e.target.localName.toLowerCase() === "a" &&
            e.target.classList &&
            typeof e.target.classList.contains === "function" &&
            e.target.classList.contains("btn"))
        ) {
          window.utag.link({
            event_name: "Click",
            link_text: e.target.innerText,
            link_type: "Button",
            link_url: window.location.href,
          });
        }
      }
    } catch (e) {}
  }
};

const onTrackPageNavigation = (url, role, journey_name, journey_step_name) => {
  if (window && window.utag && typeof window.utag.view === "function") {
    try {
      if (typeof url === "string") {
        window.utag.view({
          link_url: url,
          PageRole: role,
          JourneyName: journey_name,
          JourneyStepName: journey_step_name,
        });
      }
    } catch (e) {}
  }
};

const onTrackError = (message, customObject) => {
  if (window && window.utag) {
    try {
      window._uxa = window._uxa || [];
      window._uxa.push([
        "trackError",
        message,
        customObject ? customObject : {},
      ]);
    } catch (e) {}
  }
};

export { onTrackLink, onTrackPageNavigation, onTrackError };
