import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BasePage from "../../../pages/basepages/basepage/BasePage";
import PageHeading from "../../../component/page-heading/PageHeading";

const PaymentHolidaysPage = () => {
  return (
    <BasePage pageTitle="Payment holidays">
      <PageHeading align="left">
        We're here to help, please get in touch
      </PageHeading>
      <p>
        If you don't think you'll be able to make your full monthly payment, we
        can help find a plan that works for you.
      </p>
      <div className="d-flex flex-column gap-3 col-12 col-sm-8 col-lg-6 col-xl-5 mx-auto mt-4">
        <Button
          as={Link}
          to="/help"
          id="btnHelpAndSupport"
          className="text-decoration-none"
        >
          Help and support
        </Button>
        <Button
          as={Link}
          to="/home"
          id="btnHome"
          variant="outline-primary"
          className="text-decoration-none"
        >
          Home
        </Button>
      </div>
    </BasePage>
  );
};

export default PaymentHolidaysPage;
