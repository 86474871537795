import { OverlayTrigger, Tooltip } from "react-bootstrap";

const getBackgroundFromVariant = (variant) => {
  let variantToReturn = "";
  switch (variant) {
    case "success":
      variantToReturn = "bg-success";
      break;
    case "info":
      variantToReturn = "bg-info  text-dark";
      break;
    case "warning":
      variantToReturn = "bg-warning  text-dark";
      break;
    case "danger":
      variantToReturn = "bg-danger";
      break;
    case "secondary":
      variantToReturn = "bg-secondary";
      break;
    default:
      variantToReturn = "bg-info  text-dark";
      break;
  }
  return variantToReturn;
};

const RoundedPill = ({
  toolTip,
  variant = "success",
  isMobileView,
  children,
}) => {
  return (
    <OverlayTrigger
      delay={{ hide: 100, show: 100 }}
      overlay={(props) => (
        <Tooltip {...props} aria-live="polite">
          {toolTip}
        </Tooltip>
      )}
      placement="top"
    >
      <div
        className={`${getBackgroundFromVariant(
          variant
        )} badge w-100 py-2 rounded-pill text-uppercase ${
          isMobileView ? "px-4 d-inline-block w-auto" : ""
        }`}
      >
        <div className="text-decoration-dotted h6 mb-0">{children}</div>
      </div>
    </OverlayTrigger>
  );
};

export default RoundedPill;
