import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  GrossIncomeField,
  ShareOfRentField,
  ShareOfChildCostsField,
  ExpectedChangesField,
  DescriptionOfExpectedChanges,
} from "../fields";

import * as fieldSchemas from "../schemas/fields";

const FinancialDetailsForm = ({ defaultValues, onFormSubmit }) => {
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        ...fieldSchemas.grossIncomeSchema,
        ...fieldSchemas.shareOfRentSchema,
        ...fieldSchemas.shareOfChildCostsSchema,
        ...fieldSchemas.expectedChangesSchema,
        descriptionExpectedChanges: yup
          .string()
          .when(["expectedChanges"], ([expectedChanges], schema) => {
            return expectedChanges === 1
              ? fieldSchemas.descriptionExpectedSchema
                  .descriptionExpectedChanges
              : schema.notRequired();
          }),
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { handleSubmit, watch } = methods;
  const expectedChanges = watch("expectedChanges");
  const onSubmit = (data) => {
    onFormSubmit(data);
  };
  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="FinancialDetailsForm"
        autoComplete="off"
      >
        <GrossIncomeField />
        <ShareOfRentField />
        <ShareOfChildCostsField />
        <ExpectedChangesField />
        {expectedChanges?.toString() === "1" && (
          <DescriptionOfExpectedChanges />
        )}

        <div className="d-grid gap-2">
          <Button className="mt-3" type="submit" id="btnFinancialDetailsForm">
            Continue to review application
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};
export default FinancialDetailsForm;
