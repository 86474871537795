import { Button, Form } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import addressSchema from "../schemas/address-schema";
import AddressLookup from "../address-lookup/AddressLookup";
import {
  useAddressesByPostcode,
  useAddressByLineId,
} from "../../../api/hooks/addresses";

const CaptureAddressForm = ({ onFormSubmit, buttonText }) => {
  const methods = useForm({
    resolver: yupResolver(yup.object().shape({ ...addressSchema })),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { watch, handleSubmit } = methods;

  const watchSelectAddress = watch("selectAddress");
  const {
    refetch,
    addresses,
    isSuccess: isAddressesByPostcodeSuccess,
  } = useAddressesByPostcode(watch("postCode"));
  const { address } = useAddressByLineId(watchSelectAddress);

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="captureAddressForm"
      >
        <AddressLookup
          addresses={addresses}
          refetchAddresses={refetch}
          addressDetails={address}
          isAddressFetchSucessful={isAddressesByPostcodeSuccess}
        />
        <br />
        <div className="d-grid gap-2">
          <Button type="submit">{buttonText}</Button>
        </div>
      </Form>
    </FormProvider>
  );
};

export default CaptureAddressForm;
