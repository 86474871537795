import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const MakeAPaymentIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    role="img"
    viewBox="0 0 28 28"
    aria-label={title}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <path
        id="make-a-payment-icon_svg__a"
        d="M29.042 10.313v-3.23a2.842 2.842 0 0 0-2.834-2.833H6.375a2.833 2.833 0 0 0-2.833 2.833v19.834a2.833 2.833 0 0 0 2.833 2.833h19.833a2.842 2.842 0 0 0 2.834-2.833v-3.23c.835-.496 1.416-1.389 1.416-2.437v-8.5c0-1.048-.58-1.94-1.416-2.437Zm-1.417 2.437v8.5h-9.917v-8.5h9.917ZM6.375 26.917V7.083h19.833v2.834h-8.5a2.842 2.842 0 0 0-2.833 2.833v8.5a2.842 2.842 0 0 0 2.833 2.833h8.5v2.834H6.375Zm15.583-12.042a2.125 2.125 0 1 0 0 4.25 2.125 2.125 0 0 0 0-4.25Z"
      />
    </defs>
    <use
      xlinkHref="#make-a-payment-icon_svg__a"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-3 -4)"
    />
  </svg>
);

MakeAPaymentIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { MakeAPaymentIcon };
export default MakeAPaymentIcon;
