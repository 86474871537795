import { Button, Row, Col, Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import BasePage from "../../basepages/basepage/BasePage";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import {
  reset,
  step,
  selectedContractId as dispatchSelectedContractId,
  proposedAmendment,
  contractDetails,
  isExistingQuote,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import Tile from "../../../component/tile/Tile";
import EmptyStateIcon from "../../../component/icons/EmptyStateIcon";
import {
  useContract,
  useContractAmendExisting,
} from "../../../api/hooks/contracts";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import useAuth from "../../../hooks/use-auth/useAuth";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const ExistingQuotesPage = () => {
  useContractAmendsStepCheck(steps.existingQuotes, "../");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const { auth } = useAuth();
  const [counterParty] = useState(() => {
    return auth?.user?.counterParty?.code;
  });

  const quoteStatuses = {
    "outstanding information": "Financial Details Required",
    "pending for approval": "Application in Progress",
    "contract amend referred": "Application in Progress",
    "ready to dispatch": "Application Successful",
    dispatched: "Application Successful",
    declined: "Application Unsuccessful",
    accepted: "Amendment Completed",
    expired: "Expired",
    existingapplicationinprogress: "Financial Details Required",
  };

  const getQuoteStatus = (status) => {
    if (status && quoteStatuses[status.toLowerCase()]) {
      return quoteStatuses[status.toLowerCase()];
    }
    return "Unknown";
  };

  const { quotes, isError, errorResponse } = useContractAmendExisting();
  const {
    contract,
    isError: isContractError,
    errorResponse: contractErrorResponse,
  } = useContract(selectedContractId);

  useEffect(() => {
    if (
      contract &&
      !contract.__isDefault &&
      contract.contractId &&
      selectedContractId &&
      selectedQuote
    ) {
      dispatch(isExistingQuote(true));
      dispatch(contractDetails(contract));
      if (
        selectedQuote?.currentStatus?.toLowerCase() ===
          "outstanding information" ||
        selectedQuote?.currentStatus?.toLowerCase() ===
          "existingapplicationinprogress"
      ) {
        dispatch(step(steps.newQuote.saveQuote));
        navigate("../save-quote");
      } else {
        dispatch(step(steps.applicationSuccess));
        navigate("../application-success");
      }
    }
  }, [
    contract,
    dispatch,
    navigate,
    counterParty,
    selectedContractId,
    selectedQuote,
  ]);

  const onViewQuote = (caNumber) => {
    dispatch(reset());
    if (caNumber) {
      const currentQuote = quotes.find(
        (quote) =>
          quote.caNumber && quote.caNumber.toString() === caNumber.toString()
      );
      setSelectedContractId(null);
      if (
        currentQuote &&
        currentQuote.contractId &&
        currentQuote.currentStatus
      ) {
        setSelectedQuote(currentQuote);
        const quoteStatus = currentQuote.currentStatus.toLowerCase();
        if (
          quoteStatus === "pending for approval" ||
          quoteStatus === "contract amend referred"
        ) {
          navigate("../application-referred");
        } else if (quoteStatus === "declined" && counterParty === 21) {
          navigate("../application-declined");
        } else if (quoteStatus === "declined") {
          navigate("../bch/application-declined");
        } else if (
          quoteStatus === "outstanding information" ||
          quoteStatus === "ready to dispatch" ||
          quoteStatus === "dispatched" ||
          quoteStatus === "existingapplicationinprogress"
        ) {
          setSelectedContractId(currentQuote.contractId);
          dispatch(dispatchSelectedContractId(currentQuote.contractId));
          dispatch(proposedAmendment(currentQuote));
        }
      }
    }
  };

  const onStartNewQuote = () => {
    dispatch(reset());
    dispatch(step(steps.newQuote.vehicleSelection));
    navigate("../vehicle-selection", { replace: true });
  };

  return (
    <BasePage pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <div className="text-center">
        <CenterAlignedContainer>
          <PageHeading>Existing quotes</PageHeading>
          <p>Here is a summary of your recent contract amendment quotes.</p>
          {isContractError && (
            <Alert variant="danger">
              {getSanitisedErrorMessage(
                contractErrorResponse.localErrorMessage
              )}
            </Alert>
          )}
          {!isContractError && isError && (
            <Alert variant="danger">
              {getSanitisedErrorMessage(errorResponse.localErrorMessage)}
            </Alert>
          )}
        </CenterAlignedContainer>
      </div>
      <Row className="my-3">
        {quotes &&
          quotes.length > 0 &&
          quotes.map((quote) => {
            return (
              <Tile
                cardBorder={true}
                key={quote.caNumber?.toString()}
                cardId={quote.caNumber?.toString()}
                cardLinkContent="View quote"
                md={12}
                lg={6}
                isCardLinkDisabled={
                  !quote?.currentStatus ||
                  quote.currentStatus.toLowerCase() === "expired" ||
                  quote.currentStatus.toLowerCase() === "accepted"
                }
                isLink={false}
                callBack={onViewQuote}
              >
                <dl className="row mb-0">
                  <dt className="col-6 mb-2 text-end">
                    Vehicle registration:{" "}
                  </dt>
                  <dd
                    className={`col-6 text-start ps-0 d-flex align-items-end ${magicStrings.maskInformation}`}
                  >
                    {quote.vehicleReg}
                  </dd>
                  <dt className="col-6 mb-2 text-end">Quote reference: </dt>
                  <dd
                    className={`col-6 text-start ps-0 d-flex align-items-start ${magicStrings.maskInformation}`}
                  >
                    {quote.caNumber}
                  </dd>
                  <dt className="col-6 mb-2 text-end">Status: </dt>
                  <dd
                    className={`col-6 text-start ps-0 d-flex align-items-start ${magicStrings.maskInformation}`}
                  >
                    {getQuoteStatus(quote.currentStatus)}
                  </dd>
                  <dt className="col-6 mb-2 text-end">Expiry date: </dt>
                  <dd
                    className={`col-6 text-start ps-0 d-flex align-items-start ${magicStrings.maskInformation}`}
                  >
                    {/* Need to use formatDate function here once other change has merged */}
                    {DateTime.fromISO(quote.expiryDate).toFormat("dd/MM/yyyy")}
                  </dd>
                </dl>
              </Tile>
            );
          })}
        {!quotes ||
          (quotes.length <= 0 && (
            <Col className="my-4">
              <EmptyStateIcon
                title="Empty State"
                textContent="You dont have any saved quotes"
              />
            </Col>
          ))}
      </Row>
      <CenterAlignedButtonContainer>
        <Button id="btnStartNewQuote" onClick={onStartNewQuote}>
          Start new quote
        </Button>
        <BackToHomeButton />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ExistingQuotesPage;
