import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/use-auth/useAuth";
import BasePage from "../../basepages/basepage/BasePage";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";

const ErrorNotFoundPage = () => {
  const { auth } = useAuth();
  return (
    <BasePage pageTitle="Manage My Vehicle">
      <CenterAlignedContainer>
        <TitleWithIcon title="Page not found" variant="failure" />
        <p className="text-center my-5">
          Unfortunately we are unable to find the page you are looking for.
        </p>
        {auth?.user?.status === "OK" ? (
          <CenterAlignedButtonContainer>
            <BackToHomeButton variant="primary" />
          </CenterAlignedButtonContainer>
        ) : (
          <CenterAlignedButtonContainer>
            <Button
              as={Link}
              to="/"
              id="lnkErrorGoToLoginPage"
              className="text-decoration-none"
            >
              Login
            </Button>
          </CenterAlignedButtonContainer>
        )}
      </CenterAlignedContainer>
    </BasePage>
  );
};

export default ErrorNotFoundPage;
