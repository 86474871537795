import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../contract-amends-stepper/ContractAmendsStepper";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import {
  step,
  piAddressDetails,
  reset,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import AddressDetailsForm from "../../../component/forms/contract-amendment/AddressDetailsForm";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import PageHeading from "../../../component/page-heading/PageHeading";

const AddressDetailsPage = () => {
  useContractAmendsStepCheck(
    steps.newQuote.addressDetails,
    "../personal-details"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addresses = useSelector(
    (state) => state.contractAmends.privateIndividual.addressDetails
  );

  const onFormSubmit = (data) => {
    dispatch(piAddressDetails(data));
    dispatch(step(steps.newQuote.employmentDetails));
    navigate("../employment-details");
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Address details</PageHeading>
          <p>
            We require a minimum of 3 years address history, if you have lived
            in your current address for less than this you will also need to
            provide your previous addresses.
          </p>
        </div>
      </CenterAlignedContainer>

      <CenterAlignedButtonContainer>
        <AddressDetailsForm
          minimumTimeAtAddressMonths={36}
          defaultValues={addresses}
          onFormSubmit={onFormSubmit}
        />
        <BackToHomeButton onButtonClicked={() => dispatch(reset())} />
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default AddressDetailsPage;
