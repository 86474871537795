import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";

const getDocuments = async (axios) => {
  return axios.get("/account/documents").then((res) => {
    return res.data;
  });
};

const useDocuments = () => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const { data = fallback, isError } = useQuery(
    queryKeys.documents,
    () => getDocuments(axios),
    { cacheTime: 10 * 60 * 1000, staleTime: 10 * 60 * 1000 }
  );
  return {
    documents: data,
    isError,
  };
};

export { useDocuments };
