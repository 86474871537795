import { useRef } from "react";
import {
  loadPDFFromBase64,
  loadPDFFromDataURI,
} from "../../utils/pdf/pdf-utils";

const PDFViewer = ({
  base64,
  dataURI,
  onLoadSuccess = () => {},
  onLoadFailure = () => {},
}) => {
  const pdfContainerRef = useRef(null);

  const onLoad = (data) => {
    // Wait until the PDFViewer has loaded
    if (data?.isLoading) return;

    if (data && data.loadSuccessful) {
      onLoadSuccess(data);
    } else {
      onLoadFailure(data);
    }
  };

  if (dataURI) {
    loadPDFFromDataURI(pdfContainerRef, dataURI, onLoad);
  } else if (base64) {
    loadPDFFromBase64(pdfContainerRef, base64, onLoad);
  } else {
    onLoad({
      loadSuccessful: false,
      message: "PDF: No valid base64, dataURI or file path was given",
    });
  }

  return (
    <div
      ref={pdfContainerRef}
      id="pdfContainerRef"
      className="d-flex justify-content-center align-content-center flex-wrap flex-column my-2 py-3 px-2"
    ></div>
  );
};

export default PDFViewer;
