import FooterBasePage from "../basepages/footer-basepage/FooterBasePage";
import { Table } from "react-bootstrap";

const CookiesPage = () => {
  return (
    <FooterBasePage
      pageTitle="Cookies"
      pageSubTitle="Protecting and managing your online privacy."
    >
      <div className="bg-white my-0 my-sm-4 main-container">
        <p className="fw-bold">Last updated July 2020.</p>
        <p>
          Our Cookies policy explains how we use cookies and and similar
          tracking technologies. It also explains the choices you can make about
          whether we can put some types of cookies on your PC, tablet or mobile
          phone.
        </p>
        <p>
          In this policy, where we say 'cookies' it can also mean similar
          tracking technologies that collect data while you use our websites and
          mobile apps, and when you receive, open or respond to emails we send
          you. More information on the tracking we use in our emails, including
          how you can stop it, can be found in the Email Tracking section below.
        </p>
        <p>
          Data we collect will be held by Lex Autolease Limited, which is part
          of Lloyds Banking Group. We use this data to:
        </p>
        <ul>
          <li>Protect you from fraud and keep improving security.</li>
          <li>
            Study how people use our websites and apps and our other services,
            so we can improve them.
          </li>
          <li>
            Decide which of our products, services and offers may be relevant
            for you.
          </li>
          <li>
            Tailor the marketing you see on social media, apps and other
            websites.
          </li>
        </ul>
        <p>
          We may share this data with other companies in the Group. We also
          share some data with outside organisations. Some of them may place
          their own cookies on your device when you visit our website. You can
          find out more about how we share data – and who we share it with – in
          our{" "}
          <a href="/privacy" className="border-bottom border-white">
            Privacy notice.
          </a>
        </p>
        <p>We do not sell data to organisations outside our Group.</p>
        <h2 className="h3">What are cookies?</h2>
        <p>
          Cookies are small files that are sent to your PC, mobile phone or
          tablet when you visit a website. They stay on your device and are sent
          back to the website they came from when you visit it again. Cookies
          collect data and send it to us while you use the site. These are
          written into mobile apps and web pages that you go to on your device.
        </p>
        <h2 className="h3">Session and persistent cookies</h2>
        <p>All cookies are either session cookies or persistent cookies.</p>
        <p>
          <span className="fw-bold">Session cookies</span> last for the length
          of your visit to a website. They delete themselves when you close your
          browser. Session cookies can help with security. For instance, they
          can keep you logged on as you move around a website.
        </p>
        <p>
          <span className="fw-bold">Persistent cookies</span> stay on your
          device when you close your browser. They are used for many jobs. For
          instance, they can remember your User ID for websites where you log
          on. They can also store your choices for when you go back to a
          website.
        </p>
        <p>
          Organisations find them useful to see how people use their websites.
          They can also be used to tailor the ads and marketing you see on
          social media, apps and other websites.
        </p>
        <h2 className="h3">First and third party cookies</h2>
        <p>
          Whether a cookie is first or third party depends on where it comes
          from.
        </p>
        <p>
          <span className="fw-bold">First party cookies</span> are set by
          websites you go to.
        </p>
        <p>
          <span className="fw-bold">Third party cookies</span> are set by
          outside organisations
        </p>
        <p>
          such as social media, search engines, other advertising networks, and
          our business partners.
        </p>
        <p className="fw-bold">Our Third Party Partners</p>
        <p>
          If you have given us your consent, we may allow third parties to
          collect data by setting their own cookies on your device. If you use
          our Mobile Banking app, they may also use mobile device identifiers to
          personalise and measure adverts shown to you.
        </p>
        <p>
          You can change your choices at any time. Select the 'Cookies' link at
          the bottom of any page of our website and go from there.
        </p>
        <p>
          If you use one of our mobile apps, you can also change how your mobile
          device identifier is used by updating your settings in the app
        </p>
        <p>
          Here are links to the information notices for our main Third Party
          Partners.
        </p>
        <Table responsive="md" bordered>
          <tbody>
            <tr>
              <td valign="middle">Google</td>
              <td>
                <p>
                  <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How Google use cookies in advertising
                  </a>
                </p>
                <p>
                  <a
                    href="https://policies.google.com/technologies/partner-sites"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How Google uses the data it collects
                  </a>
                </p>
                <p>
                  <a
                    href="https://policies.google.com/technologies/cookies#types-of-cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Types of cookies we use with Google
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">Oracle Cloud</td>
              <td>
                <p>
                  <a
                    href="https://www.oracle.com/uk/legal/privacy/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Oracle Data Cloud Privacy Policy
                  </a>
                </p>
                <p>
                  <a
                    href="https://datacloudoptout.oracle.com/#optout"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Opt-out from Oracle cookies
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>
                <a
                  href="https://www.facebook.com/policies/cookies/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook Cookies Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>Appnexus (also known as xandr)</td>
              <td>
                <a
                  href="https://www.appnexus.com/platform-privacy-policy-072016"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
        <h2 className="h3">Essential cookies and cookies you can choose</h2>
        <p>
          Different types of cookies do different jobs on our website. Some are
          needed to make the website work. We need your consent to use others
          that are not essential. You can change your choices at any time. Just
          click the 'Cookies' link at the end of any page.
        </p>
        <h2 className="h3">Strictly neccessary</h2>
        <p>
          These cookies are needed to run our website, to keep it secure if you
          are logged on and to obey regulations that apply to us.
        </p>
        <p>
          If you are a customer, they help us know who you are so that you can
          log on and manage your accounts. They also help us keep your details
          safe and private.
        </p>
        <p>Other important jobs they do are:</p>
        <ul>
          <li>Help you move around the site.</li>
          <li>
            Tell us if you've been to it before and which pages you went to.
          </li>
          <li>
            Tell us how the site is working, so we can find and fix any
            problems.
          </li>
        </ul>
        <p className="fw-bold">You can't turn off these cookies.</p>
        <h2 className="h3">Functional</h2>
        <p>These cookies are used for remembering things like:</p>
        <ul>
          <li>Your user ID on the log on page.</li>
          <li>Your region or country.</li>
          <li>Your preferred language.</li>
          <li>Accessibility options like large font or high contrast pages.</li>
        </ul>
        <p className="fw-bold">You can't turn off these cookies.</p>
        <h2 className="h3">Performance</h2>
        <p>
          These cookies tell us how you and our other customers user our
          website. We combine all this data together and study it. This helps us
          to:
        </p>
        <ul>
          <li>Improve the performance of our services.</li>
          <li>Improve the products we provide.</li>
        </ul>
        <p className="fw-bold">
          We'll ask for your consent to use these cookies.
        </p>
        <h2 className="h3">Marketing</h2>
        <p>
          These cookies help us decide which of our products, services and
          offers may be relevant for you.
        </p>
        <p>
          We may use this data to tailor the marketing and ads you see on our
          own and other websites and mobile apps, including social media. For
          instance, you may see our ads on other sites after you have been to
          our website.
        </p>
        <p>
          If you turn off marketing cookies, you will still see ads online, but
          they will not be tailored to things that may interest you.
        </p>
        <p className="fw-bold">
          We'll ask for your consent to use these cookies.
        </p>
        <h2 className="h3">Performance and marketing cookies</h2>
        <p>
          Lloyds Banking Group is changing its websites to make sure we ask for
          your consent to use these cookies. It will take some time to update
          all our systems. Until then, you won't be able to make your choices on
          some websites run by companies within our Group. This means you may
          still see tailored marketing and ads from us during this time, even if
          you would prefer not to. We will update this cookies policy as soon as
          the work is complete.
        </p>
        <h2 className="h3">Keep in mind</h2>
        <p className="fw-bold">Multiple users</p>
        <p>
          If more than one person uses your device, the choices set up by other
          people will apply to you as well unless you change them. For example,
          if you share a computer with your family, you may see ads based on
          sites they have visited as well as sites you have visited.
        </p>
        <p className="fw-bold">Cookies with more than one use</p>
        <p>
          Some of our cookies collect data for more than one use. We will only
          use these cookies for their essential purposes unless you have given
          your consent to any other uses they have.
        </p>
        <p className="fw-bold">Cookies that are already on your device</p>
        <p>
          Turning off one or more types of cookies will not delete any that have
          been downloaded in the past. We may still use data we collected up to
          that point, but will stop collecting new data.
        </p>
        <p className="fw-bold">Managing cookies choices in your browser</p>
        <p>
          You can turn off or delete cookies in your browser. If you do this, it
          may affect sites that use similar cookies to us.
        </p>
        <p>
          Cookies choices you set in your browser replace any you set on a
          single website. For instance, if you run an ad blocker on your
          browser, you won't see ads that are tailored to you, even if you have
          turned on marketing cookies.
        </p>
        <p>
          Find out how to manage cookies in common browsers (Internet Explorer,
          Chrome, Firefox and Safari) on the{" "}
          <a
            href="https://ico.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="border-bottom border-white"
          >
            Information Commissioners' Office (ICO) website.
          </a>
        </p>
        <h2 className="h3">Email tracking</h2>
        <p>
          This section explains more about the technologies we use to track
          emails, why we do this, and how you can stop it.
        </p>
        <p>
          We track emails to help us improve the communications we send. We use
          small images called pixels within our emails to tell us things like
          whether you opened the email, how many times and the device you used.
          We may also set a cookie to find out if you clicked on any links in
          the email. The image stays in the email but leaves nothing else on
          your device.
        </p>
        <p className="fw-bold">How to stop email tracking</p>
        <p>
          You can stop this by closing the email before you download any images
          or click on any links. You can also set your browser or email program
          to restrict or block our emails. Sometimes your browser or email
          program setting will automatically accept cookies. For more details on
          this, you will need to read the instructions for your browser, email
          program or device.
        </p>
        <p className="fw-bold">Readability grade</p>
        <p>A - Acceptable </p>
        <p>
          The text meets the required standard. It may contain some jargon or
          other special terms where necessary. Make sure explanations are
          provided to conform to AA standard. Flesch index 73.30
        </p>
      </div>
    </FooterBasePage>
  );
};

export default CookiesPage;
