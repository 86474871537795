import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BasePage from "../basepages/basepage/BasePage";
import ForgotLoginDetailsForm from "../../component/forms/forgotten-details/ForgotLoginDetailsForm";
import PageHeading from "../../component/page-heading/PageHeading";
import CenterAlignedButtonContainer from "../../component/center-aligned-button-container/CenterAlignedButtonContainer";
const ForgotLoginDetailsPage = () => {
  const navigate = useNavigate();
  const onFormSubmit = (data) => {
    switch (data.forgotLoginRadio) {
      case "1":
        navigate("./user-id");
        break;
      case "2":
        navigate("./password");
        break;
      case "3":
        navigate("./user-id-and-password");
        break;
      default:
        break;
    }
  };
  return (
    <BasePage
      pageTitle="Forgotten your details"
      pageSubTitle="Follow this journey if you've forgotten your user ID or alias and/or password."
    >
      <PageHeading align="center">
        What login details have you forgotten?
      </PageHeading>
      <p className="text-center">
        Select the option that best describes your issue.
      </p>
      <CenterAlignedButtonContainer className="mt-3">
        <ForgotLoginDetailsForm onFormSubmit={onFormSubmit} />

        <p>
          Remember your details? <Link to="/login">Log in here</Link>.
        </p>
        <p>
          If you have already attempted to change your password but haven't
          received an email, please try the{" "}
          <strong>'I have forgotten my password and user ID or alias'</strong>{" "}
          option instead.
        </p>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default ForgotLoginDetailsPage;
