import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Questionnaire from "../../../../component/questionnaire/Questionnaire";
import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import { step } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";
import CollectionStepper from "../../collection-stepper/CollectionStepper";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import PageHeading from "../../../../component/page-heading/PageHeading";

const Question5Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useVehicleCollectionStepCheck(steps.roadWorthyQuestion5, "../question4");

  const onActionClicked = (result) => {
    dispatch(step(steps.roadWorthyQuestion6));
    if (result === 1) {
      navigate("../question6");
    } else if (result === 2) {
      navigate("../not-road-worthy");
    }
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      <PageHeading align="left">Arranging a collection</PageHeading>
      <p className="mb-5">
        We need to ask you some questions to check that your vehicle is
        roadworthy and ready to be collected. If a collection is arranged and
        the vehicle is unsafe to drive your collection will be cancelled and you
        will be charged a fee.
      </p>
      <h3>
        Question 5 of 6: Are all tyres within the legal tyre depth limit and
        free from bumps, bulges, splits, foreign objects or cracks?
      </h3>

      <p className="mb-5">
        The legal limit for minimum depth of the tread on your tyres is 1.6
        millimetres, across the central three-quarters of the tread around the
        complete circumference of the tyre.
      </p>
      <Questionnaire
        option1Title="Yes"
        option2Title="No"
        option1Content="All tyres are roadworthy."
        option2Content="One or more tyres are not road worthy."
        option1ActionContent="Yes"
        option2ActionContent="No"
        actionCallback={onActionClicked}
      />
    </WizardBasePage>
  );
};

export default Question5Page;
