import { createSlice } from "@reduxjs/toolkit";

import revertAll from "../../actions/revert-all/revertAll";

const initialState = {
  reviewSteps: [],
  orderDetails: {
    orderId: "",
  },
  lastDocumentSigned: false,
  signedOrderDetails: {},
};

export const orderReviewSlice = createSlice({
  name: "orderReview",
  initialState,
  reducers: {
    reviewSteps: (state, action) => {
      state.reviewSteps = action.payload;
    },
    orderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    lastDocumentSigned: (state, action) => {
      state.lastDocumentSigned = action.payload;
    },
    signedOrderDetails: (state, action) => {
      state.signedOrderDetails = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  reviewSteps,
  orderDetails,
  reset,
  lastDocumentSigned,
  signedOrderDetails,
} = orderReviewSlice.actions;

export default orderReviewSlice.reducer;
