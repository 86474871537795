import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const ArrowDownIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    role="img"
    viewBox="0 0 20 14"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.75 9.14 6.797-6.523c.208-.208.417-.208.625 0l1.21 1.172c.21.208.21.417 0 .625l-8.32 7.969a.422.422 0 0 1-.312.117.422.422 0 0 1-.313-.117l-8.32-7.969c-.208-.208-.208-.417 0-.625l1.211-1.172c.208-.208.417-.208.625 0L9.75 9.141Z"
    />
  </svg>
);

ArrowDownIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { ArrowDownIcon };
export default ArrowDownIcon;
