import { Container, Row, Col, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { getLocalStorage } from "../../storage/LocalStorage";
import brandingContent from "./footer-brand-config.json";

const Footer = () => {
  const [brandDetails] = useState(() => {
    const brandName = getLocalStorage("brandName");
    return brandingContent.find((b) => b.brandName === brandName);
  });

  return (
    <>
      <Row className="bg-black">
        <Col>
          <Container>
            {brandDetails ? (
              <div className="text-center text-white py-5">
                {brandDetails.copyright} <br />
                {brandDetails.registerNo} <br />
                {brandDetails.text}
              </div>
            ) : (
              ""
            )}
          </Container>
        </Col>
      </Row>
      <Row className="background-secondary-regular-light">
        <Col>
          <div className="d-flex justify-content-around py-2 px-0">
            <Nav className="footer-nav flex-column flex-sm-row text-center text-sm-start">
              <NavLink to="/legal" className="border-start-0">
                Legal
              </NavLink>
              <NavLink to="/privacy">Privacy Policy</NavLink>
              <NavLink to="/accessibility">Accessibility</NavLink>
              <NavLink to="/cookies">Cookies</NavLink>
              <NavLink to="/terms-and-conditions">Terms and Conditions</NavLink>
            </Nav>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Footer;
