import { Link, useNavigate } from "react-router-dom";
import { Accordion, Alert, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import BasePage from "../../basepages/basepage/BasePage";
import steps from "../vehicle-collection-steps";

import {
  reset,
  step,
} from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import { useEffect } from "react";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { useContractsForExistingVehicleCollections } from "../../../api/hooks/contracts";
import PageHeading from "../../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const VehicleCollectionIntroductionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const { contracts, isError, errorResponse } =
    useContractsForExistingVehicleCollections();

  const onArrangeCollection = () => {
    dispatch(step(steps.vehicleSelection));
    navigate("/vehicle-collection/vehicle-selection");
  };

  const onMyBookings = () => {
    dispatch(step(steps.existingBookings.listOfBookings));
    navigate("/vehicle-collection/existing-bookings");
  };

  return (
    <BasePage
      pageTitle="Book a collection"
      pageSubTitle="How to return a vehicle when a contract ends."
    >
      <PageHeading align="left">Returning a vehicle</PageHeading>
      <p>
        As you approach the end of your contract, you'll need to think about if
        you want to keep your vehicle or return it.
      </p>
      <p>
        If you'd like to return your vehicle, let us know when you'd like us to
        come and collect it. You can book your collection as far ahead as 90
        days before your contract end date. You can{" "}
        <Link to="/vehicles">find your contract end date here</Link>.
      </p>
      <p>
        It's best to arrange your collection as soon as possible so that we can
        try to visit on your preferred date. Thursdays and Fridays are our
        busiest days and dates can fill up quickly. We're closed on bank
        holidays. We recommend that you book your collection at least 14 days in
        advance. You can also ask us to collect your vehicle if your contract
        end date has already passed, and we will charge you for any additional
        days that you keep the vehicle past this.
      </p>
      <p>
        If you'd prefer to keep your vehicle, you can find out{" "}
        <Link to="/contract-amendment">how to extend your contract here</Link>.
      </p>
      <Accordion className="border-top my-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Ending a contract early</Accordion.Header>
          <Accordion.Body>
            <p>
              If you've asked to end a contract earlier than planned, you'll
              need to review and accept our Early Termination quote before you
              ask us to collect your vehicle.
            </p>
            <p>
              If you'd like to end a contract early but haven't told us yet, you
              can{" "}
              <Link to="/early-termination">
                request an Early Termination here
              </Link>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Get ready for your collection</Accordion.Header>
          <Accordion.Body>
            <p>
              When we collect your vehicle, we'll check to make sure it's in
              good condition. To help avoid additional charges for any damage
              the vehicle may have, make sure you check it for any damage or
              faults before you book a collection. This will give you time to
              arrange getting any necessary repairs fixed.
            </p>
            <p>
              If your vehicle needs repairs, you can find your nearest garage
              and book directly with them. Alternatively, you can visit our
              driver portal to find your nearest garage and make a booking.
            </p>
            <p>
              We always allow for fair wear and tear, but we will charge you for
              any damage outside of normal wear and tear.
            </p>
            <p>
              We class damage as something caused by a specific event, or series
              of events. It can be caused by impact in an accident, spillage in
              the interior or by something you've kept in the vehicle. Failure
              to service the vehicle to the manufacturer's instructions or harsh
              treatment can also cause damage.
            </p>
            <p>
              We are members of the British Vehicle Rental & Leasing Association
              (BVRLA) and follow their Code of Conduct and industry Fair Wear
              and Tear Standards. You can{" "}
              <a
                href="https://issuu.com/bfwsn67/docs/car_fwt_standard_2016?e=2001091/34033703"
                target="_blank"
                rel="noopener noreferrer"
              >
                find out more here
              </a>
              .
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Changing an existing booking</Accordion.Header>
          <Accordion.Body>
            <p>
              If you've already asked us to collect a vehicle but would like to
              change the date, you can make changes up to 2 working days before
              the collection date. To view or change existing bookings, please
              click on My bookings.
            </p>
            <p>
              To make changes within 2 working days of your collection date,
              please call us on{" "}
              <Link to="tel:0344 879 6633">0344 879 6633</Link>. Our lines are
              open 8.30am - 5.30pm Monday - Friday. We're closed on weekends and
              bank holidays. We may need to charge you for a cancelled
              collection.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <CenterAlignedButtonContainer>
        {isError && (
          <Alert variant="danger">
            {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
          </Alert>
        )}

        <Button id="btnArrangeCollection" onClick={onArrangeCollection}>
          Arrange a collection
        </Button>
        {contracts && contracts.length > 0 && (
          <Button id="btnMyBookings" onClick={onMyBookings}>
            My bookings
          </Button>
        )}
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default VehicleCollectionIntroductionPage;
