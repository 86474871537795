import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import { step } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";
import CollectionStepper from "../../collection-stepper/CollectionStepper";
import { reset } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";

const VehicleNotRoadWorthyPage = () => {
  const currentStep = useSelector((state) => state.vehicleCollection.step);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentStep || currentStep < steps.roadWorthyQuestion2) {
      navigate("../question1", { replace: true });
    }
  }, [navigate, currentStep]);

  const onContinueWithBooking = () => {
    dispatch(step(steps.collectionContactDetails));
    navigate("../../contact-and-collection-details");
  };

  const onHomepageClicked = () => {
    dispatch(reset());
    navigate("/home");
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper overrideActiveStep={2} />}
    >
      <p>
        Your answer indicates your vehicle is not roadworthy and ready for
        collection. You are contractually required to return the vehicle to us
        in a good, roadworthy condition.
      </p>
      <p>
        Before you can arrange a vehicle collection you must ensure the vehicle
        meets the full list of return conditions.
      </p>
      <p>Vehicle return condition:</p>
      <ul>
        <li>
          The vehicle has a valid MOT and will have one at the point of
          collection.
        </li>
        <li>
          The windscreen is free from chips and cracks. If there are any chips
          or cracks present, they must not be within the driver's eyeline,
          driver's wiper blade sweeping range or deemed unsafe to drive with.
          Vehicles returned with chips or cracks outside of Fair wear and tear
          standards may result in de-hire damage charges, please{" "}
          <a
            href="https://issuu.com/bfwsn67/docs/car_fwt_standard_2016?e=2001091/34033703"
            target="_blank"
            rel="noopener noreferrer"
          >
            refer to BVRLA
          </a>{" "}
          for further details.
        </li>
        <li>
          There are no engine management warning lights displayed on your
          dashboard (any warning lights should be resolved prior to arranging
          your collection, your manufacturer handbook contains details of what
          the different lights mean). Vehicles may still be collected with an
          active service reminder light, however, if the vehicle is returned and
          found to have not been serviced in accordance to the manufacturers
          servicing/maintenance schedule this may result in de-hire damage
          charges.
        </li>
        <li>
          All tyres are within the legal tyre depth limit and free from bumps,
          bulges, splits, foreign objects or cracks (the legal limit for minimum
          depth of the tread on your tyres is 1.6 millimetres, across the
          central ¾ of the tread around the complete circumference of the tyre.)
        </li>
        <li>
          The vehicle has a working battery, and if it is an electric or a
          hybrid the battery will be fully charged ready for collection.
        </li>
      </ul>
      <p className="mb-5">
        <strong>
          If a collection is arranged and the vehicle is unsafe to drive the
          collection will be cancelled and you will be charged a fee.
        </strong>
      </p>
      <div className="d-flex flex-column gap-3 col-12 col-sm-8 col-lg-6 col-xl-5 mx-auto">
        <Button id="btnContinueWithBooking" onClick={onContinueWithBooking}>
          Continue with booking
        </Button>
        <Button
          onClick={onHomepageClicked}
          id="btnHome"
          variant="outline-primary"
        >
          Home
        </Button>
      </div>
    </WizardBasePage>
  );
};

export default VehicleNotRoadWorthyPage;
