import PropTypes from "prop-types";
import TickOutlineIcon from "../icons/TickOutlineIcon";
import WarningOutlineIcon from "../icons/WarningOutlineIcon";

const TitleWithIcon = ({ title, variant, iconTitle }) => {
  return (
    <>
      <div className="text-center">
        {variant === "success" && (
          <>
            <TickOutlineIcon
              title={`${iconTitle ? iconTitle : "Success"}`}
              className="text-success"
              size="lg"
            />
          </>
        )}
        {variant === "failure" && (
          <WarningOutlineIcon
            title={`${iconTitle ? iconTitle : "Failure"}`}
            className="text-danger"
            size="lg"
          />
        )}
        {variant === "info" && (
          <WarningOutlineIcon
            title={`${iconTitle ? iconTitle : "Information"}`}
            size="lg"
          />
        )}
        <h2 className="text-secondary h1 mt-2">{title}</h2>
        <hr />
      </div>
    </>
  );
};

TitleWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["success", "failure", "info"]),
  iconTitle: PropTypes.string,
};

export default TitleWithIcon;
