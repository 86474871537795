import { Link } from "react-router-dom";
import BasePage from "../../../pages/basepages/basepage/BasePage";
import CenterAlignedContainer from "../../center-aligned-container/CenterAlignedContainer";
import CenterAlignedButtonContainer from "../../center-aligned-button-container/CenterAlignedButtonContainer";
import TitleWithIcon from "../../title-with-icon/TitleWithIcon";
import { useEffect } from "react";
import { onTrackError } from "../../../utils/analytics/analytics-utils";

const ErrorFallback = ({ error }) => {
  useEffect(() => {
    onTrackError("Unhandled error in app", { message: error?.message });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BasePage pageTitle="We can't process your request at the moment">
      <div className="my-3">
        <TitleWithIcon
          title="We're fixing a technical fault and should have everything running
            smoothly again soon."
          variant="failure"
        />
        <CenterAlignedContainer>
          <p className="text-center my-3">
            Please try again in a few minutes. If you find the same issue,
            please call us and our customer support team will help you.
          </p>
          <p className="text-center">
            You can call us on <Link to="tel:0344 879 6633">0344 879 6633</Link>
            . Lines are open Monday - Friday between 9am - 5.30pm. We are closed
            weekends and bank holidays.
          </p>
          <p className="text-center" style={{ display: "none" }}>
            {error?.message}
          </p>
          <CenterAlignedButtonContainer>
            <a href="/" className="btn btn-primary text-decoration-none">
              Home
            </a>
          </CenterAlignedButtonContainer>
        </CenterAlignedContainer>
      </div>
    </BasePage>
  );
};

export default ErrorFallback;
