import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const LoginForm = ({ onFormSubmit }) => {
  const registerFormSchema = yup.object().shape({
    userId: yup.string().required("User ID or alias is required.").max(50),
    password: yup.string().required("Password is required.").max(50),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerFormSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate name="loginForm">
      <Form.Group className="mb-3" controlId="userId">
        <Form.Label>User ID or alias</Form.Label>
        <Form.Control
          type="string"
          autoComplete="off"
          maxLength={50}
          isInvalid={errors.userId}
          {...register("userId")}
        />
        <Form.Control.Feedback type="invalid">
          {errors.userId?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-4" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          maxLength={50}
          isInvalid={errors.password}
          {...register("password")}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <div className="d-grid gap-2">
        <Button className="px-5" type="submit" id="loginFormSubmitBtn">
          Login
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;
