import * as yup from "yup";

const requiredMessage = "Please enter a valid number in all fields.";

const minMaxMessage =
  "Please enter a valid number between 0 and 9 for all fields.";

export const verifyDigitsSchema = {
  verifyDigit1: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .required(requiredMessage)
    .integer()
    .min(0, minMaxMessage)
    .max(9, minMaxMessage),
  verifyDigit2: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .required(requiredMessage)
    .integer()
    .min(0, minMaxMessage)
    .max(9, minMaxMessage),
  verifyDigit3: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .required(requiredMessage)
    .integer()
    .min(0, minMaxMessage)
    .max(9, minMaxMessage),
  verifyDigit4: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .required(requiredMessage)
    .integer()
    .min(0, minMaxMessage)
    .max(9, minMaxMessage),
};

export const questionnaireRadioSchema = {
  questionnaireRadio: yup.string().required("Please choose an option."),
};
