import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import {
  pageTitle,
  pageSubTitle,
} from "../../utils/contract-amends-page-utils";
import ContractAmendsStepper from "../../contract-amends-stepper/ContractAmendsStepper";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import CenterAlignedContainer from "../../../../component/center-aligned-container/CenterAlignedContainer";
import CaptureAddressForm from "../../../../component/forms/capture-address/CaptureAddressForm";
import {
  partners as dispatchPartners,
  step,
} from "../../../../redux/features/contract-amends/contractAmendsSlice";
import useContractAmendsStepCheck from "../../hooks/useContractAmendsStepCheck";
import steps from "../../contract-amends-steps";
import PageHeading from "../../../../component/page-heading/PageHeading";

const CaptureAddressPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSoleTrader = useSelector(
    (state) => state.contractAmends.isSoleTrader
  );
  const currentPartnerIndex = useSelector(
    (state) => state.contractAmends.currentPartnerIndex
  );
  const partnerType = useSelector((state) => state.contractAmends.partnerType);

  const partners = useSelector((state) => state.contractAmends.partners);
  const totalPartners = Array.isArray(partners) ? partners.length : 0;

  useContractAmendsStepCheck(
    isSoleTrader
      ? steps.bch.soleTrader.personalDetails
      : totalPartners > 0
      ? steps.bch.limitedCompany.partners[currentPartnerIndex].captureAddress
      : steps.exit,
    "../personal-details"
  );

  const onFormSubmit = (data) => {
    const partnersToSave = [...partners];
    const partnerToSave = { ...partnersToSave[currentPartnerIndex] };
    partnerToSave.addressDetails = { ...partnerToSave.addressDetails, ...data };
    partnerToSave.isNewPartnerSaved = true;
    partnersToSave[currentPartnerIndex] = partnerToSave;
    dispatch(dispatchPartners(partnersToSave));
    if (isSoleTrader || currentPartnerIndex >= 2) {
      if (isSoleTrader) {
        dispatch(step(steps.bch.soleTrader.reviewApplication));
      } else {
        dispatch(step(steps.bch.limitedCompany.reviewApplication));
      }
      navigate("../review-application");
    } else {
      navigate("../add-new-partner");
    }
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Address details</PageHeading>
          <p>
            {isSoleTrader && `What is your current address?`}
            {!isSoleTrader && `What is the ${partnerType}'s current address?`}
          </p>
        </div>
      </CenterAlignedContainer>
      <CenterAlignedButtonContainer>
        <div>
          {!isSoleTrader && (
            <>
              <div>
                {Array.isArray(partners) &&
                totalPartners > 0 &&
                partners[currentPartnerIndex]?.isNewPartner ? (
                  <strong>New {partnerType}</strong>
                ) : (
                  <strong>
                    {partnerType}{" "}
                    {`${currentPartnerIndex + 1} of ${totalPartners}`}
                  </strong>
                )}
              </div>
              <hr className="mt-1 mb-3" />
            </>
          )}
          <CaptureAddressForm
            buttonText="Continue"
            onFormSubmit={onFormSubmit}
          />
        </div>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default CaptureAddressPage;
