import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const HamburgerIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    role="img"
    viewBox="0 0 20 20"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 14h20v2H1v-2ZM1 2h20v2H1V2Zm0 6h20v2H1V8Z"
    />
  </svg>
);

HamburgerIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { HamburgerIcon };
export default HamburgerIcon;
