import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/use-auth/useAuth";

/**
 * Ensures the current step in registration journey.
 * Redirects the user to
 * @param {string} status - Step to check that page needs to be.
 */
const useRegistrationStepCheck = (status = "") => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const currentStatus = auth?.user?.status?.toUpperCase();

  useEffect(() => {
    if (currentStatus !== status) {
      navigate("/", { replace: true });
    }
  }, [navigate, currentStatus, status]);

  return currentStatus;
};

export default useRegistrationStepCheck;
