import { Row, Col, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Tile from "../../component/tile/Tile";
import TilesBasePage from "../basepages/tiles-basepage/TilesBasePage";
import MakeAPaymentIcon from "../../component/icons/MakeAPaymentIcon";
import ManageDirectDebitIcon from "../../component/icons/ManageDirectDebitIcon";
import PaymentHolidayIcon from "../../component/icons/PaymentHolidayIcon";
import useAuth from "../../hooks/use-auth/useAuth";
import { useGetAccountBalance } from "../../api/hooks/account";

const PaymentsPage = () => {
  const { auth } = useAuth();
  const userRegulated = auth?.user?.regulated;
  const navigate = useNavigate();
  const { data, isError } = useGetAccountBalance();

  const directDebitNavigation = () => {
    const existingDDPaymentMethod = ["DirectDebit", "SpecialDirectDebit"];
    const paymentMethod = data?.paymentMethod;
    const navigationUrl = existingDDPaymentMethod.includes(paymentMethod)
      ? "/payments/existing-direct-debit"
      : "/payments/new-direct-debit";
    navigate(navigationUrl);
  };

  return (
    <TilesBasePage
      pageTitle="My payments"
      pageSubTitle="Explore the payment options available."
    >
      <Row className="my-3">
        {isError && (
          <Alert variant="danger">
            We are sorry but we are unable to retrieve details. Please try again
            and if the problem continues contact us.
          </Alert>
        )}
        <Tile
          cardId="makeAPayment"
          cardLinkUrl="/payments/make-a-payment"
          cardLinkContent="Make a payment"
        >
          <MakeAPaymentIcon
            width={100}
            height={100}
            className="text-primary"
            title="Make a payment"
          />
        </Tile>
        <Tile
          cardId="manageDirectDebit"
          cardLinkContent="Manage Direct Debit"
          callBack={directDebitNavigation}
          isLink={false}
        >
          <ManageDirectDebitIcon
            width={100}
            height={100}
            className="text-primary"
            title="Manage Direct Debit"
          />
        </Tile>
        <Tile
          cardId="paymentHoliday"
          cardLinkUrl="/payments/payment-holidays"
          cardLinkContent="Payment holidays"
        >
          <PaymentHolidayIcon
            width={100}
            height={100}
            className="text-primary"
            title="Payment holiday"
          />
        </Tile>
      </Row>
      <Row className="mb-5 row">
        <Col lg={{ offset: 2, span: 8 }}>
          <div className="bg-white text-center py-4 px-4">
            <h2 className="h3">Money worries</h2>
            <p className="py-3">
              We're here to help if you're finding things hard.
            </p>
            <div className="d-flex justify-content-center gap-2 mb-2">
              <Link
                id="btnMoneyWorriesSupport"
                to={
                  userRegulated === true
                    ? "/help/money-worries"
                    : "/help/money-worries-non-regulated"
                }
                className="btn btn-primary text-decoration-none flex-fill mb-2"
                style={{ maxWidth: "20rem" }}
              >
                Money worries support
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </TilesBasePage>
  );
};

export default PaymentsPage;
