import { createSlice } from "@reduxjs/toolkit";

import revertAll from "../../actions/revert-all/revertAll";

const initialState = {
  step: 0,
  collectionDate: "",
  contactDetails: {
    contactName: "",
    mobileNumber: "",
    homeNumber: "",
    workNumber: "",
    emailAddress: "",
  },
  contractDetails: {
    vehicle: {
      registrationNumber: "",
    },
  },
  collectionAddress: {
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    county: "",
    postCode: "",
    isAddressManuallyEntered: false,
  },
  isExisting: false,
  updatedCollectionDetails: null,
};

export const vehicleCollectionSlice = createSlice({
  name: "vehicleCollection",
  initialState,
  reducers: {
    step: (state, action) => {
      state.step = action.payload;
    },
    collectionDate: (state, action) => {
      state.collectionDate = action.payload;
    },
    contactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    contractDetails: (state, action) => {
      state.contractDetails = action.payload;
    },
    collectionAddress: (state, action) => {
      state.collectionAddress = action.payload;
    },
    isExisting: (state, action) => {
      state.isExisting = action.payload;
    },
    updatedCollectionDetails: (state, action) => {
      state.updatedCollectionDetails = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  step,
  reset,
  collectionDate,
  contactDetails,
  contractDetails,
  collectionAddress,
  isExisting,
  updatedCollectionDetails,
} = vehicleCollectionSlice.actions;

export default vehicleCollectionSlice.reducer;
