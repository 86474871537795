import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";

import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import {
  pageTitle,
  pageSubTitle,
} from "../../utils/contract-amends-page-utils";
import ContractAmendsStepper from "../../contract-amends-stepper/ContractAmendsStepper";
import CenterAlignedContainer from "../../../../component/center-aligned-container/CenterAlignedContainer";
import {
  currentPartnerIndex as dispatchCurrentPartnerIndex,
  partners as dispatchPartners,
  step,
} from "../../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../../contract-amends-steps";
import useContractAmendsStepCheck from "../../hooks/useContractAmendsStepCheck";
import useAuth from "../../../../hooks/use-auth/useAuth";
import PageHeading from "../../../../component/page-heading/PageHeading";

const AddNewPartnerPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const [isInitialCheckDone, setIsInitialCheckDone] = useState(false);
  const partners = useSelector((state) => state.contractAmends.partners);
  const partnerType = useSelector((state) => state.contractAmends.partnerType);
  const currentPartnerIndex = useSelector(
    (state) => state.contractAmends.currentPartnerIndex
  );

  const [minPartnersRequired] = useState(() => {
    let minPartners = 2;
    if (auth?.user?.counterParty?.code) {
      if (
        auth.user.counterParty.code === 6 ||
        auth.user.counterParty.code === 16
      ) {
        minPartners = 1;
      }
    }
    return minPartners;
  });

  // Adding this to ensure the two navigation checks don't conflict with each other.
  const skipStepCheck =
    location?.state?.checkPartners === true &&
    Array.isArray(partners) &&
    partners.length > 0;

  useEffect(() => {
    if (
      location?.state?.checkPartners === true &&
      Array.isArray(partners) &&
      partners.length > 0 &&
      !isInitialCheckDone
    ) {
      setIsInitialCheckDone(true);
      dispatch(dispatchCurrentPartnerIndex(0));
      dispatch(
        step(steps.bch.limitedCompany.partners[0].validateExistingPartner)
      );
      navigate("../validate-existing-partner", { replace: true });
    }
  }, [navigate, location, dispatch, partners, isInitialCheckDone]);

  useContractAmendsStepCheck(
    steps.bch.limitedCompany.partners[currentPartnerIndex].addNewPartner,
    "../../save-quote",
    skipStepCheck
  );

  const onButtonNoClicked = () => {
    if (partners && partners.length >= minPartnersRequired) {
      dispatch(step(steps.bch.limitedCompany.reviewApplication));
      navigate("../review-application");
    } else {
      dispatch(step(steps.bch.limitedCompany.partnerError));
      navigate("../partner-error");
    }
  };

  const onButtonYesClicked = () => {
    let partnersToSave;
    if (partners.length === 0 || currentPartnerIndex + 1 === partners.length) {
      partnersToSave = [...partners];
      partnersToSave.push({
        personalDetails: {},
        addressDetails: {},
        isNewPartner: true,
        isNewPartnerSaved: false,
      });
    } else {
      partnersToSave = partners;
    }

    const pIndex =
      currentPartnerIndex + 1 >= partnersToSave.length
        ? partnersToSave.length - 1
        : currentPartnerIndex + 1;
    dispatch(dispatchCurrentPartnerIndex(pIndex));
    dispatch(step(steps.bch.limitedCompany.partners[pIndex].personalDetails));
    dispatch(dispatchPartners(partnersToSave));
    navigate("../personal-details", { replace: true });
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Additional details</PageHeading>
          <p>
            Please check we have all the required details for your business.
          </p>
        </div>
      </CenterAlignedContainer>
      <CenterAlignedButtonContainer>
        <div>
          <div className="text-primary">
            Are there any more <strong>{partnerType}s</strong> that you need to
            add?
          </div>
          <hr className="mt-1" />
          <div>
            <strong>
              If your company has 2 or more then you need to supply a minimum of
              2 of their details.
            </strong>
          </div>
          <hr className="mt-1" />
        </div>
        <Button id="btnAddNewPartnerNo" onClick={onButtonNoClicked}>
          No, continue
        </Button>
        <Button
          id="btnAddNewPartnerYes"
          onClick={onButtonYesClicked}
          variant="outline-primary"
        >
          Yes, I need to add another
        </Button>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default AddNewPartnerPage;
