const getGatewayPayload = (formData, selectedInvoices, auth) => {
  const firstName =
    `${formData.firstName} ${formData.lastName}`.length > 26
      ? formData.firstName.charAt(0)
      : formData.firstName;
  const nameOnCard = `${firstName} ${formData.lastName}`;
  let totalAmount = 0.0;
  selectedInvoices.forEach((invoice) => {
    totalAmount = totalAmount + parseFloat(invoice.totalAmount);
  });

  return {
    invoices: selectedInvoices.map((invoice) => {
      return {
        invoiceNumber: invoice.referenceNumber,
        amount: invoice.totalAmount,
      };
    }),
    amount: totalAmount.toFixed(2),
    fleetNo: auth?.user?.fleet,
    referenceNo: auth?.user?.fleet,
    nameOnCard: nameOnCard,
    userLogon: auth?.user?.username,
    customer: {
      billingFirstName: formData.firstName,
      billingLastName: formData.lastName,
      billingPostcode: formData.postCode,
      email: auth?.user?.emailAddress,
      billingAddress1: formData.addressLine1,
      billingCity: formData.city ?? "",
      mobileNum: formData.mobileNumber ?? "",
    },
    brandId: "LASI",
  };
};

const usePayeeDetails = () => {
  return { getGatewayPayload };
};

export default usePayeeDetails;
