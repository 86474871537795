import * as yup from "yup";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import {
  FirstNameField,
  LastNameField,
  DobField,
  MaritalStatusField,
  NumOfDependantsField,
  AccommodationField,
  PhoneNumbersFormFields,
  EmailAddressField,
  TitleTextField,
} from "../fields";
import * as fieldSchemas from "../schemas/fields";

const PersonalDetailsForm = ({ defaultValues, onFormSubmit }) => {
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      yup.object().shape(
        {
          ...fieldSchemas.titleSchema,
          ...fieldSchemas.firstNameSchema,
          ...fieldSchemas.lastNameSchema,
          ...fieldSchemas.dobSchema,
          ...fieldSchemas.maritalStatusSchema,
          ...fieldSchemas.numOfDependantsSchema,
          ...fieldSchemas.accommodationSchema,
          ...fieldSchemas.phoneNumbersSchema,
          ...fieldSchemas.emailAddressSchema,
        },
        [...fieldSchemas.phoneNumbersSchemaDependencies]
      )
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    data.dobDay = parseInt(data.dobDay);
    data.dobMonth = parseInt(data.dobMonth);
    data.dobYear = parseInt(data.dobYear);
    onFormSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        name="personalDetailsForm"
      >
        <TitleTextField disabled={true} />
        <FirstNameField disabled={true} />
        <LastNameField disabled={true} />
        <DobField disabled={true} />
        <MaritalStatusField />
        <NumOfDependantsField />
        <AccommodationField />
        <PhoneNumbersFormFields />
        <EmailAddressField />
        <div className="d-grid gap-2">
          <Button
            className="mt-3"
            type="submit"
            id="btnPersonalDetailsFormSubmit"
          >
            Continue to address details
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

PersonalDetailsForm.propTypes = {
  defaultValues: PropTypes.shape({
    title: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dobDay: PropTypes.number,
    dobMonth: PropTypes.number,
    dobYear: PropTypes.number,
    maritalStatus: PropTypes.number,
    numOfDependants: PropTypes.number,
    accommodation: PropTypes.number,
    mobileNumber: PropTypes.string,
    homeNumber: PropTypes.string,
    workNumber: PropTypes.string,
    emailAddress: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func,
};
export default PersonalDetailsForm;
