import BasePage from "../basepage/BasePage";

const FooterBasePage = ({ pageTitle, pageSubTitle, children }) => {
  return (
    <BasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      showBackToLoginLink={true}
    >
      {children}
    </BasePage>
  );
};
export default FooterBasePage;
