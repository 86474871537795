import FooterBasePage from "../basepages/footer-basepage/FooterBasePage";

const TermsConditionsPage = () => {
  return (
    <FooterBasePage pageTitle="Website terms and conditions">
      <p>This website is an official website of Lex Autolease Ltd.</p>
      <p>
        You should read these terms and conditions (“terms”) carefully before
        using this website. Using the website indicates that you accept these
        terms regardless of whether or not you choose to register with us. If
        you do not accept these terms, do not use the website. You should also
        read the terms and conditions about each service on this website.
      </p>
      <p>Please note these Terms changed on 1 June 2010.</p>
      <h2 className="h3">1. Introduction</h2>
      <p>
        You may access most areas of the Website without registering your
        details with us. Certain areas of the Website are only open to you if
        you register. By accessing any part of the Website, you shall be deemed
        to have accepted these Terms in full. If you do not accept these Terms,
        you must leave the Website immediately. We may revise these Terms at any
        time and you should periodically check the Website to review the then
        current Terms because they are binding on you.
      </p>
      <p>
        Certain provisions of these Terms may be superseded by expressly
        designated legal notices or terms located on particular pages of the
        Website. Where you have registered for additional services (for example
        Internet Banking or Mobile Services) you may also be subject to
        additional Terms and Conditions. If you want more information about
        anything on this Website, please contact any of our branches.
      </p>
      <h2 className="h3">2. Content</h2>
      <p>
        Unless otherwise stated, the copyright, trademarks and other
        intellectual property rights in all material on the Website (including
        without limitation text, tools, photographs and graphical images) are
        owned by us or our licensors.
      </p>
      <p>
        Certain trademarks and other materials used on this site are the
        copyright of Lex Autolease Limited and Lloyds Bank plc. Lloyds Bank is a
        registered trademark of Lloyds Banking Group plc. All rights reserved.
      </p>
      <p>
        You may not reproduce or adapt the Website in whole or in part without
        our prior written consent, except for the purpose of accessing our
        products and/or services and for producing print outs for your own
        personal and non-commercial purposes. No part of the Website may be
        reproduced or stored in any other website or included in any public or
        private electronic retrieval system or service without our prior written
        permission.
      </p>
      <p>
        Any use of the Website other than in accordance with these Terms is
        prohibited. If you breach any of these Terms, your permission to use the
        Website automatically terminates and you must immediately destroy any
        downloaded or printed extracts from the Website.
      </p>
      <p>Any rights not expressly granted in these Terms are reserved.</p>
      <h2 className="h3">3. Service access</h2>
      <p>
        While the Website is normally available 24 hours a day, we shall not be
        liable if for any reason the Website is unavailable at any time. Access
        to the Website may be suspended temporarily and without notice in the
        case of system failure, maintenance or repair or for reasons beyond our
        control.
      </p>
      <h2 className="h3">4. Visitor material and conduct</h2>
      <p>
        Other than personally identifiable information, which is covered under
        our Privacy Policy, any material you transmit or post to the Website
        shall be considered non-confidential and non-proprietary. We shall have
        no obligations with respect to such material. We shall be free to copy,
        disclose, distribute, incorporate and otherwise use such material and
        all data, images, sounds, text and other things embodied in them for any
        and all commercial or non-commercial purposes.
      </p>
      <p>
        You must not post or transmit to or from the Website any material: (a)
        that is threatening, defamatory, obscene, indecent, seditious,
        offensive, pornographic, abusive, liable to incite racial hatred,
        discriminatory, menacing, scandalous, inflammatory, blasphemous, in
        breach of confidence, in breach of privacy or which may cause annoyance
        or inconvenience; or (b) for which you have not obtained all necessary
        licenses and/or approvals; or (c) which constitutes or encourages
        conduct that would be considered a criminal offence, give rise to civil
        liability, or otherwise be contrary to the law of or infringe the rights
        of any third party, in any country in the world; or (d) which is
        technically harmful (including, without limitation, computer viruses,
        logic bombs, Trojan horses, worms, harmful components, corrupted data or
        other malicious software or harmful data). You may not misuse the
        Website (including, without limitation, by hacking).
      </p>
      <p>
        We will co-operate fully with any law enforcement authorities or court
        order requesting or directing us to disclose the identity or locate
        anyone posting any material in breach of these Terms.
      </p>
      <h2 className="h3">5. Links to and from other websites</h2>
      <p>
        Links to third party websites on the Website are provided solely for
        your convenience. If you use these links, you leave the Website. We have
        not reviewed all of these third party websites and do not control and
        are not responsible for their content or availability. We do not endorse
        or make any representations about them, or any material found there. If
        you decide to access any of the third party websites linked to the
        Website, you do so entirely at your own risk.
      </p>
      <p>
        If you would like to link to the Website, you may only do so on the
        basis that you link to, but do not replicate, the home page of the
        Website, and subject to the following conditions: (a) you do not remove,
        distort or otherwise alter the size or appearance of the any images or
        logos; (b) you do not create a frame or any other browser or border
        environment around the Website; (c) you do not in any way imply that the
        we are endorsing any products or services other than our own; (d) you do
        not misrepresent your relationship with us nor present any other false
        information about us; (e) you do not otherwise use any trademarks
        displayed on the Website without our express written permission; (f) you
        do not link from a website that is not owned by you; and (g) your
        website does not contain content that is distasteful, offensive or
        controversial, infringes any intellectual property rights or other
        rights of any other person or otherwise does not comply with all
        applicable laws and regulations. We expressly reserve the right to
        revoke the right granted in this clause for breach of these Terms and to
        take any action deem appropriate. You shall be responsible for any loss
        or damage suffered by us for breach of this clause.
      </p>
      <h2 className="h3">6. Registration</h2>
      <p>
        Where you are invited to register for any part of this Website, each
        registration is for a single user only. You must not share your user
        name and password with any other person or with multiple users on a
        network. Responsibility for the security of any passwords issued rests
        with you.
      </p>
      <h2 className="h3">7. Disclaimer</h2>
      <p>
        This Website is here to give you information about our products and
        services. It is up to you to decide whether they are suitable for you.
        You may want to get advice about this from an independent financial
        advisor.
      </p>
      <p>
        The information on the Website may not tell you all you need to know,
        and you can ask us or an independent financial advisor for more details.
      </p>
      <p>
        Though we try and keep the information up to date, there may be a time
        delay before we can do so. You should not rely solely on the information
        on this Website. You should check details about important things like
        price or interest rates in any actual agreement you sign as it is the
        terms in that document that form the agreement between us.
      </p>
      <h2 className="h3">8. Our and your liability for use of this website</h2>
      <p>
        The Website only complies with UK laws. You should only use it from the
        UK. The products and services on it are only available to you if you are
        a UK resident.
      </p>
      <p>
        You may not always be able to access this Website. We are not
        responsible for any losses if you cannot do so (for example if you
        cannot obtain a product at a stated price). We are not responsible if
        the means of communication you use to access the Website or to send us
        information about yourself or to receive information from us does not
        work properly.
      </p>
      <p>
        As we've explained above, the information on this Website may not always
        be quite up to date or complete. We advise you to check it carefully
        before you agree to buy a product or service, and we are not responsible
        for any losses you suffer if you fail to do so unless what we put on the
        Website was incorrect or misleading (and provided the correct
        information was not provided to you or made available before you
        completed your purchase).
      </p>
      <p>
        Whilst we take reasonable care to ensure the accuracy of information on
        third party websites (outside the Lloyds Banking Group) which we may
        have linked through this Website, as we cannot control what they
        contain, we are not responsible for any losses or other damage you
        suffer as a result of using those other websites.
      </p>
      <p>
        We are not liable for losses or costs caused by abnormal and
        unforeseeable circumstances outside our reasonable control, which would
        have been unavoidable despite all efforts to the contrary, for example
        delays or failures caused by industrial action, problems with another
        system or network, third party viruses or malware, mechanical breakdown
        or data-processing failures.
      </p>
      <p>
        Please remember that email is not a completely secure means of
        communication: it may be possible for others to intercept and read your
        messages. So if you choose to send us information by email, it is at
        your own risk.
      </p>
      <h2 className="h3">9. Governing law and jurisdiction</h2>
      <p>
        These Terms shall be governed by and construed in accordance with
        English law. Disputes arising in connection with these Terms shall be
        subject to the exclusive jurisdiction of the English courts.
      </p>
    </FooterBasePage>
  );
};

export default TermsConditionsPage;
