import * as yup from "yup";

export const signatureSchema = {
  signature: yup
    .string()
    .required(" Please enter a valid name.")
    .matches(/^[a-zA-Z\-.,'\s]+$/, "A valid name is required."),
};

export const SignAgreementSchema = {
  isSignAgreementChecked: yup
    .bool()
    .oneOf([true], "Terms and Conditions must be accepted."),
};

export const personalDetailsTermsSchema = {
  personalDetailsTermsAccepted: yup
    .bool()
    .oneOf([true], "Terms must be accepted."),
};

export const creditCheckTermsSchema = {
  creditCheckTermsAccepted: yup.bool().oneOf([true], "Terms must be accepted."),
};
