import { useState, useEffect, useContext } from "react";
import ApplicationRoutes from "../../routing/ApplicationRoutes";
import { removeItem, getItem } from "../../storage/SessionStorage";
import { useRefreshToken } from "../../api/hooks/auth";
import AuthContext from "../../providers/auth-provider/AuthProvider";

const SessionWrapper = () => {
  const [loadRoutes, setLoadRoutes] = useState(false);
  const { setAuth } = useContext(AuthContext);

  const onTokenRefreshSuccess = (data) => {
    const user = getItem("user");
    setAuth({ user, accessToken: data.accessToken });
    setLoadRoutes(true);
  };

  const onTokenRefreshFailure = () => {
    removeItem("user");
    setLoadRoutes(true);
  };

  const { mutate } = useRefreshToken(
    onTokenRefreshSuccess,
    onTokenRefreshFailure
  );

  const restoreSession = async () => {
    const currentUser = getItem("user");
    if (currentUser) {
      const userStatus = currentUser.status;
      if (userStatus !== "OK") {
        removeItem("user");
        setLoadRoutes(true);
      } else {
        mutate();
      }
    } else {
      setLoadRoutes(true);
    }
  };

  useEffect(() => {
    restoreSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadRoutes ? (
    <ApplicationRoutes />
  ) : (
    <div className="spinner-position">
      <div className="spinner-custom"></div>
      <h1 className="h3">Loading...</h1>
    </div>
  );
};

export default SessionWrapper;
