import { DateTime } from "luxon";
import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { useQuery, useMutation } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";
import queryClient from "../react-query/query-client";
import apiKeys from "../error-handling/api-keys";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";

const getContracts = async (axios) => {
  return axios
    .get("/contracts", {
      headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
    })
    .then((res) => {
      return res.data;
    });
};

const getContract = async (axios, contractId) => {
  return axios
    .get(`/contracts/${contractId}`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
    })
    .then((res) => {
      return res.data;
    });
};

const getEarlyTerminationDetails = async (axios, contract) => {
  const { data } = await axios.post(
    `/contracts/${contract.contractId}/termination`,
    contract,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
    }
  );
  return data;
};
const getEarlyTerminationAccept = async (axios, contract) => {
  const { data } = await axios.post(
    `/contracts/${contract.contractId}/termination/accept`,
    contract,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.liveContracts),
    }
  );
  return data;
};

const getContractAmendLimits = async (axios, contractId) => {
  return axios
    .get(`/contracts/${contractId}/amendment/limits`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.contractAmendments),
    })
    .then((res) => {
      return res.data;
    });
};

const calculateQuote = async (axios, contractId, proposedAmendment) => {
  const { data } = await axios.post(
    `/contracts/${contractId}/amendments/calculate`,
    proposedAmendment,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.contractAmendments),
    }
  );
  return data;
};

const saveQuote = async (axios, contractId, proposedAmendment) => {
  const { data } = await axios.post(
    `/contracts/${contractId}/amendments`,
    proposedAmendment,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.contractAmendments),
    }
  );
  return data;
};

const getContractAmendDetails = async (axios, contractId, caNumber) => {
  return axios
    .get(`/contracts/${contractId}/amendments/${caNumber}`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.contractAmendments),
    })
    .then((res) => {
      return res.data;
    });
};

const getExistingContractAmendments = async (axios) => {
  return axios
    .get("/contracts/amendments", {
      headers: attachApiKeyHeaderToRequest(apiKeys.contractAmendments),
    })
    .then((res) => {
      return res.data;
    });
};

const contractAmendsubmitProposal = async (
  axios,
  contractId,
  caNumber,
  payload
) => {
  const { data } = await axios.put(
    `/contracts/${contractId}/amendments/${caNumber}`,
    payload,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.contractAmendments),
    }
  );
  return data;
};

const contractAmendSignAgreement = async (
  axios,
  contractId,
  caNumber,
  payload
) => {
  const { data } = await axios.post(
    `/contracts/${contractId}/amendments/${caNumber}/agreement/sign`,
    payload,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.contractAmendments),
    }
  );
  return data;
};

const getContractAmendAgreement = async (axios, contractId, caNumber) => {
  const { data } = await axios.get(
    `/contracts/${contractId}/amendments/${caNumber}/agreement`,
    {
      headers: attachApiKeyHeaderToRequest(apiKeys.contractAmendments),
    }
  );
  return data;
};

const useContract = (contractId, onSuccess) => {
  const axios = useAxiosPrivate();
  const fallback = {
    __isDefault: true,
  };

  const {
    data = fallback,
    isError,
    error,
    refetch,
  } = useQuery(
    queryKeys.contract(contractId),
    () => getContract(axios, contractId),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      enabled: contractId ? true : false,
      onSuccess: onSuccess,
    }
  );
  let monthlyRental = data?.monthlyRental ? data.monthlyRental : 0;
  let serviceRental = data?.serviceRental ? data.serviceRental : 0;
  let contractMonthlyAndServiceRental = monthlyRental + serviceRental;
  let totalMonthlyAndServiceRental =
    contractMonthlyAndServiceRental > 0
      ? contractMonthlyAndServiceRental
      : null;

  let totalMonthlyRentalCharge = {
    totalMonthlyRentalCharge: totalMonthlyAndServiceRental,
  };

  return {
    contract: { ...data, ...totalMonthlyRentalCharge },
    isError,
    errorResponse: error?.response?.data,
    refetch,
  };
};

const useContracts = () => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(queryKeys.contracts, () => getContracts(axios), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
  });
  return { contracts: data, isError, errorResponse: error?.response?.data };
};

const useContractsForVehicleCollection = () => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(queryKeys.contracts, () => getContracts(axios), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
  });
  return {
    contracts: data
      ?.filter(
        (c) =>
          c.currentCollectionStatus && c.currentCollectionStatus.match(/none/i)
      )
      ?.filter(
        (c) =>
          c.eocStatus &&
          (c.eocStatus.match(/due/i) ||
            c.eocStatus.match(/earlytermination/i) ||
            c.eocStatus.match(/informalextension/i))
      ),
    isError,
    errorResponse: error?.response?.data,
  };
};

const useContractsForExistingVehicleCollections = () => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(queryKeys.contracts, () => getContracts(axios), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    select: (contracts) => {
      const shouldReturnContract = (contract) => {
        let collectionToReturn = null;
        if (
          contract &&
          Array.isArray(contract.collections) &&
          contract.collections.length > 0
        ) {
          if (
            contract.collections.some(
              (c) =>
                typeof c.collectionStatus === "string" &&
                (c.collectionStatus.toLowerCase() === "completed" ||
                  c.collectionStatus.toLowerCase() === "scheduled")
            )
          ) {
            // Find collection with highest triggerId value
            const latestCollection = contract.collections.reduce((a, b) =>
              a.triggerId > b.triggerId ? a : b
            );
            if (
              latestCollection &&
              typeof latestCollection.collectionStatus === "string" &&
              latestCollection.collectionStatus.toLowerCase() === "scheduled"
            ) {
              collectionToReturn = latestCollection;
            } else {
              if (latestCollection && latestCollection.collectionDate) {
                const cDate = DateTime.fromISO(latestCollection.collectionDate);
                if (cDate.isValid) {
                  if (DateTime.now().plus({ days: -30 }) < cDate) {
                    collectionToReturn = latestCollection;
                  }
                }
              }
            }
          }
        }
        return collectionToReturn;
      };

      return contracts
        ?.filter(
          (c) =>
            c.currentCollectionStatus &&
            c.currentCollectionStatus.toLowerCase() === "inprogress" &&
            shouldReturnContract(c) &&
            c.eocStatus &&
            (c.eocStatus.match(/due/i) ||
              c.eocStatus.match(/earlytermination/i) ||
              c.eocStatus.match(/informalextension/i))
        )
        .map((c) => {
          const latestCollection = shouldReturnContract(c);
          return { ...c, latestCollection };
        });
    },
  });
  return {
    contracts: data,
    isError,
    errorResponse: error?.response?.data,
  };
};

const useContractsForContractAmends = () => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(queryKeys.contracts, () => getContracts(axios), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    select: (contracts) => {
      return contracts?.filter((c) => c.canAmendContract);
    },
  });
  return {
    contracts: data,
    isError,
    errorResponse: error?.response?.data,
  };
};

const useContractAmendExisting = () => {
  const axios = useAxiosPrivate();
  const fallback = [];
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(
    queryKeys.contractAmendQuotes,
    () => getExistingContractAmendments(axios),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      select: (quotes) => {
        return Array.isArray(quotes)
          ? quotes.filter((quote) => {
              return (
                quote?.currentStatus !== "Rejected By Approver" &&
                quote?.currentStatus !== "Pending For Approval"
              );
            })
          : [];
      },
    }
  );
  return {
    quotes: data,
    isError,
    errorResponse: error?.response?.data,
  };
};

const useContractAmendLimits = (contractId) => {
  const axios = useAxiosPrivate();
  const fallback = {
    __isDefault: true,
  };

  const {
    data = fallback,
    isError,
    error,
  } = useQuery(
    queryKeys.contractAmendLimits(contractId),
    () => getContractAmendLimits(axios, contractId),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      enabled: contractId ? true : false,
    }
  );

  return {
    contractLimits: data,
    isError,
    errorResponse: error?.response?.data,
  };
};

const useContractAmendCalculateQuote = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    (data) => calculateQuote(axios, data.contractId, data.proposedAmendment),
    {
      onSuccess: onSuccess,
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

const useContractAmendSaveQuote = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    (data) => saveQuote(axios, data.contractId, data.proposedAmendment),
    {
      onSuccess: (d) => {
        queryClient.resetQueries(queryKeys.contracts);
        onSuccess(d);
      },
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

const useContractAmendDetails = (contractId, caNumber) => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(
    queryKeys.contractAmendDetails(contractId, caNumber),
    () => getContractAmendDetails(axios, contractId, caNumber),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      enabled: contractId && caNumber ? true : false,
    }
  );
  return {
    contractAmendDetails: data,
    isError,
    errorResponse: error?.response?.data,
  };
};

const useContractAmendSubmitProposal = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    ({ contractId, caNumber, payload }) =>
      contractAmendsubmitProposal(axios, contractId, caNumber, payload),
    {
      onSuccess: (data) => {
        queryClient.resetQueries(queryKeys.contracts);
        onSuccess(data);
      },
      onError: onError,
    }
  );
};

const useContractAmendSignAgreement = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    ({ contractId, caNumber, payload }) =>
      contractAmendSignAgreement(axios, contractId, caNumber, payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
        queryClient.resetQueries(queryKeys.contracts);
        queryClient.resetQueries(queryKeys.documents);
      },
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

const useContractAmendAgreement = (contractId, caNumber) => {
  const axios = useAxiosPrivate();
  const fallback = {};

  const {
    data = fallback,
    isError,
    refetch,
    error,
  } = useQuery(
    queryKeys.contractAmendAgreement(contractId, caNumber),
    () => getContractAmendAgreement(axios, contractId, caNumber),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      enabled: contractId && caNumber ? true : false,
    }
  );

  return {
    contractAmendAgreement: data,
    isError,
    refetch,
    errorResponse: error?.response?.data,
  };
};

const useEarlyTerminationDetails = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    (contract) => getEarlyTerminationDetails(axios, contract),
    {
      onSuccess: (data) => {
        queryClient.resetQueries(queryKeys.contracts);
        onSuccess(data);
      },
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

const useEarlyTerminationAccept = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((contract) => getEarlyTerminationAccept(axios, contract), {
    onSuccess: (data) => {
      queryClient.resetQueries(queryKeys.contracts);
      onSuccess(data);
    },
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

export {
  useContracts,
  useContract,
  useContractsForVehicleCollection,
  useEarlyTerminationDetails,
  useEarlyTerminationAccept,
  useContractsForContractAmends,
  useContractAmendLimits,
  useContractAmendCalculateQuote,
  useContractAmendSaveQuote,
  useContractAmendDetails,
  useContractAmendExisting,
  useContractAmendSubmitProposal,
  useContractAmendAgreement,
  useContractAmendSignAgreement,
  useContractsForExistingVehicleCollections,
};
