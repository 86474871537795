import { Form, Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { ForgotLoginRadioField } from "../fields/forgot-login-details-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotLoginDetailsForm = ({ onFormSubmit }) => {
  const schema = yup.object().shape({
    forgotLoginRadio: yup.string().required("Please select an option"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    onFormSubmit(data);
  };
  const Radiodata = [
    { id: "1", text: "I have forgotten my user ID or alias" },
    { id: "2", text: "I have forgotten my password" },
    { id: "3", text: "I have forgotten my password and user ID or alias" },
  ];
  return (
    <>
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          name="forgotLoginUserDetailsForm"
        >
          <ForgotLoginRadioField data={Radiodata} />
          <div className="d-grid gap-2 my-3">
            <Button type="submit" id="btnForgottenloginFormSubmit">
              Submit
            </Button>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};
export default ForgotLoginDetailsForm;
