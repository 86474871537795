import BasePage from "../basepage/BasePage";
import PropTypes from "prop-types";

const TilesBasePage = ({ pageTitle, pageSubTitle, children }) => {
  return (
    <BasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      showMainContainer={false}
      showTilesContainer={true}
      tilesContent={children}
    >
      <></>
    </BasePage>
  );
};

TilesBasePage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageSubTitle: PropTypes.string,
};

export default TilesBasePage;
