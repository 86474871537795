import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import BasePage from "../../../basepages/basepage/BasePage";
import Tile from "../../../../component/tile/Tile";
import EmptyStateIcon from "../../../../component/icons/EmptyStateIcon";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import { useContracts } from "../../../../api/hooks/contracts";
import { contractDetails } from "../../../../redux/features/early-termination/earlyTerminationSlice";
import { formatDate } from "../../../../utils/pages/date-format-utils";
import magicStrings from "../../../../utils/magic-string";
import PageHeading from "../../../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../../../api/error-handling/transform-error";

const SavedQuotesPage = () => {
  const { contracts, isError, errorResponse } = useContracts();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let dateInPast;
  let quotesMaxScheduleIdLength;
  let quotesWithMaxScheduleId = [];
  let earlyTerminationsNewData;
  let scheduleId = [];
  let maxScheduleId;

  const quoteExpiredThirtyDaysBefore = (quoteExpiry) => {
    let today = DateTime.now().toFormat("dd/MM/yyyy");
    let dateThirtyDaysBeforeToday = DateTime.fromISO(
      today - 30 * 24 * 60 * 60 * 1000
    );
    dateInPast = dateThirtyDaysBeforeToday > quoteExpiry ? true : false;
  };

  contracts?.map((c) => {
    let isEarlyTerminations = "earlyterminations" in c;

    if (isEarlyTerminations === true) {
      let earlyTerminationsData = c?.earlyterminations;
      let v = c.vehicle;

      earlyTerminationsData?.map((et) => {
        scheduleId.push(et.scheduleId);
        return false;
      });

      maxScheduleId = Math.max.apply(null, scheduleId);

      let etToDisplay = earlyTerminationsData?.find((et) => {
        // eslint-disable-next-line
        return et.scheduleId == maxScheduleId;
      });

      let quoteBExpiryDate = formatDate(etToDisplay?.quoteB?.expiryDate);
      quoteExpiredThirtyDaysBefore(quoteBExpiryDate);

      if (
        dateInPast === false &&
        (etToDisplay?.quoteB?.quoteStatus === "Accepted" ||
          etToDisplay?.quoteB?.quoteStatus === "Expired")
      ) {
        earlyTerminationsNewData = [
          {
            quoteNumber: etToDisplay?.quoteB?.quoteNumber,
            registrationNumber: v.registrationNumber,
            make: v.make,
            model: v.model,
            quoteStatus: etToDisplay?.quoteB?.quoteStatus,
            expiryDate: quoteBExpiryDate,
            contractId: c.contractId,
          },
        ];
        quotesWithMaxScheduleId.push(...earlyTerminationsNewData);
        quotesMaxScheduleIdLength = Object.keys(
          earlyTerminationsNewData
        ).length;
        return false;
      }
    }
    return false;
  });

  const onClickNavigation = (contractId) => {
    const contract = contracts.find(
      // eslint-disable-next-line
      (c) => c.contractId == contractId
    );
    dispatch(contractDetails(contract));
    navigate("/early-termination/existing-quote/quote-details", {
      state: { contractId },
    });
  };

  return (
    <BasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
    >
      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}

      <PageHeading>Your quotes</PageHeading>
      <p className="text-center">
        Here is a summary of your recent early termination quotes.
      </p>

      {contracts?.length > 0 && quotesMaxScheduleIdLength > 0 ? (
        <Row className="tile-list">
          {quotesWithMaxScheduleId?.map((etExistingQuote, index) => (
            <Tile
              key={index}
              cardId={`existing-quote-${index}`}
              cardTitle=""
              cardLinkUrl=""
              cardLinkContent="View quote"
              isLink={false}
              callBack={() => onClickNavigation(etExistingQuote.contractId)}
              cardBorder={true}
              isCardLinkDisabled={
                quotesWithMaxScheduleId[index].quoteStatus !== "Accepted"
                  ? true
                  : false
              }
            >
              <dl className="row mb-0">
                <dt className="col-6 mb-2 text-end">Quote reference:</dt>
                <dd
                  className={`col-6 text-start ps-0 d-flex align-items-center ${magicStrings.maskInformation}`}
                >
                  {quotesWithMaxScheduleId[index].quoteNumber}
                </dd>

                <dt className="col-6 mb-2 text-end">Vehicle registration:</dt>
                <dd
                  className={`col-6 text-start ps-0 d-flex align-items-center ${magicStrings.maskInformation}`}
                >
                  {quotesWithMaxScheduleId[index].registrationNumber}
                </dd>
                <dt className="col-6 mb-2 text-end">Vehicle make:</dt>
                <dd
                  className={`col-6 text-start ps-0 d-flex align-items-center ${magicStrings.maskInformation}`}
                >
                  {quotesWithMaxScheduleId[index].make}{" "}
                  {quotesWithMaxScheduleId[index].model}
                </dd>
                <dt className="col-6 mb-2 text-end">Status:</dt>
                <dd
                  className={`col-6 text-start ps-0 d-flex align-items-center ${magicStrings.maskInformation}`}
                >
                  {quotesWithMaxScheduleId[index].quoteStatus}
                </dd>
                <dt className="col-6 mb-2 text-end">Expiry date:</dt>
                <dd
                  className={`col-6 text-start ps-0 d-flex align-items-center ${magicStrings.maskInformation}`}
                >
                  {quotesWithMaxScheduleId[index].expiryDate}
                </dd>
              </dl>
            </Tile>
          ))}
        </Row>
      ) : (
        <Row>
          <Col className="my-4">
            <EmptyStateIcon
              title="Empty State"
              textContent="You dont have any saved quotes"
            />
          </Col>
        </Row>
      )}

      <CenterAlignedButtonContainer>
        <Button
          as={Link}
          to="/early-termination/new-quote/vehicle-selection"
          id="btnGetNewQuote"
          className="text-decoration-none"
        >
          Get a new quote
        </Button>
        <BackToHomeButton />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default SavedQuotesPage;
