import BasePage from "../../../basepages/basepage/BasePage";
import ConfirmedDirectDebit from "../../../../component/payments/direct-debit/confirmed-direct-debit/ConfirmedDirectDebit";

const NewDirectDebitConfirmedPage = () => {
  return (
    <BasePage pageTitle="Set up a Direct Debit">
      <ConfirmedDirectDebit />
    </BasePage>
  );
};

export default NewDirectDebitConfirmedPage;
