export const iconSizeSquare = (size = "md") => {
  switch (size) {
    case "xs":
      return 16;
    case "sm":
      return 24;
    case "md":
      return 32;
    case "lg":
      return 40;
    case "xl":
      return 64;
    default:
      return 24;
  }
};
