import * as React from "react";
import { iconSizeSquare } from "./icons-utils";
import PropTypes from "prop-types";

const TickIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    role="img"
    viewBox="0 0 22 22"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <defs>
      <circle id="success-icon_svg__a" cx={11} cy={11} r={10} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <use xlinkHref="#success-icon_svg__a" />
        <use xlinkHref="#success-icon_svg__a" />
      </g>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="m6.729 9.978-1.564 1.564 3.91 3.91 7.298-7.299L14.81 6.59l-5.734 5.735z"
      />
    </g>
  </svg>
);

TickIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
export { TickIcon };
export default TickIcon;
