import { Form, Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import {
  VehicleRegOrFleetNumberField,
  FirstNameField,
  LastNameField,
  DobField,
} from "../../fields";
import * as fieldSchemas from "../../schemas/fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotUserIdAndPasswordForm = ({ onFormSubmit }) => {
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape(
        {
          ...fieldSchemas.vehicleRegOrFleetSchema,
          ...fieldSchemas.firstNameSchema,
          ...fieldSchemas.lastNameSchema,
          ...fieldSchemas.dobSchema,
        },
        [...fieldSchemas.vehicleRegOrFleetSchemaDependencies]
      )
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    onFormSubmit(data);
  };
  return (
    <>
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          name="forgotUserIdAndPasswordForm"
        >
          <VehicleRegOrFleetNumberField />
          <FirstNameField />
          <LastNameField />
          <DobField />
          <div className="d-grid gap-2 my-5">
            <Button type="submit" id="btnForgottenUserIdFormSubmit">
              Submit
            </Button>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};
export default ForgotUserIdAndPasswordForm;
