import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useState } from "react";
import { Alert } from "react-bootstrap";

import BasePage from "../../basepages/basepage/BasePage";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import SignAgreementForm from "../../../component/forms/contract-amendment/SignAgreementForm";
import { useContractAmendSignAgreement } from "../../../api/hooks/contracts";
import {
  contractAgreement as dispatchContractAgreement,
  step,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import PDFViewer from "../../../component/pdf/PDFViewer";
import PageHeading from "../../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const SignAgreementPage = () => {
  useContractAmendsStepCheck(steps.signAgreement, "../view-summary");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSignAgreementError, setSignAgreementError] = useState({});
  const contractId = useSelector(
    (state) => state.contractAmends.selectedContractId
  );

  const onSignSuccess = (data) => {
    dispatch(step(steps.confirmNextSteps));
    dispatch(dispatchContractAgreement(data));
    navigate("../confirm-next-steps");
  };

  const onSignFailure = (error) => {
    setSignAgreementError(error);
  };

  const { mutate } = useContractAmendSignAgreement(
    onSignSuccess,
    onSignFailure
  );

  const contractAgreement = useSelector(
    (state) => state.contractAmends.contractAgreement
  );

  const onFormSubmit = (data) => {
    mutate({
      contractId: contractId,
      caNumber: contractAgreement?.caNumber,
      payload: { signatoryName: data.signature },
    });
  };

  return (
    <BasePage pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <div className="text-center">
        <PageHeading>Sign your amended contract</PageHeading>
        <p>Here are the details of your amended contract.</p>
        <p>
          Please read through them carefully and check the changes are correct.
        </p>
        <p>Once you're happy, please click to sign your amended contract.</p>
      </div>
      <h2 className="h3 text-primary">Your new agreement</h2>
      <br />
      {contractAgreement && contractAgreement.agreementDocument && (
        <>
          <div className="w-100 h-100">
            <PDFViewer base64={contractAgreement.agreementDocument} />
          </div>
        </>
      )}
      <br />

      <CenterAlignedButtonContainer>
        {isSignAgreementError?.localErrorMessage && (
          <Alert variant="danger">
            {getSanitisedErrorMessage(isSignAgreementError.localErrorMessage)}
          </Alert>
        )}
        <SignAgreementForm onFormSubmit={onFormSubmit}></SignAgreementForm>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default SignAgreementPage;
