import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import BasePage from "../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import { reset } from "../../../redux/features/contract-amends/contractAmendsSlice";

const ApplicationDeclinedPage = () => {
  const dispatch = useDispatch();

  const onBackToHomeClicked = () => {
    dispatch(reset());
  };

  return (
    <BasePage pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <TitleWithIcon
        title="We've declined your request to amend your contract"
        variant="info"
      />

      <p className="mt-5">
        Thank you for applying to amend your contract. We've reviewed your
        request, and we're sorry to tell you we've declined your application at
        this time.
      </p>

      <p>
        We made our decision based on information provided by you and the credit
        reference agencies Experian and TransUnion.{" "}
      </p>

      <h3 className="title mt-5">
        This means you need to keep to the terms of your existing contract
      </h3>

      <p>
        Your existing contract with us is still in place. You can view your
        contract term, end date and agreed mileage on the vehicle details page.{" "}
      </p>
      <p>
        If you exceed the mileage agreed in your contract, you'll face an excess
        mileage charge. You can visit the vehicle details page to find out the
        extra cost per mile. Start planning now to ensure you don't incur any
        additional cost.{" "}
      </p>

      <h3 className="title mt-5">
        If you think you may exceed your agreed mileage, get in touch
      </h3>

      <p>
        We're here to help. If you have already gone over your agreed mileage,
        or expect to in the future, and you don't think you'll be able to cover
        the cost, please call us on{" "}
        <Link to="tel: 0800 302 9163">0800 302 9163</Link>. We can help you find
        an option that works for you. Our phone lines are open Monday - Friday
        between 8:30am - 5:30pm.
      </p>

      <h3 className="title mt-5">
        To understand why we declined your request, ask to see your credit
        report
      </h3>

      <p>
        If you would like to know more about why we declined your application,
        you can request a copy of your credit report from the following credit
        reference agencies. Please be aware they may charge you for the
        information.{" "}
      </p>

      <p>
        <strong>Experian</strong>
        <br />
        Website:{" "}
        <a
          className="fw-normal"
          href="https://www.experian.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.experian.co.uk
        </a>
        <br />
        Phone: <Link to="tel:0800 013 8888"> 0800 013 8888</Link>
        <br />
        Post: Customer Support Centre, Experian Ltd, PO Box 8000, Nottingham,
        NG80 7WF
      </p>

      <p>
        <strong>TransUnion (formerly Callcredit)</strong>
        <br /> Website:{" "}
        <a
          className="fw-normal"
          href="https://www.transunion.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.transunion.co.uk
        </a>
        <br />
        Phone: <Link to="tel: 0330 024 7574"> 0330 024 7574</Link>
        <br />
        Post: Consumer Services Team, TransUnion Information Group, Po Box 491,
        Leeds, LS3 1WZ
      </p>

      <CenterAlignedButtonContainer>
        <BackToHomeButton
          variant="primary"
          onButtonClicked={onBackToHomeClicked}
        />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ApplicationDeclinedPage;
