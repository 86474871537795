import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Alert } from "react-bootstrap";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../contract-amends-stepper/ContractAmendsStepper";
import {
  step,
  selectedContractId,
  reset,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import { useContractsForContractAmends } from "../../../api/hooks/contracts";
import VehicleSelectionForm from "../../../component/forms/vehicle-selection/VehicleSelectionForm";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import PageHeading from "../../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const VehicleSelectionPage = () => {
  useContractAmendsStepCheck(steps.newQuote.vehicleSelection, "../");
  const { contracts, isError, errorResponse } = useContractsForContractAmends();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormSubmit = (selectedContract) => {
    dispatch(reset());
    dispatch(selectedContractId(selectedContract.vehicle));
    dispatch(step(steps.newQuote.calculateQuote));
    navigate("../calculate-quote");
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <div className="text-center">
        {/* This needs to be looked at, we probably need a page header component.  */}
        {/* Temporarily setting display-6 class */}
        <PageHeading>Select a vehicle</PageHeading>
        <p className="lead">Choose from the list</p>
      </div>
      <CenterAlignedButtonContainer>
        {isError && (
          <Alert variant="danger">
            {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
          </Alert>
        )}
        {!isError && (
          <Alert variant="warning">
            If you can't select your car, this means you won't be able to change
            your contract.
          </Alert>
        )}
        <VehicleSelectionForm
          vehicles={contracts.map((c) => ({
            id: c.contractId,
            registrationNumber: c.vehicle.registrationNumber,
          }))}
          onFormSubmit={onFormSubmit}
          submitButtonText="Continue"
        />
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default VehicleSelectionPage;
