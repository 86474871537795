const attachApiKeyHeaderToRequest = (apiKey, currentHeaders) => {
  const headersToReturn =
    typeof currentHeaders === "object" ? currentHeaders : {};
  if (apiKey) {
    headersToReturn["x-local-api-key"] = apiKey;
  }
  return headersToReturn;
};

export { attachApiKeyHeaderToRequest };
