import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import ProfileIcon from "../icons/ProfileIcon";
import CloseIcon from "../icons/CloseIcon";
import HamburgerIcon from "../icons/HamburgerIcon";
import MessageBox from "../modals/message-box/MessageBox";
import useLogout from "../../hooks/use-logout/useLogout";

const HeaderNavLinks = ({ currentStatus }) => {
  const logout = useLogout();
  const [showLogout, setShowLogout] = useState(false);

  const onLogout = (buttonId) => {
    if (buttonId === 1) {
      logout();
    }
    setShowLogout(false);
  };

  return (
    currentStatus && (
      <>
        <MessageBox
          title="Logout"
          show={showLogout}
          onClose={onLogout}
          buttons={["OK", "Cancel"]}
        >
          Are you sure you want to logout?
        </MessageBox>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          className="py-2 mx-4 mx-sm-0 border-0 float-end"
        >
          <HamburgerIcon
            className="hamburgerIcon text-primary"
            title="Menu"
            size="sm"
          />
          <CloseIcon
            className="closeIcon text-primary"
            title="Close menu"
            size="sm"
          />
        </Navbar.Toggle>

        <Navbar.Collapse id="navbarScroll">
          <Nav navbarScroll>
            {currentStatus === "OK" && (
              <>
                <NavLink to="/home">Home</NavLink>
                <NavLink to="/orders">Orders</NavLink>
                <NavLink to="/profile">Profile</NavLink>
                <NavLink to="/help">Help</NavLink>
              </>
            )}

            <NavLink
              to="#logout"
              onClick={(e) => {
                e.preventDefault();
                setShowLogout(true);
              }}
              className="pe-lg-0"
            >
              <ProfileIcon
                title="Logout"
                className="align-baseline me-lg-1 d-none d-lg-inline-block d-xl-inline-block d-xxl-inline-block"
                size="xs"
              />
              Logout
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </>
    )
  );
};

export default HeaderNavLinks;
