import { Form, Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { UserIdField } from "../../fields/login-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotPasswordForm = ({ onFormSubmit }) => {
  const schema = yup.object().shape({
    userId: yup.string().required("User ID or alias is required.").max(50),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    onFormSubmit(data);
  };
  return (
    <>
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          name="forgotPasswordForm"
        >
          <UserIdField />
          <div className="d-grid gap-2 my-5">
            <Button type="submit" id="btnForgottenPasswordFormSubmit">
              Submit
            </Button>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};
export default ForgotPasswordForm;
