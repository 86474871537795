import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Form, Button, Container, Row, Alert, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Stepper from "../../../component/stepper/Stepper";
import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import {
  reset,
  lastDocumentSigned,
  signedOrderDetails,
} from "../../../redux/features/order-review/orderReviewSlice";
import {
  useOrderDocumentsAll,
  useSignOrderDocument,
} from "../../../api/hooks/orders";
import stepsPageData from "./orders-review-steps-data.json";
import DownloadIcon from "../../../component/icons/DownloadIcon";
import PDFViewer from "../../../component/pdf/PDFViewer";
import { openPdfDocument } from "../../../utils/pdf/pdf-utils";
import magicStrings from "../../../utils/magic-string";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import PageHeading from "../../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const OrderReviewPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { documentNumber } = useParams();
  let documentOrderNumber = parseInt(documentNumber);
  const [pdfViewerError, setPdfViewerError] = useState(false);
  const [documentSignError, setDocumentSignError] = useState({});
  const [islastDocumentSigned, setLastDocumentSigned] = useState(false);

  const steps = useSelector((state) => state.orderReview.reviewSteps);
  const orderId = useSelector(
    (state) => state?.orderReview?.orderDetails?.orderId
  );

  const islastDocumentCreditCheckPassed = useSelector(
    (state) => state?.orderReview?.lastDocumentSigned
  );

  const [pageData, setPageData] = useState(
    stepsPageData[steps[documentOrderNumber]?.documentType]
  );
  const {
    isDocumentError,
    refetch,
    data: pdfContent,
    errorResponse: documentErrorResponse,
  } = useOrderDocumentsAll(orderId, pageData?.documentType, () => {});

  useEffect(() => {
    setPageData(stepsPageData[steps[documentOrderNumber]?.documentType]);
    setDocumentSignError({});
  }, [documentOrderNumber, steps]);

  useEffect(() => {
    if (!orderId || islastDocumentCreditCheckPassed) {
      dispatch(reset());
      navigate("/orders", { replace: true });
    }

    if (!pdfContent && orderId) {
      setPdfViewerError(false);
      refetch();
    }
  }, [
    orderId,
    documentOrderNumber,
    setPdfViewerError,
    refetch,
    setPageData,
    dispatch,
    navigate,
    pdfContent,
    islastDocumentCreditCheckPassed,
    steps,
    setDocumentSignError,
  ]);

  const nextStepHandler = () => {
    // Sign each document
    onSignOrderAgreement();
  };

  const onSuccess = (data) => {
    setDocumentSignError({});
    if (islastDocumentSigned && data?.creditCheckPassed) {
      dispatch(signedOrderDetails(data));
      navigate("/orders/order-signed");
    } else if (islastDocumentSigned && !data?.creditCheckPassed) {
      dispatch(signedOrderDetails(data));
      navigate("/orders/order-signed-failure");
    } else {
      navigate(`/orders/order-review/document/${documentOrderNumber + 1}`);
    }
  };
  const onFailure = (error) => {
    setDocumentSignError(error);
    dispatch(lastDocumentSigned(false));
    setLastDocumentSigned(false);
  };

  const { mutate } = useSignOrderDocument(onSuccess, onFailure);

  const onSignOrderAgreement = async (data) => {
    if (documentOrderNumber + 1 === steps?.length) {
      setLastDocumentSigned(true);
    }

    mutate({
      orderId: orderId,
      documentType: pageData?.documentType,
      signature: data?.signYourName ?? "",
    });
  };

  const prevStepHandler = () => {
    navigate(-1);
  };

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        signYourName: yup
          .string()
          .required("Please enter a valid name.")
          .matches(/^[a-zA-Z\-.,'\s]+$/i, "Invalid characters."),
      })
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;

  return (
    <WizardBasePage
      pageTitle={pageData?.pageTitle ?? ""}
      pageSubTitle={pageData?.pageSubtitle ?? ""}
      showScrollProgress={true}
      wizardContent={
        <Stepper
          steps={steps}
          activeStep={documentOrderNumber + 1}
          onClickHandler={() => {}}
          mode="number"
          showCompletionStep="tick"
        />
      }
    >
      <>
        <Container>
          <PageHeading align="left">{pageData?.contentTitle}</PageHeading>

          <p>{pageData?.contentDescription}</p>

          {isDocumentError && (
            <Row>
              <Col className="mt-5">
                <Alert variant="danger">
                  {getSanitisedErrorMessage(
                    documentErrorResponse?.localErrorMessage
                  )}
                </Alert>
              </Col>
            </Row>
          )}

          {!isDocumentError && (
            <>
              {!pdfViewerError && pdfContent && (
                <>
                  <Row>
                    <div
                      className={`w-100 h-100 ${magicStrings.maskInformation}`}
                    >
                      <PDFViewer
                        base64={pdfContent}
                        onLoadSuccess={() => {
                          setPdfViewerError(false);
                        }}
                        onLoadFailure={() => {
                          setPdfViewerError(true);
                        }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Link
                      onClick={() => {
                        openPdfDocument(
                          pdfContent,
                          `${pageData?.documentType}_${orderId}.pdf`
                        );
                      }}
                    >
                      <div className="d-flex flex-row flex-wrap align-items-center mt-4">
                        <DownloadIcon
                          className="text-primary mx-2"
                          title="Download"
                        />
                        <div>
                          Download the{" "}
                          {pageData?.documentType === "DDM"
                            ? pageData?.documentDescription
                            : pageData?.documentDescription.toLowerCase()}
                        </div>
                      </div>
                    </Link>
                  </Row>
                </>
              )}
            </>
          )}

          {pageData?.acceptanceText ? (
            <p className="text-center border-top border-bottom py-3 my-5">
              {pageData?.acceptanceText}
            </p>
          ) : (
            ""
          )}

          {documentSignError?.localErrorMessage && (
            <Alert variant="danger">
              {getSanitisedErrorMessage(documentSignError?.localErrorMessage)}
            </Alert>
          )}

          {documentOrderNumber + 1 < steps?.length ? (
            <Row className="mt-5">
              <Col xs={12} md={5} lg={4} xl={3}>
                <div className="d-grid mb-2 mb-md-0">
                  <Button
                    onClick={prevStepHandler}
                    disabled={documentOrderNumber <= 1 || isDocumentError}
                  >
                    Previous document
                  </Button>
                </div>
              </Col>
              <Col
                lg={{ offset: 4, span: 4 }}
                xl={{ offset: 6, span: 3 }}
                md={{ offset: 2, span: 5 }}
                xs={12}
              >
                <div className="d-grid mt-2 mt-md-0">
                  <Button onClick={nextStepHandler} disabled={isDocumentError}>
                    Next document
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <FormProvider {...methods}>
              <Form
                onSubmit={handleSubmit(onSignOrderAgreement)}
                noValidate
                name="signOrderAgreementForm"
                style={{ fontSize: "1.05rem" }}
              >
                <CenterAlignedButtonContainer>
                  <Form.Group className="my-3" controlId="signYourName">
                    <Form.Label>Add your name to sign</Form.Label>
                    <Form.Control
                      type="string"
                      maxLength={100}
                      isInvalid={errors?.signYourName}
                      {...register("signYourName")}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className={`${
                        errors?.signYourName ? "d-block" : "d-none"
                      }`}
                    >
                      {errors?.signYourName?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </CenterAlignedButtonContainer>

                <Row>
                  <Col xs={12} md={5} lg={4} xl={3}>
                    <div className="d-grid mb-2 mb-md-0">
                      <Button
                        onClick={prevStepHandler}
                        disabled={documentOrderNumber <= 1 || isDocumentError}
                      >
                        Previous document
                      </Button>
                    </div>
                  </Col>
                  <Col
                    lg={{ offset: 4, span: 4 }}
                    xl={{ offset: 6, span: 3 }}
                    md={{ offset: 2, span: 5 }}
                    xs={12}
                  >
                    <div className="d-grid mt-2 mt-md-0">
                      <Button
                        className="px-5"
                        type="submit"
                        id="btnSignOrderAgreementFormSubmit"
                        disabled={isDocumentError}
                      >
                        Sign agreement
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          )}
        </Container>
      </>
    </WizardBasePage>
  );
};

export default OrderReviewPage;
