import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BasePage from "../../../basepages/basepage/BasePage";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import {
  reset,
  step,
} from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import steps from "../../vehicle-collection-steps";
import { useCancelCollection } from "../../../../api/hooks/vehicle-collection";
import PageHeading from "../../../../component/page-heading/PageHeading";

const CancelCollectionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useVehicleCollectionStepCheck(steps.existingBookings.cancel, "../summary");

  const contractDetails = useSelector(
    (state) => state.vehicleCollection.contractDetails
  );

  const onCancelSuccess = () => {
    dispatch(step(steps.existingBookings.cancelSuccess));
    navigate("../cancel-success");
  };

  const onCancelFailure = (error) => {
    if (error.localCode === 1040002) {
      navigate("/vehicle-collection/amendment-unavailable");
    } else {
      dispatch(step(steps.existingBookings.cancelFailure));
      navigate("../cancel-failure");
    }
  };

  const { mutate } = useCancelCollection(onCancelSuccess, onCancelFailure);

  const onBackToHomeClicked = () => {
    dispatch(reset());
  };

  const onCancelClicked = () => {
    mutate(contractDetails.contractId);
  };

  return (
    <BasePage
      pageTitle="Amend a collection"
      pageSubTitle="Amend an existing vehicle collection booking."
    >
      <PageHeading>Cancel a booked collection</PageHeading>

      <p className="text-primary h3 mt-5">
        Some things to consider before you cancel your collection
      </p>
      <p>
        You can cancel or change any booking up to 2 working days before the
        collection date. If you make changes within 2 working days of your
        collection date, we may need to charge you for a cancelled collection.
      </p>
      <CenterAlignedButtonContainer>
        <Button
          id="btnCancelBooking"
          onClick={onCancelClicked}
          disabled={!contractDetails || !contractDetails.contractId}
        >
          Cancel booking
        </Button>
        <BackToHomeButton onButtonClicked={onBackToHomeClicked} />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default CancelCollectionPage;
