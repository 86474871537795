import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  reset,
  onClickStep,
} from "../../../redux/features/early-termination/earlyTerminationSlice";

const useStepper = ({ activeStep, currentStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedContractId = useSelector(
    (state) => state.earlyTermination.contractDetails.contractId
  );

  useEffect(() => {
    if (currentStep === 1) {
      if (selectedContractId !== "") {
        let currentActiveStep = activeStep - currentStep;
        let newActiveStep = activeStep - currentActiveStep;
        dispatch(onClickStep(newActiveStep));
      }
    } else {
      if (selectedContractId !== "") {
        let currentActiveStep = activeStep - currentStep;
        let newActiveStep = activeStep - currentActiveStep;
        dispatch(onClickStep(newActiveStep));
      } else {
        dispatch(reset());
        navigate("/early-termination");
      }
    }
  }, [dispatch, navigate, activeStep, selectedContractId, currentStep]);
};

export { useStepper };
