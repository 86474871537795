import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import FooterBasePage from "../basepages/footer-basepage/FooterBasePage";
import PageHeading from "../../component/page-heading/PageHeading";

const PrivacyPolicyPage = () => {
  return (
    <FooterBasePage
      pageTitle="Privacy"
      pageSubTitle="How your personal information is used by Lloyds Banking Group companies."
    >
      <p>Last updated on March 2023.</p>

      <PageHeading align="left">
        How we use your personal information
      </PageHeading>
      <p>
        This privacy notice explains how companies within Lloyds Banking Group
        use and look after your personal information. This includes what you
        tell us about yourself, what we learn by having you as a customer, and
        your marketing choices. This notice also tells you about your privacy
        rights and how the law protects you.
      </p>
      <p>
        This privacy notice will apply to you if you are or have been a customer
        of Lloyds Banking Group. If your personal information has been provided
        to Lloyds Banking Group, but you are a not a customer, it may also apply
        to you. For example, if you have applied for a product or raised a
        complaint with us.
      </p>
      <p>
        There are a range of different products and services throughout Lloyds
        Banking Group. Each one may have different requirements for the
        collection and use of your personal information. For this reason, some
        sections of this notice do not apply to all Lloyds Banking Group
        products and services.
      </p>

      <h3 className="pt-3">Our Privacy Promise</h3>
      <p className="mb-2">We promise: </p>
      <ul className="pb-2">
        <li>To keep your personal information safe and private.</li>
        <li>Not to sell your personal information.</li>
        <li>
          To give you ways to manage and review your marketing choices at any
          time.
        </li>
      </ul>

      <Table responsive="md" bordered>
        <thead>
          <tr>
            <th>Personal information and the law</th>
            <th>How personal information is used</th>
            <th>The control you have</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              This section tells you who we are, what your personal information
              is, and how we get it. It explains how the law protects you by
              controlling what is allowed to happen to it.
            </td>
            <td>
              This tells you who we share personal information with. It explains
              what it's used for in marketing, to provide credit and to combat
              fraud - and how this can affect you.
            </td>
            <td>
              This section explains your data privacy rights and how you can
              action them. It also covers how to contact us or the data privacy
              regulator, or how to make a complaint.
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>
                  <a href="#Who-we-are">Who we are</a>.
                </li>
                <li>
                  <a href="#How-the-law-protects-you">
                    How the law protects you
                  </a>
                  .
                </li>
                <li>
                  <a href="#Groups-of-Personal-Information">
                    Groups of Personal Information
                  </a>
                  .
                </li>
                <li>
                  <a href="#Where-we-collect-personal-information-from">
                    Where we collect personal information from
                  </a>
                  .
                </li>
                <li>
                  <a href="#How-long-we-keep-your-personal-information">
                    How long we keep your personal information
                  </a>
                  .
                </li>
                <li>
                  <a href="#If-you-choose-not-to-give-personal-information">
                    If you choose not to give personal information
                  </a>
                  .
                </li>
                <li>
                  <a href="#Cookies-and-similar-tracking-technologies">
                    Cookies and similar tracking technologies
                  </a>
                  .
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <a href="#Who-we-share-your-personal-information-with">
                    Who we share your personal information with
                  </a>
                  .
                </li>
                <li>
                  <a href="#How-we-work-out-what-marketing-you-receive">
                    How we work out what marketing you receive
                  </a>
                  .
                </li>
                <li>
                  <a href="#How-we-use-your-information-to-make-automated-decisions">
                    How we use your information to make automated decisions
                  </a>
                  .
                </li>
                <li>
                  <a href="#Credit-Reference-Agencies-CRAs">
                    Credit Reference Agencies (CRAs)
                  </a>
                  .
                </li>
                <li>
                  <a href="#Fraud-prevention-agencies-FPAs">
                    Fraud prevention agencies (FPAs)
                  </a>
                  .
                </li>
                <li>
                  <a href="#Sending-data-outside-of-the-UK-and-EEA">
                    Sending data outside of the UK, Isle of Man, Channel Islands
                    and EEA
                  </a>
                  .
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <a href="#Your-Data-Privacy-Rights">
                    Your data privacy rights
                  </a>
                  .
                </li>
                <li>
                  <a href="#How-To-Contact-Us">How to contact us</a>.
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>

      <h2 className="pt-4 pb-2">Personal information and the law</h2>

      <h3 className="pt-2" id="Who-we-are">
        Who we are
      </h3>
      <p>
        This section gives you the legal name of the company that holds your
        personal information - known as the 'legal entity'. It also tells you
        how you can get in touch with us.
      </p>
      <p>
        Your personal information will be held by Lex Autolease Ltd, which is
        part of Lloyds Banking Group. Lloyds Banking Group is made up of a mix
        of companies, set up on different legal entities. We'll let you know
        which you have a relationship with, when you take out a product or
        service with us. You can find out more about us at{" "}
        <a
          href="https://www.lloydsbankinggroup.com/who-we-are/our-brands.html"
          target="_blank"
          rel="noopener noreferrer"
          className="text-break"
        >
          https://www.lloydsbankinggroup.com/who-we-are/our-brands.html
        </a>
        .
      </p>
      <p className="mb-1 mt-4">
        <strong>Contacting us about data privacy</strong>
      </p>
      <p>
        Please see the 'How to contact us section' to contact us about any of
        the topics set out in this Privacy notice.
      </p>

      <h3 className="pt-3" id="How-the-law-protects-you">
        How the law protects you
      </h3>
      <p>
        This section sets out the legal reasons we rely on, for each of the ways
        we may use your personal information.
      </p>
      <p>
        As well as our Privacy Promise, your privacy is protected by law. This
        section explains how that works.
      </p>
      <p className="mb-1">
        Data Protection law says that we are allowed to use personal information
        only if we have a proper reason to do so. This includes sharing it
        outside Lloyds Banking Group. The law says we must have one or more of
        these reasons:
      </p>
      <ul>
        <li>To fulfil a contract we have with you, or</li>
        <li>When it is our legal duty, or</li>
        <li>When it is in our legitimate interest, or</li>
        <li>When you consent to it, or</li>
        <li>When it is in the public interest.</li>
      </ul>

      <p className="mt-4">
        When we have a business or commercial reason of our own to use your
        information, this is called a 'legitimate interest'. We will tell you
        what that is, if we are going to rely on it as the reason for using your
        data. Even then, it must not unfairly go against your interests.
      </p>
      <p>
        You have the right to object to use of your personal information in this
        way. You can do this by telling us anything that we may need to
        consider, to understand if our use of your personal information is fair.
        Please see the 'Your data privacy rights' section for more information.
      </p>
      <p className="mb-1">
        The law and other regulations treat some types of sensitive personal
        information as special. This personal information is called 'special
        categories of personal information'. We will not collect or use these
        types of data without your consent unless the law allows us to do so. If
        we do, it will only be when it is necessary:
      </p>
      <ul>
        <li>For reasons of substantial public interest, or</li>
        <li>To establish, exercise or defend legal claims.</li>
      </ul>
      <p className="mt-4">
        Here is a list of all the ways that we may use your personal
        information, and which of the reasons we rely on to do so. This is also
        where we tell you what our legitimate interests are and where it is the
        reason for using your personal information.
      </p>
      <p className="pb-3">
        We may rely on different reasons for using the same personal
        information, as this depends on the situation. For example, we may use
        your personal information to make an automated decision about you, which
        could be because of a legal duty, or to fulfil a contract we've made
        with you.
      </p>

      <Table responsive="md" bordered className="table-container">
        <thead>
          <tr>
            <th style={{ width: "25%" }} scope="col">
              Our reasons for using your personal information
            </th>
            <th scope="col">What we use your personal information for</th>
            <th scope="col">Our legitimate interests</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="3">
              <b>Serving you as a customer</b>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Your consent.</li>
                <li>Fulfilling contracts.</li>
                <li>Our legitimate interests.</li>
                <li>Our legal duty.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>To manage our relationship with you or your business.</li>
                <li>
                  To study how our customers use products and services from us
                  and other organisations.
                </li>
                <li>
                  To communicate with you about our products and services.
                </li>
                <li>
                  To develop and manage our brands, products and services.
                </li>
                <li>To develop and carry out marketing activities.</li>
                <li>To make automated decisions about you or your products.</li>
                <li>Managing competitions and promotions.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Keeping our records up to date, working out which of our
                  products and services may interest you and telling you about
                  them.
                </li>
                <li>
                  Developing products and services, and what we charge for them.
                </li>
                <li>
                  Defining types of customers for new products or services.
                </li>
                <li>Seeking your consent when we need it to contact you.</li>
                <li>
                  Being efficient about how we fulfil our legal and contractual
                  duties.
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td colSpan="3">
              <b>Business improvement</b>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Fulfilling contracts.</li>
                <li>Our legitimate interests.</li>
                <li>Our legal duty.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>To test new products.</li>
                <li>
                  To manage how we work with other companies that provide
                  services to us and our customers.
                </li>
                <li>
                  To develop new ways to meet our customers' needs and to grow
                  our business.
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Developing products and services, and what we charge for them.
                </li>
                <li>
                  Defining types of customers for new products or services.
                </li>
                <li>
                  Being efficient about how we fulfil our legal and contractual
                  duties.
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td colSpan="3">
              <b>Managing our operations</b>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Fulfilling contracts.</li>
                <li>Our legitimate interests.</li>
                <li>Our legal duty.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>To deliver of our products and services.</li>
                <li>To make and manage customer payments.</li>
                <li>
                  To manage fees, charges and interest due on customer accounts.
                </li>
                <li>To collect and recover money that is owed to us.</li>
                <li>
                  To manage and provide treasury and investment products and
                  services.
                </li>
                <li>To archive for historical purposes.</li>
                <li>
                  To analyse our environmental impact and information about
                  climate.
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Being efficient about how we fulfil our legal and contractual
                  duties.
                </li>
                <li>Complying with rules and guidance from regulators.</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td colSpan="3">
              <b>Managing security, risk and crime prevention</b>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Fulfilling contracts.</li>
                <li>Our legitimate interests.</li>
                <li>Our legal duty.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  To detect, investigate, report, and seek to prevent financial
                  crime.
                </li>
                <li>To manage risk for us and our customers.</li>
                <li>To obey laws and regulations that apply to us.</li>
                <li>To respond to complaints and seek to resolve them.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Developing and improving how we deal with financial crime,
                  doing our legal duties in this respect.
                </li>
                <li>Complying with rules and guidance from regulators.</li>
                <li>
                  Being efficient about how we fulfil our legal and contractual
                  duties.
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td colSpan="3">
              <b>Business management</b>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Our legitimate interests.</li>
                <li>Our legal duty.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  To run our business in an efficient and proper way. This
                  includes managing our financial position, business capability,
                  planning, adding and testing systems and processes, managing
                  communications, corporate governance, and audit.
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Complying with rules and guidance from regulators.</li>
                <li>
                  Being efficient about how we fulfil our legal and contractual
                  duties.
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Fulfilling contracts.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  To exercise our rights set out in agreements or contracts.
                </li>
              </ul>
            </td>
            <td></td>
          </tr>
        </tbody>
      </Table>

      <Table responsive="md" bordered className="mt-4">
        <thead>
          <tr>
            <th colSpan="2">
              For processing special categories of personal information
            </th>
          </tr>
          <tr>
            <th style={{ width: "25%" }} scope="col">
              Our reasons for using your personal information
            </th>
            <th scope="col">What we use your personal information for</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                <li>Substantial public interest.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Using criminal records data to help prevent, detect, and
                  prosecute unlawful acts and fraudulent behaviour.
                </li>
                <li>
                  Using criminal and health information as needed to provide
                  insurance products.
                </li>
                <li>
                  Using your data to understand, test systems and respond to
                  your support needs.
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Responding to regulatory requirements.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Showing whether we have assessed your situation in the right
                  way.
                </li>
                <li>
                  Passing information to the regulator as needed to allow
                  investigation into whether we have acted in the right way.
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Legal claims.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Using any special categories of data as needed to establish,
                  exercise or defend legal claims.
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <ul>
                <li>Consent.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Telling you that we need your consent to process special
                  categories of personal information, when that is what we rely
                  on for doing so.
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>

      <h3 className="pt-3" id="Groups-of-Personal-Information">
        Groups of personal information
      </h3>
      <p>
        This explains the meaning of the different types of personal information
        that data protection law covers.
      </p>

      <p>
        We use many different kinds of personal information for a range of
        reasons, including managing our relationship with you and the products
        you hold. They are grouped together like this and listed below. We may
        use all or only some of these groups depending on the relationship you
        have with us The groups are all listed here so that you can see what we
        may know about you, even if we simply get it from seeing which shops you
        buy things in. We don't use all this data in the same way. Some of it is
        useful for marketing, or for providing services to you. But some of it
        is private and sensitive and we treat it that way.
      </p>

      <Table responsive="md" bordered>
        <thead>
          <tr>
            <th style={{ width: "25%" }}>Type of personal information</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>Financial</p>
            </td>
            <td>
              <p>Your financial position, status and history.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contact</p>
            </td>
            <td>
              <p>Your name, where you live and how to contact you.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Socio-Demographic</p>
            </td>
            <td>
              <p>
                This includes details about your work or profession,
                nationality, education and where you fit into general social or
                income groupings.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Transactional</p>
            </td>
            <td>
              <p>
                Details about payments to and from your accounts, and insurance
                claims you make.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Contractual</p>
            </td>
            <td>
              <p>Details about the products or services we provide to you.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Locational</p>
            </td>
            <td>
              <p>
                Data we get about where you are. This may come from your mobile
                phone or the place where you connect a computer to the internet.
                It can also include shops where you buy something with your
                card.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Behavioural</p>
            </td>
            <td>
              <p>
                Details about how you use our products and services from us and
                other organisations.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Technical</p>
            </td>
            <td>
              <p>
                Details on the devices and technology you use, including your
                Internet Protocol (IP) address.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Communications</p>
            </td>
            <td>
              <p>
                What we learn about you from letters and emails you write to us,
                and conversations between us.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Social Relationships</p>
            </td>
            <td>
              <p>Your family, friends, and other relationships.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Open Data and Public Records</p>
            </td>
            <td>
              <p>
                Details about you that are in public records such as the
                Electoral Register, and information about you that is openly
                available on the internet.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Usage Data</p>
            </td>
            <td>
              <p>Other data about how you use our products and services.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Documentary Data</p>
            </td>
            <td>
              <p>
                Details about you that are stored in documents in different
                formats, or copies of them. This could include things like your
                passport, drivers licence, or birth certificate.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Gender Identity</p>
            </td>
            <td>
              <p>Information about the gender that you may identify as.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Special categories of data</p>
            </td>
            <td>
              <p className="mb-1">
                The law and other regulations treat some types of personal
                information as special. We will only collect and use these if
                the law allows us to do so:
              </p>
              <ul className="mb-1">
                <li>Racial or ethnic origin.</li>
                <li>Religious, political or philosophical beliefs.</li>
                <li>Trade union membership.</li>
                <li>Genetic and bio-metric data.</li>
                <li>Health data.</li>
                <li>
                  Information that could identify aspects of your sex life.
                </li>
                <li>Sexual orientation.</li>
                <li>Criminal records of convictions and offences.</li>
                <li>Allegations of criminal offences.</li>
              </ul>
              <p>
                You can read how we may use special types of data in the table
                'How the law protects you'.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Choices</p>
            </td>
            <td>
              <p>
                Any permissions, consents, or preferences that you give us. This
                includes things like how you want us to contact you, or if you
                prefer large-print formats.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>National Identifier</p>
            </td>
            <td>
              <p>
                A number or code given to you by a government to identify who
                you are, such as a National Insurance or social security number
                or Tax Identification Number (TIN).
              </p>
            </td>
          </tr>
        </tbody>
      </Table>

      <h3 className="pt-3" id="Where-we-collect-personal-information-from">
        Where we collect personal information from
      </h3>
      <p>
        This section lists all the places where we get data that counts as part
        of your personal information.
      </p>
      <p>
        We may collect personal information about you from other Lloyds Banking
        Group companies and any of these sources:
      </p>
      <p className="pt-1 mb-1">
        <strong>Data you give to us</strong>
      </p>
      <p>
        This covers data that you give and data provided by people linked with
        you or your business's product or service, or people working on your
        behalf.
      </p>
      <p>
        <em>
          This could mean a joint account holder, trustee, or fellow company
          director.
        </em>
      </p>
      <ul>
        <li>When you apply for our products and services.</li>
        <li>
          When you talk to us on the phone or in branch, including recorded call
          and notes we make.
        </li>
        <li>
          When you use our websites, mobile device apps, web chat or{" "}
          <a
            href="https://www.lloydsbank.com/accessibility/signvideo.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign Video
          </a>{" "}
          services.
        </li>
        <li>In emails and letters.</li>
        <li>In insurance claims or other documents.</li>
        <li>In financial reviews and interviews.</li>
        <li>In customer surveys.</li>
        <li>If you take part in our competitions or promotions.</li>
      </ul>

      <p className="pt-1 mb-1">
        <strong>Data we collect when you use our services</strong>
      </p>
      <p>
        This covers two things: details about how and where you access our
        services, and account activity that is shown on your statement.
      </p>
      <ul>
        <li>Payment and transaction data.</li>
      </ul>
      <p>
        <em>
          This includes the amount, frequency, type, location, origin and
          recipients.
        </em>
      </p>
      <p>
        <em>
          If you borrow money, it also includes details of repayments and
          whether they are made on time and in full.
        </em>
      </p>
      <ul>
        <li>Profile and usage data.</li>
      </ul>
      <p>
        <em>
          This includes the security details you create and use to connect to
          our services. It also includes your settings and marketing choices. We
          also gather data from the devices you use (such as computers and
          mobile phones) to connect to our internet, mobile and telephone
          banking services.
        </em>
      </p>
      <p>
        <em>
          We may also use cookies and similar tracking technologies to collect
          data from our websites or mobile apps and when you receive, open or
          respond to emails we send you.
        </em>
      </p>
      <p>
        <em>
          You can find out more about this in our{" "}
          <a
            href="https://www.lloydsbank.com/cookies.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookies Policy
          </a>
          .
        </em>
      </p>

      <p className="pt-1 mb-1">
        <strong>Data from outside organisations</strong>
      </p>
      <ul>
        <li>
          Companies that introduce you to us. For example, a price comparison
          website, a broker, or a store or car dealership offering finance deals
          through us.
        </li>
        <li>Financial advisers.</li>
        <li>Credit card providers like Visa and Mastercard.</li>
        <li>
          Credit reference agencies such as{" "}
          <a
            href="https://www.transunion.co.uk/legal/crain-retention"
            target="_blank"
            rel="noopener noreferrer"
          >
            TransUnion
          </a>
          ,{" "}
          <a
            href="https://www.equifax.co.uk/crain"
            target="_blank"
            rel="noopener noreferrer"
          >
            Equifax
          </a>{" "}
          and{" "}
          <a
            href="https://www.experian.co.uk/crain"
            target="_blank"
            rel="noopener noreferrer"
          >
            Experian
          </a>
          .
        </li>
        <li>Insurers.</li>
        <li>Retailers.</li>
        <li>
          Social networks and other technology providers. For instance, when you
          click on one of our ads.
        </li>
        <li>Fraud prevention agencies.</li>
        <li>
          Other financial services companies. This could be to fulfil a payment
          or other service as part of a contract with you, or to help prevent,
          detect and prosecute unlawful acts and fraudulent behaviour.
        </li>
        <li>
          Employers. For instance, to get a reference if you apply for a
          mortgage.
        </li>
        <li>Payroll service providers.</li>
        <li>
          Land agents, such as firms who do property valuations for mortgages.
        </li>
        <li>
          Public information sources such as the Electoral Register, Companies
          House, published media and social networks.
        </li>
        <li>
          Products which have a loyalty scheme, agents, suppliers,
          sub-contractors and advisers.
        </li>
      </ul>
      <p>
        <em>
          These can be types of firms we use to help us run accounts and
          services. They can also be specialist companies who advise us on ways
          to develop and improve our business.
        </em>
      </p>
      <ul>
        <li>Market researchers.</li>
      </ul>
      <p>
        <em>
          These firms may get in touch with you on our behalf to ask you for
          your opinions and feedback. Sometimes these firms will combine what
          you tell them with data from other sources to study it. They'll use
          this to help us understand our customers' point of view, so that we
          can improve the way we work as a business.
        </em>
      </p>
      <ul>
        <li>
          Firms providing data services. We receive reports that we use to
          better understand our customers and look for general patterns and
          trends.
        </li>
        <li>
          Medical professionals. For some insurance products, we may ask your GP
          or other medical practitioner to send us a report (we will only do
          this if we get your consent first).
        </li>
        <li>Government and law enforcement agencies.</li>
      </ul>

      <h3 className="pt-3" id="How-long-we-keep-your-personal-information">
        How long we keep your personal information
      </h3>
      <p>
        This section explains how long we may keep your information for and why.
      </p>

      <p>
        We will keep your personal information for as long as you have a product
        or are using a service from us, and for up to 10 years after. The
        reasons we may do this are:
      </p>
      <ul>
        <li>
          To respond to a question or complaint, or to show whether we gave you
          fair treatment.
        </li>
        <li>To study customer data as part of our own internal research.</li>
        <li>To obey rules that apply to us about keeping records.</li>
      </ul>

      <p>
        We may also keep your data for longer than 10 years if we archive it for
        historical purposes or if we can't delete it for legal, regulatory or
        technical reasons. For example, we have to hold pension transfer
        information indefinitely.
      </p>

      <p>
        We will only use your personal information for those purposes and will
        make sure that your privacy is protected.
      </p>

      <h3 className="pt-3" id="If-you-choose-not-to-give-personal-information">
        If you choose not to give personal information
      </h3>
      <p>
        You can choose not to give us personal information. In this section we
        explain the effects this may have.
      </p>
      <p>
        We may need to collect personal information by law, or to enter into or
        fulfil a contract we have with you.
      </p>
      <p>
        If you choose not to give us this personal information, it may delay or
        prevent us from fulfilling our contract with you, or doing what we must
        do by law. It may also mean that we cannot run your products or
        services. It could mean that we cancel a product or service you have
        with us.
      </p>
      <p>
        We sometimes ask for information that is useful, but not required by law
        or a contract. We will make this clear when we ask for it. You do not
        have to give us these extra details and it won't affect the products or
        services you have with us.
      </p>

      <h3 className="pt-3" id="Cookies-and-similar-tracking-technologies">
        Cookies and similar tracking technologies
      </h3>
      <p>
        This section explains how we use data on our websites, apps and emails.
      </p>
      <p>
        We may use cookies and similar tracking technologies on our websites and
        apps, and in emails we send you.
      </p>

      <p className="mb-1">
        <strong>Cookies</strong>
      </p>
      <p>
        Cookies are small computer files that get sent to your computer, tablet
        or mobile device by websites when you visit them. They stay on your
        device and get sent back to the website they came from, when you go
        there again. Cookies store information about your visits to that
        website, such as your choices and other details. Some of this data does
        not contain personal details about you or your business, but it is still
        protected by this Privacy notice.
      </p>

      <p className="mb-1">
        <strong>Email Tracking</strong>
      </p>
      <p>
        We track emails to help us improve the communications we send. Small
        images called pixels within our emails tell us things like whether you
        opened the email, how many times and the device you used. We may also
        set a cookie to find out if you clicked on any links in the email.
      </p>
      <p>
        To find out more about how we use cookies and email tracking, please see
        our{" "}
        <a
          href="https://www.lloydsbank.com/cookies.asp"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookies Policy
        </a>
        .
      </p>

      <h2 className="pt-4 pb-2">How personal information is used</h2>

      <h3 id="Who-we-share-your-personal-information-with">
        Who we share your personal information with
      </h3>
      <p>
        We may share your personal information with outside organisations such
        as credit card providers, insurers or tax authorities. This is so that
        we can provide you with products and services, run our business, and
        obey rules that apply to us. Here we list all the types of organisation
        that we may share your personal information with.
      </p>

      <p className="pt-3 mb-1">
        <strong>Lloyds Banking Group</strong>
      </p>
      <p>
        We may share your personal information with other companies in Lloyds
        Banking Group.
      </p>

      <p className="pt-3 mb-1">
        <strong>Authorities</strong>
      </p>
      <p>This means official bodies that include:</p>
      <ul>
        <li>Central and local government.</li>
        <li>HM Revenue &amp; Customs, regulators and other tax authorities.</li>
        <li>
          UK Financial Services Compensation Scheme and other deposit guarantee
          schemes.
        </li>
        <li>Law enforcement and fraud prevention agencies.</li>
      </ul>

      <p className="pt-3 mb-1">
        <strong>Banking and financial services</strong>
      </p>
      <p className="mb-1">
        Outside companies we work with to provide services to you and to run our
        business.
      </p>
      <ul className="mb-1">
        <li>Agents, suppliers, sub-contractors and advisers.</li>
      </ul>
      <p>
        <em>
          These are types of firm that we use to help us run accounts, policies
          and services.
        </em>
      </p>

      <ul className="pt-3 mb-1">
        <li>
          Credit reference agencies such as{" "}
          <a
            href="https://www.transunion.co.uk/legal/crain-retention"
            target="_blank"
            rel="noopener noreferrer"
          >
            TransUnion
          </a>
          ,{" "}
          <a
            href="https://www.equifax.co.uk/crain"
            target="_blank"
            rel="noopener noreferrer"
          >
            Equifax
          </a>{" "}
          and{" "}
          <a
            href="https://www.experian.co.uk/crain"
            target="_blank"
            rel="noopener noreferrer"
          >
            Experian
          </a>
          .
        </li>
        <li>Someone linked with you or your business's product or service.</li>
      </ul>
      <p>
        <em>
          This could mean a joint account holder, trustee, or fellow company
          director.
        </em>
      </p>

      <ul className="pt-3 mb-1">
        <li>
          Other financial services companies. This can be to fulfil a payment or
          other service as part of a contract with you; or to help prevent,
          detect and prosecute unlawful acts and fraudulent behaviour.
        </li>
        <li>Independent Financial Advisors.</li>
      </ul>
      <p>
        <em>
          This could be someone who you, your employer, or product owner have
          chosen to advise on things like pensions or life assurance.
        </em>
      </p>

      <ul className="pt-3 mb-1">
        <li>Agents who help us to collect what is owed to us.</li>
        <li>Price comparison websites and similar companies.</li>
      </ul>
      <p>
        <em>
          We only do so if you buy something from us through such a company. We
          only share details needed to confirm what you have bought, to fulfil
          our own contract with the company.
        </em>
      </p>

      <ul className="pt-3 mb-1">
        <li>
          Employers. For instance, to confirm your identity if we ask for a
          mortgage reference
        </li>
        <li>Companies you ask us to share your data with.</li>
      </ul>
      <p>
        <em>
          This is to do with something called{" "}
          <a
            href="https://www.openbanking.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Banking
          </a>
          , which gives you more freedom and control to use your own banking
          data. It can make it easier for you or your business to shop around
          for products like credit cards, savings and current accounts.
        </em>
      </p>

      <ul className="pt-3 mb-1">
        <li>
          Companies whose shares we hold for you in one of our nominee
          companies.
        </li>
      </ul>
      <p>
        <em>
          This is usually to do with Share Dealing accounts, where we hold
          shares for you in the name of our nominee company, and are asked by
          the company to share details of the underlying shareholder with them.
        </em>
      </p>

      <p className="pt-3 mb-1">
        <strong>Insurers</strong>
      </p>
      <p className="mb-1">
        We share personal information with insurance industry companies to
        process claims and help reduce fraud. We do that in these ways:
      </p>
      <ul>
        <li>
          If you apply for insurance through us, we may pass your personal or
          business details to the insurer.
        </li>
        <li>
          If you apply for insurance with us as the insurer, we may share your
          personal or business details with reinsurers.
        </li>
        <li>
          If you make an insurance claim, information you give to us or the
          insurer may be put on a register of claims. This will be shared with
          other insurers, our agents, suppliers and sub-contractors. In some
          cases we may also share it with our business partners, if you also
          have a relationship with them.
        </li>
      </ul>

      <p className="pt-3 mb-1">
        <strong>Other services and schemes</strong>
      </p>
      <p className="mb-1">
        These are organisations that we may need to share your personal
        information with, because of what you can do with the product or service
        you have with us.
      </p>
      <ul className="mb-1">
        <li>
          If you have a debit, credit or charge card with us, we will share
          transaction details with companies which help us to provide this
          service (such as Visa and Mastercard).
        </li>
      </ul>
      <p>
        <em>
          We need to do this to keep your account balance and statements up to
          date, for example.
        </em>
      </p>

      <ul>
        <li>
          If you use Direct Debits, we will share your data with the Direct
          Debit scheme.
        </li>
        <li>
          If you have a product which has a loyalty scheme, we will share your
          data with that scheme.
        </li>
        <li>
          If you have a product with benefits such as travel insurance or
          discount offers, we will share your data with the benefit providers.
          We may also share it with other companies involved in how you use the
          service (such as a private car park operator).
        </li>
        <li>
          If you have a secured loan or mortgage with us, we may share
          information with other lenders who also hold a charge on the property,
          such as the other party in a shared ownership scheme.
        </li>
        <li>
          If you or your employer have a product with us that includes extra
          services or features, such as daily rental vehicles, we will share
          your data with the providers of those services or features.
        </li>
        <li>
          If a third party contacts us about fines, penalties or charges imposed
          on you, we may share this with your employer, or share your details
          with these third parties.
        </li>
        <li>
          If we carry out driving licence, vehicle or any other checks as part
          of our products, services, or features, we may share information with
          suppliers and other third parties needed for us to provide those to
          you, or your employer.
        </li>
      </ul>

      <p className="pt-3 mb-1">
        <strong>General business</strong>
      </p>
      <p className="mb-1">
        Outside companies we use to provide our services, help grow and improve
        our business.
      </p>
      <ul className="mb-1">
        <li>
          Companies we have a contractual arrangement, joint venture or
          agreement with.
        </li>
      </ul>
      <p>
        <em>
          {" "}
          This could be a store, a manufacturer, or a broker who is offering
          finance deals or other products, services or features through us.
        </em>
      </p>

      <ul className="pt-3 mb-1">
        <li>Organisations that introduce you to us.</li>
      </ul>
      <p>
        <em>
          This might be a store or car dealership that offers finance deals
          through us, or a price comparison website.
        </em>
      </p>
      <ul className="pt-3 mb-1">
        <li>Market researchers.</li>
      </ul>
      <p>
        <em>
          These firms may get in touch with you on our behalf to ask you for
          your opinions and feedback. Sometimes these firms will combine what
          you tell them with data from other sources to study it. They'll use
          this to help us understand our customers' point of view, so that we
          can improve the way we work as a business.
        </em>
      </p>

      <ul className="pt-3 mb-1">
        <li>
          Advisers who help us to come up with new ways of doing business.
        </li>
      </ul>
      <p>
        <em>This might be a legal firm, IT supplier or consultancy.</em>
      </p>

      <ul className="pt-3">
        <li>
          Advertisers and technology providers that you use, such as websites
          you visit, social media, and providers of apps and smart devices.
        </li>
      </ul>

      <p className="mb-1">This is so that:</p>
      <ul>
        <li>
          You are only shown marketing we think you might be interested in.
        </li>
        <li>
          You are not shown marketing we think you won't be interested in.
        </li>
        <li>
          We can try to find new customers who are like you, or have similar
          interests to yours.
        </li>
      </ul>

      <p>
        Learn more about how we use your information to make automated decisions
        below.
      </p>

      <p>Outside companies you may use.</p>
      <p className="mb-1">This could include:</p>
      <ul>
        <li>
          Price comparison websites and similar companies.
          <em>
            {" "}
            We only do so if you buy something from us through such a company.
            We only share details needed to confirm what you have bought, to
            fulfil our own contract with the company.
          </em>
        </li>
        <li>
          Third party payment providers, for example if you use an App on your
          phone to pay for something, then we may share payment and transaction
          data with them.
          <em>
            {" "}
            This includes the amount, type, location, origin and recipients.
          </em>
        </li>
      </ul>

      <p className="pt-3 mb-1">
        <strong>
          Company mergers, takeovers and transfers of products or services
        </strong>
      </p>
      <p className="mb-1">
        We may also share your personal information if the ownership of products
        or services or the make-up of Lloyds Banking Group changes in the
        future:
      </p>
      <ul>
        <li>
          We may choose to sell, transfer, or merge parts of our business, or
          our assets, including products or services. Or we may try to bring
          other businesses into Lloyds Banking Group.
        </li>
      </ul>
      <p>
        <em>
          This is sometimes called Mergers &amp; Acquisitions or 'company
          takeovers'.
        </em>
      </p>
      <ul>
        <li>
          During any such process, we may share your data with other parties
          involved. We'll only do this if they agree to keep your data safe and
          private.
        </li>
        <li>
          If the change to our Group happens, then other parties may use your
          data in the same way as set out in this notice.
        </li>
      </ul>

      <p className="pt-3 mb-1">
        <strong>Sharing data that does not say who you are</strong>
      </p>
      <p>
        We may share or sell some data to other companies outside Lloyds Banking
        Group, but only when no-one's identity can be known or found out. One of
        the ways we can do this is by grouping customers together.
      </p>

      <p>
        We do this so we, and those other companies can look for general
        patterns and trends. For instance, we might look at customers in a
        specific location shopping for clothes. We could look at a group of
        customers by their age group, the area they live in, or their spending
        habits, such as the average amount spent in one month. But we would not
        include any data about who these customers are. When we use data this
        way, we use all of the information - including current and historical
        data - that we hold about you and our other customers.
      </p>

      <p>
        We and those other companies do this to learn about the types of
        customers we each have, how they use our products, and how our products
        perform for them. We and those other companies may also learn about the
        markets we each operate in and how our competitors perform. The law says
        customer data is not considered to be personal information after
        customer identities have been securely hidden in this way.
      </p>

      <h3 className="pt-3" id="How-we-work-out-what-marketing-you-receive">
        How we work out what marketing you receive
      </h3>
      <p>
        We use marketing to let you know about products, services and offers
        that you may want from us. This section tells you how we decide what
        marketing to show or send you. It also explains how we work out what you
        may be interested in.
      </p>

      <p>
        We may use your personal information to make decisions about what
        products, services and offers we think you may be interested in. This is
        what we mean when we talk about 'marketing'.
      </p>

      <p className="pt-3 mb-1">
        <strong>When we can use your personal information for marketing</strong>
      </p>
      <p>
        We can only use your personal information to send you marketing messages
        if we have either your consent or a 'legitimate interest'. That is when
        we have a business or commercial reason to use your information. It must
        not conflict unfairly with your own interests.
      </p>

      <p className="pt-3 mb-1">
        <strong>How we decide what marketing may interest you</strong>
      </p>
      <p>
        The personal information we have for you is made up of what you tell us,
        and data we collect when you use our services, or from outside
        organisations we work with. We study this to form a view on what we
        think you may want or need, or what may be of interest to you. This is
        how we decide which products, services and offers may be relevant for
        you.
      </p>

      <p>
        This is called profiling for marketing purposes. You can contact us at
        any time and ask us to stop using your personal information this way.
        Please see the 'Your data privacy rights' section for more information,
        and the 'How to contact us' section to contact us about this.
      </p>

      <p className="pt-3 mb-1">
        <strong>How we send you marketing</strong>
      </p>
      <p>
        We may show or send you marketing material online (on our own and other
        websites including social media), in our own and other apps, or by
        email, mobile phone, post or through smart devices and other digital
        channels.
      </p>

      <p>
        You can also tell us not to collect data while you are using our
        websites or mobile apps. If you do, you will still see some marketing
        but it will not be tailored to you. See our{" "}
        <a
          href="https://www.lloydsbank.com/cookies.asp"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookies Policy
        </a>{" "}
        for details about how we use this data to improve our websites and
        mobile apps.
      </p>

      <p className="pt-3 mb-1">
        <strong>Your marketing choices</strong>
      </p>
      <p>
        You can tell us to stop sending you marketing at any time. Whatever you
        choose, we'll still send you statements and other important information
        such as changes to your existing products and services.{" "}
      </p>
      <p>
        We do not sell the personal information we have about you to outside
        organisations.
      </p>
      <p>
        We may ask you to confirm or update your choices, if you take out any
        new products or services with us in future. We will also ask you to do
        this if there are changes in the law, regulation, or the structure of
        our business.
      </p>
      <p>
        If you change your mind you can contact us to update your choices at any
        time. Please see the 'Your data privacy rights' section for more
        information, and the 'How to contact us' section to contact us about
        this.
      </p>

      <h3
        className="pt-3"
        id="How-we-use-your-information-to-make-automated-decisions"
      >
        How we use your information to make automated decisions
      </h3>
      <p>
        Here we tell you how we use automated systems to make decisions about
        you and your money. We also explain the rights you have to challenge
        decisions made this way.
      </p>
      <p>
        We sometimes use systems to make automated decisions about you or your
        business. This helps us to make sure our decisions are quick, fair,
        efficient and correct, based on what we know. Automated decisions can
        affect the products, services or features we may offer you now or in the
        future, or the price that we charge you for them. They are based on
        personal information that we have or that we are allowed to collect from
        others.
      </p>

      <p>Here are the types of automated decision we make:</p>

      <p className="pt-3 mb-1">
        <strong>Pricing</strong>
      </p>
      <p>
        We may decide what to charge for some products and services based on
        what we know. For instance, if you use our online mortgage calculator,
        it will use the personal financial details you put in to estimate the
        kind of mortgage we may offer you. If you apply for insurance, we will
        compare what you tell us with other records to work out how likely you
        are to make a claim. This will help us to decide whether to offer you
        the product and what price to charge you.
      </p>

      <p className="pt-3 mb-1">
        <strong>Tailoring products and services</strong>
      </p>
      <p>
        The personal information we have for you is made up of what you tell us,
        and data we collect when you use our services, or from outside
        organisations we work with. We study this to form a view on what we
        think you may do, want, or need. This is how we decide which products,
        services and offers may be relevant for you. We use this information to
        place you in groups with similar customers. These are called customer
        segments. We use these to study and learn about our customers' needs,
        and to make decisions based on what we learn. This is called profiling.
        It helps us to design products, services and offers for different
        customer segments, and to manage our relationships with them. It also
        helps us tailor what individuals see on our own and other websites and
        mobile apps, including social media.{" "}
      </p>

      <p>
        We also use customer segments from outside companies we work with - such
        as social networks - by asking them for groups of people who are similar
        to you, or have interests that are like yours in some ways. These
        companies help us look for new customers who may be interested in
        products, services or offers that our existing customers are interested
        in.
      </p>

      <p className="pt-3 mb-1">
        <strong>Detecting fraud</strong>
      </p>
      <p>
        We use your personal information to help decide if your personal or
        business accounts may be being used for fraud or money-laundering. We
        may detect that an account is being used in ways that fraudsters work.
        Or we may notice that an account is being used in a way that is unusual
        for you or your business. If we think there is a risk of fraud, we may
        stop activity on the accounts or refuse access to them.
      </p>

      <p className="pt-3 mb-1">
        <strong>Opening accounts</strong>
      </p>
      <p>
        When you open an account with us, we check that the product or service
        is relevant for you, based on what we know. We also check that you or
        your business meet the conditions needed to open the account. This may
        include checking age, residency, nationality or financial position. It
        may mean we cannot offer you the account you want.
      </p>

      <p className="pt-3 mb-1">
        <strong>Approving credit</strong>
      </p>
      <p>
        We use a system to decide whether to lend money to you or your business,
        when you apply for credit such as a loan or credit card. This is called
        credit scoring. It uses past data to assess how you're likely to act
        while paying back any money you borrow. This includes data about similar
        accounts you may have had before.
      </p>

      <p className="mb-1">Credit scoring uses data from three sources:</p>
      <ul>
        <li>Your application form.</li>
        <li>Credit reference agencies.</li>
        <li>Data we may already hold.</li>
      </ul>

      <p>
        It gives an overall assessment based on this. Banks and other lenders
        use this to help us make responsible lending decisions that are fair and
        informed.
      </p>
      <p>
        Credit scoring methods are tested regularly to make sure they are fair
        and unbiased.
      </p>
      <p>
        This credit score can determine whether we will give you credit, or
        allow you to take another product or service.
      </p>

      <p className="pt-3 mb-1">
        <strong>Your rights</strong>
      </p>
      <p>
        You can object to an automated decision we have made, and ask that a
        person reviews it.
      </p>
      <p>
        If you want to know more about these rights, please see the 'Your data
        privacy rights' section for more information, and the 'How to contact
        us' section to contact us about this.
      </p>

      <h3 className="pt-3" id="Credit-Reference-Agencies-CRAs">
        Credit Reference Agencies (CRAs)
      </h3>
      <p>
        This section explains how we work with outside companies to decide
        whether to lend you money through products like credit cards or loans.
        It explains how we manage our products during your relationship with us.
        It explains what we do and why we do it.
      </p>

      <p>
        We carry out credit and identity checks when you apply for a product or
        services for you or your business. We may use Credit Reference Agencies
        to help us with this.
      </p>

      <p>
        We will go on sharing your personal information with CRAs for the
        duration of your relationship with us. From time to time we may also
        search information that the CRAs have, to help us manage those accounts.
      </p>

      <p className="mb-1">
        We will share your personal information with CRAs and they will give us
        information about you. The data we exchange can include:
      </p>
      <ul>
        <li>Name, address and date of birth.</li>
        <li>Credit application.</li>
        <li>Details of any shared credit.</li>
        <li>Financial situation and history.</li>
        <li>Fraud prevention information.</li>
      </ul>

      <p className="mb-1">We'll use this data to:</p>
      <ul>
        <li>
          Assess whether you or your business is able to afford to make
          repayments
        </li>
        <li>Make sure what you've told us is true, correct and up to date.</li>
        <li>Help detect and prevent financial crime.</li>
        <li>Manage your accounts with us.</li>
        <li>Trace and recover debts.</li>
        <li>Make sure any offers are relevant for you.</li>
      </ul>

      <p>
        This will include details of money going into the account, and the
        account balance. If you borrow, it will also include details of your
        repayments and whether you repay in full and on time. We will also tell
        the CRAs when you settle your accounts with us. The CRAs may give this
        information to other organisations that want to check your credit
        status.
      </p>
      <p>
        When we ask CRAs about you or your business, they will note it on your
        credit file. This is called a credit search. Other lenders may see this
        and we may see credit searches from other lenders.
      </p>
      <p>
        If you apply for a product with someone else, we will link your records
        with theirs. We will do the same if you tell us you have a spouse,
        partner or civil partner - or that you are in business with other
        partners or directors.
      </p>
      <p>
        You should tell them about this before you apply for a product or
        service. It is important that they know your records will be linked
        together, and that credit searches may be made on them.
      </p>
      <p>
        CRAs will also link your records together. These links will stay on your
        files unless one of you asks the CRAs to break the link. You will
        normally need to give proof that you no longer have a financial link
        with each other.
      </p>

      <p className="mb-1">
        You can find out more about the CRAs on their websites, in the Credit
        Reference Agency Information Notice (CRAIN). This includes details
        about:{" "}
      </p>
      <ul>
        <li>Who they are.</li>
        <li>Their role as fraud prevention agencies.</li>
        <li>The data they hold and how they use it.</li>
        <li>How they share personal information.</li>
        <li>How long they can keep data.</li>
        <li>Your data protection rights.</li>
      </ul>
      <p className="mb-1">
        Here are links to the information notice for each of the three main
        Credit Reference Agencies:
      </p>
      <p className="mb-1">
        <a
          href="https://www.transunion.co.uk/legal/crain-retention"
          target="_blank"
          rel="noopener noreferrer"
        >
          TransUnion
        </a>
      </p>
      <p className="mb-1">
        <a
          href="https://www.equifax.co.uk/crain"
          target="_blank"
          rel="noopener noreferrer"
        >
          Equifax
        </a>
      </p>
      <p className="mb-1">
        <a
          href="https://www.experian.co.uk/crain"
          target="_blank"
          rel="noopener noreferrer"
        >
          Experian
        </a>
      </p>

      <p className="pt-3">
        We use these agencies primarily with UK, Isle of Man, or EEA products
        and customers. For non-UK, Isle of Man or EEA products and customers, we
        may not use these agencies. We may instead use other regional CRAs.
        Please see the 'How to contact us' section if you'd like to speak to us
        about this.
      </p>

      <h3 className="pt-3" id="Fraud-prevention-agencies-FPAs">
        Fraud prevention agencies (FPAs)
      </h3>
      <p>
        This section deals with information we share outside our group to help
        fight financial crime. This includes crimes such as fraud,
        money-laundering and terrorist financing.
      </p>

      <p>
        We may need to confirm your identity before we provide products or
        services to you or your business. This may include carrying out fraud
        checks at the point of sale.
      </p>

      <p className="mb-1">
        Once you have become a customer of ours, we will share your personal
        information as needed to help combat fraud and other financial crime.
        The organisations we share data with are:
      </p>
      <ul>
        <li>Registered Fraud Prevention Agencies.</li>
        <li>Other agencies and bodies acting for the same purpose.</li>
        <li>Industry databases used for this purpose.</li>
        <li>Insurers.</li>
      </ul>
      <p>
        Throughout our relationship with you, we and these organisations
        exchange data between us to help prevent, deter, detect and investigate
        fraud and money-laundering.
      </p>

      <p>
        We and these organisations can't use your personal information unless we
        have a proper reason to do so. It must be needed either for us to obey
        the law, or for a 'legitimate interest'.
      </p>

      <p>
        When we have a business or commercial reason of our own to use your
        information, this is called a 'legitimate interest'. We will tell you
        what that is, if we are going to rely on it as the reason for using your
        data. Even then, it must not unfairly go against your interests.
      </p>

      <p className="mb-1">We will use the information to:</p>
      <ul>
        <li>Confirm identities.</li>
        <li>Help prevent fraud and / or money-laundering.</li>
        <li>Fulfil any contracts you or your business has with us.</li>
      </ul>
      <p>
        We or an FPA may allow law enforcement agencies to access your personal
        information. This is to support their duty to prevent, detect,
        investigate and prosecute crime.
      </p>

      <p>
        These other organisations can keep personal information for different
        lengths of time, up to six years.
      </p>

      <p className="pt-3 mb-1">
        <strong>The information we use</strong>
      </p>
      <p className="mb-1">
        These are some of the kinds of personal information that we use:
      </p>
      <ul>
        <li>Name.</li>
        <li>Date of birth.</li>
        <li>Residential address.</li>
        <li>History of where you have lived.</li>
        <li>Contact details, such as email addresses and phone numbers.</li>
        <li>Financial data.</li>
        <li>Whether you have been a victim of fraud.</li>
        <li>Data about insurance claims you have made.</li>
        <li>Data relating to your or your businesses products or services.</li>
        <li>Employment details.</li>
        <li>Vehicle details.</li>
        <li>
          Data that identifies computers or other devices you use to connect to
          the internet. This includes your Internet Protocol (IP) address.
        </li>
      </ul>

      <p className="pt-3 mb-1">
        <strong>Automated decisions for fraud prevention</strong>
      </p>
      <p>
        The information we have for you or your business is made up of what you
        tell us, and data we collect when you use our services, or from third
        parties we work with.
      </p>

      <p>
        We and other organisations acting to prevent fraud may process your
        personal information in systems that look for fraud by studying patterns
        in the data. We may find that an account or policy is being used in ways
        that fraudsters work. Or we may notice that an account is being used in
        a way that is unusual for you or your business. Either of these could
        indicate a risk that fraud or money-laundering against a customer, the
        bank or the insurer.
      </p>

      <p className="pt-3 mb-1">
        <strong>How this can affect you</strong>
      </p>
      <p>
        If we or an FPA decide there is a risk of fraud, we may stop activity on
        the accounts or block access to them. FPAs and cross-industry
        organisations may also keep a record of the risk that you or your
        business may pose.
      </p>

      <p>
        This may result in other organisations refusing to provide you with
        products or services, or to employ you.
      </p>

      <p className="pt-3 mb-1">
        <strong>
          Data transfers out of the UK, Isle of Man, Channel Islands and EEA
        </strong>
      </p>
      <p>
        FPAs and other organisations we share data with for these purposes may
        send personal information to countries outside the UK, Isle of Man,
        Channel Islands and European Economic Area ('EEA'). When they do, there
        will be a contract in place to make sure the recipient protects the data
        to the same standard as the EEA. This may include following
        international frameworks for making data sharing secure.
      </p>

      <p className="mb-1">
        Here is the web page for the information notice of the main Fraud
        Prevention Agency we use:
      </p>
      <p>
        <a
          href="https://www.cifas.org.uk/fpn"
          target="_blank"
          rel="noopener noreferrer"
        >
          CIFAS
        </a>
      </p>

      <h3 className="pt-3" id="Sending-data-outside-of-the-UK-and-EEA">
        Sending data outside of the UK, Isle of Man, Channel Islands and EEA
      </h3>
      <p>
        This section tells you about the safeguards that keep your personal
        information safe and private, if it is sent outside the UK, Isle of Man,
        Channel Islands and EEA.
      </p>

      <p className="pt-1 mb-1">
        We will only send your data outside of the UK, Isle of Man, Channel
        Islands and European Economic Area ('EEA') to:
      </p>
      <ul>
        <li>Follow your instructions.</li>
      </ul>
      <p className="pt-1 mb-1">
        <em>
          For example, if you book a hotel in Australia with your Lloyds Banking
          Group card we will send your details necessary to fulfil that payment
          to the hotel's bank.
        </em>
      </p>
      <ul>
        <li>Comply with a legal duty.</li>
      </ul>
      <p className="pt-1 mb-1">
        <em>
          For example, we share information about US citizens with the US tax
          authority.
        </em>
      </p>
      <ul>
        <li>
          Work with our suppliers who help us to run your accounts and services.
        </li>
      </ul>
      <p className="pt-1 mb-1">
        If we do transfer your personal information outside the UK, Isle of Man,
        Channel Islands and EEA to our suppliers, we will make sure it's
        protected to the same extent as in the UK, Isle of Man, Channel Islands
        and EEA. We'll use one of these safeguards:
      </p>
      <ul>
        <li>
          Transfer it to a non-EEA country with privacy laws that give the same
          protection as the UK, Isle of Man, Channel Islands and EEA. Learn more
          on the{" "}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection_en"
            target="_blank"
            rel="noopener noreferrer"
          >
            European Commission Justice website
          </a>
          .
        </li>
        <li>
          Put in place a contract with the recipient that means they must
          protect it to the same standards as the UK, Isle of Man, Channel
          Islands and EEA. Read more about this here on the{" "}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection_en"
            target="_blank"
            rel="noopener noreferrer"
          >
            European Commission Justice website
          </a>
          .
        </li>
      </ul>

      <h2 className="pt-4 pb-2">The control you have</h2>
      <h3 id="Your-Data-Privacy-Rights">Your Data Privacy Rights</h3>
      <p>
        This section explains your data privacy rights and how to contact us
        about them.
      </p>
      <p>
        You have several rights around use of your personal information.
        Following is a list of these rights, including a description and, if
        applicable, how to contact us about them. These rights do not apply in
        all scenarios.
      </p>

      <p className="pt-2 mb-1">
        <strong>The right to be informed</strong>
      </p>
      <p>
        You have the right to be informed about the collection and use of your
        personal information. This means that we should provide you with details
        of how we use your personal information. This Data Privacy Notice is an
        example of this.{" "}
      </p>

      <p className="pt-2 mb-1">
        <strong>The right of access</strong>
      </p>
      <p>
        You have the right to access a copy of your personal information,
        referred to as a Subject Access Request (SAR). Please see the 'How to
        get a copy of your personal information' instructions in the 'How to
        contact us' section.
      </p>

      <p className="pt-2 mb-1">
        <strong>The right to rectification</strong>
      </p>
      <p>
        You have the right to question any information we have about you that
        you think is incorrect. We'll take reasonable steps to check this for
        you and correct it. Please visit one of our branches, or see the 'How to
        contact us' section if you want to do this.
      </p>

      <p className="pt-2 mb-1">
        <strong>The right to erasure</strong>
      </p>
      <p>
        You have the right to have your personal information deleted or removed
        if there is no reason for us to keep it. This is also known as 'the
        right to be forgotten'. There may be legal or other official reasons why
        we need to keep or use your personal information. Please see the 'How to
        contact us' section if you think we shouldn't be using it.
      </p>

      <p className="pt-2 mb-1">
        <strong>The right to restrict processing</strong>
      </p>
      <p>
        You have the right to restrict processing of your personal information.
        This means it can only be used for certain things, such as legal claims
        or to exercise legal rights. You can ask us to do so if your personal
        information is not accurate, has been used unlawfully, is not relevant
        anymore, or if you have already asked us to stop using your personal
        information but you are waiting for us to tell you if we are allowed to
        keep on using it. If we do restrict your personal information in this
        way, we won't use or share it in other ways while it is restricted.
        Please see the 'How to contact us' section to do this.{" "}
      </p>

      <p className="pt-2 mb-1">
        <strong>The right to data portability</strong>
      </p>
      <p>
        You have the right to get certain personal information from us as a
        digital file. This means you can keep and use it yourself, and give it
        to other organisations if you choose to. If you want, we will give it to
        you in an electronic format that can be easily re-used, or you can ask
        us to pass it on to other organisations for you. Please see the 'How to
        contact us' section to do this.
      </p>

      <p className="pt-2 mb-1">
        <strong>The right to object</strong>
      </p>
      <p>
        You have the right to object to us keeping or using your personal
        information. There may be legal or other official reasons why we need to
        keep or use your personal information. Please see the 'How to contact
        us' section if you think that we shouldn't be using it.
      </p>

      <p className="pt-2 mb-1">
        <strong>
          Rights in relation to automated decision making and profiling
        </strong>
      </p>
      <p>
        You have rights around automated decision making and profiling.
        Automated decision making means a decision made solely by automated
        means, without any human involvement. Profiling means the automated
        processing of your personal information to evaluate certain things about
        you. You have the right to information about these kinds of processing,
        and the right to ask for human intervention or to challenge an automated
        decision. You can do this when an automated decision is made about you,
        or you can see the 'How to contact us' section to speak to us about
        this. Please see the 'How we work out what marketing you receive'
        section for more information on profiling, and see the 'How we use your
        information to make automated decisions' section for more information on
        automated decision making.
      </p>

      <p className="pt-2 mb-1">
        <strong>The right to withdraw consent</strong>
      </p>
      <p>
        You have the right to withdraw your consent for us to use your personal
        information at any time. This will only affect the way we use personal
        information when our reason for doing so is that we have your consent.
        If you withdraw your consent, we may not be able to provide certain
        products or services to you. If this is so, we will tell you. You can
        manage your preferences for activities like marketing in a few ways. Use
        your internet or mobile banking applications, visit one of our branches,
        or see the 'How to contact us' section to speak to us about this.
      </p>

      <h3 className="pt-3 mb-1" id="How-To-Contact-Us">
        How To Contact Us
      </h3>
      <p>
        This section gives details of how to contact us about how we use your
        personal information. It also shows you where you can get in touch with
        the government regulator.
      </p>

      <p className="mb-1">
        <strong>
          If you have a question about how we use your personal information, or
          would like to speak to us about your data privacy rights
        </strong>
      </p>
      <p>
        If you have any questions, want more details about how we use your
        personal information, or would like to speak to us about one of your
        data privacy rights, you can call us on{" "}
        <Link to="tel:0345 603 8725">0345 603 8725</Link> (
        <Link to="tel:+44 345 603 8725">+44 345 603 8725</Link> from outside the
        UK). We may monitor or record your calls. Lines are open from 9 - 5pm
        Monday - Friday.{" "}
      </p>
      <p>
        If you have a hearing or speech impairment you can also call us using
        Relay UK. If you use a textphone, just add 18001 before the number
        you're calling. On a smartphone, tablet or computer, you can call
        through the Relay UK app. There's more information on the Relay UK help
        pages.
      </p>
      <p>
        If you're Deaf and a BSL user, you can talk to us live through a BSL
        interpreter online with our{" "}
        <a
          href="https://www.lloydsbank.com/contact-us/sign-video.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          BSL SignVideo service
        </a>
      </p>

      <p className="pt-2 mb-1">
        <strong>If you want a copy of your personal information</strong>
      </p>
      <p>
        To get a copy of all the personal information we hold about you, Lex
        Autolease Ltd, write to us at this address:{" "}
      </p>

      <div className="container-block-list">
        <p>Contract Hire Risk</p>
        <p>Lex Autolease Ltd</p>
        <p>Heathside Park</p>
        <p>Heathside Park Road</p>
        <p>Stockport</p>
        <p>Cheshire</p>
        <p>SK3 0RB</p>
      </div>

      <p className="pt-3 mb-1">
        <strong>
          If you would like to contact our Group Data Protection Officer
        </strong>
      </p>
      <p>
        You can contact our Group Data Protection Officer by writing to the
        following address:{" "}
      </p>

      <div className="container-block-list">
        <p>LBG Data Protection Officer</p>
        <p>Lloyds Banking Group</p>
        <p>PO BOX 548</p>
        <p>Leeds</p>
        <p>LS1 1WU</p>
      </div>

      <p className="pt-3 mb-1">
        <strong>
          If you are unhappy with how we have used your personal information
        </strong>
      </p>
      <p>
        If you are unhappy with how we have used your personal information, you
        can contact us at:
      </p>
      <div className="container-block-list">
        <p>Lex Autolease Ltd</p>
        <p>Heathside Park</p>
        <p>Heathside Park Road</p>
        <p>Stockport</p>
        <p>Cheshire</p>
        <p>SK3 0RB</p>
      </div>

      <p className="pt-3 mb-1">
        <strong>If you are not happy with the outcome of a complaint</strong>
      </p>
      <p>
        You also have the right to complain to the regulator, and to lodge an
        appeal if you're not happy with the outcome of a complaint.{" "}
      </p>
      <div className="container-block-list">
        <p>
          In the UK this is the Information Commissioner's Office. Find out on
          their website how to{" "}
          <a
            href="https://ico.org.uk/make-a-complaint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            report a concern
          </a>
          .
        </p>
        <p>
          In Jersey, please contact the{" "}
          <a
            href="https://jerseyoic.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Office of the Information Commissioner
          </a>
          .
        </p>
        <p>
          In Guernsey, please contact the{" "}
          <a
            href="https://dataci.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Office of the Data Protection Commissioner
          </a>
          .
        </p>
        <p>
          In Isle of Man, please contact the{" "}
          <a
            href="https://www.inforights.im/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Information Commissioner
          </a>
          .
        </p>
      </div>
    </FooterBasePage>
  );
};

export default PrivacyPolicyPage;
