import SessionWrapper from "./component/session-wrapper/SessionWrapper";
import LoadingSpinner from "./component/loading-spinner/LoadingSpinner";
import ScrollHelper from "./component/scroll-helper/ScrollHelper";
import ErrorBoundaryProvider from "./providers/ErrorBoundaryProvider";
import PageViewHelper from "./component/page-view-helper/PageViewHelper";
import UnsavedDataPromptHelper from "./component/unsaved-data-prompt-helper/UnsavedDataPromptHelper";

const queryParameters = new URLSearchParams(window.location.search);
let apiMode = window?.envConfig?.apiMode;
const queryApiMode = queryParameters.has("api")
  ? queryParameters.get("api").toLowerCase().trim()
  : "";
if (queryApiMode) {
  apiMode = queryApiMode;
}
if (apiMode !== "real") {
  import(
    /* webpackChunkName: "mockapi" */ "./api/mock-server/mockApiServer"
  ).then((module) => {
    const mockApiServer = module.default;
    mockApiServer.init();
  });
}

function App() {
  return (
    <ErrorBoundaryProvider>
      <UnsavedDataPromptHelper />
      <LoadingSpinner />
      <SessionWrapper />
      <ScrollHelper />
      <PageViewHelper />
    </ErrorBoundaryProvider>
  );
}

export default App;
