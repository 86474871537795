import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import EmptyStateIcon from "../../component/icons/EmptyStateIcon";
import ArrowDownIcon from "../../component/icons/ArrowDownIcon";
import Tile from "../../component/tile/Tile";
import MessageBox from "../../component/modals/message-box/MessageBox";
import { useOrderDocumentsAll } from "../../api/hooks/orders";
import { formatDate } from "../../utils/pages/date-format-utils";
import { openPdfDocument } from "../../utils/pdf/pdf-utils";
import { orderDetails } from "../../redux/features/order-review/orderReviewSlice";
import { getSanitisedErrorMessage } from "../../api/error-handling/transform-error";

const CurrentAndHistoricalOrders = ({
  ordersData,
  loadMoreOrders,
  maxOrdersLimit,
  ordersType = "current",
  isError,
  errorResponse,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [documentDetail, setDocumentDetail] = useState({});

  const onDocumentDownloaded = () => {
    setOpenPopup(true);
  };

  const {
    isDocumentError,
    errorResponse: documentErrorResponse,
    refetch,
    data: pdfContent,
  } = useOrderDocumentsAll(
    documentDetail?.orderId,
    documentDetail?.documentType,
    onDocumentDownloaded
  );

  const current = "current";
  const historical = "historical";

  useEffect(() => {
    if (documentDetail?.orderId) {
      setOpenPopup(false);
      refetch();
    }
  }, [refetch, documentDetail?.orderId]);

  useEffect(() => {
    if (isDocumentError) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [isDocumentError]);

  const openDownloadDocument = (buttonId) => {
    setOpenPopup(false);
    if (buttonId === 1 && pdfContent) {
      openPdfDocument(pdfContent, `ALL_${documentDetail?.orderId}.pdf`);
    }
    setDocumentDetail({});
  };

  const onClickNavigation = (orderId, ordersType) => {
    if (ordersType === current) {
      dispatch(orderDetails({ orderId: orderId }));
      navigate("./order-details");
    } else if (ordersType === historical) {
      setDocumentDetail({ orderId: orderId, documentType: "ALL" });
    }
  };

  return (
    <>
      {isDocumentError && (
        <Container>
          <Row>
            <Col>
              <Alert variant="danger">
                {getSanitisedErrorMessage(
                  documentErrorResponse?.localErrorMessage
                )}
              </Alert>
            </Col>
          </Row>
        </Container>
      )}
      {isError && (
        <Container>
          <Row>
            <Col>
              <Alert variant="danger">
                {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
              </Alert>
            </Col>
          </Row>
        </Container>
      )}

      {!isError &&
      ordersData &&
      Array.isArray(ordersData[ordersType]) &&
      ordersData[ordersType].length > 0 ? (
        <>
          <MessageBox
            title="Document ready"
            show={openPopup}
            onClose={openDownloadDocument}
            buttons={["Open", "Cancel"]}
          >
            Your document is ready. Please press "Open" to download and view it.
          </MessageBox>

          <Row className="my-3">
            {ordersData[ordersType]
              ?.slice(0, maxOrdersLimit)
              .map((order, innerIndex) => {
                return (
                  <Tile
                    key={order.orderId}
                    cardId={`${ordersType}OrderId-${innerIndex}`}
                    cardTitle={`${order?.manufacturer}`}
                    callBack={() =>
                      onClickNavigation(order?.orderId, ordersType)
                    }
                    cardLinkContent={
                      ordersType === current
                        ? "View order"
                        : ordersType === historical
                        ? "Download documents"
                        : ""
                    }
                    isLink={false}
                  >
                    <p className="text-uppercase">
                      {order?.vehicleDescription}
                    </p>
                    <p>
                      <strong>{order?.orderNumber}</strong>
                    </p>

                    {ordersType === "historical" && (
                      <p>
                        Date signed: {formatDate(order?.dateSigned, "ddMMyyyy")}
                      </p>
                    )}
                  </Tile>
                );
              })}
          </Row>
          {maxOrdersLimit <
            (ordersData &&
              Array.isArray(ordersData[ordersType]) &&
              ordersData[ordersType].length) && (
            <Row>
              <Col>
                <div className="mx-auto text-center">
                  <Button
                    id={`btn${ordersType}OrdersLoadMore`}
                    variant="link"
                    className="text-decoration-none text-primary my-3"
                    onClick={() => loadMoreOrders(ordersType)}
                  >
                    <h2 className="h3 p-0 m-0">Load more</h2>
                    <ArrowDownIcon title="Load more" size="xs" />
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Row>
          <Col className="mt-4 mb-5">
            <EmptyStateIcon
              title={`You have no ${ordersType} orders`}
              textContent={`You have no ${ordersType} orders`}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default CurrentAndHistoricalOrders;
