import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const DownloadIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    style={{
      verticalAlign: "text-top",
    }}
    role="img"
    viewBox="0 0 43 43"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M1 41.868h40.868V1H1z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M11.653 17.524h5.683V9h8.524v8.524h5.682l-9.935 9.952-9.954-9.952Zm0 15.476H31.56v-2.841H11.653V33Z"
      />
    </g>
  </svg>
);

DownloadIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { DownloadIcon };
export default DownloadIcon;
