import { useState, useEffect } from "react";
import { Alert, Row, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import MessageBox from "../../../component/modals/message-box/MessageBox";
import {
  reset,
  lastDocumentSigned,
} from "../../../redux/features/order-review/orderReviewSlice";
import { useOrderDocumentsAll } from "../../../api/hooks/orders";
import DownloadIcon from "../../../component/icons/DownloadIcon";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import { openPdfDocument } from "../../../utils/pdf/pdf-utils";
import magicStrings from "../../../utils/magic-string";
import { formatDate } from "../../../utils/pages/date-format-utils";
import useAuth from "../../../hooks/use-auth/useAuth";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const OrderSignedPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useAuth();

  const [openPopup, setOpenPopup] = useState(false);

  const onViewOrders = () => {
    dispatch(reset());
    navigate("/orders", { replace: true });
  };

  const orderIdDetails = useSelector(
    (state) => state?.orderReview?.orderDetails
  );
  const orderId = useSelector(
    (state) => state?.orderReview?.orderDetails?.orderId
  );

  const signedOrderDetails = useSelector(
    (state) => state?.orderReview?.signedOrderDetails
  );

  const {
    refetch,
    data: pdfContent,
    isDocumentError,
    errorResponse: documentErrorResponse,
  } = useOrderDocumentsAll(orderId, "ALL", () => {});

  useEffect(() => {
    if (!orderId) {
      dispatch(reset());
      navigate("/orders", { replace: true });
    }

    if (!orderId) {
      setOpenPopup(false);
    }
    dispatch(lastDocumentSigned(true));
  }, [orderId, dispatch, navigate, setOpenPopup]);

  const openDownloadDocument = (buttonId) => {
    setOpenPopup(false);
    if (buttonId === 1 && pdfContent) {
      openPdfDocument(pdfContent, `ALL_${orderId}.pdf`);
    }
  };

  const downloadDocument = () => {
    setOpenPopup(true);
    refetch();
  };
  return (
    <WizardBasePage pageTitle="Your document is now signed" pageSubTitle="">
      <TitleWithIcon
        title="Thank you for signing your documents"
        variant="success"
      />
      <MessageBox
        title="Document ready"
        show={openPopup}
        onClose={openDownloadDocument}
        buttons={["Open", "Cancel"]}
      >
        Your document is ready. Please press "Open" to download and view it.
      </MessageBox>

      <Row>
        <Col xs={12}>
          <h2 className="text-primary mt-8">Order summary</h2>
          <p className="mt-4">
            {" "}
            An email of your signed agreement has been sent to you. Ensure that
            you keep safe for future reference. You may also download a copy of
            your signed agreement from the historical section on the orders
            page. You can do this by{" "}
            <Link to="/orders">clicking the orders page here</Link>.
          </p>
          <p>
            Telling us about any accessibility needs, disabilities or other life
            events means we can make sure we're supporting you in the best way.
            If you'd like to share more about yourself with us, you can do this
            by <Link to="/help">clicking help and support here</Link>.
          </p>
        </Col>
        <Col xs={12}>
          <dl className="table-list-row">
            <Row>
              <dt>Full name</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {auth?.user?.firstName} {auth?.user?.lastName}
              </dd>
            </Row>
            <Row>
              <dt>Order number</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {orderIdDetails?.orderNumber ?? "N/A"}
              </dd>
            </Row>
            <Row>
              <dt>Vehicle description</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {orderIdDetails?.vehicleDescription ?? "N/A"}
              </dd>
            </Row>
            <Row>
              <dt>Date signed</dt>
              <dd className={`${magicStrings.maskInformation}`}>
                {signedOrderDetails?.dateSigned
                  ? formatDate(signedOrderDetails?.dateSigned, "ddMMyyyy")
                  : "N/A"}
              </dd>
            </Row>
          </dl>
        </Col>
      </Row>

      {isDocumentError && (
        <Alert variant="danger" className="mt-8">
          {getSanitisedErrorMessage(documentErrorResponse?.localErrorMessage)}
        </Alert>
      )}

      <Link
        onClick={() => {
          downloadDocument();
        }}
        className="mx-2"
        state={orderId}
      >
        <div className="my-4 d-flex flex-row flex-wrap align-items-center">
          <DownloadIcon className="text-primary" />
          <span className="ps-2">Download documents</span>
        </div>
      </Link>
      <hr />
      <CenterAlignedButtonContainer>
        <Button onClick={onViewOrders}>View orders</Button>
      </CenterAlignedButtonContainer>
    </WizardBasePage>
  );
};

export default OrderSignedPage;
