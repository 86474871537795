import BasePage from "../basepage/BasePage";
import PropTypes from "prop-types";

const TilesPlusMainContentBasePage = ({
  pageTitle,
  pageSubTitle,
  tilesContent,
  children,
}) => {
  return (
    <BasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      showTilesContainer={true}
      tilesContent={tilesContent}
    >
      {children}
    </BasePage>
  );
};

TilesPlusMainContentBasePage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  tilesContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  pageTitle: PropTypes.string.isRequired,
  pageSubTitle: PropTypes.string,
};

export default TilesPlusMainContentBasePage;
