import { useEffect } from "react";
import { Alert, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { useContractsForExistingVehicleCollections } from "../../../../api/hooks/contracts";
import BasePage from "../../../basepages/basepage/BasePage";
import Tile from "../../../../component/tile/Tile";
import { formatDate } from "../../../../utils/pages/date-format-utils";
import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import EmptyStateIcon from "../../../../component/icons/EmptyStateIcon";
import {
  isExisting,
  contractDetails,
  step,
  updatedCollectionDetails,
  reset,
} from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";
import magicStrings from "../../../../utils/magic-string";
import PageHeading from "../../../../component/page-heading/PageHeading";
import { getSanitisedErrorMessage } from "../../../../api/error-handling/transform-error";

const ExistingCollectionsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const { contracts, isError, errorResponse } =
    useContractsForExistingVehicleCollections();

  const onBookingSelected = (contractId) => {
    if (contractId && Array.isArray(contracts)) {
      const selectedContract = contracts.find(
        (c) => c.contractId?.toString() === contractId
      );
      if (
        selectedContract &&
        selectedContract.latestCollection &&
        selectedContract.latestCollection.collectionStatus?.toLowerCase() ===
          "scheduled"
      ) {
        dispatch(updatedCollectionDetails(null));
        dispatch(step(steps.existingBookings.summary));
        dispatch(isExisting(true));
        dispatch(contractDetails(selectedContract));
        navigate("./summary");
      }
    }
  };

  return (
    <BasePage
      pageTitle="Change a collection"
      pageSubTitle="Amend an existing vehicle collection booking."
    >
      <PageHeading>Your bookings</PageHeading>
      <p>
        You can change a booking up to 2 working days before the collection
        date. If you make changes to your arrangements within 2 working days of
        our visit, we may need to charge you.
      </p>

      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}

      {contracts && contracts.length > 0 && (
        <>
          <Row className="my-3">
            {contracts.map((c, index) => {
              return (
                <Tile
                  cardId={c.contractId.toString()}
                  key={index}
                  isLink={false}
                  cardLinkContent="View or amend details"
                  cardBorder={true}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={{ span: 5, offset: index % 2 === 0 ? 1 : 0 }}
                  isCardLinkDisabled={
                    !(
                      c?.latestCollection?.collectionStatus?.toLowerCase() ===
                      "scheduled"
                    )
                  }
                  callBack={onBookingSelected}
                >
                  <div className={`h3 mb-1 ${magicStrings.maskInformation}`}>
                    {c?.vehicle?.registrationNumber}
                  </div>
                  <div
                    className={`${magicStrings.maskInformation}`}
                  >{`${c?.vehicle?.make} ${c?.vehicle?.model}`}</div>
                  <dl className="row mt-3 mb-0">
                    <dt className="col-sm-6 text-sm-end">Collection date:</dt>
                    <dd
                      className={`col-sm-6 text-sm-start ${magicStrings.maskInformation}`}
                    >
                      {formatDate(c?.latestCollection?.collectionDate)}
                    </dd>
                    <dt className="col-sm-6 text-sm-end">Collection status:</dt>
                    <dd
                      className={`col-sm-6 text-sm-start ${magicStrings.maskInformation}`}
                    >
                      {c?.latestCollection?.collectionStatus?.toLowerCase() ===
                      "scheduled"
                        ? c.latestCollection.collectionStatus
                        : "Collected"}
                    </dd>
                  </dl>
                </Tile>
              );
            })}
          </Row>
          <hr />
        </>
      )}
      {!isError && contracts && contracts.length === 0 && (
        <>
          <br />
          <EmptyStateIcon
            className="my-3"
            title="No bookings"
            textContent="You currently have no bookings"
          />
        </>
      )}
      <CenterAlignedButtonContainer>
        <BackToHomeButton />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ExistingCollectionsPage;
