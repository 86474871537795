import { Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TilesBasePage from "../basepages/tiles-basepage/TilesBasePage";
import Tile from "../../component/tile/Tile";
import { useContracts } from "../../api/hooks/contracts";
import EmptyStateIcon from "../../component/icons/EmptyStateIcon";
import { getSanitisedErrorMessage } from "../../api/error-handling/transform-error";

const VehiclesPage = () => {
  const { contracts, isError, errorResponse } = useContracts();

  const navigate = useNavigate();

  const onClickNavigation = (contractId) => {
    navigate("./vehicle-description", { state: { contractId } });
  };

  return (
    <TilesBasePage
      pageTitle="View vehicles"
      pageSubTitle="View a summary of your current vehicles and contract details."
    >
      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}

      {contracts?.length > 0 ? (
        <Row className="my-3">
          {contracts?.map((detail) => {
            let contractId = "contractId-" + detail.contractId;
            return (
              <Tile
                key={detail.contractId}
                cardId={contractId}
                cardTitle={`${detail?.vehicle?.make} ${detail?.vehicle?.model}`}
                callBack={() => onClickNavigation(detail?.contractId)}
                cardLinkContent="View vehicle"
                isLink={false}
              >
                {detail?.vehicle?.registrationNumber}
              </Tile>
            );
          })}
        </Row>
      ) : (
        <Row>
          <Col className="mt-4 mb-5">
            <EmptyStateIcon
              title="No vehicles"
              textContent="You currently have no vehicles"
            />
          </Col>
        </Row>
      )}
    </TilesBasePage>
  );
};

export default VehiclesPage;
