import { useMutation } from "@tanstack/react-query";
import apiKeys from "../error-handling/api-keys";
import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";

const setUserCredentials = async (axios, payload) => {
  return axios
    .post("/auth/register/set-password", payload, {
      headers: attachApiKeyHeaderToRequest(apiKeys.profile),
    })
    .then((res) => {
      return res.data;
    });
};

const useSetUserCredentials = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation((payload) => setUserCredentials(axios, payload), {
    onSuccess: (data) => onSuccess(data),
    onError: (error) => {
      onError(error?.response?.data);
    },
  });
};

export { useSetUserCredentials };
