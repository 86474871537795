import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const ProfileIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    role="img"
    viewBox="0 0 16 16"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M8.5 8.5a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 1 0 0 6.5Zm0 1.625c-2.17 0-6.5 1.089-6.5 3.25V15h13v-1.625c0-2.161-4.33-3.25-6.5-3.25Z"
      opacity={0.8}
    />
  </svg>
);

ProfileIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
export { ProfileIcon };
export default ProfileIcon;
