import PropTypes from "prop-types";

const CenterAlignedButtonContainer = ({ children, className = "" }) => {
  return (
    <div
      className={`d-flex flex-column gap-3 col-12 col-lg-6 col-xl-5 my-5 mx-auto col-sm-8 ${className}`}
    >
      {children}
    </div>
  );
};
CenterAlignedButtonContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
export default CenterAlignedButtonContainer;
