import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, Alert } from "react-bootstrap";
import BasePage from "../basepages/basepage/BasePage";
import { setItem } from "../../storage/SessionStorage";
import AuthContext from "../../providers/auth-provider/AuthProvider";
import RegisterForm from "../../component/forms/register/RegisterForm";
import {
  useValidateRegistrationDetails,
  useValidateUserId,
} from "../../api/hooks/register";
import PageHeading from "../../component/page-heading/PageHeading";
import CenterAlignedButtonContainer from "../../component/center-aligned-button-container/CenterAlignedButtonContainer";

const RegisterPage = () => {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const setUserDetails = (data) => {
    setItem("user", data?.user);
    setItem("temp_email", data?.user?.email);
    setAuth({ user: data?.user, accessToken: data?.accessToken });
  };

  const onRegisterSuccessful = (data) => {
    let nextPage = "/";
    const status = data?.user?.status.toUpperCase();
    if (status === "PENDINGCHECK") {
      nextPage = "/iiq";
      setUserDetails(data);
    } else if (status === "PENDINGPASSWORD") {
      nextPage = "/user-credential";
      setUserDetails(data);
    } else if (status === "PENDINGIDENTITYCHECK") {
      nextPage = "/verification";
      setUserDetails(data);
    } else if (status === "OK") {
      nextPage = "/confirmation";
      setAuth({});
    }
    navigate(nextPage, { replace: true });
  };

  const onRegisterFailure = (error) => {
    setErrorMessage(error?.localErrorMessage);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const { mutate } = useValidateUserId(onRegisterSuccessful, onRegisterFailure);

  const { mutate: mutateRegister } = useValidateRegistrationDetails(
    onRegisterSuccessful,
    onRegisterFailure
  );

  const onRegister = (data) => {
    setErrorMessage("");
    data.isExistingUser === "yes"
      ? mutate({ userId: data.userId })
      : mutateRegister({
          fleetNumber: data.fleetNumber,
          vehicleRegistration: data.vehicleReg,
          dateOfBirth: `${data.dobYear}-${data.dobMonth}-${data.dobDay}`,
          firstName: data.firstName,
          lastName: data.lastName,
        });
  };

  return (
    <BasePage
      pageTitle="Manage My Vehicle"
      pageSubTitle="Sign your new vehicle agreement and schedule, view invoices, update personal details and more."
    >
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}
      <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <strong>Important information for business customers</strong>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              If you're trying to access the portal on behalf of your employer,
              you'll need to ask the account holder, director or partner of your
              business to set you up as a new user.
            </p>
            <p>
              They can set up a new user by calling{" "}
              <Link to="tel:0344 879 6633">0344 879 6633</Link>. They'll need
              these details on-hand for the call:
            </p>
            <ul>
              <li>Your full name.</li>
              <li>Your date of birth.</li>
              <li>The email address you'll be using for this account.</li>
            </ul>
            <p>
              Once they've set you up as a user, you can register by following
              the steps below.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <PageHeading align="center" className="mt-5">
        Do you have a user ID?
      </PageHeading>
      <p className="text-center m-0">
        If you have already been sent a user ID, select
        <strong> 'Yes, I have a user ID'.</strong>
      </p>
      <CenterAlignedButtonContainer className="mt-3 mb-0 col-sm-12">
        <RegisterForm onFormSubmit={onRegister} />
        <p className="mt-3 mb-0">
          Already have an account? <Link to="/login">Log in here</Link>
        </p>
        <p>
          Forgotten your details?{" "}
          <Link to="/forgot-login-details">Reset here</Link>
        </p>
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default RegisterPage;
