import { Link } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import BasePage from "../../basepages/basepage/BasePage";
import InfoIcon from "../../../component/icons/InfoIcon";
import { findOrdinalByDay } from "../../../utils/ordinal/ordinal-utils";
import NotificationBanner from "../../../component/notification-banner/NotificationBanner";
import BankAccountAndAddressDetails from "../../../component/payments/direct-debit/bank-account-and-address-details/BankAccountAndAddressDetails";
import { useDirectDebitMandate } from "../../../api/hooks/direct-debit";
import magicStrings from "../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const ExistingDirectDebitPage = () => {
  const { mandate, isError, errorResponse } = useDirectDebitMandate();

  return (
    <BasePage pageTitle="Manage Direct Debit">
      {isError ? (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      ) : (
        <>
          <NotificationBanner variant="info" size="lg">
            <div className="d-flex flex-row p-sm-2 p-md-4 p-lg-5">
              <div className="text-center me-3 mt-1">
                <InfoIcon title="Info Icon" className="text-info" size="md" />
              </div>
              <div className="ms-3">
                <div className="mb-2">
                  Your Direct Debit payments will be taken on the{" "}
                  <strong className={`${magicStrings.maskInformation}`}>
                    {findOrdinalByDay(mandate?.deferredDdDay)}
                  </strong>{" "}
                  of each month, or on the next working day after.
                </div>
                <div className="mt-3">
                  To change your collection date please contact us on{" "}
                  <Link to="tel:0800 302 9160">0800 302 9160</Link>.
                </div>
              </div>
            </div>
          </NotificationBanner>
          <h2 className="text-primary mb-0 mt-5 h3">Your bank details:</h2>
          <BankAccountAndAddressDetails
            savedBankAccountAndAddressDetails={mandate}
          />
          <div className="mt-5 col-12 col-md-7 col-lg-5 mx-auto">
            <Button
              as={Link}
              type="button"
              variant="outline-primary"
              id="btnChangeBankDetails"
              className="px-5 w-100 text-decoration-none"
              to="/payments/existing-direct-debit/change-bank-details"
            >
              Change bank details
            </Button>
          </div>
        </>
      )}
    </BasePage>
  );
};

export default ExistingDirectDebitPage;
