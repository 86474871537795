import { Link } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";

const CollectionInProgressPage = () => {
  return (
    <BasePage
      pageTitle="Book a collection"
      pageSubTitle="End a contract or return your vehicle earlier than planned."
    >
      <TitleWithIcon
        title="You've already asked us to collect this vehicle"
        variant="info"
      />

      <p className="mt-5">
        Do you want to make changes to this collection? You can cancel or change
        any booking up to 2 working days before the collection date, free of
        charge.
      </p>
      <p>
        Call us on <Link to="tel:0344 879 6633">0344 879 6633</Link>, and one of
        our advisers will set up the new collection details for you. Our phones
        are open Monday - Friday between 8:30am - 5:30pm. We're closed on
        weekends and bank holidays.
      </p>
      <p>
        If you ask us to make any changes within 2 working days of the date
        we're due to visit you, we may need to charge you for a cancelled
        collection.
      </p>

      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};
export default CollectionInProgressPage;
