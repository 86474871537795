import useAxiosPrivate from "../../hooks/use-axios-private/useAxiosPrivate";
import { useQuery, useMutation } from "@tanstack/react-query";
import queryKeys from "../react-query/queryKeys";
import queryClient from "../react-query/query-client";
import apiKeys from "../error-handling/api-keys";
import { attachApiKeyHeaderToRequest } from "../error-handling/error-structure/utils";

const getOrders = async (axios) => {
  return axios
    .get("/orders", {
      headers: attachApiKeyHeaderToRequest(apiKeys.orders),
    })
    .then((res) => {
      return res.data;
    });
};

const getOrder = async (axios, orderId) => {
  return axios
    .get(`/orders/${orderId}`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.orders),
    })
    .then((res) => {
      return res.data;
    });
};

const getOrderDocumentsAll = async (axios, documentOrderId, documentType) => {
  return axios
    .get(`/orders/${documentOrderId}/documents/${documentType}/download`, {
      headers: attachApiKeyHeaderToRequest(apiKeys.documents),
    })
    .then((res) => {
      return res.data;
    });
};

const signOrderDocument = async (axios, orderId, documentType, payload) => {
  return axios
    .post(`/orders/${orderId}/documents/${documentType}/sign`, payload, {
      headers: attachApiKeyHeaderToRequest(apiKeys.documents),
    })
    .then((res) => {
      return res.data;
    });
};

const useOrders = () => {
  const axios = useAxiosPrivate();
  const fallback = {};
  const {
    data = fallback,
    isError,
    error,
  } = useQuery(queryKeys.orders, () => getOrders(axios), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
  });
  return { orders: data, isError, errorResponse: error?.response?.data };
};

const useOrder = (orderId) => {
  const axios = useAxiosPrivate();
  const fallback = { isLoading: true };
  const {
    data = fallback,
    isError,
    error,
    refetch,
    isRefetching,
  } = useQuery(queryKeys.order(orderId), () => getOrder(axios, orderId), {
    enabled: orderId?.length > 0, // Restricts empty order ID search
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
  });
  return {
    order: data,
    isError,
    errorResponse: error?.response?.data,
    refetch,
    isRefetching,
  };
};

const useOrderDocumentsAll = (documentOrderId, documentType, onSuccess) => {
  const axios = useAxiosPrivate();
  const fallback = {
    content: "",
  };
  const {
    data = fallback,
    isError,
    error,
    refetch,
  } = useQuery(
    queryKeys.orderDocument(documentOrderId, documentType),
    () => getOrderDocumentsAll(axios, documentOrderId, documentType),
    {
      enabled: false,
      onSuccess: onSuccess,
    }
  );
  return {
    data: data?.content,
    isDocumentError: isError,
    errorResponse: error?.response?.data,
    refetch,
  };
};

const useSignOrderDocument = (onSuccess, onError) => {
  const axios = useAxiosPrivate();
  return useMutation(
    (payload) =>
      signOrderDocument(axios, payload.orderId, payload.documentType, {
        signatoryName: payload?.signature,
      }),
    {
      onSuccess: (data, payload) => {
        queryClient.resetQueries(queryKeys.orders);
        queryClient.resetQueries(queryKeys.documents);
        onSuccess(data, payload);
      },
      onError: (error) => {
        onError(error?.response?.data);
      },
    }
  );
};

export { useOrders, useOrder, useOrderDocumentsAll, useSignOrderDocument };
