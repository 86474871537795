import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import WizardBasePage from "../../basepages/wizard-basepage/WizardBasePage";
import ContractAmendsStepper from "../contract-amends-stepper/ContractAmendsStepper";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import steps from "../contract-amends-steps";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import QuoteDetailsPreview from "../../../component/contract-amends/quote-details-preview/QuoteDetailsPreview";
import ContractAmendmentsPreview from "../../../component/contract-amends/contract-amendments-preview/ContractAmendmentsPreview";
import CenterAlignedContainer from "../../../component/center-aligned-container/CenterAlignedContainer";
import { formatCurrency } from "../../../utils/pages/number-format-utils";
import ReviewApplicationForm from "../../../component/forms/contract-amendment/ReviewApplicationForm";
import useReviewApplication from "../hooks/useReviewApplication";
import useAuth from "../../../hooks/use-auth/useAuth";
import { useContractAmendSubmitProposal } from "../../../api/hooks/contracts";
import { step } from "../../../redux/features/contract-amends/contractAmendsSlice";
import PageHeading from "../../../component/page-heading/PageHeading";
import magicStrings from "../../../utils/magic-string";

const ReviewApplicationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useContractAmendsStepCheck(
    steps.newQuote.reviewApplication,
    "../financial-details"
  );

  const { auth } = useAuth();

  const { getApplicationPayload } = useReviewApplication();

  const savedAmendment = useSelector(
    (state) => state.contractAmends.proposedAmendment
  );

  // For cases where user directly lands on the page
  const isAmendmentValid = !!Object.keys(savedAmendment).length;

  const privateIndividual = useSelector(
    (state) => state.contractAmends.privateIndividual
  );

  const personalDetails = privateIndividual.personalDetails;
  const addressDetails = privateIndividual.addressDetails;
  const financialDetails = privateIndividual.financialDetails;
  const employmentDetails = privateIndividual.employmentDetails;

  const contract = useSelector((state) => state.contractAmends.contractDetails);

  const onEditDetails = (page) => {
    navigate(-1 * (4 - page.id));
  };

  const onSubmitProposalSuccessful = (data) => {
    if (data && data.caProposalDecisionStatus) {
      const decision = data.caProposalDecisionStatus.toLowerCase();
      if (decision === "accept") {
        dispatch(step(steps.applicationSuccess));
        navigate("../application-success");
      } else if (decision === "refer") {
        dispatch(step(steps.applicationReferred));
        navigate("../application-referred");
      } else if (decision === "decline") {
        dispatch(step(steps.applicationDeclined));
        navigate("../application-declined");
      } else {
        dispatch(step(steps.customerServices));
        navigate("../customer-services");
      }
    } else {
      dispatch(step(steps.customerServices));
      navigate("../customer-services");
    }
  };

  const onSubmitProposalFailure = () => {
    dispatch(step(steps.customerServices));
    navigate("../customer-services");
  };

  const { mutate } = useContractAmendSubmitProposal(
    onSubmitProposalSuccessful,
    onSubmitProposalFailure
  );

  const onFormSubmit = () => {
    const payload = getApplicationPayload(
      auth?.user?.counterParty?.code,
      privateIndividual
    );
    mutate({
      payload,
      contractId: contract.contractId,
      caNumber: savedAmendment.caNumber,
    });
  };

  return (
    <WizardBasePage
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
      wizardContent={<ContractAmendsStepper />}
    >
      <CenterAlignedContainer>
        <div className="text-center">
          <PageHeading>Review application</PageHeading>
          <p>
            Please read over your application and check all the details are
            correct.
          </p>
          <br />
          <div className="text-center">
            <div className="h3 fw-bold mb-0">Registration:</div>
            <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
              {contract?.vehicle?.registrationNumber}
            </div>
            <div className={`${magicStrings.maskInformation}`}>
              {contract?.vehicle?.description}
            </div>
            <div className="h3 fw-bold mt-4 mb-0">Total rental payment:</div>
            <div className={`display-6 mb-0 ${magicStrings.maskInformation}`}>
              {formatCurrency(savedAmendment?.proposedRentalPayment)}
            </div>
            <div>
              {savedAmendment?.vatInclusive ? "including VAT" : "excluding VAT"}
            </div>
          </div>
        </div>
      </CenterAlignedContainer>
      <br />

      {isAmendmentValid && (
        <>
          <QuoteDetailsPreview
            currentContract={contract}
            proposedAmendment={savedAmendment}
          />
          <ContractAmendmentsPreview
            personalDetails={personalDetails}
            financialDetails={financialDetails}
            employmentDetails={employmentDetails}
            addressDetails={addressDetails}
            onRouteToEdit={onEditDetails}
          />
        </>
      )}
      <br />
      <ReviewApplicationForm
        onFormSubmit={onFormSubmit}
        extraContent={
          <>
            <br />
            <h3 className="text-primary">What happens next?</h3>
            <p>
              We'll use the information you've provided to begin our credit
              searches and affordability assessment right away. Please wait a
              few minutes as we make our initial assessments. In most cases,
              we'll be able to give you an answer as soon as we've completed the
              assessments. However, in some cases, we may need to ask you for
              additional details to help process your request.
            </p>
          </>
        }
      />

      {/*  */}
    </WizardBasePage>
  );
};

export default ReviewApplicationPage;
