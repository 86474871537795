import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { onTrackPageNavigation } from "../../utils/analytics/analytics-utils";
import pageJSON from "../../utils/mmv-pages.json";

const PageViewHelper = () => {
  const [page, setPage] = useState(null);
  const location = useLocation();

  const getPageInformation = (pageData, url) => {
    let current_page = pageData.find((page) => page.path === url);
    let page_role = current_page ? current_page.page_role : "";
    let journey_name = current_page ? current_page.journey_name : "";
    let journey_step_name = current_page ? current_page.journey_step_name : "";
    return {
      url: url,
      role: page_role,
      journey: journey_name,
      step: journey_step_name,
    };
  };

  useEffect(() => {
    if (location && location.pathname !== page) {
      setPage(location.pathname);
      let pageData = pageJSON;
      let pageTrackingData = getPageInformation(pageData, location.pathname);
      window.setTimeout(() => {
        if (window?.document?.title) {
          window.document.title = `${
            window?.envConfig?.siteFriendlyName
              ? window.envConfig.siteFriendlyName
              : "Manage My Vehicle"
          } ${pageTrackingData.journey ? " | " : ""} ${
            pageTrackingData.journey
          }`;
        }
        onTrackPageNavigation(
          pageTrackingData.url,
          pageTrackingData.role,
          pageTrackingData.journey,
          pageTrackingData.step
        );
      }, 500);
    }
  }, [location, page]);
};

export default PageViewHelper;
