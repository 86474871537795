import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Questionnaire from "../../../../component/questionnaire/Questionnaire";
import WizardBasePage from "../../../basepages/wizard-basepage/WizardBasePage";
import { step } from "../../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import steps from "../../vehicle-collection-steps";
import CollectionStepper from "../../collection-stepper/CollectionStepper";
import useVehicleCollectionStepCheck from "../../hooks/useVehicleCollectionStepCheck";
import PageHeading from "../../../../component/page-heading/PageHeading";

const Question3Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useVehicleCollectionStepCheck(steps.roadWorthyQuestion3, "../question2");

  const onActionClicked = (result) => {
    dispatch(step(steps.roadWorthyQuestion4));
    if (result === 1) {
      navigate("../question4");
    } else if (result === 2) {
      navigate("../not-road-worthy");
    }
  };

  return (
    <WizardBasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
      wizardContent={<CollectionStepper />}
    >
      <PageHeading align="left">Arranging a collection</PageHeading>
      <p className="mb-5">
        We need to ask you some questions to check that your vehicle is
        roadworthy and ready to be collected. If a collection is arranged and
        the vehicle is unsafe to drive your collection will be cancelled and you
        will be charged a fee.
      </p>
      <h3>Question 3 of 6: Is the windscreen free from chips and cracks?</h3>

      <p className="pb-5">
        Any chips or cracks which are within the driver's eyeline, driver's
        wiper blade sweeping range or deemed unsafe to drive are not acceptable.
        Vehicles returned with chips or cracks outside of fair wear and tear
        standards may result in de-hire damage charges, please{" "}
        <a
          href="https://issuu.com/bfwsn67/docs/car_fwt_standard_2016?e=2001091/34033703"
          target="_blank"
          rel="noopener noreferrer"
        >
          refer to BVRLA
        </a>{" "}
        for further details.
      </p>
      <Questionnaire
        option1Title="Yes"
        option2Title="No"
        option1Content="The windscreen is free from chips and cracks."
        option2Content="The windscreen is not free from chips and cracks."
        option1ActionContent="Yes"
        option2ActionContent="No"
        actionCallback={onActionClicked}
      />
    </WizardBasePage>
  );
};

export default Question3Page;
