import { useEffect } from "react";
import { useDispatch } from "react-redux";
import BasePage from "../../basepages/basepage/BasePage";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import { reset } from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";

const CollectionSuccessPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <BasePage
      pageTitle="Book a collection"
      pageSubTitle="Arrange to return your vehicle at end of contract."
    >
      <TitleWithIcon title="Thanks for your request" variant="success" />

      <h2 className="text-primary h3 mt-5">Next steps</h2>
      <p>
        We've received your request and will email you to confirm the collection
        date.
      </p>
      <p>Please keep your vehicle fully insured until we collect it.</p>

      <h3 className="text-primary mt-4">Before we collect your vehicle</h3>
      <p>
        Please remove all personal items from the vehicle before we collect it -
        we're not able to return anything once we've taken the vehicle away. And
        go through the internal systems such as the Sat Nav and phonebook to
        delete any personal data off them.
      </p>
      <p>
        Please make sure that the following items are in your vehicle, before we
        collect it, so you aren't charged for missing items.
      </p>
      <ul>
        <li>Any accessories supplied with the vehicle on delivery.</li>
        <li>All keys, including spares.</li>
        <li>Wheel security key, if your vehicle has one.</li>
        <li>All vehicle operating manuals.</li>
        <li>
          Service/Maintenance book. If the service records are held
          electronically, please provide printed copies of maintenance records
          when returning the vehicle.
        </li>
        <li>Any charger leads, if your vehicle is electric.</li>
      </ul>

      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default CollectionSuccessPage;
