const pluralRules = new Intl.PluralRules("en-GB", { type: "ordinal" });

const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);

const findOrdinalByDay = (n) => {
  const rule = pluralRules.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export { findOrdinalByDay };
