import * as yup from "yup";

// Employment Details Schema

export const employmentStatusSchema = {
  employmentStatus: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .min(0, "Select a valid employment status.")
    .required("Employment status is required."),
};

export const occupationSchema = {
  occupation: yup
    .string()
    .required("Occupation is required.")
    .max(50, "Enter 50 or less characters for occupation."),
};

export const employerNameSchema = {
  employerName: yup
    .string()
    .required("Employer name is required.")
    .max(50, "Enter 50 or less characters for employer name."),
};

export const timeAtEmploymentSchema = {
  timeAtEmploymentYears: yup
    .number()
    .typeError("Please enter a valid time at employment.")
    .min(0, "Years in employment must be 0 or more.")
    .max(99, "Years in employment must be 99 or less.")
    .required("Please enter a valid time at employment.")
    .integer("Please enter a valid time at employment."),

  timeAtEmploymentMonths: yup
    .number()
    .typeError("Please enter a valid time at employment.")
    .min(0, "Months in employment must be 0 or more.")
    .max(11, "Months in employment must be 11 or less.")
    .required("Please enter a valid time at employment.")
    .integer("Please enter a valid time at employment."),
};
