import * as yup from "yup";

// Financial Details Schema

export const grossIncomeSchema = {
  grossIncome: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .min(0, "Gross annual income must be greater than or equal to 0.")
    .nullable()
    .required("Gross annual income is required."),
};

export const shareOfRentSchema = {
  shareOfRent: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .min(0, "Monthly mortgage/rent must be greater than or equal to 0.")
    .nullable()
    .required("Monthly mortgage/rent is required."),
};

export const shareOfChildCostsSchema = {
  shareOfChildCosts: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .min(
      0,
      "Childcare costs, spousal/child maintenance and school fees must be greater than or equal to 0."
    )
    .nullable()
    .required(
      "Childcare costs, spousal/child maintenance and school fees are required."
    ),
};

export const expectedChangesSchema = {
  expectedChanges: yup
    .number()
    .transform((value, originalvalue) => {
      return originalvalue === "" ? null : value;
    })
    .oneOf([1, 2], "Select a valid option.")
    .nullable()
    .required("Please select your option."),
};
export const descriptionExpectedSchema = {
  descriptionExpectedChanges: yup
    .string()
    .required("Expected changes detail is required.")
    .max(400, "Text can not exceed 400 characters."),
};
