import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import FooterBasePage from "../basepages/footer-basepage/FooterBasePage";
import PageHeading from "../../component/page-heading/PageHeading";

const FAQsPage = () => {
  return (
    <FooterBasePage
      pageTitle="Frequently Asked Questions"
      pageSubTitle="Help and support."
    >
      <PageHeading align="left">Frequently Asked Questions</PageHeading>
      <hr />

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            How do I set up a new account on Manage My Vehicle?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              If you signed your agreement online, setting up your Manage My
              Vehicle account is simple:
            </p>
            <ol className="ps-3">
              <li>
                Go to <Link to="/">Manage My Vehicle</Link> and select{" "}
                <strong>Register</strong>.
              </li>
              <li>
                Select <strong>No</strong> when it asks if you have a user ID.
              </li>
              <li>Enter your vehicle registration or fleet number.</li>
              <li>Enter your first name, family name and date of birth.</li>
              <li>Read the privacy terms and conditions.</li>
              <li>
                If you are happy to proceed, tick the box next to{" "}
                <strong>Accept privacy terms and conditions</strong>, then
                select <strong>Register</strong>.
              </li>
              <li>
                Enter the user ID we emailed you when you signed your agreement,
                create a password, then enter your email address. You can tick a
                box to use your email address as your username if you like.
              </li>
              <li>
                Enter the last four digits of the bank account number you'll use
                to pay your Direct Debit.
              </li>
              <li>
                You can now log in to <Link to="/login">Manage My Vehicle</Link>
                .
              </li>
            </ol>
            <p>
              You can change your user ID any time after you set up your Manage
              My Vehicle account.
            </p>
            <p>
              If you didn't sign your agreement online, you might not have a
              Manage My Vehicle profile.
            </p>
            <p>
              If you have a Personal Contract Hire agreement for more than one
              vehicle, you might need to set up a separate account for each one.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Why does Manage My Vehicle not recognise my details when I try to
            register?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              If there's an issue with your login details, it usually means that
              you've entered the wrong information.
            </p>
            <p>
              You should check you're entering the correct vehicle registration
              or fleet number and make sure that your personal details exactly
              match those on your agreement.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            What should I do if I forget my log in details?
          </Accordion.Header>
          <Accordion.Body>
            <p>It's easy to reset your login details if you forget them:</p>
            <p>
              Go to <Link to="/login">Manage My Vehicle</Link> and select{" "}
              <strong>Forgotten your details?</strong> and we'll send you a
              reset link.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            How do I log in to Manage My Vehicle?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              If you signed your vehicle agreement online, you should be able to
              log into Manage My Vehicle straightaway:
            </p>
            <ol className="ps-3">
              <li>
                Go to <Link to="/">Manage My Vehicle</Link> and select{" "}
                <strong>Login</strong>.
              </li>
              <li>
                Enter the user ID we emailed you when you signed your agreement.
                Check your junk folder if you can't find it.
              </li>
              <li>
                Enter your password. If you don't know it, select{" "}
                <strong>Forgotten your details?</strong> and we'll send you a
                link to reset.
              </li>
              <li>
                Select <strong>Submit</strong> to log in.
              </li>
            </ol>
            <p>
              You can change your user ID any time after you set up your Manage
              My Vehicle account.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>
            I requested a password reset but haven't received the email. Why is
            this?
          </Accordion.Header>
          <Accordion.Body>
            <p>There are a few things you can try:</p>
            <ul>
              <li>Check your email junk folder.</li>
              <li>
                Make sure you've entered the correct user ID. If it doesn't
                match the details on your account, we won't send a reset link.
              </li>
              <li>
                If you haven't set up your account yet, you won't receive a
                reset link. Not sure if you have an account? Go to{" "}
                <Link to="/">Manage My Vehicle</Link> and try to log in. If that
                doesn't work, you probably need to set up an account, so select{" "}
                <strong>Register</strong> and follow the instructions.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>
            I can't log in to my account. Why is this?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              If you're having issues logging in, there are a few things you can
              try:
            </p>
            <ul>
              <li>
                Check you completed the registration process. You might need to
                start again. Go to <Link to="/">Manage My Vehicle</Link> and
                select <strong>Register</strong>.
              </li>
              <li>
                If you have more than one vehicle registered, each one should
                have its own account and user ID. Check you are using the right
                one. If you don't know your user ID, log into{" "}
                <Link to="/login">Manage My Vehicle</Link>, select{" "}
                <strong>Forgotten your details?</strong>, then{" "}
                <strong>I have forgotten my user ID or alias</strong> and{" "}
                <strong>Submit</strong>.
              </li>
              <li>
                Check that the details you enter exactly match those shown on
                your agreement.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <p className="mt-5">
        If the above doesn't answer your query, we will be happy to help you.
        Call us on <Link to="tel:0344 879 6633">0344 879 6633</Link>.
      </p>
    </FooterBasePage>
  );
};

export default FAQsPage;
