import { Suspense, lazy } from "react";
import {
  isSupportedBrand,
  saveBrand,
  getSavedBrand,
  getDefaultBrand,
} from "../../utils/branding/brand-utils";

const queryParameters = new URLSearchParams(window.location.search);
let brandName = queryParameters.has("BrandId")
  ? queryParameters.get("BrandId").toLowerCase().trim()
  : "";

if (!brandName) {
  brandName = getSavedBrand();
}
if (!isSupportedBrand(brandName)) {
  brandName = getDefaultBrand();
}

saveBrand(brandName);

const CurrentTheme = lazy(() => import("../../styles/" + brandName + "/index"));

function renderLoader() {
  return (
    <div className="spinner-position">
      <div className="spinner-custom"></div>
      <h1 className="h3">Loading...</h1>
    </div>
  );
}

const ThemeProvider = (props) => {
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <CurrentTheme />
        {props.children}
      </Suspense>
    </>
  );
};

export default ThemeProvider;
