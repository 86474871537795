import { useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BasePage from "../../basepages/basepage/BasePage";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import steps from "../../vehicle-collection/vehicle-collection-steps";
import {
  reset,
  etQuoteAccepted,
} from "../../../redux/features/early-termination/earlyTerminationSlice";
import {
  step,
  contractDetails as vcContractDetails,
} from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";
import { useContracts } from "../../../api/hooks/contracts";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const ThankYouPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contracts, isError, errorResponse } = useContracts();

  const selectedContractId = useSelector(
    (state) => state.earlyTermination.contractDetails.contractId
  );

  useEffect(() => {
    dispatch(etQuoteAccepted(true));
    if (selectedContractId === "") {
      dispatch(reset());
      navigate("/early-termination");
    }
  }, [dispatch, navigate, selectedContractId]);

  const vcPreviousContractDetails = useSelector(
    (state) => state.vehicleCollection.contractDetails
  );

  const onClickHandler = () => {
    if (!isError) {
      const etSelectedContract = contracts.find(
        // eslint-disable-next-line
        (c) => c.contractId == vcPreviousContractDetails.contractId
      );
      dispatch(vcContractDetails(etSelectedContract));
      if (etSelectedContract?.currentCollectionStatus === "InProgress") {
        navigate("/vehicle-collection/in-progress");
      } else {
        dispatch(step(steps.roadWorthyQuestion1));
        navigate("/vehicle-collection/road-worthiness/question1");
      }
    }
  };

  return (
    <BasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
    >
      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}
      <TitleWithIcon
        title="Thanks for accepting your quote"
        variant="success"
      />

      <h3 className="text-primary pt-3 pb-1">What happens next?</h3>
      <p>
        All you need to do now is tell us when you'd like us to collect your
        vehicle. You can do this by selecting 'Book a collection' below. If you
        prefer, you can book your collection later by selecting 'Vehicle
        collection' on the homepage. When choosing your collection date, give
        yourself some extra time to check your vehicle and arrange for any
        damage to be fixed. This may help you avoid additional charges later on.
      </p>
      <p>
        You can ask us to collect your vehicle any day, Monday - Friday. We're
        closed on weekends and bank holidays.{" "}
        <strong>
          Please book at least 2 working days before your preferred collection
          date.
        </strong>{" "}
        It's best to arrange your collection as soon as possible so that we can
        try to visit on your preferred date. Thursdays and Fridays are our
        busiest days and dates can fill up quickly.
      </p>
      <p>
        If things change and you need to cancel or change your collection date,
        then you can make changes up to 2 working days before the date we're due
        to visit you. After this, we may need to charge you for a cancelled
        collection.
      </p>
      <h3 className="text-primary">After we've collected your vehicle</h3>
      <ul>
        <li>We'll send you your invoice for the Early Termination Charge.</li>
        <li>
          If you're behind with your payments or you've had a payment holiday,
          you'll need to pay the outstanding amount on top of your ETC. We don't
          include outstanding payments in your quote. You can find details of
          any outstanding invoices on the{" "}
          <Link to="/transactions">Transaction page</Link>.
        </li>
        <li>
          If you've used more than your agreed mileage allowance, or if there is
          any damage to the vehicle, we will send you separate invoices for
          these.
        </li>
      </ul>
      <p>
        Changed your mind? No problem. If you decide not to return your vehicle,
        you'll stay on your current contract and won't need to pay the Early
        Termination Charge.
      </p>
      <CenterAlignedButtonContainer>
        <Button
          id="btnBookACollection"
          className="text-decoration-none"
          disabled={isError}
          onClick={onClickHandler}
        >
          Book a collection
        </Button>
        <BackToHomeButton />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ThankYouPage;
