import { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import {
  PostCodeField,
  SelectAddressField,
  AddressLine1Field,
  AddressLine2Field,
  AddressLine3Field,
  CityField,
  CountyField,
} from "../fields";

const AddressLookup = ({
  addresses,
  refetchAddresses,
  addressDetails,
  isAddressFetchSucessful,
}) => {
  const methods = useFormContext();
  const {
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    resetField,
    formState,
  } = methods;
  const watchIsAddressManuallyEntered = watch("isAddressManuallyEntered");
  const watchPostcode = watch("postCode");
  const [hasSearched, setHasSearched] = useState(false);
  const [isNoResults, setIsNoResults] = useState(false);
  useEffect(() => {
    setHasSearched(false);
    if (formState.dirtyFields["postCode"] && !watchIsAddressManuallyEntered) {
      resetField("selectAddress", { defaultValue: "" });
      resetField("addressLine1", { defaultValue: "" });
      resetField("addressLine2", { defaultValue: "" });
      resetField("addressLine3", { defaultValue: "" });
      resetField("city", { defaultValue: "" });
      resetField("county", { defaultValue: "" });
    } else if (
      formState.dirtyFields["postCode"] &&
      watchIsAddressManuallyEntered
    ) {
      resetField("selectAddress", { defaultValue: "" });
    }
  }, [
    setValue,
    watchPostcode,
    formState.dirtyFields,
    resetField,
    watchIsAddressManuallyEntered,
  ]);

  useEffect(() => {
    if (addressDetails && !watchIsAddressManuallyEntered) {
      setValue("addressLine1", addressDetails.addressLine1);
      setValue("addressLine2", addressDetails.addressLine2);
      setValue("addressLine3", addressDetails.addressLine3);
      setValue("city", addressDetails.town);
      setValue("county", addressDetails.county);
    }
  }, [setValue, addressDetails, watchIsAddressManuallyEntered, addresses]);

  useEffect(() => {
    if (
      hasSearched &&
      (addresses && addresses.length) <= 0 &&
      !watchIsAddressManuallyEntered
    ) {
      setError("postCode", { type: "focus" }, { shouldFocus: true });
      if (!isNoResults) {
        setIsNoResults(true);
      }
    } else {
      if (isNoResults) {
        setIsNoResults(false);
      }
    }
  }, [
    hasSearched,
    setError,
    addresses,
    watchIsAddressManuallyEntered,
    isNoResults,
  ]);

  useEffect(() => {
    if (watchIsAddressManuallyEntered && isNoResults) clearErrors("postCode");
  }, [clearErrors, watchIsAddressManuallyEntered, isNoResults]);

  useEffect(() => {
    if (isAddressFetchSucessful) {
      setHasSearched(true);
    }
  }, [isAddressFetchSucessful]);

  const onAddressSearch = () => {
    if (watchPostcode) {
      refetchAddresses();
    }
  };

  return (
    <>
      <PostCodeField
        onAddressSearch={onAddressSearch}
        showSearchButton={!watchIsAddressManuallyEntered}
      />
      <Form.Group className="mb-3" controlId="acceptTerms">
        <Form.Check
          type="checkbox"
          label="Enter address manually"
          {...register("isAddressManuallyEntered")}
        ></Form.Check>
      </Form.Group>
      {!watchIsAddressManuallyEntered &&
      (formState.isSubmitted || (addresses && addresses.length > 0)) ? (
        <>
          <SelectAddressField
            addresses={
              addresses instanceof Array
                ? addresses.map((address) => ({
                    id: address.lineId,
                    value: address.lineId,
                    text: address.address,
                  }))
                : []
            }
          />
        </>
      ) : (
        isNoResults && (
          <Alert variant="danger">
            No addresses were found for your postcode. If the problem continues
            then please enter your address manually.
          </Alert>
        )
      )}

      {watchIsAddressManuallyEntered && (
        <>
          <AddressLine1Field />
          <AddressLine2Field />
          <AddressLine3Field />
          <CityField />
          <CountyField />
        </>
      )}
    </>
  );
};

export default AddressLookup;
