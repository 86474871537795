import { createSlice } from "@reduxjs/toolkit";
import revertAll from "../../actions/revert-all/revertAll";

const initialState = {
  activeStep: 1,
  contractDetails: {
    vehicle: {
      registrationNumber: "",
    },
    contractId: "",
  },
  etQuoteDetails: {
    quoteNumber: "",
  },
  etQuoteAccepted: false,
};

export const earlyTerminationsSlice = createSlice({
  name: "earlyTerminations",
  initialState,
  reducers: {
    contractDetails: (state, action) => {
      state.contractDetails = action.payload;
    },
    etQuoteDetails: (state, action) => {
      state.etQuoteDetails = action.payload;
    },
    prevStep: (state) => {
      state.activeStep -= 1;
    },
    nextStep: (state) => {
      state.activeStep += 1;
    },
    onClickStep: (state, action) => {
      state.activeStep = action.payload;
    },
    etQuoteAccepted: (state, action) => {
      state.etQuoteAccepted = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  activeStep,
  reset,
  contractDetails,
  etQuoteDetails,
  prevStep,
  nextStep,
  onClickStep,
  etQuoteAccepted,
} = earlyTerminationsSlice.actions;

export default earlyTerminationsSlice.reducer;
