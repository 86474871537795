import PropTypes from "prop-types";

const Divider = ({ children, type = "responsive", breakpoint = "md" }) => {
  const renderVertical = type === "vertical" || type === "responsive";
  const renderHorizontal = type === "horizontal" || type === "responsive";

  let horizontalClassName = "d-flex";
  let verticalClassName = "d-flex";
  if (type === "responsive") {
    switch (breakpoint) {
      case "sm":
        horizontalClassName = "d-flex d-sm-none";
        verticalClassName = "d-none d-sm-flex";
        break;
      case "md":
        horizontalClassName = "d-flex d-md-none";
        verticalClassName = "d-none d-md-flex";
        break;
      case "lg":
        horizontalClassName = "d-flex d-lg-none";
        verticalClassName = "d-none d-lg-flex";
        break;
      default:
        break;
    }
  }

  return (
    <>
      {renderVertical && (
        <div className={`${verticalClassName} flex-column h-100`}>
          <div className="flex-grow-1 text-center pb-1">
            <div className="vr h-100"></div>
          </div>
          <div className="text-center">{children}</div>
          <div className="flex-grow-1 text-center pt-1">
            <div className="vr h-100"></div>
          </div>
        </div>
      )}

      {renderHorizontal && (
        <div
          className={`${horizontalClassName} flex-row h-100 align-items-center`}
        >
          <div className="flex-grow-1">
            <hr className="me-2" />
          </div>
          <div className="text-center">{children}</div>
          <div className="flex-grow-1">
            <hr className="ms-2" />
          </div>
        </div>
      )}
    </>
  );
};

Divider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  breakpoint: PropTypes.oneOf(["sm", "md", "lg"]),
  type: PropTypes.oneOf(["responsive", "horizontal", "vertical"]),
};

export default Divider;
