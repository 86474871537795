import * as React from "react";
import PropTypes from "prop-types";
import { iconSizeSquare } from "./icons-utils";

const ArrowLeftIcon = ({ title, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSizeSquare(size)}
    height={iconSizeSquare(size)}
    aria-label={title}
    style={{
      verticalAlign: "text-top",
    }}
    role="img"
    viewBox="0 0 11 18"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m4.023 9.25 5.872 6.117c.187.188.187.375 0 .563L8.84 17.02c-.188.187-.375.187-.563 0L1.105 9.53A.38.38 0 0 1 1 9.25a.38.38 0 0 1 .105-.281L8.277 1.48c.164-.164.352-.152.563.036L9.895 2.57c.187.188.187.375 0 .563L4.023 9.25Z"
    />
  </svg>
);

ArrowLeftIcon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export { ArrowLeftIcon };
export default ArrowLeftIcon;
