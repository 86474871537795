import { useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BasePage from "../../basepages/basepage/BasePage";
import steps from "../contract-amends-steps";
import useContractAmendsStepCheck from "../hooks/useContractAmendsStepCheck";
import { pageTitle, pageSubTitle } from "../utils/contract-amends-page-utils";
import QuoteDetailsPreview from "../../../component/contract-amends/quote-details-preview/QuoteDetailsPreview";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import NotificationBanner from "../../../component/notification-banner/NotificationBanner";
import WarningOutlineIcon from "../../../component/icons/WarningOutlineIcon";
import { useContractAmendAgreement } from "../../../api/hooks/contracts";
import { formatDate } from "../../../utils/pages/date-format-utils";
import {
  contractAgreement as dispathContractAgreement,
  step,
  reset,
} from "../../../redux/features/contract-amends/contractAmendsSlice";
import PageHeading from "../../../component/page-heading/PageHeading";
import PaymentHolidayFinancialSupportTable from "../../../component/payment-holiday/PaymentHolidayFinancialSupportTable";
import { usePaymentHolidayFinancialSupport } from "../../../api/hooks/account";
import magicStrings from "../../../utils/magic-string";
import { getSanitisedErrorMessage } from "../../../api/error-handling/transform-error";

const ViewSummaryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useContractAmendsStepCheck(steps.viewSummary, "../application-success");

  const savedAmendment = useSelector(
    (state) => state.contractAmends.proposedAmendment
  );
  const contract = useSelector((state) => state.contractAmends.contractDetails);

  const { contractAmendAgreement, isError, errorResponse } =
    useContractAmendAgreement(contract?.contractId, savedAmendment?.caNumber);

  const { paymentHolidayFinancialSupport, isError: isPaymentHolidayError } =
    usePaymentHolidayFinancialSupport();

  const [showCollectionWarning] = useState(() => {
    let showWarning = false;
    if (
      contract &&
      Array.isArray(contract.collections) &&
      contract.collections.length > 0
    ) {
      const scheduledCollections = contract.collections.find((col) => {
        return (
          col?.collectionStatus &&
          col.collectionStatus.toUpperCase() === "SCHEDULED"
        );
      });
      if (scheduledCollections) {
        showWarning = true;
      }
    }
    return showWarning;
  });

  const onSignAgreement = () => {
    dispatch(step(steps.signAgreement));
    dispatch(dispathContractAgreement(contractAmendAgreement));
    navigate("../sign-agreement");
  };

  const onBackToHomeClicked = () => {
    dispatch(reset());
  };

  return (
    <BasePage pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <div className="text-center">
        <PageHeading>Sign agreement</PageHeading>

        <p>We've reviewed your application and are happy to accept.</p>
        <p>
          Once signed, we will create an invoice for your new contract on{" "}
          <span className={`${magicStrings.maskInformation}`}>
            {formatDate(contractAmendAgreement?.nextBillingDate)}.
          </span>
        </p>
      </div>

      <br />
      {showCollectionWarning && (
        <>
          <NotificationBanner variant="warning">
            <div className="p-5">
              <Row>
                <Col xs={3} md={2} lg={1}>
                  <WarningOutlineIcon className="text-warning" size="lg" />
                </Col>
                <Col xs={9} md={10} lg={11}>
                  <p>
                    <strong>
                      You have a scheduled collection for this vehicle.
                    </strong>
                  </p>
                  <p>
                    If you continue with your contract amend we will
                    automatically cancel this booking. Please note if the
                    collection date is within the next 48 hours you could be
                    charged an aborted collection fee in line with our T&C's.
                  </p>
                </Col>
              </Row>
            </div>
          </NotificationBanner>
          <br />
        </>
      )}
      <h2 className="h3 text-primary">Your new agreement</h2>
      <p>
        This is a summary of the changes you have requested to your contract.
        Please review this and ensure they are correct before selecting to sign
        your agreement.
      </p>
      <br />
      {isError && (
        <Alert variant="danger">
          {getSanitisedErrorMessage(errorResponse?.localErrorMessage)}
        </Alert>
      )}
      {!isError && contract && (
        <QuoteDetailsPreview
          currentContract={contract}
          proposedAmendment={savedAmendment}
        />
      )}

      {isPaymentHolidayError && (
        <Alert variant="warning">
          Sorry, we are unable to retrieve your payment holiday details at this
          time. Please try again later. Please note if you've had a payment
          holiday, you'll need to pay the outstanding amount in addition to any
          contract change or early termination charge.
        </Alert>
      )}

      {!isPaymentHolidayError &&
        paymentHolidayFinancialSupport?.totalAmount > 0 && (
          <section className="my-4">
            <h3 className="text-primary">
              You have a payment holiday on your account
            </h3>
            <p>
              Any payments accrued during, and included in, your payment holiday
              are not included in your above contract amendment.
            </p>
            <p>
              The balance remaining on your payment holiday will continue to be
              payable once you have returned the vehicle. This does not affect
              your right to make early repayments and details of how to do this
              can be found in the{" "}
              <Link to="/payments/payment-holidays">payment holiday page</Link>.
            </p>
            <PaymentHolidayFinancialSupportTable
              paymentHolidayFinancialSupportData={
                paymentHolidayFinancialSupport
              }
            />
          </section>
        )}

      <CenterAlignedButtonContainer>
        <Button
          onClick={onSignAgreement}
          id="btnContinueToSignAgreement"
          disabled={isError}
        >
          Continue to sign agreement
        </Button>
        <BackToHomeButton onButtonClicked={onBackToHomeClicked} />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default ViewSummaryPage;
