import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./providers/theme-provider/ThemeProvider";
import queryClient from "./api/react-query/query-client";
import { store } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";

import { AuthProvider } from "./providers/auth-provider/AuthProvider";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ReduxProvider store={store}>
              <App />
            </ReduxProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
