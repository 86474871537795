// To be used as anbsolute back for error messages.
const UNIVERSAL_ERROR_CODE = 98989898;

const globalErrorCodeMappings = {
  400: {
    default: UNIVERSAL_ERROR_CODE,
  },
  403: {
    default: UNIVERSAL_ERROR_CODE,
  },
  404: {
    default: UNIVERSAL_ERROR_CODE,
  },
  500: {
    default: UNIVERSAL_ERROR_CODE,
  },
  501: {
    default: UNIVERSAL_ERROR_CODE,
  },
  504: {
    default: UNIVERSAL_ERROR_CODE,
  },
  default: {
    default: UNIVERSAL_ERROR_CODE,
  },
};

export { globalErrorCodeMappings, UNIVERSAL_ERROR_CODE };
