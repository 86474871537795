import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import BackToHomeButton from "../../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import ThumbsUpIcon from "../../../../component/icons/ThumbsUpIcon";
import BasePage from "../../../basepages/basepage/BasePage";
import steps from "../card-payment-steps";
import { useEffect } from "react";

const NoPaymentRequiredPage = () => {
  const navigate = useNavigate();
  const currentStep = useSelector((state) => state.cardPayment.step);

  useEffect(() => {
    if (currentStep < steps.noPaymentRequired) {
      navigate("../invoice-selection", { replace: true });
    }
  }, [currentStep, navigate]);

  return (
    <BasePage pageTitle="Make a payment">
      <div className="text-center">
        <ThumbsUpIcon title="No payment required" size="xl" />
        <h2 className="text-secondary h1 mt-2">Good news!</h2>
        <hr />
        <p className="lead my-5">You're up to date with your payments.</p>
      </div>
      <CenterAlignedButtonContainer>
        <BackToHomeButton variant="primary" />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default NoPaymentRequiredPage;
