import { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import HeaderCookies from "./HeaderCookies";
import HeaderNavbar from "./HeaderNavbar";
import useAuth from "../../hooks/use-auth/useAuth";
import { getItem } from "../../storage/SessionStorage";

const Header = ({ showScrollProgress = false }) => {
  const { auth } = useAuth();
  let status = auth?.user?.status;
  let isLoggedIn = status ? true : false;
  const [isClose, setClose] = useState(() => {
    const cookiesDismiss = localStorage.getItem("cookies.dismiss");
    return cookiesDismiss !== null;
  });

  const [isCauraUser] = useState(() => {
    if (window?.envConfig?.isCauraEnabled === "1") {
      return getItem("isCaura") ? true : false;
    } else {
      return false;
    }
  });

  const toggleHandler = () => {
    localStorage.setItem("cookies.dismiss", true);
    const getItem = localStorage.getItem("cookies.dismiss");
    setClose(getItem);
  };

  // Scroll Progress Bar
  const [scrollProgress, setScrollProgress] = useState(0); // How much of the target element has been scrolled through

  useEffect(() => {
    const getScrollProgress = () => {
      const targetElement =
        document.getElementById("mainContainer") ??
        document.getElementsByTagName("body")[0];
      const scrollPosY = window.scrollY + window.innerHeight;
      setScrollProgress(
        ((scrollPosY - targetElement?.offsetTop) /
          targetElement?.offsetHeight) *
          100
      );
    };

    if (showScrollProgress) {
      window.addEventListener("scroll", getScrollProgress);
      window.addEventListener("resize", getScrollProgress);
    }

    return () => {
      window.removeEventListener("scroll", getScrollProgress);
      window.removeEventListener("resize", getScrollProgress);
    };
  }, [showScrollProgress]);

  return (
    <div className="sticky-top overflow-hidden">
      <header id="header">
        <HeaderCookies
          toggleCookiesHandler={toggleHandler}
          isClose={isClose}
          isLoggedIn={isLoggedIn}
        />
        <HeaderNavbar currentStatus={status} isCaura={isCauraUser} />
        {showScrollProgress && (
          <ProgressBar
            now={scrollProgress}
            label="Page scroll progress"
            visuallyHidden
          />
        )}
      </header>
    </div>
  );
};

export default Header;
