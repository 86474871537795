import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import BasePage from "../../basepages/basepage/BasePage";
import { reset } from "../../../redux/features/early-termination/earlyTerminationSlice";
import PageHeading from "../../../component/page-heading/PageHeading";

const IntroductionPage = () => {
  const dispatch = useDispatch();
  dispatch(reset());

  return (
    <BasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
    >
      <PageHeading className="h3" align="left">
        Ending a contract early
      </PageHeading>
      <p>
        If you'd like to send a vehicle back to us earlier than planned, you can
        book your return online. Or, if you'd prefer to speak to someone, call
        our Customer Service team on{" "}
        <Link to="tel:0344 879 6633">0344 879 6633</Link>. Lines are open Monday
        - Friday between 9am - 5pm. We're closed on weekends and bank holidays.
      </p>
      <p>
        In most cases, we apply a charge for ending your contract early and
        we'll let you know how much this is. You may need to pay other costs
        such as excess mileage and damage charges too. We'll let you know about
        these once you've returned your vehicle and we've carried out an
        assessment of it in line with fair wear and tear guidelines. If any
        charges apply, we'll invoice you separately for them.
      </p>
      <p>
        You can find your contract end date on the{" "}
        <Link to="/vehicles">Vehicle details</Link> page.
      </p>
      <h2 className="text-primary h3 mt-4">How ending a contract works</h2>
      <ol>
        <li>Choose the vehicle you want to return.</li>
        <li>
          We'll tell you if you need to pay a charge for ending your contract
          early.
        </li>
        <li>
          If you want to go ahead with the return, accept the charge and use our
          online booking tool to arrange this.
        </li>
      </ol>
      <h2 className="text-primary h3 mt-4">If you need more support</h2>
      <p>
        If you're thinking of returning a vehicle because you're finding it hard
        to keep up with the payments, we may have other options for you.{" "}
      </p>
      <p>
        Please call us on <Link to="tel:0800 302 9160">0800 302 9160</Link> to
        speak to one of our advisers before arranging to return your vehicle
        early.
      </p>
      <p>
        Our phone lines are open 8am - 5:30pm Monday - Friday. We're closed
        weekends and bank holidays.
      </p>
      <h2 className="text-primary h3 mt-4">
        Thinking of returning a vehicle early?
      </h2>
      <p>
        Select 'Get a quote' button and we'll tell you if you'll need to pay a
        charge.
      </p>
      <div className="d-flex flex-column gap-3 col-12 col-sm-8 col-lg-6 col-xl-5 mx-auto">
        <Button
          as={Link}
          to="/early-termination/new-quote/vehicle-selection"
          id="btnGetQuote"
          className="text-decoration-none"
        >
          Get a quote
        </Button>
        <Button
          as={Link}
          to="/early-termination/existing-quote/saved-quotes"
          id="btnSavedQuotes"
          variant="outline-primary"
          className="text-decoration-none"
        >
          Find my saved quotes
        </Button>
      </div>
    </BasePage>
  );
};

export default IntroductionPage;
