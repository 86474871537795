import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import { useEarlyTerminationAccept } from "../../../api/hooks/contracts";

const AcceptQuoteForm = ({ showErrorMessage }) => {
  const navigate = useNavigate();

  const etContractDetails = useSelector(
    (state) => state.earlyTermination.contractDetails
  );

  const schema = yup.object().shape({
    areTermsAccepted: yup.bool().oneOf([true], "Quote must be accepted."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const onUpdateSuccessful = () => {
    navigate("/early-termination/thank-you");
  };
  const onUpdateFailure = (error) => {
    showErrorMessage(error);
  };
  const { mutate } = useEarlyTerminationAccept(
    onUpdateSuccessful,
    onUpdateFailure
  );

  const onSubmit = () => {
    mutate({ contractId: etContractDetails.contractId });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate name="acceptQuoteForm">
      <Form.Group className="mb-3 me-3" controlId="acceptTerms">
        <Form.Check
          type="checkbox"
          label="I've read the important information and would like to accept the quote."
          {...register("areTermsAccepted")}
          isInvalid={errors.areTermsAccepted}
        />
        <Form.Control.Feedback
          type="invalid"
          style={
            errors.areTermsAccepted ? { display: "block" } : { display: "none" }
          }
        >
          {errors.areTermsAccepted?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <CenterAlignedButtonContainer>
        <Button id="btnEtNewQuoteAccept" variant="primary" type="submit">
          Accept quote
        </Button>
        <BackToHomeButton />
      </CenterAlignedButtonContainer>
    </Form>
  );
};

AcceptQuoteForm.propTypes = {
  showErrorMessage: PropTypes.func.isRequired,
};

export default AcceptQuoteForm;
