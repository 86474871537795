import { createPortal } from "react-dom";
import { Modal, Button } from "react-bootstrap";

const MessageBox = ({
  title,
  show,
  onClose,
  buttons,
  closeOnBackgroundClick = true,
  scrollable = true,
  size = "md",
  showCloseButton = false,
  isVerticallyCentered = true,
  children,
}) => {
  let dialogButtons = [];
  if (buttons && buttons.length >= 1) {
    dialogButtons.push({
      id: 1,
      variant: "primary",
      text: buttons[0],
    });
  }
  if (buttons && buttons.length === 2) {
    dialogButtons.push({
      id: 2,
      variant: "link",
      text: buttons[1],
    });
  }

  if (buttons && buttons.length === 3) {
    dialogButtons.push({
      id: 2,
      variant: "outline-primary",
      text: buttons[2],
    });
    dialogButtons.push({
      id: 3,
      variant: "link",
      text: buttons[2],
    });
  }

  if (dialogButtons.length === 0) {
    showCloseButton = true;
  } else {
    dialogButtons = dialogButtons.reverse();
  }
  let modalContainer = document.getElementById("modal");
  if (!modalContainer) {
    return null;
  }

  return createPortal(
    <Modal
      show={show}
      onHide={() => onClose(0)}
      centered={isVerticallyCentered}
      keyboard={closeOnBackgroundClick ? true : false}
      size={size}
      scrollable={scrollable}
      backdrop={closeOnBackgroundClick ? true : "static"}
    >
      <Modal.Header closeButton={showCloseButton}>
        <Modal.Title>
          <h3 className="h2 fw-bolder">{title}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">{children}</Modal.Body>
      {dialogButtons.length > 0 && (
        <Modal.Footer>
          {dialogButtons.map((b) => {
            return (
              <Button
                variant={b.variant}
                id={`btn_${b.id}`}
                key={b.id}
                onClick={() => onClose(b.id)}
              >
                {b.text}
              </Button>
            );
          })}
        </Modal.Footer>
      )}
    </Modal>,
    modalContainer
  );
};

export default MessageBox;
