const directDebitErrorMessages = {};
// ErrorCode - 1640000 & 1640001 - unlikely scenario but safer side we are handling here.
directDebitErrorMessages[`1640000`] =
  "Request parameters are missing or invalid format. Please try again.";
directDebitErrorMessages[`1640001`] =
  "Format or length of the data is invalid. Please try again.";

directDebitErrorMessages[`1650000`] =
  "Something went wrong while processing request. Please try again.";

directDebitErrorMessages[`1699901`] =
  "An unexpected error has occurred. Please try again.";

export default directDebitErrorMessages;
