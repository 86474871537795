import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import BasePage from "../../basepages/basepage/BasePage";
import TitleWithIcon from "../../../component/title-with-icon/TitleWithIcon";
import BackToHomeButton from "../../../component/back-to-home-button/BackToHomeButton";
import CenterAlignedButtonContainer from "../../../component/center-aligned-button-container/CenterAlignedButtonContainer";
import steps from "../../vehicle-collection/vehicle-collection-steps";
import {
  step,
  contractDetails,
} from "../../../redux/features/vehicle-collection/vehicleCollectionSlice";

const NotRequiredPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const etContractDetails = useSelector(
    (state) => state.earlyTermination.contractDetails
  );

  const onClickHandler = () => {
    if (etContractDetails?.currentCollectionStatus === "InProgress") {
      navigate("/vehicle-collection/in-progress");
    } else {
      dispatch(step(steps.roadWorthyQuestion1));
      dispatch(contractDetails(etContractDetails));
      navigate("/vehicle-collection/road-worthiness/question1");
    }
  };
  return (
    <BasePage
      pageTitle="Early termination"
      pageSubTitle="End a contract and return a vehicle earlier than planned."
    >
      <TitleWithIcon
        title="Your contract is already due to end"
        variant="info"
      />

      <h3 className="text-primary pt-3 pb-1">What happens next</h3>
      <p>
        As your contract is already due to end soon, you don't need an early
        termination. So if you're ready to return your vehicle, all you need to
        do is ask us to come and collect it. You can do this now by selecting
        'Book a collection' below, or at a later date by choosing 'Vehicle
        collection' on the Home screen.
      </p>
      <p>
        When choosing your collection date, give yourself some extra time to
        check your vehicle and arrange for any damage to be fixed. This may help
        you avoid additional charges later on.
      </p>
      <p>
        You can ask us to collect your vehicle any day, Monday to Friday, but{" "}
        <strong>
          please book at least 2 working days before your preferred collection
          date.
        </strong>{" "}
        We're closed on weekends and bank holidays. We recommend arranging your
        collection as soon as possible to make sure that we can visit on your
        preferred date. Thursdays and Fridays are our busiest days and dates can
        fill up quickly.
      </p>
      <p>
        If things change and you need to cancel or change your collection date,
        then you can make changes up to 2 working days before the date we're due
        to visit you. After this, we may need to charge you for a cancelled
        collection.
      </p>
      <h3 className="text-primary">After we've collected your vehicle</h3>
      <ul>
        <li>
          If you've used more than your agreed mileage allowance, or if there is
          any damage to the vehicle, we will send you separate invoices for
          these.{" "}
        </li>
        <li>
          You'll still need to pay your usual monthly rental amount for the
          month you return your vehicle.
        </li>
        <li>
          If you're behind with your payments or you've had a payment holiday,
          you'll need to pay the outstanding amount. You can find details of any
          outstanding invoices on the Transaction page.
        </li>
      </ul>
      <CenterAlignedButtonContainer>
        <Button
          id="btnBookAcollection"
          className="text-decoration-none"
          onClick={onClickHandler}
        >
          Book a collection
        </Button>
        <BackToHomeButton />
      </CenterAlignedButtonContainer>
    </BasePage>
  );
};

export default NotRequiredPage;
