import { Link } from "react-router-dom";
import BasePage from "../basepages/basepage/BasePage";
import PageHeading from "../../component/page-heading/PageHeading";

const MaintenanceTyrePage = () => {
  return (
    <BasePage
      pageTitle="Maintenance and tyres"
      pageSubTitle="Important information about your cover."
    >
      <PageHeading align="left">Maintenance</PageHeading>
      <h3 className="h4 mt-4">How do I know if I have maintenance cover?</h3>
      <p>
        If you have maintenance cover, you'll find it detailed in your contract
        document. If you're unsure whether you have it or not, contact us.
      </p>
      <p>
        Maintenance cover is optional and included at the start of your contract
        with us, as an additional cost that's part of your monthly rental
        payment.
      </p>
      <p>
        We can't add maintenance cover once your contract (lease) has started.
      </p>
      <h3 className="h4 mt-4">What's included in maintenance cover?</h3>
      <ul>
        <li>Routine servicing.</li>
        <li>Tyres.</li>
        <li>Replacement parts.</li>
        <li>Repair.</li>
        <li>Associated labour costs due to fair wear and tear.</li>
      </ul>
      <p>
        The vehicle must be serviced by an approved service dealer, following
        the manufacturer's guidelines. To find your local dealer please visit{" "}
        <a
          href="https://driver.lexautolease.co.uk/"
          rel="noopener noreferrer"
          target="_blank"
        >
          driver.lexautolease.co.uk.
        </a>
      </p>
      <h3 className="h4 mt-4">Not included in maintenance cover</h3>
      <ul>
        <li>
          Day-to-day consumables such as fuel, oil, anti-freeze, engine and
          battery fluids, and AdBlue.
        </li>
        <li>Sat-nav updates.</li>
        <li>
          Repairs as a result of driver misuse, abuse, or accident negligence.
        </li>
        <li>Items damaged, broken, stolen or vandalised.</li>
        <li>Failure occurring through driver-negligence or non-maintenance.</li>
      </ul>
      <h3 className="h4 mt-4">
        A warning light has come on in my car. What do I do?
      </h3>
      <p>
        Please contact your local franchised dealer and give them the details.
        They'll be able to give you an appointment based on what you need.
      </p>
      <h3 className="h4 mt-4">
        How do I know when my vehicle is due a service?
      </h3>
      <p>Please refer to your vehicle's handbook.</p>
      <p>
        Service intervals vary from manufacturer to manufacturer and are
        generally based on age and mileage. Many vehicles have a warning light
        on the dashboard to let you know a service is due.
      </p>
      <p>
        To maintain your warranty, it's essential to service your vehicle in
        line with the manufacturer's guidelines. Not doing so could incur extra
        costs for damage caused by missing a service.
      </p>
      <h3 className="h4 mt-4">
        My vehicle needs a service and/or maintenance. How do I arrange this?
      </h3>
      <p>
        For servicing and routine maintenance or repair, please contact your
        local franchised dealer directly. They will be able to arrange an
        appointment based on what you need.
      </p>
      <h3 className="h4 mt-4">Vehicle collection and delivery</h3>
      <p>
        Many dealers provide a collection and delivery service (depending on
        availability). Please speak to your franchised dealer when you call to
        make a booking, or alternatively ask about while-you-wait appointments.
      </p>
      <h3 className="h4 mt-4">What's excluded from my maintenance contract?</h3>
      <p>
        You're liable for any repairs resulting from damage, theft, vandalism,
        driver neglect, or misuse. We'll send you an invoice for these repairs
        and take payment via Direct Debit.
      </p>
      <h3 className="h4 mt-4">
        I'm not sure if I have maintenance cover. How do I find out?
      </h3>
      <p>
        Check your contract to see if you included maintenance cover when you
        took out the contract with us. If you're unsure,{" "}
        <Link to="/contact-us">contact us</Link>.
      </p>
      <h2>Tyres</h2>
      <h3 className="h4 mt-4">Who replaces my tyres?</h3>
      <p>
        Your tyres go through a lot on a daily basis, so it's inevitable they
        will pick up some wear and tear during your lease.
      </p>
      <p>
        At some point you will likely need to replace one or more of your tyres
        either because the tread has worn down or because the tyre shows signs
        of damage such as cracks and bulges.
      </p>
      <p>
        In the event that you need to replace any of your tyres on your lease
        vehicle, and you have our optional servicing and maintenance package,
        please get in contact with our tyre partner Kwik Fit. Kwik Fit are the
        leading fast-fit supplier of tyres in the country and carry stocks of
        over 600,000 products from leading manufacturers including Pirelli,
        Goodyear, Michelin, Continental and Dunlop with over 600 Centres in the
        UK. By using our approved tyre partner it's also unlikely that you'll
        have anything additional to pay.
      </p>
      <h3 className="h4 mt-4">
        What do I do if I don't have the optional servicing and maintenance
        package?
      </h3>
      <p>
        If you don't have our servicing and maintenance package and you need to
        replace any of your tyres you can select any provider you choose to
        change your tyres. Please be aware that you will need to pay for any
        tyre repairs as this is not covered as part of your agreement.
      </p>
      <p>
        If you're unsure whether you have our optional servicing and maintenance
        package please{" "}
        <a
          href="https://www.lexautolease.co.uk/lexautolease/business/contact-us"
          rel="noopener noreferrer"
          target="_blank"
        >
          get in touch
        </a>{" "}
        or check your original leasing agreement.
      </p>
      <h3 className="h4 mt-4">At what point do I need to replace my tyres?</h3>
      <p>
        Before Kwik Fit can replace a tyre as part of the maintenance package
        the tread depth must be no greater than 2mm, 0.4mm above the legal
        minimum requirement of 1.6mm per tyre. For those leasing directly
        through their employer certain criteria may differ from the above, for
        more information please contact your employer.
      </p>
      <h3 className="h4 mt-4">What times are Kwik Fit open? </h3>
      <p>Kwik Fit are open 7-days-a-week:</p>
      <ul>
        <li>Monday - Friday: 8.30am - 6pm.</li>
        <li>Saturday: 8:30am - 5pm.</li>
        <li>Sunday: 10am - 4pm.</li>
      </ul>
      <h3 className="h4 mt-4">How do I book?</h3>
      <p>
        To avoid potential delays we recommend using Kwik Fit's online booking
        tool before you visit a centre. Not only can you choose an appointment
        time to suit your needs, but Kwik Fit will also ensure the correct size
        tyres are in stock ready for your arrival.
      </p>
      <p>
        To proceed with booking your appointment you will require your vehicle
        registration number as well the tyre size reference. Your tyre size can
        be found on the sidewall of your current tyre and is a sequence of
        numbers and letters. The most common tyre size in the UK is 205/55R16
        but there are many variations so it's important to check your existing
        tyre or your vehicle handbook to ensure the right tyre size is going to
        be installed.
      </p>
      <p>
        Use the{" "}
        <a
          href="https://fleetbookings.kwik-fit.com/tyres?"
          rel="noopener noreferrer"
          target="_blank"
        >
          link here
        </a>{" "}
        to make a booking directly with Kwik Fit, or alternatively please ring
        the Kwik Fit booking line any time day or night on{" "}
        <Link to="tel:0330 123 1520">0330 123 1520</Link> to complete your
        booking.
      </p>
    </BasePage>
  );
};

export default MaintenanceTyrePage;
